import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer } from 'utils/redux-stateful';
import { GET_TEXTS } from './constants';

const stateReducer = createStatefulReducer(GET_TEXTS);
const actions = createStatefulActionTypes(GET_TEXTS);

const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
  }),
};

export const textsReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
