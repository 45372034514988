import { routerMiddleware } from 'connected-react-router';

import { composeStoreInitializer } from './core';
import { createRootReducer } from './reducers';
import { browserHistory } from './history';


export const initializeStore = composeStoreInitializer(
  createRootReducer(browserHistory),
  [routerMiddleware(browserHistory)],
);
