import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { Button } from 'components';
import { getSuccessfullyInfoPopup } from 'store/common/content/selectors';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const SuccessfullyInfoPopup = ({ content, onCloseClick }) => (
  <div className={cx('successWrapper')}>
    <div className={cx('successText')}>{content.heading}</div>
    <Button className="mart-m" isWide onClick={onCloseClick}>{content.buttonText}</Button>
  </div>
);

SuccessfullyInfoPopup.propTypes = {
  onCloseClick: PropTypes.func,
  content: PropTypes.object,
};

const mapStateToProps = state => ({
  content: getSuccessfullyInfoPopup(state),
});

export default connect(mapStateToProps)(SuccessfullyInfoPopup);
