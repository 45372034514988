import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getApiDocContent } from 'store/common/content';
import { getCompanyDetails } from 'store/features/company-details/action';
import classNames from 'classnames/bind';

import PageLayout from 'shared/PageLayout';
import { Heading } from 'components';
import { APIDoc } from './components/APIDoc';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const companyId = localStorage.getItem('companyId');

const APIDocPage = ({ isMobile, apiDocItems, fetchCompanyDetails }) => {
  useEffect(() => {
    fetchCompanyDetails(companyId);
  }, []);

  return (
    <PageLayout isWide>
      <div className={cx('title-wrapper')}>
        <Heading size={1} isMobile={isMobile}>API для интеграции</Heading>
      </div>

      <APIDoc shouldRender={!!apiDocItems.length} items={apiDocItems} isMobile={isMobile} />
    </PageLayout>
  );
};

APIDocPage.propTypes = {
  isMobile: PropTypes.bool,
  fetchCompanyDetails: PropTypes.func,
  apiDocItems: PropTypes.array,
};

const mapStateToProps = state => ({
  isMobile: isScreenBelow800(state),
  apiDocItems: getApiDocContent(state),
});

const mapDispatchToProps = {
  fetchCompanyDetails: getCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(APIDocPage);
