import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { sliceBy } from 'utils/fn';

import { FN_COMPANYGROUPS } from 'constants/fieldNames';

import { Text } from 'components';
import { RowItem, Row } from 'shared/Order/Grid';
import { FormDropDown } from './FormDropDown';
import { CompanyTypeHOC } from './CompanyTypeHOC';
import { FormInputsConnected } from './FormInputs';
import { FormTabs } from './FormTabs';

import { isRenderFieldsRows, sortFieldsRows } from '../helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const FormGroup = ({
  name,
  fields,
  alias,
  isIndividual,
  dictionaries,
  onFormChange,
  allFormValues,
}) => {
  const fieldsGroupInRows = sliceBy(
    fields,
    (field) => field.groupOrder ?? NaN, // fon NaN separate group
  );

  const renderRow = (groupAlias) => (row) => {
    if (isRenderFieldsRows(row, isIndividual)) {
      return (
        <Row key={row[0].alias}>
          {sortFieldsRows(row).map(renderRowField(groupAlias))}
        </Row>
      );
    }
    return null;
  };

  const renderRowField = (groupAlias) => (field) => {
    if (
      groupAlias === FN_COMPANYGROUPS.contactData &&
      field.isIndividual !== undefined &&
      isIndividual !== field.isIndividual
    ) {
      return null;
    }
    switch (field?.type) {
      case 'dropdown':
        return (
          <RowItem key={field?.alias} className={cx('dropdown-container')}>
            {field?.isExtended ? (
              <CompanyTypeHOC>
                <FormDropDown {...field} />
              </CompanyTypeHOC>
            ) : (
              <FormDropDown
                {...field}
                options={dictionaries[field.optionsKey]}
              />
            )}
          </RowItem>
        );
      case 'tab':
        return (
          <RowItem key={field?.alias}>
            <Text className={cx('tabs-label')}>{field.name}</Text>
            <FormTabs
              {...field}
              initialValue={allFormValues.gender}
              onTabChange={(v) => onFormChange({ ...allFormValues, gender: v })}
            />
          </RowItem>
        );
      default:
        return (
          <RowItem
            className={cx('rowItem', { narrow: field?.isNarrow })}
            key={field?.alias}
            isWide={field?.isWide}
            isNarrow={field?.isNarrow}
          >
            <FormInputsConnected
              {...field}
              // TODO: Пришлось...
              {...(isIndividual && field?.individualOptions
                ? field.individualOptions
                : field)}
            />
          </RowItem>
        );
    }
  };

  return (
    <div key={name}>
      <h3 className={cx('groupName')}>{name}</h3>
      {fieldsGroupInRows.map(renderRow(alias))}
    </div>
  );
};

FormGroup.propTypes = {
  name: PropTypes.string,
  fields: PropTypes.array,
  alias: PropTypes.string,
  isIndividual: PropTypes.bool,
  dictionaries: PropTypes.object,
  onFormChange: PropTypes.func,
  allFormValues: PropTypes.object,
};
