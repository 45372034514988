import React from 'react';
import { useActiveTabContext } from '../ActiveTabProvider/useActiveTabContext';

export const ActiveTabPanel = ({ index, children, Component, ...restProps }) => {
  const [activeTab] = useActiveTabContext();
  const isActive = activeTab === index;
  const childComp = typeof children === 'function' ? children(restProps) : children;
  const renderComponent = Component ? <Component {...restProps} /> : childComp;

  return isActive ? renderComponent : null;
};
