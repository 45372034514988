import { axiosAuthorizedRequest, axiosBaseRequest } from '../helpers';
import {
  getCreateOrderUrl,
  getIPListValidationUrl,
  getSaveOrderGroupFieldsUrl,
  getEmloyeeSaveOrderGroupFieldsUrl,
  getCheckUrlsUrl,
} from './constants';
import { mapHealthCheckResults } from '../mappers/createOrder';

export const createOrderRequest = ({ companyId }) => axiosAuthorizedRequest({
  url: getCreateOrderUrl({ companyId }),
  method: 'POST',
})
  .then(({ data }) => data);

export const saveOrderGroupFieldsRequest = ({
  alias,
  orderId,
  fields,
}) => axiosAuthorizedRequest({
  url: getSaveOrderGroupFieldsUrl(),
  method: 'POST',
  data: {
    fieldsGroupAlias: alias,
    customerFormId: orderId,
    fields,
  },
})
  .then(({ data }) => data);

export const saveEmployeeOrderGroupFieldsRequest = ({
  alias,
  orderId,
  fields,
}) => axiosAuthorizedRequest({
  method: 'POST',
  url: getEmloyeeSaveOrderGroupFieldsUrl(),
  data: {
    fieldsGroupAlias: alias,
    customerFormId: orderId,
    fields,
  },
})
  .then(({ data }) => data);

export const ipListValidationRequest = (ipList) => axiosAuthorizedRequest({
  method: 'POST',
  url: getIPListValidationUrl(),
  data: { ipList },
})
  .then(({ data }) => {
    if (!data.isValid) {
      throw new Error('ip address is not valid');
    }
  })
  .catch(() => Promise.reject({ IncomingIPAddresses: 'Некорректно введён(ы) IP адрес(а)' }));

export const appUrlsValidationRequest = (urls) => axiosAuthorizedRequest({
  method: 'POST',
  url: getCheckUrlsUrl(),
  data: urls,
}).then(({ data }) => mapHealthCheckResults(data));
