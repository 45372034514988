import { createStatefulActionTypes } from 'utils/redux-stateful';
import { createClaimIDRequest } from 'requests/api/claimID';
import { CREATE_CLAIM_ID, CLEAR_STATUS } from './constants';

const actions = createStatefulActionTypes(CREATE_CLAIM_ID);

export const createClientID = ({ formId }) => async (dispatch) => {
  dispatch({ type: actions.SET_PENDING });
  try {
    const result = await createClaimIDRequest({ formId });
    return dispatch({ type: actions.SET_SUCCESS, data: { ...result } });
  } catch (err) {
    return dispatch({ type: actions.SET_ERROR });
  }
};

export const clearStatus = () => ({ type: CLEAR_STATUS });
