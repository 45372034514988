import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT } from 'constants/validators';

import {
  getTabName,
  isShouldShowField,
  isShouldShowNotation,
} from 'utils/orders/helpers';
import { VIEW_TYPES } from 'utils/orders/constants';
import { sliceBy, compose } from 'utils/fn';
import { composeXSSValidator } from 'utils/field-validators';
import { useMemoOnce } from 'hooks';
import withIsMobile from 'enhancers/withIsMobile';

import { getIsAllOperators } from 'store/features/draft-order/selectors';
import { getIsEmployee } from 'store/features/auth/selectors';
import { getAllTexts } from 'store/common/texts/selectors';

import { Text, Button, Tabs, InfoTooltip } from 'components';
import {
  FinalForm,
  FFTextInput,
  FFPhoneInput,
  FFTextarea,
  FFDropDown,
} from 'components/FinalForm';
import Checkbox from 'components/Checkbox';
import commonStyles from 'styles/common.pcss';

import { Row, RowItem } from '../Grid';
import { Notification } from './Notification';
import {
  swapEnumItems,
  filterFields,
  mapFields,
  getInitialValues,
  getValidators,
  getFieldsDisplayValue,
  getExtSelectValue,
} from './helpers';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const OrderFormGroup = ({
  fields,
  onSubmit,
  alias,
  hiddenFields,
  submitError,
  isMobile,
  isFinalStep,
  isAllOperators,
  dictionary,
  isEmployee,
}) => {
  const initialValues = useMemoOnce(() =>
    getInitialValues(fields, hiddenFields));

  const fieldsInfo = useMemo(
    () => mapFields(filterFields(fields, hiddenFields)),
    [fields, hiddenFields],
  );

  const fieldsRows = sliceBy(
    fieldsInfo,
    (fieldInfo) => fieldInfo.group ?? NaN,
  ).sort((a, b) => a[0]?.groupOrder - b[0]?.groupOrder);

  const [isDraftSubmit, setIsDraftSubmit] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);

  const onTabChange = useCallback((itemAlias, val) => {
    setFormValues((prev) => ({ ...prev, [itemAlias]: val }));
  }, []);

  const onCheckboxChange = useCallback((field, val) => {
    if (field.alias === 'WorkScheme') {
      setFormValues((prev) => ({ ...prev, [field.alias]: val ? 'SI' : 'DI' }));
    } else {
      setFormValues((prev) => ({ ...prev, [field.alias]: val }));
    }
  }, []);

  const resolveIsChecked = (fieldAlias) => {
    if (fieldAlias === 'WorkScheme') {
      return formValues[fieldAlias] === 'SI';
    }

    return formValues[fieldAlias];
  };

  const getFieldNameByEmployee = useCallback(
    (field) => (isEmployee ? field.nameForEmployee : field.name),
    [isEmployee],
  );

  useEffect(() => {
    const fieldsDisplayValue = getFieldsDisplayValue(fields);
    setFormValues((prev) => ({ ...prev, ...fieldsDisplayValue }));
  }, []);

  return (
    <FinalForm
      isValidateOnSubmit
      values={formValues}
      onChange={setFormValues}
      getValidators={getValidators(fieldsInfo, formValues)}
      onSubmit={async (values) => {
        setIsDraftSubmit(false);

        if (!isFinalStep || isDraftSubmit) {
          Object.keys(values).forEach((key) => {
            if (
              isShouldShowField(
                { alias: key, isConditional: true },
                formValues,
              ) === false
            ) {
              values[key] = null;
            }
          });

          return onSubmit({ alias, values: getExtSelectValue(values, alias) });
        }

        // eslint-disable-next-line consistent-return
        return onSubmit({
          alias,
          isFinalSubmit: true,
          values: getExtSelectValue(values, alias),
        });
      }}
    >
      {fieldsRows.map((row) => {
        const filteredRow = row.filter((field) =>
            !field.isConditional || isShouldShowField(field, formValues));
        if (!filteredRow.length) return null;

        /* MOBID-1235 */
        if (row[0].alias === 'WorkScheme') {
          return null;
        }

        return (
          <Row key={row[0].alias}>
            {row.map((field) => {
              const withNotation = isShouldShowNotation(
                field,
                formValues,
                isAllOperators,
              );

              /* MOBID-1235 */
              if (field.alias === 'WorkScheme') {
                return null;
              }

              if (field.viewType === VIEW_TYPES.Phone) {
                return (
                  <RowItem
                    key={field.alias}
                    isWide={field.isWide}
                    className={cx('row-item')}
                  >
                    <Text className={cx('marb-xs')}>{field.name}</Text>
                    <FFPhoneInput
                      name={field.alias}
                      placeholder={field.placeholder}
                    />
                  </RowItem>
                );
              }

              if (field.viewType === VIEW_TYPES.Textarea) {
                return (
                  <RowItem
                    key={field.alias}
                    isWide={field.isWide}
                    className={cx('row-item')}
                  >
                    <FFTextarea
                      height="96px"
                      name={field.alias}
                      placeholder={field.placeholder}
                    />
                  </RowItem>
                );
              }

              if (
                field.viewType === VIEW_TYPES.ExtSelect ||
                field.viewType === VIEW_TYPES.Select
              ) {
                return (
                  <RowItem
                    key={field.alias}
                    isWide={field.isWide}
                    className={cx('row-item')}
                  >
                    <FFDropDown
                      name={field.alias}
                      options={field.options}
                      placeholder={field.placeholder}
                    />
                    {field.viewType === VIEW_TYPES.ExtSelect &&
                      formValues[field.alias]?.withExtension && (
                        <FFTextarea
                          height="96px"
                          maxLength="450"
                          name="ExtendedValue"
                          wrapperClass="mart-se"
                          placeholder={field.placeholder}
                          validate={composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT)}
                        />
                      )}
                  </RowItem>
                );
              }

              if (field.viewType === VIEW_TYPES.Tab) {
                swapEnumItems(field);
                const isInline = field.alias === 'ClientDataAccessLevel';

                return (
                  <RowItem key={field.alias} isWide className={cx('row-item')}>
                    {field.title && (
                      <>
                        <Text isTitle isLarge>
                          {field.title}
                        </Text>
                        <Text isSmall className={cx('mart-xxs')}>
                          {dictionary[field.description]}
                        </Text>
                      </>
                    )}
                    <Tabs
                      className={cx('mart-s', 'tabs', {
                        tabsQuater: field.enumItems.length >= 3,
                        inline: isInline,
                      })}
                      isStyled={field.enumItems.length < 3}
                      initiallyActive={formValues[field.alias] ?? null}
                      onTabChange={(tabAlias) =>
                        onTabChange(field.alias, tabAlias)
                      }
                      tabs={field.enumItems.map((enumItem) => {
                        const tooltip = field.tooltips?.find((t) => t.itemAlias === enumItem);
                        const info = field.info?.find((i) => i.itemAlias === enumItem);
                        return {
                          alias: enumItem,
                          children: (
                            <div className={cx('tab-content')}>
                              <Text>{getTabName(field.alias, enumItem)}</Text>
                              {info?.littleText && (
                                <Text className={cx('tabLittleText')}>
                                  {info.littleText}
                                </Text>
                              )}
                              {info && (
                                <Text
                                  isSmall
                                  isTitle
                                  className={cx('mart-xxs')}
                                >
                                  {dictionary[info.text]}
                                </Text>
                              )}

                              {/* quick fix */}

                              {dictionary[tooltip?.text] && (
                                <InfoTooltip className={cx('tab-tooltip')}>
                                  <div className={cx('tooltip-content')}>
                                    <Text isSmaller>
                                      {dictionary[tooltip.text]}
                                    </Text>
                                  </div>
                                </InfoTooltip>
                              )}
                            </div>
                          ),
                          className: cx('tab', {
                            quater: field.enumItems.length >= 3,
                            big: field.isBig,
                            lowPriority: enumItem === 'AuthOnly',
                          }),
                        };
                      })}
                    />
                    <FFTextInput hidden name={field.alias} />
                    {withNotation && (
                      <Notification
                        data={field.notation}
                        dictionary={dictionary}
                      />
                    )}
                  </RowItem>
                );
              }

              if (field.viewType === VIEW_TYPES.Checkbox) {
                return (
                  <RowItem isWide className={cx('row-item')} key={field.alias}>
                    <div className={cx('checkboxWrapper')}>
                      <Checkbox
                        onChange={(val) => onCheckboxChange(field, val)}
                        checked={resolveIsChecked(field.alias)}
                      />
                      <Text className={cx('checkboxText')}>{field.name}</Text>
                      {field.tooltip && (
                        <div>
                          <InfoTooltip className={cx('checkboxTooltip')}>
                            <div className={cx('tooltip-content')}>
                              {field.tooltip}
                            </div>
                          </InfoTooltip>
                        </div>
                      )}
                    </div>
                    {field.alias !== 'WorkScheme' && (
                      <div className={cx('notationWrapper')}>
                        {dictionary[field.checkboxNotation]}
                      </div>
                    )}
                    {withNotation && (
                      <Notification
                        data={field.notation}
                        dictionary={dictionary}
                      />
                    )}
                  </RowItem>
                );
              }

              return (
                <RowItem
                  key={field.alias}
                  className={cx('row-tem', 'text-field-row')}
                  isWide={field.isWide}
                >
                  <Text className={cx('marb-xs')}>
                    {getFieldNameByEmployee(field)}
                  </Text>

                  <FFTextInput
                    name={field.alias}
                    tooltip={field.tooltipMsg}
                    placeholder={field.placeholder}
                  />

                  {field.tooltip && dictionary[field.tooltip] && (
                    <InfoTooltip className={cx('input-tooltip')}>
                      <div className={cx('tooltip-content')}>
                        <Text isSmaller>{dictionary[field.tooltip]}</Text>
                      </div>
                    </InfoTooltip>
                  )}

                  {withNotation && (
                    <Notification
                      data={field.notation}
                      dictionary={dictionary}
                    />
                  )}
                </RowItem>
              );
            })}
          </Row>
        );
      })}
      {isFinalStep ? (
        <div className={cx('mart-m')}>
          <Row>
            <Button className={cx('final-btn')} isWide={isMobile} isSubmit>
              <Text isSmall={isMobile}>Подключить ресурс</Text>
            </Button>
            <Button
              className={cx({ 'marl-s': !isMobile, 'mart-xs': isMobile })}
              onClick={() => setIsDraftSubmit(true)}
              isWide={isMobile}
              isSubmit
              isSecondary
            >
              Сохранить черновик
            </Button>
          </Row>

          {submitError && (
            <Text isSmall className={cx('mart-xs', 'error')}>
              {submitError}
            </Text>
          )}
        </div>
      ) : (
        <div className={cx('mart-m', 'btn-wrap')}>
          <Button isSubmit className={cx('submit-btn')}>
            Сохранить
          </Button>
          {submitError && (
            <Text isInline isSmall className={cx('error')}>
              {submitError}
            </Text>
          )}
        </div>
      )}
    </FinalForm>
  );
};

OrderFormGroup.propTypes = {
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
  alias: PropTypes.string,
  hiddenFields: PropTypes.array,
  submitError: PropTypes.string,
  isFinalStep: PropTypes.bool,
  isMobile: PropTypes.bool,
  isEmployee: PropTypes.bool,
  isAllOperators: PropTypes.bool,
  dictionary: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  isAllOperators: getIsAllOperators(state),
  isEmployee: getIsEmployee(state),
  dictionary: getAllTexts(state),
});

export default compose(connect(mapStateToProps), withIsMobile)(OrderFormGroup);
