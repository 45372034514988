import React from 'react';

import PropTypes from 'prop-types';

import { Tabs, TextInput } from 'components';
import { Text } from 'components/Text';
import { getTabName, getValue } from 'utils/orders/helpers';

const FeeSection = ({
  fields,
  onChange,
  formValues,
  errors,
}) => (
  <div>
    {fields.map((field) => (
      <React.Fragment key={field.alias}>
        <Tabs
          initiallyActive={getValue(field)}
          onTabChange={(value) => {
            onChange({ value, fieldAlias: field.alias });
          }}
          tabs={field.enumItems.map((enumItem) => ({
            alias: enumItem,
            children: (
              <Text isSmall>{getTabName(field.alias, enumItem)}</Text>
            ),
          }))}
        />
        <TextInput
          hidden
          name={field.alias}
          error={errors[field.alias]}
          value={formValues[field.alias] ?? ''}
        />
      </React.Fragment>
      ))}
  </div>
);

FeeSection.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  formValues: PropTypes.object,
  errors: PropTypes.objectOf(PropTypes.string),
};

export default FeeSection;
