import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getLoginPage } from 'store/common/content';

import { childrenPropTypes } from 'types';

import { FinalForm, FFTextInput, FFPasswordInput } from 'components/FinalForm';
import { FormTemplate } from '../FormTemplate';

import { validators, FORM_FIELDS } from './helpers';

const EmployeeLoginComponent = ({ title, link, onClick, onSubmit, children }) => (
  <FormTemplate
    title={title}
    link={link}
    onClick={onClick}
  >
    <FinalForm
      isValidateOnSubmit
      onSubmit={onSubmit}
      getValidators={validators}
    >
      <FFTextInput name={FORM_FIELDS.login} placeholder="Логин" />
      <FFPasswordInput className="mart-xs" name={FORM_FIELDS.password} placeholder="Пароль" />

      {children}
    </FinalForm>
  </FormTemplate>
);

const mapStateToProps = (state) => ({
  title: getLoginPage(state).employeeLogin?.title,
  link: getLoginPage(state).employeeLogin?.link,
});

EmployeeLoginComponent.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  children: childrenPropTypes,
};

export const EmployeeLogin = connect(mapStateToProps)(EmployeeLoginComponent);
