import { authStatuses } from 'constants';
import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer, stateValues } from 'utils/redux-stateful';
import { FETCH_AUTH_STATUS, SET_AUTH_STATUS, SET_IS_EMPLOYEE } from './constants';

const stateReducer = createStatefulReducer(FETCH_AUTH_STATUS);
const fetchAuthStatusActions = createStatefulActionTypes(FETCH_AUTH_STATUS);
const reducers = {
  [fetchAuthStatusActions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [fetchAuthStatusActions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [fetchAuthStatusActions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [fetchAuthStatusActions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    status: action.data.isAuthed ? authStatuses.authed : authStatuses.notAuth,
  }),

  [SET_AUTH_STATUS]: (state, action) => ({
    state: stateValues.Success,
    status: action.isAuthed ? authStatuses.authed : authStatuses.notAuth,
  }),
  [SET_IS_EMPLOYEE]: (state, { data }) => ({
    ...state,
    isEmployee: data.isEmployee,
  }),
};
export const authReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
