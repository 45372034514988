import { useState } from 'react';
import { ActiveTabStateContext } from './context';

export function ActiveTabProvider({ children }) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <ActiveTabStateContext.Provider value={[activeTab, setActiveTab]}>
      {children}
    </ActiveTabStateContext.Provider>
  );
}