import { getIFrameSrc } from '../iframeParams';

const getWidgetFrame = () => document.getElementById('WP_Widget');

export const createPaymentMessage = (amount, ctn, hid = '000000000') => {
  getWidgetFrame().contentWindow.postMessage({
    type: 'createPayment',
    receiptEmail: '',
    receiptPhone: ctn,
    paymentPointId: `mobile|${hid}`,
    items: [{
      amount,
      account: ctn,
    }],
  }, getIFrameSrc());
};
