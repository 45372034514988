import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'components';

// import { openTopUpPopup } from 'store/features/user/action';
import { openSendInvoicePopup } from 'store/features/user/action';
import { hasActiveCompanyBan } from 'store/features/user/selectors';

import { compose } from 'utils/fn';
import { withRenderFlag } from 'enhancers/withRenderFlag';

const TopUpButtonComponent = ({ onButtonClick, children, className }) => (
  <Button
    isWide
    className={className}
    onClick={onButtonClick}
  >
    {children}
  </Button>
);

TopUpButtonComponent.propTypes = {
  className: PropTypes.any,
  onButtonClick: PropTypes.func,
};

const mapStateToProps = (state) => ({ shouldRender: hasActiveCompanyBan(state) });
// const mapDispatchToProps = { onButtonClick: openTopUpPopup };
const mapDispatchToProps = { onButtonClick: openSendInvoicePopup };

export const TopUpButton = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(TopUpButtonComponent);
