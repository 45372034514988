import { connect } from 'react-redux';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

export default function withIsMobile(Comp, { selectorFn = isScreenBelow800 } = {}) {
  const mapStateToProps = state => ({
    isMobile: selectorFn(state),
  });

  return connect(mapStateToProps)(Comp);
}
