import React, { useState, useRef, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Route } from 'react-router-dom';

import { useEventListener, useUnauthorizedZone, useNavigation } from 'hooks';

import { getIsEmployee } from 'store/features/auth/selectors';
import { getUserInfo, getSendInvoicePopupState } from 'store/features/user/selectors';
import { getNavItems } from 'store/common/navigation/selectors';
import { getHeader, getHeaderLogo } from 'store/common/content';
import { isScreenBelow1280, isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getCompanyBalanceFormatted } from 'store/features/company-details/selectors';
import { fetchUserInfo as fetchUserInfoAction, closeSendInvoicePopup } from 'store/features/user/action';

import SendInvoicePopup from 'pages/Main/components/SendInvoicePopup';
import { Text, OverlayMenu } from 'components';
import { MAIN_ROUTE } from 'constants/routes';
import { NavLink } from './components/NavLink';
import HeaderMenu from './components/HeaderMenu';
import { TopUpButton, LogoutButton } from './components/Buttons';

import Icon from './assets/arrow.svg';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const Header = ({
  isMobile,
  userInfo,
  logo,
  content,
  className,
  navigation,
  fetchUserInfo,
  companyBalance,
  isEmployee,
  showBurgerNavigation,
  isShowSendInvoicePopup,
  handleSendInvoicePopupClose,
}) => {
  const isUnauthorizedZone = useUnauthorizedZone();
  const dropdownRef = useRef();
  const [isMenuActive, setMenuActive] = useState(false);
  useNavigation();

  const getMainPageLink = useCallback((navItem) => (isEmployee
    ? (navItem.employeeLink || navItem.link)
    : navItem.link), [isEmployee]);

  const navLinks = (customClassName, large) => navigation.map((navItem) => (
    <Route
      path={getMainPageLink(navItem)}
      exact={navItem.link.length <= 5}
      key={navItem.order}
    >
      {({ match, location }) => {
        // TODO: переделать подсветку активного пункта меню
        const isOneOfItems = !!navigation.find((item) => item.link.length > 5 &&
          item.link.indexOf(location.pathname) === 0);

        const checkLink = (link) => match?.url === link
          || !!match && isOneOfItems
          || (match?.url !== link && !isOneOfItems && link === MAIN_ROUTE);

        return (
          <NavLink
            large={large}
            isActive={checkLink(getMainPageLink(navItem))}
            className={customClassName}
            {...navItem}
            link={getMainPageLink(navItem)}
          />
        );
      }}
    </Route>
  ));

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && isMenuActive && !dropdownRef.current.contains(e.target)) {
      setMenuActive(false);
    }
  };

  useEffect(() => {
    if (!isUnauthorizedZone) {
      fetchUserInfo();
    }
  }, [isUnauthorizedZone]);

  useEventListener('click', handleOutsideClick, document.body);

  return (
    <header className={cx('component', className, isMobile && 'mobile')}>
      {logo && (
        <div className={cx('logo')}>
          <img src={logo.image} alt="" className={cx('logoImg')} />
          {logo.text}
        </div>
      )}

      {isUnauthorizedZone ? null : (
        <>
          {!showBurgerNavigation && (
            <nav className={cx('navigation')}>
              {navLinks('marr-m')}
            </nav>
          )}

          <div className={cx('menu-container', 'right')}>
            {showBurgerNavigation && (
              <OverlayMenu
                button={(
                  <>
                    <hr className={cx('lay', 'marb-xxxs')} />
                    <hr className={cx('lay')} />
                  </>
                )}
                navigation={navLinks('marb-se', true)}
              />
            )}
            <div role="button" className={cx('userInfo', 'marr-m')} onClick={() => { setMenuActive(true); }}>
              <div>
                <Text className={cx('userName')}>
                  {userInfo?.userName}
                </Text>
                {companyBalance.isValidValue && (
                  <Text isSmall className={cx('balance')}>
                    {companyBalance.text}
                  </Text>
                )}
              </div>
              <button className={cx('arrow-button')}>
                <Icon className={cx('icon', { isMenuActive })} />
              </button>

              <HeaderMenu
                innerRef={dropdownRef}
                companies={userInfo?.availableCompanies}
                isMenuActive={isMenuActive}
                username={userInfo?.userName}
                className={cx({ withIndent: companyBalance.isValidValue })}
              >
                <div className="padv-m padh-m">
                  <TopUpButton className="marb-s">
                    {content.balanceButton}
                  </TopUpButton>
                  <LogoutButton>
                    {content.logoutButton}
                  </LogoutButton>
                </div>
              </HeaderMenu>
            </div>
          </div>
        </>
      )}
      {isShowSendInvoicePopup && (
        <SendInvoicePopup
          onPopupClose={handleSendInvoicePopupClose}
        />)
      }
    </header>
  );
};

Header.propTypes = {
  fetchUserInfo: PropTypes.func,
  isMobile: PropTypes.bool,
  isEmployee: PropTypes.bool,
  className: PropTypes.string,
  navigation: PropTypes.array,
  companyBalance: PropTypes.object,
  showBurgerNavigation: PropTypes.bool,
  userInfo: PropTypes.shape({
    userName: PropTypes.string,
    availableCompanies: PropTypes.array,
  }),
  content: PropTypes.object,
  logo: PropTypes.shape({
    text: PropTypes.string,
    image: PropTypes.string,
  }),
  isShowSendInvoicePopup: PropTypes.bool,
  handleSendInvoicePopupClose: PropTypes.func,
};

const mapStateToProps = state => ({
  userInfo: getUserInfo(state),
  isEmployee: getIsEmployee(state),
  navigation: getNavItems(state),
  isMobile: isScreenBelow800(state),
  showBurgerNavigation: isScreenBelow1280(state),
  companyBalance: getCompanyBalanceFormatted(state),
  logo: getHeaderLogo(state),
  content: getHeader(state),
  isShowSendInvoicePopup: getSendInvoicePopupState(state),
});

const mapDispatchToProps = {
  fetchUserInfo: fetchUserInfoAction,
  handleSendInvoicePopupClose: closeSendInvoicePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
