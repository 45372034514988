import Cookies from 'js-cookie';

import { createFormToSend } from 'utils/fn';
import { PAYMENT_MD_COOKIE } from 'constants/index';
import { axiosAuthorizedRequest } from '../helpers';
import {
  get3DSTermUrl,
  getUserInfoUrl,
  getFakeCtnListUrl,
  getPaymentStatusUrl,
  getProceedPaymentUrl,
} from './constants';
import { mapFakeCtnList, mapPaymentStatus, MOCK_RESPONSE } from '../mappers/user';

export const getUserInfoRequest = () => axiosAuthorizedRequest({
  url: getUserInfoUrl(),
})
  .then(({ data }) => data);

export const fetchUserCtn = (companyId) => axiosAuthorizedRequest({
  method: 'GET',
  url: getFakeCtnListUrl(companyId),
})
  .then(({ data }) => mapFakeCtnList(data))
  .catch(() => MOCK_RESPONSE);

export const paymentRequest = ({ acsUrl, paReq, md }) => {
  Cookies.set(PAYMENT_MD_COOKIE, md.trim(), { expires: 0.02 }); // 30 min

  createFormToSend({
    url: acsUrl,
    fields: {
      MD: md,
      PaReq: paReq,
      TermUrl: `${window.location.origin}${get3DSTermUrl()}`,
    },
  }).submit();
};

export const proceedPaymentRequest = () => axiosAuthorizedRequest({
  method: 'POST',
  url: getProceedPaymentUrl(),
  data: {
    md: Cookies.get(PAYMENT_MD_COOKIE),
    paRes: new URLSearchParams(window.location.search).get('paRes'),
  },
});

export const fetchPaymentStatus = (id) => {
  const paymentId = id || Cookies.get(PAYMENT_MD_COOKIE);

  return axiosAuthorizedRequest({
    method: 'GET',
    url: getPaymentStatusUrl(paymentId),
  })
    .then(({ data }) => mapPaymentStatus(data?.status));
};

