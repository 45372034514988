import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { getCompanyDetails } from 'store/features/company-details/action';
import { isScreenBelow1024 } from 'store/common/breakpoint/selectors';
import { getPluginsDoc } from 'store/common/content';

import { getNavigation, getPluginContent } from 'pages/PluginsPage/mappers';

import PageLayout from 'shared/PageLayout';
import { Navigation, Content, Menu } from './components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const companyId = localStorage.getItem('companyId');
const DEFAULT_PLUGIN_KEY = '1c';

const PluginsPage = ({ isMobile, pageContent, fetchCompanyDetails }) => {
  useEffect(() => {
    fetchCompanyDetails(companyId);
  }, []);

  const NavigationWrapper = isMobile ? Menu : Fragment;

  const [activePlugin, setActivePlugin] = useState(DEFAULT_PLUGIN_KEY);

  const content = useMemo(() => getPluginContent(pageContent, activePlugin), [activePlugin, pageContent]);

  return (
    <PageLayout className={cx('layout')} isFullSize>
      <div className={cx('component')}>
        <NavigationWrapper>
          <Navigation
            className={cx('navigation')}
            data={getNavigation(pageContent)}
            activePlugin={activePlugin}
            setActivePlugin={setActivePlugin}
          />
        </NavigationWrapper>

        <Content className={cx('content')} {...content} />
      </div>
    </PageLayout>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow1024(state),
  pageContent: getPluginsDoc(state),
});

const mapDispatchToProps = {
  fetchCompanyDetails: getCompanyDetails,
};

PluginsPage.propTypes = {
  isMobile: PropTypes.bool,
  fetchCompanyDetails: PropTypes.func,
  pageContent: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginsPage);
