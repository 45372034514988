import React, { useCallback } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setAmountValue, setModalStep } from 'store/features/user/action';
import { getAmountValue, getModalStep } from 'store/features/user/selectors';
import { isScreenBelow640 } from 'store/common/breakpoint/selectors';
import { MODAL_STEPS } from 'store/features/user/constants';
import { getTopUpCompanyBalance } from 'store/common/content';

import { IS_TEST, IS_UAT, HOMELESS_MODE_COOKIE } from 'constants/index';

import { composeNumberValidator } from 'utils/field-validators';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Button, Heading, Text } from 'components';
import { FinalForm, FFPriceInput } from 'components/FinalForm';

const AmountFormComponent = ({ isMobile, content, amount, onFormChange, toCardView }) => {
  const amountValidator = useCallback(composeNumberValidator(content.amountForm?.validationMsg, {
    /* Чтобы не разорять тестировщиков */
    min: (IS_TEST || IS_UAT) && Cookies.get(HOMELESS_MODE_COOKIE) ? 1 : 1000,
  }), [content]);

  return (
    <>
      <Heading size={4} className="left marb-s">
        {content.heading}
      </Heading>
      {content.amountForm?.description && (
        <Text isSmall className={`left ${isMobile ? 'marb-xs' : 'marb-se'}`}>
          {content.amountForm.description}
        </Text>
      )}
      <FinalForm
        values={{ amount }}
        onSubmit={toCardView}
        onChange={onFormChange}
        getValidators={() => ({ amount: amountValidator })}
      >
        <FFPriceInput name="amount" className="left" />
        <Button isSubmit isWide className={isMobile ? 'mart-xs' : 'mart-s'}>
          {content.amountForm?.buttonText}
        </Button>
      </FinalForm>
    </>
  );
};

AmountFormComponent.propTypes = {
  isMobile: PropTypes.bool,
  amount: PropTypes.number,
  toCardView: PropTypes.func,
  onFormChange: PropTypes.func,
  content: PropTypes.object,
};

const mapStateToProps = (state) => ({
  shouldRender: getModalStep(state) === MODAL_STEPS.amountForm,
  isMobile: isScreenBelow640(state),
  amount: getAmountValue(state),
  content: getTopUpCompanyBalance(state),
});

const mapDispatchToProps = {
  onFormChange: setAmountValue,
  toCardView: () => setModalStep(MODAL_STEPS.cardView),
};

export const AmountForm = connect(mapStateToProps, mapDispatchToProps)(withRenderFlag(AmountFormComponent));
