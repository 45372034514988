import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ConfirmPopup } from 'components/Popup';
import { Tab } from '../Tab';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Tabs = ({
  tabs,
  className,
  onTabChange,
  initiallyActive = tabs[0]?.alias,
  isStyled = true,
  beforeChangePopup = false,
  beforeChangePopupTexts,
}) => {
  const [activeTab, setActiveTab] = useState(initiallyActive);
  const [confirmPopupOpened, setConfirmPopupOpened] = useState(false);
  const [changeAlias, setChangeAlias] = useState('');

  useEffect(() => {
    setActiveTab(initiallyActive);
  }, [initiallyActive]);

  const changeHandler = (alias) => {
    setActiveTab(alias);
    onTabChange(alias);
    setConfirmPopupOpened(false);
  };

  const onTabClick = (alias) => {
    if (beforeChangePopup) {
      setChangeAlias(alias);
      setConfirmPopupOpened(true);
    } else {
      changeHandler(alias);
    }
  };

  return (
    <div className={cx({ styled: isStyled }, className)}>
      {tabs.map((tab) => (
        <Tab
          key={tab.alias}
          isWide={isStyled}
          className={cx({ styledTab: isStyled }, tab.className)}
          isActive={activeTab === tab.alias}
          onClick={() => onTabClick(tab.alias)}
          {...tab.props}
        >
          {tab.children}
        </Tab>
      ))}
      {beforeChangePopup &&
      <ConfirmPopup
        isOpen={confirmPopupOpened}
        failureText={beforeChangePopupTexts.failureText}
        confirmText={beforeChangePopupTexts.confirmText}
        onClose={() => setConfirmPopupOpened(false)}
        confirmEvent={() => changeHandler(changeAlias)}
        failureEvent={() => setConfirmPopupOpened(false)}
      >
        <span>{beforeChangePopupTexts.generalText}</span>
      </ConfirmPopup>
      }
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    alias: PropTypes.string.isRequired,
    className: PropTypes.any,
    props: PropTypes.object,
    children: PropTypes.any,
  })).isRequired,
  className: PropTypes.any,
  isStyled: PropTypes.bool,
  initiallyActive: PropTypes.string,
  onTabChange: PropTypes.func,
  beforeChangePopup: PropTypes.bool,
  beforeChangePopupTexts: PropTypes.shape({
    generalText: PropTypes.string,
    failureText: PropTypes.string,
    confirmText: PropTypes.string,
  }),
};
