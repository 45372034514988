import { composeRequiredValidator, composeXSSValidator } from 'utils/field-validators';
import { ENTER_THE_CORRECT_PASSWORD_TEXT, ENTER_THE_CORRECT_USERNAME_TEXT,
  FIELD_CANNOT_BE_EMPTY_TEXT } from 'constants/validators';

export const FORM_FIELDS = {
  login: 'login',
  password: 'password',
};

export const validators = () => {
  const isNotEmptyFn = composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT);

  return {
    [FORM_FIELDS.login]: [isNotEmptyFn, composeXSSValidator(ENTER_THE_CORRECT_USERNAME_TEXT)],
    [FORM_FIELDS.password]: [isNotEmptyFn, composeXSSValidator(ENTER_THE_CORRECT_PASSWORD_TEXT)],
  };
};
