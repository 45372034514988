import React from 'react';
import PropTypes from 'prop-types';

import { List } from '../List';
import { DescriptionText as Text } from '../Text';
import { DescriptionInput as Input } from '../Input';
import { DescriptionMultipleRadioButtons as MultipleRadioButtons } from '../RadioButton';
import HelpAndSupportButton from '../HelpAndSupportButton';

export const ContentMapper = ({ text, input, buttonText, list, multiple, radioButtons }) => (
  <>
    {text && (
      <Text isInline>
        {text}
      </Text>
    )}

    {input && (
      <Input {...input} />
    )}

    {radioButtons?.length && (
      <MultipleRadioButtons radioButtons={radioButtons} />
    )}

    {buttonText && (
      <HelpAndSupportButton>
        {buttonText}
      </HelpAndSupportButton>
    )}

    {list && (
      <List type={list.type}>
        {list.content.map((child, index) => (
          <ContentMapper key={index.toString()} {...child} />
        ))}
      </List>
    )}

    {multiple && multiple.map((child, index) => (
      <ContentMapper key={index.toString()} {...child} />
    ))}
  </>
);

ContentMapper.propTypes = {
  buttonText: PropTypes.string,
  text: PropTypes.string,
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    tooltip: PropTypes.bool,
  }),
  multiple: PropTypes.array,
  radioButtons: PropTypes.array,
  list: PropTypes.shape({
    type: PropTypes.string,
    content: PropTypes.array,
  }),
};
