import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { logOutRequest } from 'requests/api/auth';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const LogoutButton = ({ className, children }) => (
  <button className={cx('component', className)} onClick={logOutRequest}>
    {children}
  </button>
);

LogoutButton.propTypes = { className: PropTypes.any };
