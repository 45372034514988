import { composeLengthValidator } from 'utils/field-validators';
import { shallowEqual } from 'utils/fn';

export const formSpyHandler = ({ docId, docNo }, activeField, checkPassport, prevValues) => {
  if (activeField === 'docId' || activeField === 'docNo') {
    const isValidSeries = !composeLengthValidator(true, { min: 4 })(docId ?? '');
    const isValidNumber = !composeLengthValidator(true, { min: 6 })(docNo ?? '');
    const isEqualValues = shallowEqual({ docId, docNo }, prevValues);

    if (isValidSeries && isValidNumber && !isEqualValues) {
      checkPassport(docId, docNo);
      prevValues.docId = docId;
      prevValues.docNo = docNo;
    }
  }
};
