import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { FN_COMPANY } from 'constants/fieldNames';
import { withRenderFlag } from 'enhancers/withRenderFlag';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AssociatedCompany = ({ associatedCompany }) => (
  <>
    <div className={cx('row')}>
      <div className={cx('field-name')}>
        <div className={cx('field-text')}>Название компании</div>
      </div>
      <div className={cx('field-value')}>
        <div className={cx('field-text')}>{associatedCompany.name}</div>
      </div>
    </div>
    <div className={cx('row')}>
      <div className={cx('field-name')}>
        <div className={cx('field-text')}>ИНН компании</div>
      </div>
      <div className={cx('field-value')}>
        <div className={cx('field-text')}>
          {associatedCompany[FN_COMPANY.inn]}
        </div>
      </div>
    </div>
  </>
);

AssociatedCompany.propTypes = {
  associatedCompany: PropTypes.shape({
    name: PropTypes.string,
    [FN_COMPANY.inn]: PropTypes.string,
  }),
};

export default withRenderFlag(AssociatedCompany);
