import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import iuliia from 'iuliia';

import { fetchBankSuggestion, fetchCitySuggestion } from 'requests/api/company';
import { composeLengthValidator } from 'utils/field-validators';
import {
  loadCompanyDataByINN,
  setCitySelected,
  setFormValuesByBankSuggestion,
  setFormValuesByCitySuggestion,
} from 'store/features/companyForm/actions';
import { getCitySelected, getIsIndividualEntrepreneur } from 'store/features/companyForm/selectors';

import { Text } from 'components';
import {
  FFDateInput,
  FFPhoneInput,
  FFTextInput,
  FFINNInput,
  FFPassportInput,
  FFOGRNInput,
  FFTextInputSuggestion,
} from 'components/FinalForm';
import { FN_COMPANY, FN_USER } from 'constants/fieldNames';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const FormInputs = ({
  type,
  name,
  alias,
  placeholder,
  isIndividual,
  isSelected,
  onCitySelect,
  onBankInputChange,
  onCityInputChange,
  fetchCompanyDataSuggestion,
}) => {
  const onINNInputChange = useCallback(
    (value) => {
      const validationResult = composeLengthValidator('not valid', {
        min: isIndividual ? 12 : 10,
      })(value);

      if (typeof validationResult !== 'string') {
        fetchCompanyDataSuggestion(value);
      }
      return value;
    },
    [isIndividual, fetchCompanyDataSuggestion],
  );

  const onCompanyNameEnChange = useCallback(
    (value) => iuliia.translate(value || '', iuliia.ICAO_DOC_9303),
    [],
  );

  let FieldComponent = <FFTextInput name={alias} placeholder={placeholder} />;

  if (type === 'phone') {
    FieldComponent = <FFPhoneInput name={alias} placeholder={placeholder} />;
  }

  if (alias === 'bankName') {
    FieldComponent = (
      <FFTextInputSuggestion
        name={alias}
        placeholder={placeholder}
        suggestionsFn={fetchBankSuggestion}
        externalHandleChange={onBankInputChange}
      />
    );
  }

  if (alias === 'city') {
    FieldComponent = (
      <FFTextInputSuggestion
        name={alias}
        placeholder={placeholder}
        suggestionsFn={fetchCitySuggestion}
        externalHandleChange={onCityInputChange}
        preventCustomChange
        onSelect={onCitySelect}
        isSelected={isSelected}
      />
    );
  }

  if (type === 'date') {
    FieldComponent = <FFDateInput name={alias} placeholder={placeholder} />;
  }

  if (alias === FN_USER.docId) {
    FieldComponent = (
      <FFPassportInput.Series name={alias} placeholder={placeholder} />
    );
  }

  if (alias === FN_USER.docNo) {
    FieldComponent = (
      <FFPassportInput.Number name={alias} placeholder={placeholder} />
    );
  }

  if (type === FN_COMPANY.ogrn || type === FN_COMPANY.ogrnip) {
    FieldComponent = (
      <FFOGRNInput
        name={alias}
        placeholder={placeholder}
        isIndividual={isIndividual}
      />
    );
  }

  if (type === FN_COMPANY.inn) {
    FieldComponent = (
      <FFINNInput
        name={alias}
        placeholder={isIndividual ? '012345678912' : placeholder}
        isIndividual={isIndividual}
        onChangeProxy={onINNInputChange}
      />
    );
  }

  if (alias === FN_COMPANY.nameEn) {
    FieldComponent = (
      <FFTextInput
        name={alias}
        placeholder={placeholder}
        onChangeProxy={onCompanyNameEnChange}
      />
    );
  }

  return (
    <>
      {name && <Text className={cx('fieldName')}>{name}</Text>}
      {FieldComponent}
    </>
  );
};

FormInputs.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  alias: PropTypes.string,
  isSelected: PropTypes.bool,
  isIndividual: PropTypes.bool,
  placeholder: PropTypes.string,
  onBankInputChange: PropTypes.func,
  onCityInputChange: PropTypes.func,
  onCitySelect: PropTypes.func,
  fetchCompanyDataSuggestion: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isIndividual: getIsIndividualEntrepreneur(state),
  isSelected: getCitySelected(state),
});

const mapDispatchToProps = {
  onCitySelect: setCitySelected,
  onBankInputChange: setFormValuesByBankSuggestion,
  onCityInputChange: setFormValuesByCitySuggestion,
  fetchCompanyDataSuggestion: loadCompanyDataByINN,
};

export const FormInputsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormInputs);
