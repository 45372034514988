import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer } from 'utils/redux-stateful';
import {
  FETCH_USER_INFO,
  SET_USER_INFO,
  TOGGLE_TOP_UP_POPUP,
  TOGGLE_SEND_INVOICE_POPUP,
  SET_AMOUNT_VALUE,
  SET_USER_CTN,
  SET_TRANS_ID,
  SET_POPUP_STEP,
  SET_MAKE_PROCEED_PAYMENT_REQUEST, SET_USER_ACCESS_LEVEL,
} from './constants';

const stateReducer = createStatefulReducer(FETCH_USER_INFO);
const actions = createStatefulActionTypes(FETCH_USER_INFO);

const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ ...state, state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ ...state, state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ ...state, state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    ...state,
    state: stateReducer(state, action),
    data: action.data,
  }),
  [SET_USER_INFO]: (state, action) => ({ ...state, data: action.data }),
  [TOGGLE_TOP_UP_POPUP]: (state, action) => ({ ...state, topUpPopup: action.payload }),
  [TOGGLE_SEND_INVOICE_POPUP]: (state, action) => ({ ...state, sendInvoicePopup: action.payload }),
  [SET_AMOUNT_VALUE]: (state, action) => ({ ...state, amount: action.payload.amount }),
  [SET_USER_CTN]: (state, action) => ({ ...state, ctn: action.payload }),
  [SET_USER_ACCESS_LEVEL]: (state, action) => ({ ...state, hasRequiredAccessLevel: action.payload }),
  [SET_TRANS_ID]: (state, action) => ({ ...state, transId: action.payload }),
  [SET_POPUP_STEP]: (state, action) => ({ ...state, modalStep: action.payload }),
  [SET_MAKE_PROCEED_PAYMENT_REQUEST]: (state, action) => ({ ...state, needMakeProceedRequest: action.payload }),
};

export const userReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
