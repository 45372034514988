/* eslint-disable max-len */
export const FIELD_TYPES = {
  String: 'String',
  Enum: 'Enum',
  Integer: 'Integer',
  Decimal: 'Decimal',
  Boolean: 'Boolean',
};

export const VIEW_TYPES = {
  Tab: 'Tab',
  Text: 'Text',
  Phone: 'Phone',
  Select: 'Select',
  Checkbox: 'Checkbox',
  Textarea: 'Textarea',
  ExtSelect: 'ExtSelect',
};

export const DEFAULT_TABS_VALUES = {
  Provider: 'All',
  SecurityLevel: 'LoA2',
  SMSPush: 'SMSUrl',
  ClientDataAccessLevel: 'AllData',
  WorkScheme: 'DI',
};

export const GROUPS_ORDER = [
  'AuthorizationCode',
  'CompanyInfo',
  'FakePhone',
  'BankInfo',
  'Operators',
  'WorkScheme',
  'AuthOnly',
  'AddFee',
  'TestPeriod',
  'ClientsData',
  'Fee',
];

export const EMPLOYEE_PAGE_GROUPS_ORDER = [
  'Employee-Company',
  'CompanyInfo',
  'BankInfo',
  'ApplicationName',
  'Operators',
  'WorkScheme',
  'AuthorizationCode',
  'AuthOnly',
  'AddFee',
  'TestPeriod',
  'ClientsData',
  'TargetsDescription',
  'TariffGroup',
  'AccountTypeGroup',
  'BANGroup',
  'FakePhone',
  'Fee',
];

export const FIELDS_ORDER_FOR_COMPANY_INFO = [
  'ClientName',
  'CompanyName',
  'CompanyNameEN',
  'SiteAddress',
  'Email',
  'INN',
  'ContactPerson',
  'ContactPersonPhone',
  'TargetsDescription',
];

export const STATUS_TEXTS = {
  Draft: 'Заполнение',
  Review: 'В обработке',
  Accepted: 'Активно',
  Rejected: 'Отклонено',
  Blocked: 'Заблокировано',
  Deleted: 'Удалено',
};

export const GROUPS_ALIASES = {
  authCode: 'AuthorizationCode',
  fakePhone: 'FakePhone',
  company: 'CompanyInfo',
  bank: 'BankInfo',
  operators: 'Operators',
  workScheme: 'WorkScheme',
  authOnly: 'AuthOnly',
  clientsData: 'ClientsData',
  addFee: 'AddFee',
  testPeriod: 'TestPeriod',
  fee: 'Fee',
  applicationName: 'ApplicationName',
  targetsDescription: 'TargetsDescription',
  tariffGroup: 'TariffGroup',
  accountTypeGroup: 'AccountTypeGroup',
  banGroup: 'BANGroup',
  ipAddresses: 'IPAddresses',
};

export const APP_NAME_MAX_LENGTH = 35;
export const APP_NAME_MAX_LENGTH_MESSAGE = 'Укажите название не превышающее 35 символов, но понятное для ваших клиентов.';
export const APP_SITE_MAX_LENGTH = 50;
export const TARGET_DESCRIPTION_MIN_LENGTH = 50;
export const TARGET_DESCRIPTION_MAX_LENGTH = 300;
