import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';

import { getInstructionRegistrationBlock } from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { ContentMapper } from './ContentMapper';
import styles from './styles.pcss';
import { MARGIN_BOTTOM } from '../../constants';

const cx = classNames.bind({ ...commonStyles, ...styles });

const RegistrationBlock = ({ content, isMobile, className }) => (
  <div className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl, className)}>
    <ContentMapper content={content} isMobile={isMobile} />
  </div>
);

const mapStateToProps = (state) => ({
  content: getInstructionRegistrationBlock(state),
  shouldRender: !!getInstructionRegistrationBlock(state),
  isMobile: isScreenBelow800(state),
});

RegistrationBlock.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const Registration = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(RegistrationBlock);
