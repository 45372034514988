import { blankObj } from 'utils/fn';
import { stateValues } from 'utils/redux-stateful';
import { GROUPS_ALIASES } from 'utils/orders/constants';

const getOrderDetails = (state) => state.orderDetail ?? blankObj;

export const getOdrerDetailsData = (state) => getOrderDetails(state).data;
export const getOdrerDetailsStatus = (state) => getOdrerDetailsData(state)?.currentStatusAlias;
export const getOdrerDetailsId = (state) => getOdrerDetailsData(state)?.id;
export const getCompanyData = (state) => getOdrerDetailsData(state)?.companyData;

export const getCompanyId = state => getCompanyData(state)?.id;

export const getCompanyName = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.company)?.fields
  .find(field => field.alias === 'CompanyName')?.stringValue;

export const getAppName = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.applicationName)?.fields
  .find(field => field.alias === 'ApplicationName')?.stringValue;

export const getSiteAddress = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.company).fields
  .find(field => field.alias === 'SiteAddress').stringValue;

export const getWorkScheme = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group?.alias === GROUPS_ALIASES.workScheme)?.fields
  .find(field => field.alias === 'WorkScheme').selectedEnumAlias;

export const getClientSecretGroup = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.authCode)?.fields;

export const getClientId = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.authCode)?.fields
  .find(field => field.alias === 'ClientId')?.stringValue;


export const getClientSecret = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.authCode)?.fields
  .find(field => field.alias === 'ClientSecret')?.stringValue;

export const getIsAggregator = state => !!getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.authCode)?.fields
  .find(field => field.alias === 'ClientIdAggregator' || field.alias === 'ClientSecretAggregator');

export const getClientIdAggregator = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.authCode)?.fields
  .find(field => field.alias === 'ClientIdAggregator')?.stringValue;

export const getClientSecretAggregator = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.authCode)?.fields
  .find(field => field.alias === 'ClientSecretAggregator')?.stringValue;

export const getBANGroup = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.banGroup);

export const getBAN = state => getBANGroup(state)?.fields
  .find(field => field.alias === 'BAN')?.stringValue;

export const getFakePhoneGroup = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.fakePhone);

export const getFakePhone = state => getFakePhoneGroup(state)?.fields
  .find(field => field.alias === 'FakePhone')?.stringValue;

export const getWorkSchemeGroup = state => getOdrerDetailsData(state)?.fieldsGroups
  .find(group => group.alias === GROUPS_ALIASES.workScheme);

const getOrderDetailsState = (state) => getOrderDetails(state).state;

export const isOrderDetailsLoaded = (state) => getOrderDetailsState(state) === stateValues.Success;
export const isOrderDetailsLoading = (state) => getOrderDetailsState(state) === stateValues.Initial;
export const isOrderDetailsError = (state) => getOrderDetailsState(state) === stateValues.Error;
export const isOrderDetailsInitial = (state) => getOrderDetailsState(state) === stateValues.Initial;
