import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import {
  getValue,
  sortGroupFieldsByOrder,
  getDisplayValue,
  mapFieldInfo,
  setValue,
  getCompanyInfoGroup,
} from 'utils/orders/helpers';
import {
  EMPLOYEE_PAGE_GROUPS_ORDER,
  GROUPS_ALIASES,
} from 'utils/orders/constants';
import { compose, flatten, filter, noop } from 'utils/fn';
import { composeFormValidator } from 'utils/field-validators';
import { EMPLOYEE_MAIN_PAGE_URL } from 'constants';

import {
  fetchEmployeeOrderDetails,
  setFieldsAfterAccepting,
} from 'store/features/details-order/action';
import {
  getOdrerDetailsData,
  getOdrerDetailsId,
  isOrderDetailsLoaded,
  isOrderDetailsLoading,
  getClientId,
  getClientSecret,
  getAppName,
} from 'store/features/details-order/selectors';
import {
  saveAndSendToAccept as saveAndSendToAcceptAction,
  setAcceptedOnUnmount as setAcceptedOnUnmountAction,
} from 'store/features/employee-order/action';
import { clearStatus as clearStatusAction } from 'store/features/claim/action';
import { isAcceptError as isAcceptErrorSelector } from 'store/features/employee-order/selectors';

import PageLayout from 'shared/PageLayout';
import ResultPopup from 'shared/ResultPopup';
import OrderGroupInfo from 'shared/Order/OrderGroupInfo';
import { Text } from 'components/Text';

import EditFieldsForm from './components/EditFieldsForm';
import FeeSection from './components/FeeSection';
import DownloadOrder from './components/DownloadOrder';
import AgreementBtns from './components/AgreementBtns';
import AccountControlSection from './components/AccountControlSection';
import RejectPopup from './components/RejectPopup';
import RemovePopup from './components/RemovePopup';
import AssociatedCompany from './components/AssociatedCompany';
import SelectsForm from './components/SelectsForm';
import { VirusTotalStatus } from './components/VirusTotalStatus';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const getGroupViewName = ({ alias }) => {
  const mapGroupAliasToName = {
    AuthorizationCode: 'Код авторизации',
    FakePhone: 'Фиктивный номер телефона',
    AddFee: 'Добавить стоимость',
    TestPeriod: 'Пробный период',
    Fee: 'Оплата',
    CompanyInfo: 'Контактные данные',
    BankInfo: 'Банковские реквизиты',
    Operators: 'Операторы',
    WorkScheme: 'Схемы работы',
    AuthOnly: 'Способ взаимодействия',
    ClientsData: 'Данные клиентов для передачи',
    ApplicationName: 'Приложение',
    TargetsDescription: 'Для каких целей планируется использовать Мобильный ID',
    TariffGroup: 'Тариф',
    AccountTypeGroup: 'Тип аккаунта',
  };

  return mapGroupAliasToName[alias];
};

const getEditableGroups = (groups) =>
  groups.filter((g) =>
    [
      GROUPS_ALIASES.testPeriod,
      GROUPS_ALIASES.addFee,
      // GROUPS_ALIASES.fakePhone,
      // GROUPS_ALIASES.fee,
      GROUPS_ALIASES.tariffGroup,
      GROUPS_ALIASES.accountTypeGroup,
    ].includes(g.alias));

const isExcludedFromRenderGroup = (g) =>
  [
    GROUPS_ALIASES.authCode,
    GROUPS_ALIASES.fakePhone,
    GROUPS_ALIASES.banGroup,
    GROUPS_ALIASES.addFee,
  ].includes(g.alias);

const EmployeeOrderPage = ({
  history,
  match,
  data,
  orderId,
  appName,
  clientId: clientIdOrigin,
  clientSecret: clientSecretOrigin,
  isDataLoaded,
  fetchOrderDetails,
  saveAndSendToAccept,
  isAcceptError,
  setAcceptedOnUnmount,
  setChangedFields,
  clearClientStatus,
}) => {
  const [formValues, setFormValues] = useState({});
  const [clientId, setClientId] = useState(clientIdOrigin);
  const [clientSecret, setClientSecret] = useState(clientSecretOrigin);
  const [errors, setErrors] = useState({});
  const [isRejectPopupShow, setIsRejectPopupShow] = useState(false);
  const [isShowErrorPopup, setIsShowErrorPopup] = useState(false);
  const [isShowRemovePopup, setIsShowRemovePopup] = useState(false);

  useEffect(() => {
    fetchOrderDetails({ id: match.params.id });
  }, [fetchOrderDetails, match.params.id]);

  useEffect(() => {
    if (data && isDataLoaded) {
      const initialValues = flatten(getEditableGroups(data.fieldsGroups).map((g) => g.fields)).reduce(
        (acc, f) => ({
          ...acc,
          [f.alias]: getValue(f),
        }),
        {},
      );

      setFormValues(initialValues);
    }
  }, [data, isDataLoaded]);

  useEffect(() => {
    if (isAcceptError) {
      setIsShowErrorPopup(true);
    }
  }, [isAcceptError]);

  useEffect(() => {
    setClientId(clientIdOrigin);
  }, [clientIdOrigin]);
  useEffect(() => {
    setClientSecret(clientSecretOrigin);
  }, [clientSecretOrigin]);

  useEffect(
    () => () => {
      setAcceptedOnUnmount();
      clearClientStatus();
    },
    [],
  );

  const items = useMemo(
    () =>
      data?.fieldsGroups
        .filter((group) => !isExcludedFromRenderGroup(group))
        .concat(getCompanyInfoGroup(data))
        .sort((a, b) =>
          EMPLOYEE_PAGE_GROUPS_ORDER.indexOf(a.alias) -
            EMPLOYEE_PAGE_GROUPS_ORDER.indexOf(b.alias))
        .map((group) => sortGroupFieldsByOrder(group)),
    [data],
  );

  const onChange = ({ fieldAlias, value }) => {
    setFormValues({
      ...formValues,
      [fieldAlias]: value,
    });

    if (errors[fieldAlias]) {
      setErrors(filter(errors, (_, key) => key !== fieldAlias));
    }
  };

  const validateValues = (editableGroups) => {
    const validators = flatten(editableGroups.map((g) => g.fields)).reduce(
      (acc, f) => ({
        ...acc,
        [f.alias]: mapFieldInfo[f.alias]?.validator ?? noop,
      }),
      {},
    );

    const validationErrors = composeFormValidator(() => validators)(formValues);

    if (Object.keys(validationErrors).length !== 0) {
      setErrors(validationErrors);
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const editableGroups = getEditableGroups(data.fieldsGroups);

    if (!validateValues(editableGroups)) return;

    const changedValues = flatten(getEditableGroups(data.fieldsGroups)).map((el) => ({ ...el, fields: setChangedValuesToFields(el.fields) }));

    saveAndSendToAccept({
      values: formValues,
      groups: editableGroups,
      id: orderId,
    }).then(() => setChangedFields(changedValues));
  };

  const setChangedValuesToFields = (fields) =>
    fields.map((field) => {
      const newValue = formValues[field.alias];
      return setValue(field, newValue);
    });

  const onRejectClick = () => {
    const editableGroups = getEditableGroups(data.fieldsGroups);
    if (!validateValues(editableGroups)) return;

    saveAndSendToAccept({
      values: formValues,
      groups: editableGroups,
      id: orderId,
      isChangeStatus: false,
    })
      .then(() => setIsRejectPopupShow(true))
      .catch(() => setIsRejectPopupShow(false));
  };

  const onRemoveClick = () => {
    setIsShowRemovePopup(true);
  };

  const renderGroupName = (alias, fields) => {
    const isHideAuthCodeGroup = alias === 'AuthorizationCode' && !clientId;
    const isHideAnotherGroup =
      alias !== 'AuthorizationCode' &&
      !fields.some((field) => getDisplayValue(field)) &&
      data?.currentStatusAlias !== 'Review';

    if (isHideAuthCodeGroup) {
      return null;
    }

    if (isHideAnotherGroup) {
      return null;
    }

    return (
      <Text isLarge isTitle isBold className={cx('group-title')}>
        {getGroupViewName({ alias })}
      </Text>
    );
  };

  const isStatusAccepted = data?.currentStatusAlias === 'Accepted';
  return (
    <PageLayout isLoading={!isDataLoaded}>
      <div className={cx('company-name')}>{appName}</div>
      <form onSubmit={onSubmit} action="">
        {items?.map(({ alias, fields }) => {
          const editClause = data.currentStatusAlias === 'Review';
          // const isFakePhone = alias === GROUPS_ALIASES.fakePhone && editClause;
          const isEditableGroup =
            (alias === GROUPS_ALIASES.addFee ||
              alias === GROUPS_ALIASES.testPeriod) &&
            editClause;
          const isFee = alias === GROUPS_ALIASES.fee;
          const isShowAuthCode = alias === GROUPS_ALIASES.applicationName;
          const isShowAssociatedCompany =
            alias === GROUPS_ALIASES.applicationName &&
            !!data.associatedCompany;
          const isSelectForm =
            alias === GROUPS_ALIASES.tariffGroup ||
            alias === GROUPS_ALIASES.accountTypeGroup;

          return (
            <div key={alias} className={cx('group-wrapper')}>
              {renderGroupName(alias, fields)}
              {isEditableGroup && (
                <EditFieldsForm
                  fields={fields}
                  onChange={onChange}
                  formValues={formValues}
                  errors={errors}
                />
              )}
              {isSelectForm && (
                <SelectsForm
                  fields={fields}
                  onChange={onChange}
                  formValues={formValues}
                  errors={errors}
                />
              )}
              {isFee && (
                <FeeSection
                  fields={fields}
                  onChange={onChange}
                  formValues={formValues}
                  errors={errors}
                />
              )}
              {isShowAssociatedCompany && (
                <AssociatedCompany
                  associatedCompany={data.associatedCompany}
                  shouldRender={data.associatedCompany}
                />
              )}
              {!(isEditableGroup || isFee || isSelectForm) && (
                <OrderGroupInfo alias={alias} fields={fields} forEmployeePage />
              )}
            </div>
          );
        })}

        <div className={cx('download')}>
          <DownloadOrder id={orderId} />
        </div>

        <VirusTotalStatus
          className={cx('virus-total')}
          status={data?.securityStatusAlias}
          shouldRender={!!data?.securityStatusAlias}
        />

        {!isStatusAccepted && (
          <div className={cx('agreement')}>
            <AgreementBtns
              onRejectClick={onRejectClick}
              onRemoveClick={onRemoveClick}
              status={data?.currentStatusAlias}
            />
          </div>
        )}
      </form>
      {isStatusAccepted && (
        <div className={cx('accountControlWrap')}>
          <AccountControlSection
            clientId={clientId}
            onRemoveClick={onRemoveClick}
            setClientId={setClientId}
            setClientSecret={setClientSecret}
            operators={data?.operatorsToSendNotification}
          />
        </div>
      )}
      {isRejectPopupShow && (
        <RejectPopup id={orderId} onClose={() => setIsRejectPopupShow(false)} />
      )}

      {isShowErrorPopup && (
        <ResultPopup
          onClose={() => setIsShowErrorPopup(false)}
          isSuccess={false}
          texts={{
            title: 'Ваше приложение направлено на рассмотрение',
            description: 'Мы ответим Вам в ближайшее время',
          }}
        />
      )}
      {isShowRemovePopup && (
        <RemovePopup
          id={orderId}
          currentStatus={data.currentStatusAlias}
          onClose={() => setIsShowRemovePopup(false)}
        />
      )}
    </PageLayout>
  );
};

EmployeeOrderPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  data: PropTypes.object,

  isDataLoaded: PropTypes.bool,
  isAcceptError: PropTypes.bool,

  orderId: PropTypes.string,
  appName: PropTypes.string,
  clientId: PropTypes.string,
  clientSecret: PropTypes.string,
  saveAndSendToAccept: PropTypes.func,
  fetchOrderDetails: PropTypes.func,
  setAcceptedOnUnmount: PropTypes.func,
  setChangedFields: PropTypes.func,
  clearClientStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  data: getOdrerDetailsData(state),
  orderId: getOdrerDetailsId(state),
  appName: getAppName(state),
  clientId: getClientId(state),
  clientSecret: getClientSecret(state),
  isDataLoaded: isOrderDetailsLoaded(state),
  isDataLoading: isOrderDetailsLoading(state),
  isAcceptError: isAcceptErrorSelector(state),
});

const mapDispatchToProps = {
  fetchOrderDetails: fetchEmployeeOrderDetails,
  saveAndSendToAccept: saveAndSendToAcceptAction,
  setAcceptedOnUnmount: setAcceptedOnUnmountAction,
  setChangedFields: setFieldsAfterAccepting,
  clearClientStatus: clearStatusAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EmployeeOrderPage);
