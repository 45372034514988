export const MAIN_ROUTE = '/b2b/';
export const API_DOC_ROUTE = `${MAIN_ROUTE}api-doc`;
export const PLUGINS_ROUTE = `${MAIN_ROUTE}plugins`;
export const LOGIN_ROUTE = `${MAIN_ROUTE}login/`;
export const CREATE_COMPANY_ROUTE = `${MAIN_ROUTE}create-company/`;
export const CREATE_ORDER_ROUTE = `${MAIN_ROUTE}create-order/`;
export const EDIT_ORDER_ROUTE = `${MAIN_ROUTE}edit-order/`;
export const EDIT_ORDER_ITEM_ROUTE = `${EDIT_ORDER_ROUTE}:id`;

export const VIEW_ORDER_ROUTE = `${MAIN_ROUTE}view-order/`;
export const VIEW_ORDER_ITEM_ROUTE = `${VIEW_ORDER_ROUTE}:id`;

export const VIEW_COMPANY_ROUTE = `${MAIN_ROUTE}view-company/`;
export const VIEW_COMPANY_ITEM_ROUTE = `${VIEW_COMPANY_ROUTE}:id`;

export const EMPLOYEE_ROUTE = `${MAIN_ROUTE}employee/`;
export const EMPLOYEE_MAIN_ROUTE = `${EMPLOYEE_ROUTE}main`;
export const EMPLOYEE_EDIT_ORDER_ROUTE = `${EMPLOYEE_ROUTE}edit-order/`;
export const EMPLOYEE_EDIT_ORDER_ITEM_ROUTE = `${EMPLOYEE_EDIT_ORDER_ROUTE}:id`;

export const ACCESS_DENIED_ROUTE = `${MAIN_ROUTE}access-denied`;
export const INSTRUCTION_FULL_ROUTE = `${MAIN_ROUTE}instruction/full`;
export const INSTRUCTION_SHORT_ROUTE = `${MAIN_ROUTE}instruction/short`;

export const unauthorizedRoutes = [INSTRUCTION_SHORT_ROUTE];
