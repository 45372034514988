import { blankObj } from 'utils/fn';
import { createSelector } from '@reduxjs/toolkit';
import { formatPrice } from 'utils/formatting';

const getCompanyDetails = (state) => state.companyDetails ?? blankObj;

export const getCompanyDetailsData = (state) => getCompanyDetails(state)?.data;
export const getCompanyBalance = (state) => getCompanyDetailsData(state)?.companyBalance;
export const getCompanyDetailsState = (state) => getCompanyDetails(state)?.state;
export const getAllowCorpUserInfo = (state) => getCompanyDetailsData(state)?.allowCorpUserinfo;

export const getAdmin = (state) => getCompanyDetailsData(state)?.members.find(el => el.isOwner);

export const createMemberState = (state) => getCompanyDetails(state)?.createMemberState;
export const createMemberResponseError = (state) => getCompanyDetails(state)?.error;
export const updateMemberState = (state) => getCompanyDetails(state)?.updateMemberState;
export const updateMemberResponseError = (state) => getCompanyDetails(state)?.error;
export const deleteMemberState = (state) => getCompanyDetails(state)?.deleteMemberState;
export const makeOwnerState = (state) => getCompanyDetails(state)?.makeOwnerState;

export const getCompanyBalanceFormatted = createSelector(
  getCompanyBalance,
  (companyBalanceValue) => {
    const isValidValue = typeof companyBalanceValue === 'number';
    const balance = formatPrice(companyBalanceValue);

    return {
      isValidValue,
      text: `Баланс: ${balance} ₽`,
    };
  },
);
