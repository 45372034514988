import { axiosAuthorizedRequest } from 'requests/helpers';
import { getCreateClaimIDUrl } from 'requests/api/constants';

export const createClaimIDRequest = ({ formId }) => axiosAuthorizedRequest({
  url: getCreateClaimIDUrl(),
  method: 'POST',
  data: {
    formId,
  },
})
  .then(({ data }) => data);
