export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_PENDING = 'UPDATE_COMPANY_PENDING';
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR';

export const UPDATE_COMPANY_BAN = 'UPDATE_COMPANY_BAN';
export const UPDATE_COMPANY_BAN_PENDING = 'UPDATE_COMPANY_BAN_PENDING';
export const UPDATE_COMPANY_BAN_ERROR = 'UPDATE_COMPANY_BAN_ERROR';

export const CREATE_NEW_MEMBER = 'CREATE_NEW_MEMBER';
export const CREATE_MEMBER_INITIAL = 'CREATE_MEMBER_INITIAL';
export const CREATE_MEMBER_PENDING = 'CREATE_MEMBER_PENDING';
export const CREATE_MEMBER_ERROR = 'CREATE_MEMBER_ERROR';

export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const UPDATE_MEMBER_INITIAL = 'UPDATE_MEMBER_INITIAL';
export const UPDATE_MEMBER_PENDING = 'UPDATE_MEMBER_PENDING';
export const UPDATE_MEMBER_ERROR = 'UPDATE_MEMBER_ERROR';

export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_INITIAL = 'DELETE_MEMBER_INITIAL';
export const DELETE_MEMBER_PENDING = 'DELETE_MEMBER_PENDING';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';

export const MAKE_OWNER = 'MAKE_OWNER';
export const MAKE_OWNER_INITIAL = 'MAKE_OWNER_INITIAL';
export const MAKE_OWNER_PENDING = 'MAKE_OWNER_PENDING';
export const MAKE_OWNER_ERROR = 'MAKE_OWNER_ERROR';

export const MOVE_FROM_ADMIN_TO_MEMBERS = 'MOVE_FROM_ADMIN_TO_MEMBERS';
