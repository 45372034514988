import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Accordion, AccordionItem, Text } from 'components';
import { ActiveTabPanel, ActiveTabProvider } from 'components/Tabs';
import { NavList } from '../NavList';
import { NavListItem } from '../NavListItem';

import { APIDocContent } from '../APIDocContent';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const APIDocComponent = ({ items, className, isMobile }) => {
  const itemTitles = items.map(el => el.title);

  return (
    <ActiveTabProvider>
      {isMobile
        ? (
          <Accordion>
            {items?.map((el, idx) => (
              <AccordionItem key={idx.toString()} title={<Text>{el.title}</Text>}>
                <APIDocContent text={el.text} isMobile={isMobile} />
              </AccordionItem>
            ))}
          </Accordion>
        )
        : (
          <div className={cx('container', className)}>
            <NavList>
              {itemTitles.map((title, idx) => (
                <NavListItem key={idx.toString()} index={idx}>
                  {title}
                </NavListItem>
              ))}
            </NavList>

            {items?.map((el, idx) => (
              <ActiveTabPanel key={idx.toString()} index={idx}>
                <APIDocContent className={cx('column')} {...el} />
              </ActiveTabPanel>
            ))}
          </div>
        )}
    </ActiveTabProvider>
  );
};

APIDocComponent.propTypes = {
  items: PropTypes.any,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export const APIDoc = withRenderFlag(APIDocComponent);
