import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';

import classNames from 'classnames/bind';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const AgreementBtns = ({ onRejectClick, onRemoveClick, status }) => {
  if (status === 'Deleted') {
    return null;
  }
  return (
    <div>
      <Text isTitle isLarge className={cx('title')}>
        {status === 'Review' ? 'В обработке' : 'Удаление анкеты'}
      </Text>
      <div className={cx('btns')}>
        {
          status === 'Review' && (
            <>
              <Button isSubmit className={cx('btn')}>
                Согласовать
              </Button>
              <Button isSecondary className={cx('btn')} type="button" onClick={onRejectClick}>
                Отправить на доработку
              </Button>
            </>

          )
        }
        <Button
          isSecondary
          className={cx('btn', 'trash-btn')}
          type="button"
          onClick={onRemoveClick}
        >
          <Icon name="trash" className={cx('trash-icon')}/>
          Удалить
        </Button>
      </div>
    </div>
  );
};

AgreementBtns.propTypes = {
  onRejectClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  status: PropTypes.string,
};

export default AgreementBtns;
