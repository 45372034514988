import { ACCESS_DENIED_ROUTE, API_DOC_ROUTE, EMPLOYEE_MAIN_ROUTE, LOGIN_ROUTE, MAIN_ROUTE } from './routes';

export const AUTH_REDIRECT_URL = LOGIN_ROUTE;
export const ACCESS_DENIED_URL = ACCESS_DENIED_ROUTE;
export const MAIN_PAGE_URL = MAIN_ROUTE;
export const APIDOC_PAGE_URL = API_DOC_ROUTE;
export const EMPLOYEE_MAIN_PAGE_URL = EMPLOYEE_MAIN_ROUTE;
export const authStatuses = {
  initial: 'initial',
  authed: 'authed',
  notAuth: 'notAuth',
};

export const authMobileIdStatuses = {
  retry: 'retry',
  success: 'success',
  error: 'error',
};

export const NAVIGATE_ITEM_PRICING = 'Pricing';
export const NAVIGATE_ITEM_SDK = 'MobileID_SDK';
export const NAVIGATE_ITEM_DESIGN_GUIDE = 'MobileID_Guide_DI';
export const NAVIGATE_ITEM_AGGREGATOR = 'MobileID_Aggregator_API';
export const PAYMENT_MD_COOKIE = '_wp_md::';
export const HOMELESS_MODE_COOKIE = '__homeless_mode';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_UAT = /mobileiduat/.test(window.location.origin);
export const IS_TEST = /beelinetst/.test(window.location.origin);

export const REACT_MODELS = {
  B2B_SELF_SERVICE: 'b2b-self-service',
  B2B_FULL_INSTRUCTION: 'b2b-full-instruction',
  B2B_SHORT_INSTRUCTION: 'b2b-short-instruction',
};

export const INSTRUCTION_BLOCKS = {
  LOGIN: 'login',
  ACCEPT: 'accept',
  REGISTRATION: 'registration',
  BASICS: 'basics',
  FIRST_CALL: 'firstCall',
  API: 'api',
};
