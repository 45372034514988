import React, { useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useContentMargin, useUnauthorizedZone } from 'hooks';
import commonStyles from 'styles/common.pcss';
import { CREATE_ORDER_ROUTE } from 'constants/routes';
import HelpAndSupport from '../HelpAndSupport';
import { InstructionButton } from '../InstructionButton';
import Header from '../Header';
import Footer from '../Footer';
import Breadcrumbs from '../Breadcrumbs';
import GlobalPreloader from '../GlobalPreloader';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

export default function PageLayout({ children, isWide, isFullSize, isLoading, className }) {
  const contentRef = useRef(null);
  const [marginLeft] = useContentMargin(contentRef);
  const isCreateOrderPage = !!useRouteMatch(CREATE_ORDER_ROUTE);
  const isUnauthorizedZone = useUnauthorizedZone();

  return (
    <div className={cx('container')}>
      <Header />

      <div className={cx('middle', className)}>
        <div ref={contentRef} className={cx('content', { wide: isWide, fullSize: isFullSize })}>
          <Breadcrumbs />
          {children}
        </div>

        <div className={cx('buttons')}>
          {isCreateOrderPage && <InstructionButton className={cx('button')} />}
          {!isUnauthorizedZone && <HelpAndSupport className={cx('button')} />}
        </div>
      </div>

      <Footer marginLeft={marginLeft} />

      <GlobalPreloader isOpen={isLoading} />
    </div>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node,
  isWide: PropTypes.bool,
  isFullSize: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
