import React from 'react';
import classNames from 'classnames/bind';

import { Preloader } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Suspense = () => (
  <div className={cx('suspense')}>
    <Preloader />
  </div>
);
