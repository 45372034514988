import React from 'react';
import PropTypes from 'prop-types';


export const withRenderFlag = (
  (WrappedComponent, SuspenseComponent = null) => {
    const ConditionalRenderer = ({ shouldRender, ...props }) => (
      shouldRender ? <WrappedComponent {...props} /> : SuspenseComponent
    );

    ConditionalRenderer.propTypes = {
      ...WrappedComponent.propTypes,
      shouldRender: PropTypes.bool,
    };

    /**
     * Override Connector's name to withRenderFlag(%Component%)
     */
    if (process.env.NODE_ENV !== 'production') {
      const WrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
      ConditionalRenderer.displayName = `withRenderFlag(${WrappedComponentName})`;
    }
    return ConditionalRenderer;
  }
);
