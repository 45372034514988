import React from 'react';
import PropTypes from 'prop-types';

import MaskedInput from './MaskedInput';

const unmaskValue = (value) => value.replace(/\D/g, '');

/** Превращаем дату из YYYY-MM-DD в DDMMYYYY */
const serializeDate = (value) => value.split('-').reverse().join('');

/** Превращаем дату из 8 цифр DDMMYYYY в YYYY-MM-DD */
const deserializeDate = (val) => `${val.substring(4, 8)}-${val.substring(2, 4)}-${val.substring(0, 2)}`;

export function DateInput({ value, onChange, ...props }) {
  return (
    <MaskedInput
      mask="__.__.____"
      inputMode="tel"
      unmaskValue={unmaskValue}
      {...props}
      maxLength={10}
      value={serializeDate(value)}
      onChange={(val, event) => onChange(deserializeDate(val, event))}
    />
  );
}

DateInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DateInput.defaultProps = {
  value: '',
};
