import { axiosBaseRequest } from 'requests/helpers';
import { getReactModelUrl } from 'requests/api/constants';

import { REACT_MODELS } from 'constants';

/**
 * @param {string} models - Алиас нужной реакт-модели. Если необходимо несколько реакт-моделей, аргументом функции
 * будет строка из алиасов, перечисленных через запятую
 * */
export const getReactModelRequest = (models = REACT_MODELS.B2B_SELF_SERVICE) => axiosBaseRequest({
  url: getReactModelUrl(),
  params: { models },
})
  .then(({ data }) => data);
