/* eslint-disable max-len */
import deepmerge from 'deepmerge';
import { authStatuses } from 'constants';
import { stateValues } from 'utils/redux-stateful';
// import { nbsp } from 'utils/formatting';


export default (model) => deepmerge({
  common: {
    auth: { status: authStatuses.initial },
  },
  createOrder: { state: stateValues.Initial },
  orderDetail: { state: stateValues.Initial },
  draftOrder: { state: stateValues.Initial, filledGroups: [] },
}, model, { arrayMerge: (_, incoming) => incoming });
