import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ArrowSVG from './arrow.svg';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const AccordionItem = ({ id, title, children, className }) => (
  <details id={id} className={cx('item', className)}>
    <summary className={cx('summary')}>
      {title}
      <div className={cx('arrow')}>
        <ArrowSVG />
      </div>
    </summary>
    <div className={cx('details')}>{children}</div>
  </details>
);

AccordionItem.defaultProps = {};

AccordionItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};
