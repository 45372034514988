import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getIsActiveCategory } from 'pages/PluginsPage/mappers';

import { ListItem } from './ListItem';
import { ItemContent } from './ItemContent';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Navigation = ({ data, activePlugin, setActivePlugin, className }) => (
  <ul className={cx('component', className)}>
    {data.map(({ key, heading, content }) => (
      <ListItem
        key={key}
        title={heading}
        isActive={getIsActiveCategory(content, activePlugin)}
      >
        <ItemContent
          content={content}
          onClick={setActivePlugin}
          activePluginKey={activePlugin}
        />
      </ListItem>
    ))}
  </ul>
);

Navigation.propTypes = {
  data: PropTypes.array,
  activePlugin: PropTypes.string,
  className: PropTypes.string,
  setActivePlugin: PropTypes.func,
};
