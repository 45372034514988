import { MAIN_ROUTE, API_DOC_ROUTE, PLUGINS_ROUTE,
  LOGIN_ROUTE, CREATE_COMPANY_ROUTE, CREATE_ORDER_ROUTE, EMPLOYEE_MAIN_ROUTE, ACCESS_DENIED_ROUTE,
  INSTRUCTION_FULL_ROUTE, INSTRUCTION_SHORT_ROUTE, EDIT_ORDER_ITEM_ROUTE, VIEW_ORDER_ITEM_ROUTE,
  EMPLOYEE_EDIT_ORDER_ITEM_ROUTE, VIEW_COMPANY_ITEM_ROUTE,
} from 'constants/routes';

import Main from '../Main';
import NotFoundPage from '../NotFound';
import AccessDenied from '../AccessDenied';
import { LoginPage } from '../LoginPage';
import CreateOrderPage from '../CreateOrderPage';
import EditOrderPage from '../EditOrderPage';
import ViewOrderPage from '../ViewOrderPage';
import EmployeeOrderPage from '../EmployeeOrderPage';
import OmnesEmployeeProfiles from '../OmnesEmployeeProfiles';
import ViewCompanyPage from '../ViewCompanyPage';
import CreateCompanyPage from '../CreateCompanyPage';
import APIDocPage from '../APIDocPage';
import PluginsPage from '../PluginsPage';
import { InstructionPage } from '../InstructionPage';
import { ShortInstructionPage } from '../ShortInstructionPage';

export const routes = [
  {
    path: MAIN_ROUTE,
    component: Main,
    exact: true,
    name: 'Главная страница',
  },
  {
    path: API_DOC_ROUTE,
    component: APIDocPage,
    exact: true,
    name: 'API для интеграции',
  },
  {
    path: PLUGINS_ROUTE,
    component: PluginsPage,
    exact: true,
  },
  // {
  //   path: '/b2b/registration/',
  //   component: RegistrationPage,
  //   exact: true,
  // },
  {
    path: LOGIN_ROUTE,
    component: LoginPage,
    exact: true,
  },
  {
    path: CREATE_COMPANY_ROUTE,
    component: CreateCompanyPage,
    exact: true,
    name: 'Создать компанию',
  },
  {
    path: CREATE_ORDER_ROUTE,
    component: CreateOrderPage,
    exact: true,
    name: 'Создать приложение',
  },
  {
    path: EDIT_ORDER_ITEM_ROUTE,
    component: EditOrderPage,
    exact: true,
    name: 'Редактировать приложение',
  },
  {
    path: VIEW_ORDER_ITEM_ROUTE,
    component: ViewOrderPage,
    exact: true,
    name: 'Просмотреть приложение',
  },
  {
    path: VIEW_COMPANY_ITEM_ROUTE,
    component: ViewCompanyPage,
    exact: true,
    name: 'Просмотреть компанию',
  },
  {
    path: EMPLOYEE_MAIN_ROUTE,
    component: OmnesEmployeeProfiles,
    exact: true,
    name: 'Приложения',
  },
  {
    path: EMPLOYEE_EDIT_ORDER_ITEM_ROUTE,
    component: EmployeeOrderPage,
    exact: true,
    name: 'Редактировать приложение',
  },
  {
    path: ACCESS_DENIED_ROUTE,
    component: AccessDenied,
    exact: true,
  },
  {
    path: INSTRUCTION_FULL_ROUTE,
    component: InstructionPage,
    exact: true,
  },
  {
    path: INSTRUCTION_SHORT_ROUTE,
    component: ShortInstructionPage,
    exact: true,
  },
  {
    component: NotFoundPage,
  },
];
