import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getInstructionLoginBlock } from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Text } from 'components';

import commonStyles from 'styles/common.pcss';
import { MARGIN_BOTTOM } from '../../constants';

const cx = classNames.bind(commonStyles);

const LoginBlock = ({ content: { address, registration }, isMobile, className }) => (
  <div className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl, className)}>
    <Text isInterphases isMedium>
      {address}
    </Text>
    <Text isInterphases isMedium>
      {registration}
    </Text>
  </div>
);

const mapStateToProps = (state) => ({
  content: getInstructionLoginBlock(state),
  shouldRender: !!getInstructionLoginBlock(state),
  isMobile: isScreenBelow800(state),
});

LoginBlock.propTypes = {
  content: PropTypes.shape({
    address: PropTypes.string,
    registration: PropTypes.string,
  }),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const Login = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(LoginBlock);
