import {
  FN_COMPANY,
  FN_USER,
  FN_COMPANYGROUPS,
  FN_BANK,
  FN_ADDRESS,
  FN_EMAIL,
} from 'constants/fieldNames';
import {
  CAN_ONLY_ENTER_ENGLISH_LETTERS_TEXT,
  ENTER_PASSPORT_NUMBER_TEXT,
  ENTER_THE_CORRECT_PHONE_NUMBER_TEXT,
  ENTER_THE_PASSPORT_SERIES_TEXT,
  FIELD_CANNOT_BE_EMPTY_TEXT,
  FIELD_CAN_ONLY_CONTAIN_LETTERS_TEXT,
  FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT,
  FIELD_FIXED_DATE_FORMAT_TEXT,
  INCORRECT_EMAIL_TEXT,
} from 'constants/validators';

import {
  composeDigitValidator, composeEmailValidator, composeLengthValidator, composeNameValidator,
  composePhoneValidator, composeRequiredValidator, composeEnglishLettersValidator, composeDateValidator,
} from 'utils/field-validators';
import { isNotEmptyFn } from 'utils/orders/helpers';

export const uniqueValidatorFn = field => composeRequiredValidator(mapCompanyFieldInfo[field]?.errorText);

const getRequiredValidatorWithMaxLength = ({ min = null, max = 200 } = {}) => {
  let lengthErrorText = `Максимальная длина строки ${max} символов`;

  if (max === null && min !== null) {
    lengthErrorText = `Минимальная длина строки ${min} символов`;
  } else if (max === min) {
    lengthErrorText = `Длина строки должна быть ${max} символов`;
  }

  return [
    isNotEmptyFn,
    composeLengthValidator(lengthErrorText, { min, max }),
  ];
};

export const getNextIsIndividualValue = (companyType, allCompanyTypes) =>
  allCompanyTypes?.find(({ title }) => title === companyType)?.isIndividualEntrepreneur;

export const companyGroups = {
  [FN_COMPANYGROUPS.contactData]: 'Контактные данные',
  [FN_COMPANYGROUPS.addressData]: 'Адрес',
  [FN_COMPANYGROUPS.bankData]: 'Банковские реквизиты',
};

export const COMPANY_CONTACT_FIELDS = [
  FN_COMPANY.type, FN_COMPANY.inn, FN_COMPANY.ogrn, FN_COMPANY.name, FN_COMPANY.nameEn, FN_USER.wholeName,
  FN_USER.fioEn, FN_USER.gender, FN_COMPANY.siteaddress, FN_EMAIL, FN_COMPANY.kpp, FN_COMPANY.okpo, FN_ADDRESS.address,
  FN_COMPANY.contactPerson, FN_COMPANY.contactPersonPhone, FN_ADDRESS.street, FN_ADDRESS.building,
];
export const COMPANY_BANK_FIELDS = [FN_BANK.name, FN_BANK.rs, FN_BANK.ks, FN_BANK.bik];

export const mapCompanyFieldInfo = {
  ClientId: {
    name: 'Client ID (билайн)',
  },
  [FN_COMPANY.type]: {
    name: 'Организационно-правовая форма',
    companyGroup: FN_COMPANYGROUPS.contactData,
    groupOrder: 1,
    type: 'dropdown',
    isExtended: true,
  },
  [FN_COMPANY.inn]: {
    name: 'ИНН компании',
    type: FN_COMPANY.inn,
    placeholder: '0123456789',
    groupOrder: 1.5,
    companyGroup: FN_COMPANYGROUPS.contactData,
    isWide: true,
    errorText: 'Введите ИНН компании',
  },
  [FN_COMPANY.ogrn]: {
    name: 'ОГРН',
    type: FN_COMPANY.ogrn,
    placeholder: '0123456789123',
    groupOrder: 1.6,
    companyGroup: FN_COMPANYGROUPS.contactData,
    isIndividual: false,
    isWide: true,
    errorText: 'Введите ОГРН',
  },
  [FN_COMPANY.ogrnip]: {
    name: 'ОГРНИП',
    type: FN_COMPANY.ogrnip,
    placeholder: '012345678912345',
    groupOrder: 1.6,
    companyGroup: FN_COMPANYGROUPS.contactData,
    isIndividual: true,
    isWide: true,
    errorText: 'Введите ОГРНИП',
  },
  clientName: {
    name: 'Клиент',
    placeholder: 'Наименование клиента',
    group: 'client',
    groupOrder: 1,
    isWide: true,
    isIndividual: false,
    validator: isNotEmptyFn,
    errorText: 'Введите наименование клиента',
  },
  [FN_COMPANY.name]: {
    name: 'Название компании',
    placeholder: 'Ромашка',
    // Поля с одинаковыми group или groupOrder будут отображаться в одной строке
    group: 'name',
    groupOrder: 2,
    companyGroup: FN_COMPANYGROUPS.contactData,
    isIndividual: false,
    errorText: 'Введите название компании',
    validator: getRequiredValidatorWithMaxLength(),
  },
  [FN_COMPANY.nameEn]: {
    name: 'Название компании на английском',
    placeholder: 'Romashka',
    group: 'name',
    groupOrder: 2,
    companyGroup: FN_COMPANYGROUPS.contactData,
    isIndividual: false,
    errorText: 'Введите название компании на английском',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 100 }),
      composeEnglishLettersValidator(CAN_ONLY_ENTER_ENGLISH_LETTERS_TEXT),
    ],
  },
  [FN_USER.name]: {
    name: 'Имя',
    placeholder: 'Иван',
    companyGroup: FN_COMPANYGROUPS.contactData,
    groupOrder: 2,
    isIndividual: true,
    errorText: 'Введите имя',
  },
  [FN_USER.surname]: {
    name: 'Фамилия',
    placeholder: 'Иванов',
    companyGroup: FN_COMPANYGROUPS.contactData,
    isIndividual: true,
    groupOrder: 2,
    errorText: 'Введите фамилию',
  },
  [FN_USER.middlename]: {
    name: 'Отчество',
    placeholder: 'Иванович',
    companyGroup: FN_COMPANYGROUPS.contactData,
    isIndividual: true,
    groupOrder: 2.2,
    isWide: true,
  },
  [FN_USER.wholeName]: {
    name: 'ФИО',
    isIndividual: true,
    fieldsToRender: [FN_USER.name, FN_USER.surname, FN_USER.middlename],
  },
  [FN_USER.fioEn]: {
    name: 'ФИО на английском',
    placeholder: 'Ivanov Ivan Ivanovich',
    companyGroup: FN_COMPANYGROUPS.contactData,
    groupOrder: 2.5,
    isIndividual: true,
    isWide: true,
    errorText: 'Введите ваше ФИО на английском языке',
    validator: composeEnglishLettersValidator(CAN_ONLY_ENTER_ENGLISH_LETTERS_TEXT),
  },
  [FN_USER.gender]: {
    name: 'Пол',
    companyGroup: FN_COMPANYGROUPS.contactData,
    type: 'tab',
    enumItems: ['M', 'F'],
    groupOrder: 3,
    isIndividual: true,
  },
  [FN_COMPANY.kpp]: {
    name: 'КПП компании',
    placeholder: '012345678',
    group: 'kpp',
    groupOrder: 4.1,
    isIndividual: false,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите КПП компании',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 9, max: 9 }),
    ],
  },
  [FN_COMPANY.okpo]: {
    name: 'ОКПО компании',
    placeholder: '01234567',
    group: 'kpp',
    groupOrder: 4.1,
    isIndividual: false,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите ОКПО компании',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 8, max: 8 }),
    ],
  },
  [FN_USER.birthDate]: {
    name: 'Дата рождения',
    placeholder: '01.01.1990',
    groupOrder: 4.2,
    isIndividual: true,
    type: 'date',
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите дату рождения',
    validator: [
      composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
      composeDateValidator(FIELD_FIXED_DATE_FORMAT_TEXT, {
        min: new Date('1900-01-01'),
        max: new Date(),
      }),
    ],
  },
  [FN_USER.nationality]: {
    name: 'Гражданство',
    groupOrder: 4.2,
    isIndividual: true,
    type: 'dropdown',
    optionsKey: 'countries',
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Укажите гражданство',
  },
  [FN_USER.docId]: {
    name: 'Серия паспорта',
    placeholder: '0123',
    group: 'passportNum',
    groupOrder: 4.3,
    isIndividual: true,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: ENTER_THE_PASSPORT_SERIES_TEXT,
    validator: [
      composeRequiredValidator(ENTER_THE_PASSPORT_SERIES_TEXT),
      composeLengthValidator(ENTER_THE_PASSPORT_SERIES_TEXT, { min: 4 }),
    ],
  },
  [FN_USER.docNo]: {
    name: 'Номер паспорта',
    placeholder: '012345',
    group: 'passportNum',
    groupOrder: 4.3,
    isIndividual: true,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: ENTER_PASSPORT_NUMBER_TEXT,
    validator: [
      composeRequiredValidator(ENTER_PASSPORT_NUMBER_TEXT),
      composeLengthValidator(ENTER_PASSPORT_NUMBER_TEXT, { min: 6 }),
    ],
  },
  [FN_USER.birthplace]: {
    name: 'Место рождения',
    placeholder: 'Г. Москва ул. Правды д.5',
    groupOrder: 4.38,
    isIndividual: true,
    isWide: true,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите место рождения',
  },
  [FN_USER.docIssue]: {
    name: 'Кем выдан',
    placeholder: 'УФМС России по гор. Москве',
    groupOrder: 4.4,
    isWide: true,
    isIndividual: true,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Укажите орган, выдавший документ',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 80 }),
    ],
  },
  [FN_USER.docDate]: {
    name: 'Дата выдачи',
    placeholder: '10.10.2015',
    group: 'docDate',
    groupOrder: 4.5,
    isIndividual: true,
    type: 'date',
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите дату выдачи',
    validator: [
      composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
      composeDateValidator(FIELD_FIXED_DATE_FORMAT_TEXT, {
        min: new Date('1900-01-01'),
        max: new Date(),
      }),
    ],
  },
  [FN_USER.docIssuerCode]: {
    name: 'Код подразделения',
    placeholder: '700-110',
    group: 'docDate',
    groupOrder: 4.5,
    isIndividual: true,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите код подразделения',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 10 }),
    ],
  },
  [FN_COMPANY.siteaddress]: {
    name: 'Сайт компании',
    placeholder: 'www.romashka.ru',
    group: 'contacts',
    groupOrder: 5,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите сайт компании',
    validator: getRequiredValidatorWithMaxLength(),
  },
  [FN_EMAIL]: {
    name: 'Корпоративный E-mail',
    placeholder: 'i.ivanov@romashka.ru',
    group: 'contacts',
    groupOrder: 5,
    companyGroup: FN_COMPANYGROUPS.contactData,
    errorText: 'Введите корпоративный E-mail',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 100 }),
      composeEmailValidator(INCORRECT_EMAIL_TEXT),
    ],
  },
  [FN_ADDRESS.address]: {
    name: 'Адрес',
    fieldsToRender: [
      FN_ADDRESS.postindex,
      FN_ADDRESS.city,
      FN_ADDRESS.street,
      FN_ADDRESS.building,
      FN_ADDRESS.apartment],
  },
  [FN_ADDRESS.postindex]: {
    name: 'Индекс',
    placeholder: '123100',
    groupOrder: 1,
    rowOrder: 1,
    companyGroup: FN_COMPANYGROUPS.addressData,
    errorText: 'Введите индекс',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 6, max: 6 }),
    ],
  },
  [FN_ADDRESS.city]: {
    name: 'Город',
    placeholder: 'Москва',
    groupOrder: 1,
    rowOrder: 2,
    companyGroup: FN_COMPANYGROUPS.addressData,
    errorText: 'Введите город',
  },
  [FN_ADDRESS.street]: {
    name: 'Улица',
    placeholder: 'Проспект мира',
    groupOrder: 2,
    rowOrder: 1,
    companyGroup: FN_COMPANYGROUPS.addressData,
    errorText: 'Введите улицу',
  },
  [FN_ADDRESS.building]: {
    name: 'Дом',
    placeholder: '1',
    groupOrder: 2,
    rowOrder: 2,
    isNarrow: true,
    companyGroup: FN_COMPANYGROUPS.addressData,
    errorText: 'Введите номер дома',
  },
  [FN_ADDRESS.apartment]: {
    name: 'Квартира',
    placeholder: '220',
    groupOrder: 2,
    rowOrder: 3,
    isNarrow: true,
    companyGroup: FN_COMPANYGROUPS.addressData,
  },
  [FN_COMPANY.contactPerson]: {
    name: 'ФИО контактного лица',
    placeholder: 'Иванов Иван Иванович',
    groupOrder: 3,
    companyGroup: FN_COMPANYGROUPS.addressData,
    isWide: true,
    errorText: 'Введите ФИО контактного лица',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 60 }),
      composeNameValidator(FIELD_CAN_ONLY_CONTAIN_LETTERS_TEXT),
    ],
  },
  [FN_COMPANY.contactPersonPhone]: {
    name: 'Телефон контактного лица',
    placeholder: '',
    groupOrder: 4,
    companyGroup: FN_COMPANYGROUPS.addressData,
    type: 'phone',
    isWide: true,
    errorText: 'Введите телефон контактного лица',
    validator: [
      isNotEmptyFn,
      composePhoneValidator(ENTER_THE_CORRECT_PHONE_NUMBER_TEXT),
    ],
  },
  TargetsDescription: {
    name: 'Для каких целей планируется использовать Мобильный ID',
    groupOrder: 7,
    isWide: true,
    errorText: 'Опишите, для каких целей планируется использовать Мобильный ID',
    validator: getRequiredValidatorWithMaxLength({ max: 400 }),
  },
  [FN_BANK.name]: {
    name: 'Полное наименование банка организации',
    placeholder: 'АО «Альфа-Банк»',
    companyGroup: FN_COMPANYGROUPS.bankData,
    groupOrder: 1,
    isWide: true,
    errorText: 'Введите наименование банка',
    validator: getRequiredValidatorWithMaxLength({ max: 200 }),
  },
  [FN_BANK.rs]: {
    name: 'Расчетный счет',
    placeholder: '40701810099990001234',
    companyGroup: FN_COMPANYGROUPS.bankData,
    groupOrder: 2,
    isWide: true,
    errorText: 'Введите корректный расчетный счет',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 20, max: 20 }),
    ],
  },
  [FN_BANK.ks]: {
    name: 'Корреспондентский счет',
    placeholder: '30101810200000000593',
    companyGroup: FN_COMPANYGROUPS.bankData,
    groupOrder: 3,
    isWide: true,
    errorText: 'Введите корректный корреспондентский счет',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 20, max: 20 }),
    ],
  },
  [FN_BANK.bik]: {
    name: 'БИК',
    placeholder: '044525593',
    companyGroup: FN_COMPANYGROUPS.bankData,
    groupOrder: 4,
    isWide: true,
    errorText: 'Введите БИК банка',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 9, max: 9 }),
    ],
  },
};

export const mapEnumItemsToName = {
  gender: [
    {
      itemAlias: 'M',
      name: 'Мужской',
    },
    {
      itemAlias: 'F',
      name: 'Женский',
    },
  ],
};

export const getTabName = (fieldAlias, itemAlias) => {
  const names = mapEnumItemsToName[fieldAlias];
  return names?.find(n => n.itemAlias === itemAlias)?.name ?? itemAlias;
};

export const getFieldInfo = field => ({
  uniqueValidator: uniqueValidatorFn(field),
  alias: field,
  ...mapCompanyFieldInfo[field],
});
