import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadUserCtn } from 'store/features/user/action';
import { getDocumentsRequest } from 'requests/api/contents';
import { fetchCompanyOrders } from 'store/features/orders/action';
import { fetchCompaniesList } from 'store/features/companies/action';
import { getCompanyDetails } from 'store/features/company-details/action';
import { getCurrentCompany } from 'store/features/currentCompany/selectors';
import { getCompaniesList, getIsCompaniesLoaded } from 'store/features/companies/selectors';
import { setCurrentCompany as setCurrentCompanyAction } from 'store/features/currentCompany/action';
import { getOrdersList, isOrdersLoaded as isOrdersLoadedSelector } from 'store/features/orders/selectors';

import { CREATE_COMPANY_ROUTE } from 'constants/routes';

import { CompanyPopup } from 'components/Popup';
import PageLayout from 'shared/PageLayout';
import CompanyBlock from './components/CompanyBlock';
import AppsBlock from './components/AppsBlock';
import DocumentsBlock from './components/DocumentsBlock';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const Main = ({
  orders,
  history,
  setCompany,
  companiesList,
  currentCompany,
  isOrdersLoaded,
  fetchUserCtnList,
  isCompaniesLoaded,
  loadCompaniesData,
  loadCompanyDetails,
  loadCompanyOrdersData,
}) => {
  const [popupOpened, setPopupOpened] = useState(false);
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    loadCompaniesData()
      .then(({ data }) => {
        const visited = localStorage.getItem('alreadyVisited');
        if (!data.length) {
          setCompany(undefined);
          if (!visited) {
            localStorage.setItem('alreadyVisited', 'true');
            history.replace(CREATE_COMPANY_ROUTE);
          }
        } else if (data.length === 1) {
          if (data[0].isOwner) {
            localStorage.setItem('companyId', data[0].id);
            setCompany(data[0]);
          } else {
            setPopupOpened(true);
          }
        } else {
          const isAlreadyChosen = !!localStorage.getItem('companyId');
          if (!isAlreadyChosen || data.every(el => !el.isOwner)) {
            setPopupOpened(true);
          } else {
            const companyToSet = data.find(el => el.id === localStorage.getItem('companyId'));
            setCompany(companyToSet);
          }
        }
      });
  }, []);

  useEffect(() => {
    if (currentCompany) {
      loadCompanyOrdersData(currentCompany?.id);
      loadCompanyDetails(currentCompany?.id);
      fetchUserCtnList(currentCompany?.id);
    }
    getDocumentsRequest()
      .then(res => setDocuments(res));
    setPopupOpened(false);
  }, [currentCompany]);

  const onChooseCompany = (id) => {
    localStorage.setItem('companyId', id);
    localStorage.removeItem('alreadyVisited');
    const companyToSet = companiesList.find(el => el.id === id);
    setCompany(companyToSet);
  };

  const onPopupClose = () => {
    if (!currentCompany) {
      localStorage.setItem('companyId', companiesList[0].id);
      setCompany(companiesList[0]);
    }
  };

  const onChooseCreateCompany = () => {
    setCompany(undefined);
    setPopupOpened(false);
    history.replace(CREATE_COMPANY_ROUTE);
  };

  return (
    <PageLayout>
      {
        popupOpened && companiesList.length ?
          <CompanyPopup
            isOpen
            onClose={onPopupClose}
            companies={companiesList}
            onChooseCallback={onChooseCompany}
            onChooseCreateCompanyCallback={onChooseCreateCompany}
          /> : (
            <div className={cx('wrapper')}>
              <CompanyBlock
                company={currentCompany}
                isLoaded={isCompaniesLoaded}
              />
              {
                isCompaniesLoaded &&
                  <>
                    <AppsBlock
                      isOrdersLoaded={isOrdersLoaded}
                      orders={orders}
                      isCompanyExist={!!currentCompany}
                    />
                    {documents?.length && <DocumentsBlock documents={documents} />}
                  </>
              }
              {/* <StatsTable columns={columns} /> */}
            </div>
          )
      }
    </PageLayout>
  );
};

Main.propTypes = {
  isOrdersLoaded: PropTypes.bool,
  orders: PropTypes.array,
  companiesList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    isOwner: PropTypes.bool,
  })),
  isCompaniesLoaded: PropTypes.bool,
  currentCompany: PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    isOwner: PropTypes.bool,
  }),
  loadCompanyDetails: PropTypes.func,
  loadCompaniesData: PropTypes.func,
  loadCompanyOrdersData: PropTypes.func,
  setCompany: PropTypes.func,
  history: PropTypes.object,
  fetchUserCtnList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  companiesList: getCompaniesList(state),
  isCompaniesLoaded: getIsCompaniesLoaded(state),
  orders: getOrdersList(state),
  isOrdersLoaded: isOrdersLoadedSelector(state),
  currentCompany: getCurrentCompany(state),
});

const mapDispatchToProps = {
  fetchUserCtnList: loadUserCtn,
  setCompany: setCurrentCompanyAction,
  loadCompaniesData: fetchCompaniesList,
  loadCompanyDetails: getCompanyDetails,
  loadCompanyOrdersData: fetchCompanyOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
