import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import commonStyles from 'styles/common.pcss';
import { changeOrderStatusRequest } from 'requests/api/change-status';
import { EMPLOYEE_MAIN_PAGE_URL } from 'constants';

import { BasePopupStateless } from 'components/Popup';
import { Text, Button, Preloader } from 'components';
import Error from 'shared/Error';

import classNames from 'classnames/bind';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const steps = {
  initial: 'initial',
  loading: 'loading',
  removed: 'removed',
  error: 'error',
};

const RemovePopup = ({ onClose, id, history, currentStatus }) => {
  const [step, setStep] = useState(steps.initial);

  const onRemoveClick = () => {
    setStep(steps.loading);
    changeOrderStatusRequest({ orderId: id, status: 'Deleted' })
      .then(() => setStep(steps.removed))
      .catch(() => setStep(steps.error));
  };

  const onUndoClick = () => {
    setStep(steps.loading);
    changeOrderStatusRequest({ orderId: id, status: currentStatus })
      .then(() => { onClose(); })
      .catch(() => setStep(steps.error));
  };

  const onPopupClose = () => {
    if (step === steps.removed) {
      history.push(EMPLOYEE_MAIN_PAGE_URL);
    } else {
      onClose();
    }
  };

  return (
    <BasePopupStateless className={cx('popup')} isOpen onClose={onPopupClose}>
      <div className={cx('popup-content', { 'removed-step': step === steps.removed })}>
        {step === steps.initial && (
          <>
            <Text isLarge isTitle className={cx('marb-xs')}>
              Анкета сотрудника будет удалена
            </Text>
            <Text isSmall className={cx('marb-m')}>
              Вы хотите удалить анкету?
            </Text>
            <Button isWide className={cx('marb-s')} onClick={onRemoveClick}>
              Удалить
            </Button>
            <Button isWide isSecondary onClick={onClose}>
              Назад
            </Button>
          </>
        )}
        {step === steps.removed && (
          <>
            <Text isLarge isTitle className={cx('marb-m')}>
              Анкета сотрудника удалена
            </Text>
            <Button isWide isSecondary onClick={onUndoClick}>
              Отменить удаление
            </Button>
          </>
        )}
        {step === steps.loading && (
          <div className={cx('loder-wrap')}>
            <Preloader />
          </div>
        )}
        {step === steps.error && (
          <Error />
        )}
      </div>
    </BasePopupStateless>
  );
};

RemovePopup.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  history: PropTypes.object,
  currentStatus: PropTypes.string,
};

export default withRouter(RemovePopup);
