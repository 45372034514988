import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer, stateValues } from 'utils/redux-stateful';
import {
  GET_COMPANY_DETAILS,
  CREATE_NEW_MEMBER, CREATE_MEMBER_INITIAL, CREATE_MEMBER_PENDING, CREATE_MEMBER_ERROR,
  UPDATE_MEMBER, UPDATE_MEMBER_INITIAL, UPDATE_MEMBER_PENDING, UPDATE_MEMBER_ERROR,
  DELETE_MEMBER, DELETE_MEMBER_INITIAL, DELETE_MEMBER_PENDING, DELETE_MEMBER_ERROR,
  MAKE_OWNER, MAKE_OWNER_INITIAL, MAKE_OWNER_PENDING, MAKE_OWNER_ERROR,
  UPDATE_COMPANY, UPDATE_COMPANY_PENDING, UPDATE_COMPANY_ERROR,
  MOVE_FROM_ADMIN_TO_MEMBERS,
} from './constants';

const stateReducer = createStatefulReducer(GET_COMPANY_DETAILS);
const actions = createStatefulActionTypes(GET_COMPANY_DETAILS);

const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
    createMemberState: stateValues.Initial,
    updateMemberState: stateValues.Initial,
    deleteMemberState: stateValues.Initial,
    makeOwnerState: stateValues.Initial,
  }),

  [CREATE_MEMBER_INITIAL]: (state) => ({ ...state, createMemberState: stateValues.Initial }),
  [CREATE_MEMBER_PENDING]: (state) => ({ ...state, createMemberState: stateValues.Pending }),
  [CREATE_MEMBER_ERROR]: (state, action) => ({
    ...state,
    createMemberState: stateValues.Error,
    error: action.data,
  }),
  [CREATE_NEW_MEMBER]: (state, action) => ({
    ...state,
    createMemberState: stateValues.Success,
    data: {
      ...state.data,
      members: state.data.members.concat(action.data),
    },
  }),

  [UPDATE_MEMBER_INITIAL]: (state) => ({ ...state, updateMemberState: stateValues.Initial }),
  [UPDATE_MEMBER_PENDING]: (state) => ({ ...state, updateMemberState: stateValues.Pending }),
  [UPDATE_MEMBER_ERROR]: (state, action) => ({
    ...state,
    updateMemberState: stateValues.Error,
    error: action.data,
  }),
  [UPDATE_MEMBER]: (state, action) => ({
    ...state,
    updateMemberState: stateValues.Success,
    data: {
      ...state.data,
      members: state.data.members.map(el => (el.phone === action.data.phone ? { ...el, ...action.data } : el)),
    },
  }),

  [DELETE_MEMBER_INITIAL]: (state) => ({ ...state, deleteMemberState: stateValues.Initial }),
  [DELETE_MEMBER_PENDING]: (state) => ({ ...state, deleteMemberState: stateValues.Pending }),
  [DELETE_MEMBER_ERROR]: (state) => ({ ...state, deleteMemberState: stateValues.Error }),
  [DELETE_MEMBER]: (state, action) => ({
    ...state,
    deleteMemberState: stateValues.Success,
    data: {
      ...state.data,
      members: state.data.members.filter(el => el.phone !== action.data.phone),
    },
  }),

  [MOVE_FROM_ADMIN_TO_MEMBERS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      accessInfo: { ...state.data.accessInfo, canEditCompanyMembership: false },
      members: state.data.members.map(el => (el.phone === action.data ? { ...el, isOwner: false } : el)),
    },
  }),
  [MAKE_OWNER_INITIAL]: (state) => ({ ...state, makeOwnerState: stateValues.Initial }),
  [MAKE_OWNER_PENDING]: (state) => ({ ...state, makeOwnerState: stateValues.Pending }),
  [MAKE_OWNER_ERROR]: (state) => ({ ...state, makeOwnerState: stateValues.Error }),
  [MAKE_OWNER]: (state, action) => ({
    ...state,
    makeOwnerState: stateValues.Success,
    data: {
      ...state.data,
      members: state.data.members.map(el => (el.phone === action.data.phone ? { ...el, isOwner: true } : el)),
    },
  }),

  [UPDATE_COMPANY_PENDING]: (state) => ({ ...state, state: stateValues.Pending }),
  [UPDATE_COMPANY_ERROR]: (state) => ({ ...state, state: stateValues.Error }),
  [UPDATE_COMPANY]: (state, action) => ({
    ...state,
    state: stateValues.Success,
    data: {
      ...state.data,
      ...action.data,
    },
  }),
};

export const companyDetailsReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
