import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'ahooks';

import { authStatuses, EMPLOYEE_MAIN_PAGE_URL, MAIN_PAGE_URL } from 'constants';

import { getIsEmployeeRequest } from 'requests/api/auth';

import { getAuthStatus, isAuthStatusLoaded as isAuthStatusLoadedSelector } from 'store/features/auth/selectors';
import { setIsEmployee } from 'store/features/auth/action';

export const useBeforeLogin = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthStatusLoaded = useSelector(isAuthStatusLoadedSelector);
  const isAuthed = useSelector(getAuthStatus) === authStatuses.authed;
  const previousLocation = location.state?.from;

  useEffect(() => {
    localStorage.removeItem('companyId');
    localStorage.removeItem('alreadyVisited');
  }, []);

  const { run: onBeforeLogin } = useRequest(getIsEmployeeRequest, {
    manual: true,
    onSuccess: ({ isEmployee }) => {
      dispatch(setIsEmployee({ isEmployee }));
      history.replace(isEmployee ? EMPLOYEE_MAIN_PAGE_URL : MAIN_PAGE_URL);
    },
    onError: () => history.replace(MAIN_PAGE_URL),
  });

  useEffect(() => {
    if (isAuthStatusLoaded && isAuthed && !location?.state?.from) {
      onBeforeLogin();
    }
  }, [isAuthed, isAuthStatusLoaded, previousLocation]);
};
