import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { resetCreateMemberAction, resetUpdateMemberAction } from 'store/features/company-details/action';
import { BasePopupStateless } from 'components/Popup/components/BasePopup';
import CreateForm from 'pages/ViewCompanyPage/components/CreateForm';
import EditForm from 'pages/ViewCompanyPage/components/EditForm';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const AccessCompanyPopup = ({
  id,
  isEditablePopup = false,
  data = {},
  resetCreateMember,
  resetUpdateMember,
  onCloseCallback,
  ...props
}) => {
  const onPopupClose = () => {
    if (isEditablePopup) {
      resetUpdateMember();
    } else {
      resetCreateMember();
    }

    onCloseCallback();
  };

  return (
    <BasePopupStateless
      className={cx('popup-container')}
      overlayClassName={cx('overlay')}
      onClose={onPopupClose}
      isOpen
      {...props}
    >
      <div className={cx('wrapper')}>
        {
          isEditablePopup
          ? <EditForm data={data} onPopupClose={onPopupClose} />
          : <CreateForm id={id} onPopupClose={onPopupClose} />
        }
      </div>
    </BasePopupStateless>
  );
};

AccessCompanyPopup.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({}),
  isEditablePopup: PropTypes.bool,
  onCloseCallback: PropTypes.func,
  resetCreateMember: PropTypes.func,
  resetUpdateMember: PropTypes.func,
};

const mapDispatchToProps = {
  resetCreateMember: resetCreateMemberAction,
  resetUpdateMember: resetUpdateMemberAction,
};

export default connect(null, mapDispatchToProps)(AccessCompanyPopup);
