import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Text({
  children, isLegal, isItalic, isPrimary, isSecondary, isDarker, isInterphases,
  isSmall, isSmaller, isLarge, isInline, isInverse, isTitle, className, isMedium,
}) {
  const Tag = isInline ? 'span' : 'div';
  if (isItalic) throw new Error('Italic font-style is not supported');
  return (
    <Tag
      className={cx('component', className, {
        legal: isLegal,
        primary: isPrimary,
        secondary: isSecondary,
        darker: isDarker,
        large: isLarge,
        small: isSmall,
        smaller: isSmaller,
        italic: isItalic,
        inverse: isInverse,
        title: isTitle,
        medium: isMedium,
        interphases: isInterphases,
      })}
      {...(typeof children !== 'string' ? { children } : {
        dangerouslySetInnerHTML: { __html: children },
      })}
    />
  );
}

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isInterphases: PropTypes.bool,
  isLegal: PropTypes.bool,
  isItalic: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isDarker: PropTypes.bool,
  isSmall: PropTypes.bool,
  isMedium: PropTypes.bool,
  isSmaller: PropTypes.bool,
  isLarge: PropTypes.bool,
  isInline: PropTypes.bool,
  isInverse: PropTypes.bool,
  isTitle: PropTypes.bool,
  className: PropTypes.string,
};
