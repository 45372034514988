import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { isScreenBelow800 } from 'store/common/breakpoint/selectors';
import { getFooter } from 'store/common/content';
import { Text } from 'components/Text';

import LegalInfo from './components/LegalInfo';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const Footer = ({ isMobile, content, marginLeft }) => (
  <div className={cx('component')}>
    <div className={cx('content')} style={isMobile ? null : { marginLeft }}>
      <Text isSmall>
        {content.text}
      </Text>
      <LegalInfo className={cx('legal')}>
        {content.legal}
      </LegalInfo>
    </div>
  </div>
);

Footer.propTypes = {
  isMobile: PropTypes.bool,
  marginLeft: PropTypes.number,
  content: PropTypes.shape({
    text: PropTypes.string,
    legal: PropTypes.string,
  }),
};

const mapStateToProps = state => ({
  isMobile: isScreenBelow800(state),
  content: getFooter(state),
});

export default connect(mapStateToProps)(Footer);
