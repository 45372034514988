import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import commonStyles from 'styles/common.pcss';

import { Multiselect } from 'multiselect-react-dropdown';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { sendMsgToOperatorsRequest } from 'requests/api/operators';
import classNames from 'classnames/bind';

import ResultPopup from 'shared/ResultPopup';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const Operators = ({ orderId, operators }) => {
  const [selectedOperators, setSelectedOperators] = useState([]);
  const [isMultiselectOpen, setIsMultiselectOpen] = useState(false);
  const [sendOperatorStatus, setSendOperatorStatus] = useState(null);

  useEffect(() => {
    const input = document.querySelector('#operatorMultiselect_input');

    if (!input) return;

    const handleFocus = () => {
      setIsMultiselectOpen(true);
    };
    const handleBlur = () => {
      setIsMultiselectOpen(false);
    };

    input.addEventListener('focus', handleFocus);
    input.addEventListener('blur', handleBlur);

    // eslint-disable-next-line consistent-return
    return () => {
      input.removeEventListener('focus', handleFocus);
      input.removeEventListener('blur', handleBlur);
    };
  }, []);

  const onOperatorsSend = () => {
    sendMsgToOperatorsRequest({
      id: orderId,
      operators: selectedOperators.map(({ id }) => id),
    })
      .then(() => setSendOperatorStatus('success'))
      .catch(() => setSendOperatorStatus('error'));
  };

  return (
    <div>
      <Text isLarge isTitle className={cx('mart-m', 'marb-s')}>
        Отправить анкету операторам
      </Text>
      <div className={cx('multiselectWrap', { multiselectOpened: isMultiselectOpen })}>
        <Multiselect
          options={operators.map(o => ({
            name: o.text,
            id: o.alias,
          }))}
          onSelect={setSelectedOperators}
          onRemove={setSelectedOperators}
          placeholder="Выберите оператора"
          emptyRecordMsg="Не найдено подходящих операторов"
          id="operatorMultiselect"
          displayValue="name"
          closeIcon="cancel"
          hidePlaceholder
        />
        <Icon name="dropdownArrow" className={cx('arrow')} />
      </div>
      <Button
        type="button"
        onClick={onOperatorsSend}
        className={cx('mart-s')}
        isSecondary
      >
        Отправить
      </Button>

      {sendOperatorStatus && (
        <ResultPopup
          isSuccess={sendOperatorStatus === 'success'}
          texts={{ title: 'Анкета успешно отправлена' }}
          onClose={() => setSendOperatorStatus(null)}
        />
      )}
    </div>
  );
};

Operators.propTypes = {
  orderId: PropTypes.string,
  operators: PropTypes.array,
};

export default Operators;
