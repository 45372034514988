import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { isIE11 } from 'enhancers/helpers';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const BaseButton = ({ children, type, className, ...props }) => {
  const CurrentTag = isIE11() ? 'div' : 'button';
  return (
    <CurrentTag type={type || 'button'} className={cx('button', className)} {...props}>
      {children}
    </CurrentTag>);
};

BaseButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

export default BaseButton;
