import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';
import { FFDropDown } from 'components/FinalForm';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const FormDropDown = ({ name, alias, options, onChangeProxy, className }) => (
  <>
    {name && (
      <Text className={cx('fieldName')}>
        {name}
      </Text>
    )}
    <FFDropDown
      name={alias}
      options={options}
      className={className}
      onChangeProxy={onChangeProxy}
    />
  </>
);

FormDropDown.propTypes = {
  name: PropTypes.string,
  alias: PropTypes.string,
  options: PropTypes.array,
  onChangeProxy: PropTypes.func,
  className: PropTypes.any,
};

