import React from 'react';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'types';

import { OverlayMenu, Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Menu = ({ children }) => (
  <OverlayMenu
    className={cx('wrapper')}
    buttonClassName={cx('button', 'marb-m')}
    button={(
      <Text isInverse>
        Все плагины
      </Text>
    )}
    navigation={children}
  />
);

Menu.propTypes = {
  children: childrenPropTypes,
};
