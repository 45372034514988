import { stateValues } from 'utils/redux-stateful';
import { blankArr } from 'utils/fn';

const getOrders = state => state.orders;
const getOrdersState = state => getOrders(state)?.state;
export const getOrdersData = state => getOrders(state)?.data;
export const getOrdersList = state => getOrdersData(state)?.items
  .filter(order => (order.currentStatusAlias !== 'Rejected' && order.currentStatusAlias !== 'Deleted'))
  ?? blankArr;
export const hasAvailableOrders = (state) => getOrdersList(state).length > 0;
export const isOrdersLoaded = state => getOrdersState(state) === stateValues.Success;
