import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import commonStyles from 'styles/common.pcss';
import { createClientID as createClientIdAction } from 'store/features/claim/action';
import { isClientCreatedSuccess, isClientCreatedError } from 'store/features/claim/selectors';
import { getOdrerDetailsStatus, getOdrerDetailsId } from 'store/features/details-order/selectors';
import { changeOrderStatusRequest } from 'requests/api/change-status';

import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import { Tabs } from 'components/Tabs';
import { TextInput } from 'components';

import styles from './styles.pcss';
import Operators from '../Operators';
import OrderNumberAndFakePhone from '../OrderNumberAndFakePhone';
import { OrderNumberAndFakePhoneV2 } from '../OrderNumberAndFakePhone/index_v2';
import AuthCodeSection from '../AuthCodeSection';


const cx = classNames.bind({ ...commonStyles, ...styles });

const renderSuccessTitle = (text, withMarginTop = false) => (
  <div className={cx('successTitle', { withMarginTop })}>
    <Icon name="successCircle" />
    <Text isLarge isTitle className={cx('marl-xs')}>
      {text}
    </Text>
  </div>
);

const CLAIM_ERROR_TEXT = 'Что-то пошло не так.<br /> Попробуйте отправить повторно';

const AccountControlSection = ({
  orderStatus,
  orderId,
  onRemoveClick,
  clientId,
  clientSecret,
  isClaimSuccess,
  isClaimError,
  createClientID,
  setClientId,
  setClientSecret,
  operators,
}) => {
  const [tabError, setTabError] = useState(null);
  const onCreateClick = async () => {
    const { data } = await createClientID({ formId: orderId });
    setClientId(data.clientId);
    setClientSecret(data.clientSecret);
  };
  const initiallyActiveTab = orderStatus === 'Blocked' ? 'Blocked' : 'Accepted';

  const onTabChange = (alias) => {
    setTabError(null);
    changeOrderStatusRequest({ status: alias, orderId })
      .catch(() => setTabError('Что-то пошло не так'));
  };

  return (
    <div>
      {renderSuccessTitle('Анкета согласована')}
      {/*<OrderNumberAndFakePhone />*/}
      <OrderNumberAndFakePhoneV2 />
      {(clientId || isClaimSuccess) && <AuthCodeSection />}
      {(!clientId && !isClaimSuccess) && (
        <>
          <Text isLarge isTitle className={cx('mart-m', 'marb-s')}>
            Создать учетную запись на IDGW (билайн)
          </Text>
          <div className={cx('createBtnWrapper', 'mart-se')}>
            <Button onClick={onCreateClick}>
              Создать учетную запись на IDGW
            </Button>
            {isClaimError && <Text>{CLAIM_ERROR_TEXT}</Text>}
          </div>
        </>
      )}
      {(clientId || isClaimSuccess) && renderSuccessTitle('Учетная запись в IDGW создана', true)}
      <Operators
        operators={operators}
        orderId={orderId}
      />
      <Text isLarge isTitle className={cx('mart-m', 'marb-s')}>
        Статус анкеты
      </Text>
      <Tabs
        initiallyActive={initiallyActiveTab}
        onTabChange={onTabChange}
        beforeChangeAvailable
        beforeChangePopup
        beforeChangePopupTexts={
          {
            generalText: 'Вы уверены, что хотите изменить статус анкеты?',
            confirmText: 'Да, уверен(а)',
            failureText: 'Нет',
          }
        }
        tabs={[
          {
            alias: 'Accepted',
            children: <Text isSmall>Активна</Text>,
          },
          {
            alias: 'Blocked',
            children: <Text isSmall>Заблокирована</Text>,
          },
        ]}
      />
      <TextInput
        hidden
        className={cx('mart-xxs')}
        error={tabError}
      />
      <Text isLarge isTitle className={cx('mart-m', 'marb-s')}>
        Удаление анкеты
      </Text>
      <Button
        isSecondary
        className={cx('btn', 'trash-btn')}
        type="button"
        onClick={onRemoveClick}
      >
        <Icon name="trash" className={cx('trash-icon')} />
        Удалить
      </Button>
    </div>
  );
};

AccountControlSection.propTypes = {
  orderStatus: PropTypes.string,
  orderId: PropTypes.string,
  clientId: PropTypes.string,
  clientSecret: PropTypes.string,
  isClaimSuccess: PropTypes.bool,
  isClaimError: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  setClientId: PropTypes.func,
  setClientSecret: PropTypes.func,
  createClientID: PropTypes.func,
  operators: PropTypes.array,
};

const mapStateToProps = (state) => ({
  orderStatus: getOdrerDetailsStatus(state),
  orderId: getOdrerDetailsId(state),
  isClaimSuccess: isClientCreatedSuccess(state),
  isClaimError: isClientCreatedError(state),
});

const mapDispatchToProps = {
  createClientID: createClientIdAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountControlSection);
