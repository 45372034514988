import React from 'react';
import { NumberMaskedInput } from './NumberMaskedInput';

const entityConf = {
  mask: '_____________',
  maxLength: 13,
};
const individualConf = {
  mask: '_______________',
  maxLength: 15,
};

export const OGRNInput = ({ isIndividual, ...restProps }) => (
  <NumberMaskedInput
    {...restProps}
    {...(isIndividual ? individualConf : entityConf)}
  />
);
