import { useState, useEffect } from 'react';

import { axiosAuthorizedRequest } from 'requests/helpers';
import { getOmnesEmployeeProfilesTableData } from 'requests/api/constants';

import { FIRST_PAGE_NUMBER, INCREMENT_NUMBER, INITIAL_LOAD_MORE_PAGES } from './constants';

const Params = { size: 'PageSize', number: 'PageNumber' };

export const useRows = ({ initRowQuantity }) => {
  const [tableRows, setTableRows] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [activePage, setActivePage] = useState(null);
  const [loadMorePages, setLoadMorePages] = useState(null);
  const [rowQuantity, setRowQuantity] = useState(initRowQuantity);
  const [isOrdersLoaded, setIsOrdersLoaded] = useState(false);

  const requestGetRows = (pageNumber, quantity) => {
    const params = new URLSearchParams();
    params.append(Params.size, quantity);
    params.append(Params.number, pageNumber);
    return axiosAuthorizedRequest({
      url: getOmnesEmployeeProfilesTableData(),
      params,
    });
  };

  useEffect(() => {
    requestGetRows(FIRST_PAGE_NUMBER, initRowQuantity).then(({ data }) => {
      setTableRows(data.items);
      setTotalPages(data.pagesTotal);
      setActivePage(data.pageNumber);
      setLoadMorePages(data.pageNumber);
      setIsOrdersLoaded(true);
    });
  }, []);

  const changeRowQuantity = (quantity) => {
    requestGetRows(FIRST_PAGE_NUMBER, quantity).then(({ data }) => {
      setRowQuantity(quantity);
      setTableRows(data.items);
      setTotalPages(data.pagesTotal);
      setActivePage(FIRST_PAGE_NUMBER);
      setLoadMorePages(INITIAL_LOAD_MORE_PAGES);
    });
  };

  const loadMore = () => {
    requestGetRows(loadMorePages + INCREMENT_NUMBER, rowQuantity)
      .then(({ data }) => {
        setTableRows(tableRows.concat(data.items));
        setLoadMorePages(loadMorePages + INCREMENT_NUMBER);
      });
    /* Do not change activePage */
  };

  const pageClick = (page) => {
    if (page !== '...') {
      requestGetRows(page, rowQuantity).then(({ data }) => {
        setTableRows(data.items);
        setTotalPages(data.pagesTotal);
        setActivePage(data.pageNumber);
        setLoadMorePages(data.pageNumber);
      });
    }
  };

  return {
    tableRows,
    isOrdersLoaded,
    totalPages,
    activePage,
    loadMorePages,
    rowQuantity,
    loadMore,
    pageClick,
    changeRowQuantity,
  };
};
