import React from 'react';
import PropTypes from 'prop-types';

import { withError, withIcon, withState } from '../enhancers';
import EyeClosed from './assets/eyeClosed.svg';
import EyeOpened from './assets/eyeOpened.svg';
import Input from '../views/Input';

const BaseInput = withError(withIcon(Input));


/**
 * Password input with a clickable eye icon that shows entered text and back
 */
export default class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordVisible: props.passwordVisible,
    };
  }

  getIconProps = () => (
    this.state.passwordVisible ? {
      type: 'text',
      iconAlt: 'Скрыть пароль',
      Icon: EyeOpened,
    } : {
      type: 'password',
      iconAlt: 'Показать пароль',
      Icon: EyeClosed,
    }
  );

  togglePasswordVisible = () => {
    const { onIconClick } = this.props;
    this.setState({ passwordVisible: !this.state.passwordVisible });
    if (onIconClick) onIconClick();
  };

  render() {
    const { passwordVisible, ...otherProps } = this.props;
    return (
      <BaseInput
        {...otherProps}
        {...this.getIconProps()}
        isClearable={false}
        onIconClick={this.togglePasswordVisible}
      />
    );
  }
}

PasswordInput.propTypes = {
  // only used on component initialization, makes password initially visible
  passwordVisible: PropTypes.bool,

  /** props added by the HOC */
  status: PropTypes.oneOf(['fail']),
  error: PropTypes.string,
  keepErrorIndent: PropTypes.bool,

  /** inherited from withIcon */
  // Icon: No longer available
  // iconAlt: No longer available
  // isClearable: No longer available
  onIconClick: PropTypes.func,
  // onClear: No longer available

  /** inherited from withCleanup */
  autoTrim: PropTypes.bool,
  trimInitialValue: PropTypes.bool,

  /**
   * some notable default input props are listed here
   * all the other props are simply passed down
   */
  size: PropTypes.oneOf(['big']),
  // type: No longer available for PasswordInput
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

PasswordInput.defaultProps = {
  passwordVisible: false,
};


/**
 * stateful version accepts all the same propTypes,
 * except for `value`
 */
export const StatefulPasswordInput = withState(PasswordInput);
