import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { FORM_NAMES } from 'constants/forms';

import { getPersonalDataTransfer, shouldRenderPDTransfer } from 'store/common/content';
import { updateCompanyInfo } from 'store/features/company-details/action';
import { getAllowCorpUserInfo } from 'store/features/company-details/selectors';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Button, Text } from 'components';
import { FinalForm } from 'components/FinalForm';
import { RadioTabs } from 'components/FinalForm/RadioTabs';
import { GroupTitle } from 'shared';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const TABS_NAME = 'allowCorpUserinfo';

const PersonalDataTransferComponent = ({ initialState, content, onSubmit, id }) => {
  const { title, description, tooltip, options, buttonText } = content;

  const initialValues = useMemo(() => ({ [TABS_NAME]: initialState || options[0].alias }), [initialState]);

  const handleSubmit = async (values, form) => {
    await onSubmit({ id, ...values });
    form.reset();
  };

  return (
    <div className={cx('component', 'mart-le')}>
      <GroupTitle
        className="marb-xs"
        title={title}
        tooltip={tooltip}
      />

      <Text className="marb-se">
        {description}
      </Text>

      <FinalForm
        name={FORM_NAMES.personalDataTransfer}
        values={initialValues}
        onSubmit={handleSubmit}
        onChange={(v) => v}
      >
        {({ values }) => (
          <>
            <RadioTabs
              name={TABS_NAME} options={options}
            />

            <Button className="mart-le" isSubmit isDisabled={values[TABS_NAME] === initialState}>
              {buttonText}
            </Button>
          </>
        )}
      </FinalForm>
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialState: getAllowCorpUserInfo(state),
  content: getPersonalDataTransfer(state),
  shouldRender: shouldRenderPDTransfer(state),
});

const mapDispatchToProps = {
  onSubmit: updateCompanyInfo,
};

PersonalDataTransferComponent.propTypes = {
  initialState: PropTypes.bool,
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
    tooltip: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      alias: PropTypes.string,
      text: PropTypes.string,
    })),
  }),
  onSubmit: PropTypes.func,
  id: PropTypes.string,

};

export const PersonalDataTransfer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRenderFlag,
)(PersonalDataTransferComponent);
