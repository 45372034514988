import {
  FN_ADDRESS,
  FN_COMPANY,
  FN_USER,
} from 'constants/fieldNames';
import {
  ENTER_THE_CORRECT_INN_TEXT,
  ENTER_THE_CORRECT_OGRN_TEXT,
  INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT,
} from 'constants/validators';
import { getFieldInfo, getTabName } from 'utils/companies/helpers';
import { composeLengthValidator, composeXSSValidator } from 'utils/field-validators';

export const getFormInitialValues = (data) => {
  const { readonlyFields } = data;
  data.contactPersonPhone = data.contactPersonPhone.slice(-10);

  return Object.keys(data).reduce((acc, field) => {
    const skipCurrentFieldFirstCondition = typeof data[field] !== 'string' || field === 'id';
    const skipCurrentFieldSecondCondition = readonlyFields?.map(el => el.toLowerCase()).includes(field.toLowerCase());

    if (skipCurrentFieldFirstCondition || skipCurrentFieldSecondCondition) return acc;

    return {
      ...acc,
      [field]: data[field],
    };
  }, {});
};

export const formatValues = (values, allData, comparator) => {
  const { accessInfo, members, readonlyFields, allCompanyTypes, ...requestFields } = allData;

  const { gender, name, surname, fioEn } = FN_USER;
  const fieldsToDelete = [gender, name, surname, fioEn];
  const fieldsToDeleteIndividual = [FN_COMPANY.name, FN_COMPANY.nameEn];

  const formatted = { ...requestFields, ...values, contactPersonPhone: `7${values.contactPersonPhone}` };

  if (comparator) {
    fieldsToDeleteIndividual.map(el => delete formatted[el]);
  } else {
    fieldsToDelete.map(el => delete formatted[el]);
  }

  return formatted;
};

export const getValidators = (formValues, isIndividual) => () => Object.keys(formValues).reduce((acc, field) => {
  const fieldInfo = getFieldInfo(field);

  if (fieldInfo.isIndividual !== undefined && fieldInfo.isIndividual !== isIndividual) return acc;
  const validators = fieldInfo?.validator?.length ?
    [...fieldInfo?.uniqueValidator, ...fieldInfo.validator] : fieldInfo?.uniqueValidator || null;

  return ({
    ...acc,
    [field]: [...validators, composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT)],
    [FN_COMPANY.inn]: composeLengthValidator(ENTER_THE_CORRECT_INN_TEXT, { min: isIndividual ? 12 : 10 }),
    [FN_COMPANY.ogrn]: composeLengthValidator(ENTER_THE_CORRECT_OGRN_TEXT, { min: isIndividual ? 15 : 13 }),
  });
}, {});

export const mapFieldsKeys = (fieldsKeys) => fieldsKeys.map((key) => ({
  ...getFieldInfo(key),
  key,
}));

export const isRenderFieldsGroup = (fieldKey) => (fieldKey === FN_ADDRESS.address || fieldKey === FN_USER.wholeName);
export const isRenderFields = (data, fieldKey) => (
  !data.readonlyFields.map(el => el.toLowerCase()).includes(fieldKey.toLowerCase())
);
export const getTextContent = (data, type, key) => (type === 'tab' ? getTabName(key, data[key]) : data[key]);
