import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getLoginPage, getIsAuthCaptchaData, getAuthCaptchaToken } from 'store/common/content';

import { childrenPropTypes } from 'types';

import { FFPhoneInput, FinalForm, FFCaptchaInput } from 'components/FinalForm';
import { FormTemplate } from '../FormTemplate';

import { getValidators } from './helpers';

const DefaultLoginComponent = (props) => {
  const { onClick, onSubmit, children, tooltip, title, description, link, captchaToken, isAuthCaptcha } = props;
  const [isCaptchaSuccess, setIsCaptchaSuccess] = useState(false);
  const [captchaAuthToken, setCaptchaAuthToken] = useState(null);

  const onSuccess = (token) => {
    setCaptchaAuthToken(token);
    setIsCaptchaSuccess(true);
  };

  const handleSubmit = useCallback((values) => {
    onSubmit({ phone: `7${values.phone}`, token: captchaAuthToken });
  }, [captchaAuthToken]);

  return (
    <FormTemplate
      title={title}
      description={description}
      link={link}
      tooltip={tooltip}
      onClick={onClick}
    >
      <FinalForm
        onSubmit={handleSubmit}
        getValidators={() => getValidators(isAuthCaptcha)}
      >
        <FFPhoneInput name="phone" placeholder="Телефон" />
        {isAuthCaptcha ? (
          <FFCaptchaInput
            name="captcha"
            captchaToken={captchaToken}
            checked={isCaptchaSuccess}
            onSuccess={onSuccess}
          />
        ) : null}
        {children}
      </FinalForm>
    </FormTemplate>
  );
};

const mapStateToProps = (state) => ({
  title: getLoginPage(state).defaultLogin?.title,
  description: getLoginPage(state).defaultLogin?.description,
  link: getLoginPage(state).defaultLogin?.link,
  tooltip: getLoginPage(state).tooltip,
  isAuthCaptcha: getIsAuthCaptchaData(state),
  captchaToken: getAuthCaptchaToken(state),
});

DefaultLoginComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  tooltip: PropTypes.object,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  children: childrenPropTypes,
  captchaToken: PropTypes.string,
  isAuthCaptcha: PropTypes.bool,
};

export const DefaultLogin = connect(mapStateToProps)(DefaultLoginComponent);
