import React from 'react';
import MaskedInput from './MaskedInput';

export const NumberMaskedInput = (props) => (
  <MaskedInput
    inputMode="tel"
    unmaskValue={(v) => v.replace(/\D/g, '')}
    {...props}
  />
);
