import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Notification = ({ dictionary, data }) => (Array.isArray(data)
  ? (
    data.map(el => (
      <div key={el} className={cx('notationWrapper')}>
        {dictionary[el] || el}
      </div>
    ))
  )
  : (
    <div className={cx('notationWrapper')}>
      {dictionary[data] || data}
    </div>
  ));

Notification.propTypes = {
  dictionary: PropTypes.object,
  data: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.string,
  ]),
};
