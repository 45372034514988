import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';
import { DescriptionInput as Input, DescriptionInputs as Inputs } from 'shared/Description/components/Input';
import { DescriptionMultipleRadioButtons as RadioButtons } from 'shared/Description/components/RadioButton';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
import { MARGIN_BOTTOM } from '../../constants';

const cx = classNames.bind({ ...commonStyles, ...styles });

const ListContentMapper = ({ content, isMobile }) => {
  const handleClick = useCallback(() => {
    const basicsTab = document.getElementById('basics');

    const isOpen = typeof basicsTab.getAttribute('open') === 'string';

    basicsTab.scrollIntoView({ block: 'start', behavior: 'smooth' });
    if (!isOpen) {
      basicsTab.setAttribute('open', '');
    }
  }, []);

  return (
    <>
      {content.title && (
        <Text isInterphases className={cx('title', isMobile ? MARGIN_BOTTOM.xs : MARGIN_BOTTOM.se)}>
          {content.title}
        </Text>
      )}

      {content.text && (
        <Text isInterphases isMedium>
          {content.text}
        </Text>
      )}

      {content.input && <Input {...content.input} />}

      {content.list?.length && (
        <ul className={cx('list', 'secondary')}>
          {content.list.map((listItem, index) => (
            <li key={index.toString()} className={cx('list-item')}>
              <Text isInterphases isMedium>
                {listItem}
              </Text>
            </li>
          ))}
        </ul>
      )}

      {content.list?.type && (
        <ul className={cx('list', content.list.type)}>
          {content.list.content?.map((listItem, index) => (
            <li key={index.toString()} className={cx('list-item', content.list.type)}>
              <Text isInterphases isMedium>
                {listItem}
              </Text>
            </li>
          ))}
        </ul>
      )}

      {content.inputs && <Inputs inputs={content.inputs} />}

      {content.radioButtons && <RadioButtons radioButtons={content.radioButtons} />}

      {content.afterText && (
        <div className={cx('afterText')}>
          <Text isInterphases isMedium isInline>
            {content.afterText.textBefore}
          </Text>

          <button onClick={handleClick}>
            <Text
              isInterphases
              isMedium
              isPrimary
              isInline
              className={cx('switchTabsButton')}
            >
              {content.afterText.text}
            </Text>
          </button>

          <Text isInterphases isMedium isInline>
            {content.afterText.textAfter}
          </Text>
        </div>
      )}
    </>
  );
};

ListContentMapper.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    input: PropTypes.object,
    inputs: PropTypes.arrayOf(PropTypes.object),
    radioButtons: PropTypes.arrayOf(PropTypes.object),
    list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    afterText: PropTypes.shape({
      textBefore: PropTypes.string,
      text: PropTypes.string,
      textAfter: PropTypes.string,
    }),
  }),
};

export const ContentMapper = ({ content, isMobile }) => (
  <>
    {content.text && (
      <Text isMedium isInterphases>
        {content.text}
      </Text>
    )}

    {content.imageSrc && <img src={content.imageSrc} className={cx('image')} alt="" />}

    {content.list?.length && (
      <ul className={cx('list')}>
        {content.list.map((item, index) => (
          <li key={index.toString()} className={cx('list-item', 'main')}>
            <ListContentMapper content={item} isMobile={isMobile} />
          </li>
        ))}
      </ul>
    )}
  </>
);

ContentMapper.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.shape({
    text: PropTypes.string,
    imageSrc: PropTypes.string,
    list: PropTypes.array,
  }),
};
