import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { compose } from 'utils/fn';
import { withRenderFlag, withMountListener } from 'enhancers';
import { getTopUpPopupState } from 'store/features/user/selectors';
import { closeTopUpPopup, checkToContinuePaymentProcess } from 'store/features/user/action';

import { BasePopupStateless } from 'components';
import { Result } from './Result';
import { Pending } from './Pending';
import { CardView } from './CardView';
import { AmountForm } from './AmountForm';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const TopUpCompanyBalance = ({ closePopup }) => (
  <BasePopupStateless isOpen isWide={false} onClose={closePopup} overlayClassName={cx('overlay')}>
    <section className={cx('container')}>
      <AmountForm />
      <CardView />
      <Pending />
      <Result />
    </section>
  </BasePopupStateless>
);

TopUpCompanyBalance.propTypes = {
  closePopup: PropTypes.func,
};

const mapStateToProps = (state) => ({
  shouldRender: getTopUpPopupState(state),
});

const mapDispatchToProps = {
  closePopup: closeTopUpPopup,
  onMount: checkToContinuePaymentProcess,
};

export const TopUpCompanyBalanceWidget = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withMountListener,
  withRenderFlag,
)(TopUpCompanyBalance);
