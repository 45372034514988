import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getEmloyeeDownloadDocxUrl } from 'requests/api/constants';
import commonStyles from 'styles/common.pcss';

import { Text } from 'components/Text';
import { Button } from 'components/Button';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const DownloadOrder = ({ id }) => (
  <div>
    <Text isLarge isTitle isBold className={cx('marb-xxs')}>
      Выгрузить анкету
    </Text>
    <Text isSmall isSecondary className={cx('marb-s')}>
      {/*Форматы для выгрузки:*/}
    </Text>

    <div className={cx('btns')}>
      {/* <Button isSecondary className={cx('download-btn')}>
          .EXL
        </Button> */}
      <Button
        isSecondary
        className={cx('download-btn')}
        href={getEmloyeeDownloadDocxUrl({ id })}
      >
        .DOC
      </Button>
    </div>
  </div>
);

DownloadOrder.propTypes = {
  id: PropTypes.string,
};

export default DownloadOrder;
