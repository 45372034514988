import { MODAL_STEPS } from './constants';
import { getCurrentCompany } from '../currentCompany/selectors';

const getUserStore = state => state.user;

export const getUserInfo = (state) => getUserStore(state)?.data;
export const getTopUpPopupState = (state) => getUserStore(state)?.topUpPopup;
export const getSendInvoicePopupState = (state) => getUserStore(state)?.sendInvoicePopup;
export const getAmountValue = (state) => getUserStore(state)?.amount ?? 0;
export const getUserCtn = (state) => getUserStore(state)?.ctn;
export const getTransId = (state) => getUserStore(state)?.transId;
export const getModalStep = (state) => getUserStore(state)?.modalStep ?? MODAL_STEPS.amountForm;
export const getNeedMakeProceedReq = (state) => getUserStore(state)?.needMakeProceedRequest;

export const hasActiveCompanyBan = (state) => !!getCurrentCompany(state)?.ban;
