import React from 'react';
import PropTypes from 'prop-types';
import { useRequest } from 'ahooks';
import { connect } from 'react-redux';

import { createSubscriptionRequest } from 'requests/api/subscription';
import { getOdrerDetailsId } from 'store/features/details-order/selectors';

import { View } from './View';

const BanInfoComponent = ({ orderId }) => {
  const { data } = useRequest(() => createSubscriptionRequest({ id: orderId }), { ready: !!orderId });

  return (
    <View
      {...data}
      shouldRender={data?.ban}
    />
  );
};

BanInfoComponent.propTypes = {
  orderId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  orderId: getOdrerDetailsId(state),
});

export const OrderNumberAndFakePhoneV2 = connect(mapStateToProps)(BanInfoComponent);
