import React from 'react';
import PropTypes from 'prop-types';
import { StaticRouter, MemoryRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { browserHistory } from 'store/history';


export default function withReactRouter() {
  const Router = ({ children }) => (
    typeof window === 'undefined' ? (
      <StaticRouter basename="/">
        <MemoryRouter>
          {children}
        </MemoryRouter>
      </StaticRouter>
    ) : (
      <ConnectedRouter history={browserHistory}>
        {children}
      </ConnectedRouter>
    )
  );

  Router.propTypes = {
    children: PropTypes.node,
  };

  return WrappedComponent => (
    (props) => (
      <Router baseurl="">
        <WrappedComponent {...props} />
      </Router>
    )
  );
}
