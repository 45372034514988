import { createAction } from '@reduxjs/toolkit';

import { fetchCompanyTemplateData, fetchCompanyDataByINN, checkPassportRequest } from 'requests/api/company';
import { getCompanyFormValues } from './selectors';

export const setPassportIsValid = createAction('PASSPORT_IS_VALID');
export const setInvalidPassportModal = createAction('INVALID_PASSPORT_MODAL_OPEN');
export const setCitySelected = createAction('CITY_SELECTED');
export const setConfirmResetPopupState = createAction('SET_CONFIRM_RESET_POPUP');
export const resetCompanyForm = createAction('RESET_COMPANY_FORM');
export const setCompanyFormValues = createAction('SET_CF_VALUES');
export const setIsIndividualEntrepreneur = createAction('SET_CF_IS_INDIVIDUAL');
export const setCompanyFormTemplate = createAction('SET_CF_TEMPLATE', ({ template, ...rest }) => ({
  payload: {
    values: template,
    ...rest,
  },
}));

export const openInvalidPassportModal = () => setInvalidPassportModal(true);
export const closeInvalidPassportModal = () => setInvalidPassportModal(false);

export const loadCompanyFormTemplate = () => async (dispatch) => {
  const result = await fetchCompanyTemplateData();
  dispatch(setCompanyFormTemplate(result));
};

export const loadCompanyDataByINN = (inn) => async (dispatch, getState) => {
  const result = await fetchCompanyDataByINN(inn);

  if (result) {
    const formValues = getCompanyFormValues(getState());

    dispatch(setCompanyFormValues({ ...formValues, ...result }));
  }
};

export const setFormValuesByBankSuggestion = (bankName, suggestions) => (dispatch, getState) => {
  const formValues = getCompanyFormValues(getState());
  const dataBySuggestion = suggestions.find((el) => el.bankName === bankName);

  setTimeout(() => {
    dispatch(setCompanyFormValues({ ...formValues, ...dataBySuggestion }));
  });
};

export const setFormValuesByCitySuggestion = (city, suggestions) => (dispatch, getState) => {
  const formValues = getCompanyFormValues(getState());
  const dataBySuggestion = suggestions.find((el) => el.city === city);

  setTimeout(() => {
    dispatch(setCompanyFormValues({ ...formValues, ...dataBySuggestion }));
  });
};

export const sendCheckPassportRequest = (docId, docNo) => async (dispatch) => {
  const isValidPassport = await checkPassportRequest(docId, docNo);

  dispatch(setPassportIsValid(isValidPassport));

  if (!isValidPassport) {
    dispatch(openInvalidPassportModal());
  }
};
