import { axiosAuthorizedRequest } from '../helpers';
import { getCustomerOrdersDataUrl, getCompanyOrdersUrl } from './constants';

export const getCustomerOrdersDataRequest = ({ ...args }) => axiosAuthorizedRequest({
  url: getCustomerOrdersDataUrl(),
  params: { ...args },
}).then(({ data }) => data);

export const getCompanyOrdersRequest = (companyId) => axiosAuthorizedRequest({
  url: getCompanyOrdersUrl(companyId),
})
  .then(({ data }) => data);
