import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getInstructionBasicsBlock } from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Text } from 'components';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
import { MARGIN_BOTTOM } from '../../constants';

const cx = classNames.bind({ ...commonStyles, ...styles });

const BasicsBlock = ({ content: { text, heading, list }, isMobile, className }) => (
  <div className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl, className)}>
    {text && (
      <Text
        className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl)}
        isInterphases
        isMedium
        isDarker
      >
        {text}
      </Text>
    )}

    {heading && (
      <Text
        className={cx('list-heading', isMobile ? MARGIN_BOTTOM.xs : MARGIN_BOTTOM.xl)}
        isInterphases
        isPrimary
        isLarge
      >
        {heading}
      </Text>
    )}

    {list?.length && (
      <ul className={cx('list')}>
        {list.map((item, index) => (
          <li key={index.toString()} className={cx('list-item', isMobile ? MARGIN_BOTTOM.xs : MARGIN_BOTTOM.xl)}>
            {item.title && (
              <Text
                isInterphases
                isMedium
                className={cx('title', isMobile ? MARGIN_BOTTOM.xs : MARGIN_BOTTOM.se)}
              >
                {item.title}
              </Text>
            )}

            {item.text && (
              <Text isInterphases isMedium>
                {item.text}
              </Text>
            )}
          </li>
        ))}
      </ul>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  content: getInstructionBasicsBlock(state),
  shouldRender: !!getInstructionBasicsBlock(state),
  isMobile: isScreenBelow800(state),
});

BasicsBlock.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    heading: PropTypes.string,
    list: PropTypes.array,
  }),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const Basics = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(BasicsBlock);
