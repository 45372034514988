import { isEmptyObject, isNullOrUndefined } from 'utils/fn';

const sortNavItems = (navItems) => [...navItems].sort((x, y) => x.order - y.order);
const filterNavItems = (result) => result.filter((item) => item.status === 'fulfilled');

export const mapNavigateItem = (order, data) => {
  if (isEmptyObject(data) || isNullOrUndefined(data)) {
    return undefined;
  }

  return { order, link: data.link, target: '_blank', title: data.displayName };
};

export const mapNavItems = (navItems, routes) => {
  const filteredNavItems = navItems?.reduce ? filterNavItems(navItems).map((item) => ({ ...item.value })) : navItems;
  const allItems = navItems ? [...routes, ...filteredNavItems] : routes;

  return sortNavItems(allItems);
};
