import React from 'react';
import { childrenPropTypes } from 'types';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const NavList = ({ children }) => (
  <nav className={cx('nav')} aria-label="Список пунктов документации">
    <ul className={cx('list')}>
      {children}
    </ul>
  </nav>
);

NavList.propTypes = {
  children: childrenPropTypes,
};
