import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import BackButton from 'components/BackButton';

import { BasePopupStateless } from '../BasePopup';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const PopupWithBackBtn = ({ isShowBackBtn, onBackClick, children, ...props }) => (
  <BasePopupStateless {...props}>
    {isShowBackBtn && (
      <div className={cx('backBtnWrap')}>
        <BackButton
          onClick={onBackClick}
          text="Назад"
        />
      </div>
    )}
    {children}
  </BasePopupStateless>
);

PopupWithBackBtn.propTypes = {
  ...BasePopupStateless.propTypes,
  isShowBackBtn: PropTypes.bool,
  onBackClick: PropTypes.func,
};
