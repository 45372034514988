import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Heading } from 'components/Heading';
import { Text } from 'components/Text';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ErrorPage = ({ headingText, descriptionText }) => (
  <div>
    <Heading className={cx('heading')} size={4}>
      {headingText}
    </Heading>
    <Text className={cx('description')}>
      {descriptionText}
    </Text>
  </div>
);

ErrorPage.propTypes = {
  headingText: PropTypes.string,
  descriptionText: PropTypes.string,
};

export default ErrorPage;
