import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import commonStyles from 'styles/common.pcss';

import { getApiInstructionBlock } from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Text } from 'components';
import { getRandomKey } from '../../../../tools/getRandomKey';
import { Table } from '../FirstCall/Table';
import { MARGIN_BOTTOM } from '../../constants';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const ApiInstructionBlock = ({ content: { list }, isMobile, className }) => (
  <div className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl, className)}>

    {list?.length && (
      <ol className={cx('list')}>

        {list.map((item) => (
          <li key={getRandomKey()} className={cx('list-item', isMobile ? MARGIN_BOTTOM.xs : MARGIN_BOTTOM.xl)}>
            {item.title && (
              <Text
                isInterphases
                isMedium
                className={cx('title', isMobile ? MARGIN_BOTTOM.xs : MARGIN_BOTTOM.se)}
              >
                {item.title}
              </Text>
            )}

            {item.content.length && item.content.map((contentElement) => {
              if (typeof contentElement === 'string') {
                return <Text key={getRandomKey()} isInterphases isMedium>{contentElement}</Text>;
              }
              if (typeof contentElement === 'object') {
                return <Table key={getRandomKey()} updated={contentElement} />;
              }
              return null;
            })}

          </li>
        ))}

      </ol>
    )}

  </div>
);

const mapStateToProps = (state) => ({
  content: getApiInstructionBlock(state),
  shouldRender: !!getApiInstructionBlock(state),
  isMobile: isScreenBelow800(state),
});

ApiInstructionBlock.propTypes = {
  content: PropTypes.shape({
    list: PropTypes.array,
  }),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const ApiInstruction = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(ApiInstructionBlock);
