import { blankObj } from 'utils/fn';

export const selectHelpAndSupport = (state) => state.helpAndSupport || blankObj;

export const selectIsPopupOpen = (state) => selectHelpAndSupport(state)?.isOpen;
export const selectIsMessageSent = (state) => selectHelpAndSupport(state)?.messageSent;
export const selectIsError = (state) => selectHelpAndSupport(state)?.error;
export const selectCurrentCategory = (state) => selectHelpAndSupport(state)?.currentCategory;
export const selectCategories = (state) => selectHelpAndSupport(state)?.categories;

