import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components';
import { Row, RowItem } from 'shared/Order/Grid';
import { withRenderFlag } from 'enhancers/withRenderFlag';

const ViewComponent = ({ ban, fakePhone }) => (
  <React.Fragment>
    <Text isLarge isTitle className="mart-m marb-s">
      Номер договора/фиктивный номер на биллинге
    </Text>

    {ban && (
      <Row>
        <RowItem>
          <Text isSecondary>Номер договора</Text>
        </RowItem>
        <RowItem>
          <Text>{ban}</Text>
        </RowItem>
      </Row>
    )}

    {fakePhone && (
      <Row>
        <RowItem>
          <Text isSecondary>Фиктивный номер</Text>
        </RowItem>
        <RowItem>
          <Text>{fakePhone}</Text>
        </RowItem>
      </Row>
    )}
  </React.Fragment>
);

ViewComponent.propTypes = {
  ban: PropTypes.string,
  fakePhone: PropTypes.string,
};

export const View = withRenderFlag(ViewComponent);
