import { passAsIs } from 'utils/fn';
import { SET_CURRENT_COMPANY } from './constants';

const reducers = {
  [SET_CURRENT_COMPANY]: (state, action) => ({
    data: action.data,
  }),
};

export const currentCompanyReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
