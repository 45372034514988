import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'ahooks';

import { fetchNavigateItems } from 'requests/api/contents';

import { getRoutes } from 'store/common/content';
import { setNavItems } from 'store/common/navigation/actions';

import { mapNavigateItem, mapNavItems } from './helpers';
import { useUnauthorizedZone } from '../useUnauthorizedZone';

export const useNavigation = () => {
  const isUnauthorizedZone = useUnauthorizedZone();
  const routes = useSelector(getRoutes);
  const dispatch = useDispatch();
  const onSetNavigation = (data) => dispatch(setNavItems(data));

  const { run: getNavItems } = useRequest(fetchNavigateItems, {
    manual: true,
    onSuccess: ({ data }) => { onSetNavigation(mapNavItems(mapNavigateItem(4, data), routes)); },
    onError: () => { onSetNavigation(routes); },
  });

  useEffect(() => {
    if (!isUnauthorizedZone) {
      getNavItems();
    }
  }, [isUnauthorizedZone]);
};
