import { connect } from 'react-redux';

import { Preloader } from 'components';

import { compose } from 'utils/fn';
import { withRenderFlag, withMountListener } from 'enhancers';

import { getModalStep } from 'store/features/user/selectors';
import { MODAL_STEPS } from 'store/features/user/constants';
import { continuePaymentProcess } from 'store/features/user/action';

export const Pending = compose(
  connect(
    (state) => ({ shouldRender: getModalStep(state) === MODAL_STEPS.pending }),
    { onMount: continuePaymentProcess },
  ),
  withRenderFlag,
  withMountListener,
)(Preloader);
