import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getCompanyName } from 'utils/fn';
import { BasePopupStateless } from 'components/Popup/components/BasePopup';
import { Button } from 'components/Button';
import { Text } from 'components/Text';
import DropDown from 'components/Dropdown';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const CompanyPopup = ({ companies, onChooseCallback, onChooseCreateCompanyCallback, ...props }) => {
  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (companies?.length) {
      setCompany(companies[0].id);
    }
  }, [companies]);

  const changeCompany = (option) => {
    setCompany(option);
  };

  const completeChoose = () => {
    if (!company) {
      onChooseCreateCompanyCallback();
    } else {
      onChooseCallback(company);
    }
  };

  const formatOptions = (arr) => {
    const formatted = arr.map(el => ({ value: el.id, label: getCompanyName(el) }));

    if (arr.every(el => !el.isOwner)) {
      return formatted.concat({ value: '', label: 'Создать свою компанию' });
    }

    return formatted;
  };

  return (
    <BasePopupStateless
      className={cx('popup-container')}
      overlayClassName={cx('overlay')}
      {...props}
    >
      <div className={cx('wrapper')}>
        <Text className={cx('text')}>Выберите компанию</Text>
        <DropDown
          onChange={changeCompany}
          value={company}
          options={formatOptions(companies)}
        />
        <Button className={cx('button')} isWide onClick={completeChoose}>
          {!company ? 'Создать' : 'Войти'}
        </Button>
      </div>
    </BasePopupStateless>
  );
};

CompanyPopup.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    isOwner: PropTypes.bool,
  })),
  onChooseCallback: PropTypes.func,
  onChooseCreateCompanyCallback: PropTypes.func,
};
