import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import StatelessTableCheckBox from 'components/Checkbox/StatelessTableCheckBox';

import styles from '../../styles.pcss';
const cx = classNames.bind(styles);

const TableRow = ({ columns, itemRow, rowId, active, checkBoxChangeAction, rowNumber, widthIE, onRowClick }) => (
  <tr id={rowId} className={cx('dataRow')} onClick={onRowClick} >
    <td
      style={widthIE ? { width: (`${0.6 / widthIE}%`) } : {}}
      className={cx('first-cell')}
    >
      <StatelessTableCheckBox checked={active} onChange={checkBoxChangeAction} rowId={rowId} />
    </td>
    <td
      style={widthIE ? { width: (`${0.6 / widthIE}%`) } : {}}
    >{rowNumber}
    </td>
    {
        columns.map(headLabel => (
          <td
            style={widthIE ? { width: (`${headLabel.maxSize / widthIE}%`) } : {}}
            key={headLabel.key}
          >
            {
              headLabel.prerenderFunction
                ?
                headLabel.prerenderFunction(itemRow)
                :
                <span className="default-cell">itemRow[headLabel.key]</span>
            }
          </td>
      ))}
  </tr>
);

TableRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    minSize: PropTypes.string,
    maxSize: PropTypes.number,
  })),
  active: PropTypes.bool,
  itemRow: PropTypes.object,
  rowId: PropTypes.string,
  checkBoxChangeAction: PropTypes.func,
  rowNumber: PropTypes.number,
  onRowClick: PropTypes.func,
  widthIE: PropTypes.number,
};

export default withRouter(TableRow);
