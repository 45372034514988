import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getLoginPage } from 'store/common/content';

import Error from 'shared/Error';
import { Button, Text } from 'components';
import { EmployeeLogin, DefaultLogin, Loading, PageContent } from './components';

import { useLoginRequests, useBeforeLogin } from './hooks';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AuthorizationComponent = ({ loadingText, loginText, userErrors }) => {
  useBeforeLogin();

  const [defaultForm, setDefault] = useState(true);
  const { loading, onDefaultLogin, onEmployeeLogin, isAdAuthAvailable, error, onReset } = useLoginRequests();

  const toggleForm = useCallback(() => {
    if (loading) return;

    setDefault((prev) => !prev);
  }, [loading]);

  return (
    <PageContent
      error={error && <Error onClick={onReset} {...userErrors[error]} />}
      loading={loading && (
        <Loading>
          {defaultForm && (
            <Text isSmall className={cx('mart-xxs', 'loadingText')}>
              {loadingText}
            </Text>
          )}
        </Loading>
      )}
    >
      {defaultForm ? (
        <DefaultLogin
          onSubmit={onDefaultLogin}
          onClick={isAdAuthAvailable ? toggleForm : undefined}
        >
          <Button
            className={cx('button')}
            isSubmit
            isWide
            isDisabled={loading}
          >
            {loginText}
          </Button>
        </DefaultLogin>
      ) : (
        <EmployeeLogin onSubmit={onEmployeeLogin} onClick={toggleForm}>
          <Button
            className={cx('button')}
            isSubmit
            isWide
            isDisabled={loading}
          >
            {loginText}
          </Button>
        </EmployeeLogin>
      )}
    </PageContent>
  );
};

const mapStateToProps = (state) => ({
  userErrors: getLoginPage(state).userErrors,
  loadingText: getLoginPage(state).loadingText,
  loginText: getLoginPage(state).loginText,
});

AuthorizationComponent.propTypes = {
  userErrors: PropTypes.object,
  loadingText: PropTypes.string,
  loginText: PropTypes.string,
};

export const Authorization = connect(mapStateToProps)(AuthorizationComponent);
