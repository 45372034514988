import { axiosAuthorizedRequest } from 'requests/helpers';
import { getEmloyeeSendMsgToOperatorsUrl } from './constants';

export const sendMsgToOperatorsRequest = ({ id, operators }) => axiosAuthorizedRequest({
  url: getEmloyeeSendMsgToOperatorsUrl(),
  method: 'POST',
  data: {
    formId: id,
    operators,
  },
});
