import { composePhoneValidator, composeRequiredValidator, composeCheckedValidator } from 'utils/field-validators';
import { ENTER_THE_CORRECT_PHONE_NUMBER_TEXT, FIELD_CANNOT_BE_EMPTY_TEXT, CAPCHA_REQUIRED } from 'constants/validators';

export const getValidators = (isAuthCaptcha) => ({
  phone: [
    composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
    composePhoneValidator(ENTER_THE_CORRECT_PHONE_NUMBER_TEXT),
  ],
  captcha: isAuthCaptcha ? [
    composeCheckedValidator(CAPCHA_REQUIRED),
  ] : null,
});
