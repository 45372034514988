import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import commonStyles from 'styles/common.pcss';

import styles from './styles.pcss';

const cx = classNames.bind(({ ...commonStyles, ...styles }));

/* TODO: Remove old table after release, as it's incompatible with new table structure */
export const Table = ({ content, updated, className }) => (
  <div className={cx('wrapper', className)}>
    {updated ? (
      <table className={cx('component')}>
        <thead>
          <tr className={cx('row')}>
            {updated.headings?.map((heading, index) => (
              <th key={index.toString()} className={cx('heading')}>
                <Text className={cx('name')} isInterphases isLarge>
                  {heading}
                </Text>
              </th>
          ))}
          </tr>
        </thead>
        <tbody>
          {updated.rows?.map((row, rIdx) => (
            <tr key={rIdx.toString()} className={cx('row')}>
              {row.map((value, idx) => (
                <td key={idx.toString()} className={cx('value')}>
                  <Text
                    isMedium
                    isInterphases
                  >
                    {value}
                  </Text>
                </td>
            ))}
            </tr>
        ))}
        </tbody>
      </table>
    ) : (
      <div className={cx('component', 'old')}>
        {content.map(({ name, values }, index) => (
          <div key={index.toString()} className={cx('column')}>
            <Text className={cx('name')} isInterphases isLarge>
              {name}
            </Text>
            {values.map((value, idx) => (
              <Text
                key={idx.toString()}
                className={cx('value')}
                isDarker
                isMedium
                isInterphases
              >
                {value}
              </Text>
            ))}
          </div>
        ))}
      </div>
    )}
  </div>
);

Table.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  })),
  updated: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  }),
  className: PropTypes.string,
};
