import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { Heading, Text } from 'components';
import { ConfirmPopup } from 'components/Popup';

import { nullishAnObject } from 'utils/fn';
import { getNextIsIndividualValue } from 'utils/companies/helpers';
import {
  setCompanyFormValues,
  setConfirmResetPopupState,
  setIsIndividualEntrepreneur,
} from 'store/features/companyForm/actions';
import { getCompanyFormValues, getCompanyTypes, getConfirmResetPopupState } from 'store/features/companyForm/selectors';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ModalComponent = ({
  popupState,
  closePopup,
  formValues,
  setFormData,
  allCompanyTypes,
  onCompanyTypeChangeSpy,
}) => (
  <ConfirmPopup
    isWide={false}
    isOpen={!!popupState}
    onClose={closePopup}
    failureEvent={closePopup}
    confirmEvent={() => {
      setFormData({ ...nullishAnObject(formValues), companyType: popupState, gender: 'M' });
      onCompanyTypeChangeSpy(getNextIsIndividualValue(popupState, allCompanyTypes));
      closePopup();
    }}
  >
    <section className={cx('container')}>
      <Heading size={3}>
        Внимание
      </Heading>
      <Text className={cx('text')}>
        При изменении типа юридического лица все заполненные данные будут сброшены. Продолжить ?
      </Text>
    </section>
  </ConfirmPopup>
);

ModalComponent.propTypes = {
  popupState: PropTypes.oneOfType([
    PropTypes.bool, PropTypes.string, PropTypes.object,
  ]),
  closePopup: PropTypes.func,
  setFormData: PropTypes.func,
  formValues: PropTypes.object,
  onCompanyTypeChangeSpy: PropTypes.func,
  allCompanyTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  allCompanyTypes: getCompanyTypes(state),
  formValues: getCompanyFormValues(state),
  popupState: getConfirmResetPopupState(state),
});

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => { dispatch(setConfirmResetPopupState(null)); },
  setFormData: (v) => { dispatch(setCompanyFormValues(v)); },
  onCompanyTypeChangeSpy: (v) => { dispatch(setIsIndividualEntrepreneur(v)); },
});

export const ConfirmResetPopup = connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
