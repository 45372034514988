import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Heading, Text } from 'components';

import { withRenderFlag } from 'enhancers/withRenderFlag';
import { MODAL_STEPS } from 'store/features/user/constants';
import { getModalStep } from 'store/features/user/selectors';
import { getTopUpCompanyBalance } from 'store/common/content';

const ResultComponent = ({ content }) => (
  <>
    <Heading size={4} className="center marb-s">
      {content.title}
    </Heading>

    <Text isSmall className="center">
      {content.description}
    </Text>
  </>
);

ResultComponent.propTypes = {
  content: PropTypes.object,
};

const mapStateToProps = (state) => {
  const modalStep = getModalStep(state);
  const isError = modalStep === MODAL_STEPS.fail;
  const isSuccess = modalStep === MODAL_STEPS.success;

  return {
    shouldRender: isError || isSuccess,
    content: getTopUpCompanyBalance(state)[modalStep] || {},
  };
};

export const Result = connect(mapStateToProps)(withRenderFlag(ResultComponent));
