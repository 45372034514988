import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'types';

import { DescriptionText as Text } from '../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const List = ({ text, children, type }) => (
  <>
    {text && (
      <Text>
        {text}
      </Text>
    )}

    <ol className={cx('list', type)}>
      {children.map((child, index) => (
        <li key={index.toString()} className={cx('item', type)}>
          {child}
        </li>
      ))}
    </ol>
  </>
);

List.propTypes = {
  children: childrenPropTypes,
  text: PropTypes.string,
  type: PropTypes.string,
};
