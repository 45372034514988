import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

export const RadioGroup = ({ name, value, disabled, onChange, className, children }) => (
  <div className={className}>
    {Children.toArray(children).map((child) => (
      child && cloneElement(child, {
        name,
        disabled: disabled || child.props.disabled,
        checked: value === child.props.value,
        onChange,
      })
    ))}
  </div>
);

RadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.any,
};
