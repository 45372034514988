import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { Text } from 'components/Text';
import { Button } from 'components/Button';
import commonStyles from 'styles/common.pcss';

import { FIELD_TYPES, VIEW_TYPES } from 'utils/orders/constants';
import { getFieldInfo, getValue } from 'utils/orders/helpers';
import { getAllTexts } from 'store/common/texts/selectors';
import { Row, RowItem } from '../Grid';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const OrderGroupInfo = ({ alias, fields, onEditClick, forEmployeePage = false, dictionary, canShowEditButton }) => {
  const fieldsInfo = fields
    .map(field => getFieldInfo(field))
    .filter(({ displayValue, fieldType, alias: fieldAlias }) => {
      if (fieldAlias === 'WorkScheme') return false;
      if (fieldType === FIELD_TYPES.Boolean && !getValue(fields.find(f => f.alias === fieldAlias))) return false;
      return (displayValue ?? false);
    });

  const isShowName = (field) => !forEmployeePage || field.alias !== 'TargetsDescription';
  const handleFieldName = field => {
    if (field.alias === 'ClientName' && forEmployeePage) {
      return `${field.name} (название организации в пуше)`;
    }

    if (['HttpsNotificationUrl', 'HttpsJWKSUrl', 'HttpsCallbackUrl'].includes(field.alias) && forEmployeePage) {
      return `${field.nameForEmployee}`;
    }

    if (field.alias === 'SMSPush') {
      return `${field.name} <br /><span>(Для абонентов билайн)</span>`;
    }

    return field.name;
  };

  const renderTab = (field) => {
    const info = field.info?.find(i => i.itemAlias === getValue(fields.find((f => f.alias === field.alias))));

    return (
      <div key={field.alias} className={cx('big-square')}>
        <Text>{field.displayValue}</Text>
        {info && (
          <Text isSmall isTitle className={cx('mart-xxs')}>
            {dictionary[info.text]}
          </Text>
        )}
      </div>
    );
  };

  const renderEditButton = () => {
    if (!canShowEditButton && alias === 'TargetsDescription') return null;

    return (
      onEditClick &&
      <Button className={cx('edit-btn')} isSecondary onClick={() => onEditClick(alias)}>
        Редактировать
      </Button>
    );
  };

  return (
    <div>
      {fieldsInfo.map(field => (
        (!onEditClick && field.isBig && field.viewType === VIEW_TYPES.Tab) ? (
          renderTab(field)
        ) : (
          <Row key={field.alias}>
            {isShowName(field) && (
              <RowItem key={`row-item-inner-${field.alias}`}>
                <Text className={cx('fieldName')} isSecondary>{handleFieldName(field)}</Text>
              </RowItem>
            )}
            <RowItem key={`row-item-${field.alias}`}>
              <Text className={cx('field-value')}>{field.displayValue}</Text>
            </RowItem>
          </Row>
        )
      ))}
      {renderEditButton()}
    </div>
  );
};

OrderGroupInfo.propTypes = {
  alias: PropTypes.string,
  fields: PropTypes.array,
  onEditClick: PropTypes.func,
  forEmployeePage: PropTypes.bool,
  dictionary: PropTypes.object,
  canShowEditButton: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  dictionary: getAllTexts(state),
});


export default connect(mapStateToProps)(OrderGroupInfo);
