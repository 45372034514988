// /api/v1/customer/form/sendtoreview/{id}

import { axiosAuthorizedRequest } from 'requests/helpers';
import { getSendToReviewRequestUrl } from './constants';

export const sendToReviewRequest = ({
  id,
}) => axiosAuthorizedRequest({
  url: getSendToReviewRequestUrl(id),
  method: 'POST',
})
  .then(({ data }) => data);
