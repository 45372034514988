import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { setTransactionId, toErrorTopUpView, toPendingPaymentView } from 'store/features/user/action';
import { withRenderFlag } from 'enhancers/withRenderFlag';
import { MODAL_STEPS } from 'store/features/user/constants';
import { getAmountValue, getModalStep, getUserCtn } from 'store/features/user/selectors';
import { getTopUpCompanyBalance } from 'store/common/content';

import { Button, Heading, Text } from 'components';

import { getIFrameSrc } from '../iframeParams';
import { createPaymentMessage } from './postMessage';
import { usePaymentWidget } from './usePaymentWidget';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const CardViewComponent = ({ content, amount, ctn, onChangeTransactionId, onSuccess, onError }) => {
  const [submitting, setState] = useState(false);
  const {
    hid,
    isValid,
    isDirty,
    validationMsg = content.cardView?.validationMsg,
  } = usePaymentWidget({ onChangeTransactionId, onSuccess, onError });

  return (
    <>
      <Heading size={4} className={cx('left', 'marb-s')}>
        {content.heading}
      </Heading>

      <iframe
        id="WP_Widget"
        title="WP_Widget"
        src={getIFrameSrc()}
        className={cx('iframe', { disabled: submitting })}
      />

      {isDirty && !isValid && (
        <Text isSmall className={cx('error', 'mart-s')}>
          {validationMsg}
        </Text>
      )}

      <Button
        isWide
        className="mart-s"
        isDisabled={submitting || !isValid}
        onClick={() => {
          setState(true);
          createPaymentMessage(amount, ctn, hid);
        }}
      >
        {content.cardView?.buttonText}
      </Button>
    </>
  );
};

CardViewComponent.propTypes = {
  content: PropTypes.object,
  ctn: PropTypes.string,
  amount: PropTypes.number,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  onChangeTransactionId: PropTypes.func,
};

const mapStateToProps = (state) => ({
  ctn: getUserCtn(state),
  amount: getAmountValue(state),
  shouldRender: getModalStep(state) === MODAL_STEPS.cardView,
  content: getTopUpCompanyBalance(state),
});

const mapDispatchToProps = {
  onError: toErrorTopUpView,
  onSuccess: toPendingPaymentView,
  onChangeTransactionId: setTransactionId,
};

export const CardView = connect(mapStateToProps, mapDispatchToProps)(withRenderFlag(CardViewComponent));
