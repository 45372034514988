import { sendToReviewRequest } from 'requests/api/review';
import { createStatefulActionTypes } from 'utils/redux-stateful';
import { saveGroupFieldsDraft } from '../draft-order/action';
import { isDraftOrderError } from '../draft-order/selectors';
import { SEND_TO_REVIEW, CLEAR_AFTER_SUCCESS } from './constants';

const actions = createStatefulActionTypes(SEND_TO_REVIEW);
export const sendToReview = ({
  id,
  lastGroup: {
    alias,
    values,
    fields,
  },
}) => async (dispatch, getState) => {
  dispatch({ type: actions.SET_PENDING });

  try {
    await saveGroupFieldsDraft({ id, alias, values, fields })(dispatch, getState);
    const isDraftError = isDraftOrderError(getState());

    if (isDraftError) {
      throw new Error('saveGroupFieldsDraft failed');
    }

    await sendToReviewRequest({ id });
    return dispatch({ type: actions.SET_SUCCESS, data: { id } });
  } catch (err) {
    return dispatch({ type: actions.SET_ERROR, data: { id } });
  }
};

export const clearAfterSuccess = () => ({ type: CLEAR_AFTER_SUCCESS });
