import React from 'react';
import PropTypes from 'prop-types';

export function withMountListener(WrappedComponent) {
  const MountListener = ({ onMount, onUnmount, ...props }) => {
    React.useEffect(() => {
      if (typeof onMount === 'function') {
        onMount();
      }
    }, [onMount]);

    React.useEffect(() => () => onUnmount?.(), [onUnmount]);

    return <WrappedComponent {...props} />;
  };

  MountListener.propTypes = {
    ...WrappedComponent.propTypes,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
  };

  return MountListener;
}
