import React from 'react';
import PropTypes from 'prop-types';
import { useRequest } from 'ahooks';
import { connect } from 'react-redux';

import { loadCompanyFormTemplate } from 'store/features/companyForm/actions';

import { Preloader } from 'components';
import PageLayout from 'shared/PageLayout';
import CompanyForm from 'shared/CompanyForm';

const CreateCompanyPage = ({ loadTemplate }) => {
  const { loading } = useRequest(loadTemplate);

  return (
    <PageLayout>
      {loading ? <Preloader /> : <CompanyForm />}
    </PageLayout>
  );
};

CreateCompanyPage.propTypes = { loadTemplate: PropTypes.func };

export default connect(null, { loadTemplate: loadCompanyFormTemplate })(CreateCompanyPage);
