import { changeOrderStatusRequest } from 'requests/api/change-status';
import { saveEmployeeOrderGroupFieldsRequest } from 'requests/api/create-order';
import { mapValueToSendObject } from 'utils/orders/mapValuesToSend';
import { createStatefulActionTypes } from 'utils/redux-stateful';
import { GROUPS_ALIASES } from 'utils/orders/constants';
import { SEND_TO_ACCEPT, SET_ACCEPTED } from './constants';

const actions = createStatefulActionTypes(SEND_TO_ACCEPT);

export const saveAndSendToAccept = ({
  values,
  groups,
  id,
  isChangeStatus = true,
}) => async (dispatch) => {
  const mappedGroups = groups
    .map(g => ({
      ...g,
      fields: mapValueToSendObject({ values, fields: g.fields, isExcludeEmpty: true }),
    }))
    .filter(g => g.fields.length > 0);
  dispatch({ type: actions.SET_PENDING });
  try {
    const promises = mappedGroups.map((g) => saveEmployeeOrderGroupFieldsRequest({
      alias: g.alias,
      fields: g.fields,
      orderId: id,
    }));

    await Promise.all(promises);

    if (isChangeStatus) {
      await changeOrderStatusRequest({ orderId: id, status: 'Accepted' });
    }
    return dispatch({ type: actions.SET_SUCCESS, data: { isAccepted: isChangeStatus } });
  } catch (err) {
    return dispatch({ type: actions.SET_ERROR });
  }
};

export const updateSecretAggregator = ({
  values,
  id,
}) => async (dispatch) => {
  dispatch({ type: actions.SET_PENDING });
  try {
    await saveEmployeeOrderGroupFieldsRequest({
      alias: GROUPS_ALIASES.authCode,
      fields:
        [{
          alias: 'ClientIdAggregator',
          stringValue: values.clientIdAggregator,
        },
        {
          alias: 'ClientSecretAggregator',
          stringValue: values.clientSecretAggregator,
        },
        ],
      orderId: id,
    });
    return dispatch({ type: actions.SET_SUCCESS });
  } catch (err) {
    return dispatch({ type: actions.SET_ERROR });
  }
};

export const setAcceptedOnUnmount = () => ({ type: SET_ACCEPTED, data: { isAccepted: false } });
