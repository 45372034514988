export const TOTAL_NUMBERS_3 = 3;
export const TOTAL_NUMBERS_4 = 4;
export const TOTAL_BUTTONS_5 = 5;
export const TOTAL_BUTTONS_6 = 6;

export const ACTIVE_PAGE_2 = 2;
export const WITHOUT_PAGE_NEIGHBOURS = 0;
export const PAGE_NEIGHBOURS_1 = 1;

export const START_PAGE_1 = 1;
export const START_PAGE_2 = 2;

export const DECREMENT_NUMBER = 1;
export const INCREMENT_NUMBER = 1;
