import {
  getCompanyDetailsRequest,
  getUpdateCompanyRequest,
  getCreateCompanyMemberRequest,
  getUpdateCompanyMemberRequest,
  getDeleteCompanyMemberRequest,
  getMakeCompanyOwnerRequest,
  getUpdateCompanyBanRequest,
} from 'requests/api/company';
import { createStatefulRequest } from 'utils/redux-stateful';
import { getAdmin } from 'store/features/company-details/selectors';
import { getResponseError } from 'requests/helpers';
import {
  GET_COMPANY_DETAILS,
  UPDATE_COMPANY, UPDATE_COMPANY_PENDING, UPDATE_COMPANY_ERROR,
  CREATE_NEW_MEMBER, CREATE_MEMBER_INITIAL, CREATE_MEMBER_PENDING, CREATE_MEMBER_ERROR,
  UPDATE_MEMBER, UPDATE_MEMBER_INITIAL, UPDATE_MEMBER_PENDING, UPDATE_MEMBER_ERROR,
  DELETE_MEMBER, DELETE_MEMBER_PENDING, DELETE_MEMBER_ERROR,
  MAKE_OWNER, MAKE_OWNER_PENDING, MAKE_OWNER_ERROR,
  MOVE_FROM_ADMIN_TO_MEMBERS,
  UPDATE_COMPANY_BAN,
  UPDATE_COMPANY_BAN_ERROR,
  UPDATE_COMPANY_BAN_PENDING,
} from './constants';

export const getCompanyDetails = createStatefulRequest(GET_COMPANY_DETAILS, getCompanyDetailsRequest);

export const updateCompany = (data) => ({ type: UPDATE_COMPANY, data });
export const updateCompanyInfo = data => async dispatch => {
  dispatch({ type: UPDATE_COMPANY_PENDING });
  try {
    await getUpdateCompanyRequest(data);
    return dispatch(updateCompany(data));
  } catch (err) {
    dispatch({ type: UPDATE_COMPANY_ERROR });
    console.log(err);
  }
};

export const updateCompanyBan = (data) => ({ type: UPDATE_COMPANY_BAN, data });
export const updateCompanyBanInfo = data => async dispatch => {
  dispatch({ type: UPDATE_COMPANY_BAN_PENDING });

  try {
    await getUpdateCompanyBanRequest(data);
    return dispatch(updateCompanyBan(data));
  } catch (err) {
    dispatch({ type: UPDATE_COMPANY_BAN_ERROR });
    console.log(err);
  }
};

export const resetCreateMemberAction = () => ({ type: CREATE_MEMBER_INITIAL });
export const createNewMember = (data) => ({ type: CREATE_NEW_MEMBER, data });
export const createNewMemberError = (data) => ({ type: CREATE_MEMBER_ERROR, data });
export const createCompanyMember = ({ companyId, phone, fio, position }) => async dispatch => {
  dispatch({ type: CREATE_MEMBER_PENDING });
  try {
    const res = await getCreateCompanyMemberRequest({ companyId, phone, fio, position });
    return dispatch(createNewMember(res));
  } catch (err) {
    dispatch(createNewMemberError(getResponseError(err)));
    console.log(err);
  }
};

export const resetUpdateMemberAction = () => ({ type: UPDATE_MEMBER_INITIAL });
export const updateMember = (data) => ({ type: UPDATE_MEMBER, data });
export const updateMemberError = (data) => ({ type: UPDATE_MEMBER_ERROR, data });
export const updateCompanyMember = ({ companyId, phone, fio, position }) => async dispatch => {
  dispatch({ type: UPDATE_MEMBER_PENDING });
  try {
    await getUpdateCompanyMemberRequest({ companyId, phone, fio, position });
    return dispatch(updateMember({
      companyId,
      phone,
      fio,
      position,
    }));
  } catch (err) {
    dispatch(updateMemberError(getResponseError(err)));
    console.log(err);
  }
};

export const deleteMember = (data) => ({ type: DELETE_MEMBER, data });
export const deleteCompanyMember = ({ companyId, phone }) => async dispatch => {
  dispatch({ type: DELETE_MEMBER_PENDING });
  try {
    await getDeleteCompanyMemberRequest({ companyId, phone });
    return dispatch(deleteMember({
      companyId,
      phone,
    }));
  } catch (err) {
    dispatch({ type: DELETE_MEMBER_ERROR });
    console.log(err);
  }
};

export const moveFromAdminToMembers = (data) => ({ type: MOVE_FROM_ADMIN_TO_MEMBERS, data });

export const makeOwner = (data) => ({ type: MAKE_OWNER, data });
export const makeMemberOwner = ({ companyId, phone }) => async (dispatch, getState) => {
  dispatch({ type: MAKE_OWNER_PENDING });
  try {
    await getMakeCompanyOwnerRequest({ companyId, phone });
    const prevAdmin = getAdmin(getState());
    dispatch(moveFromAdminToMembers(prevAdmin.phone));
    return dispatch(makeOwner({
      companyId,
      phone,
    }));
  } catch (err) {
    dispatch({ type: MAKE_OWNER_ERROR });
    console.log(err);
  }
};
