import React from 'react';
import PropTypes from 'prop-types';

import { fieldsRowRenderingCondition } from 'shared/CompanyForm/helpers';

import { FieldsConnected } from './Fields';
import { FieldsGroup } from './FieldsGroup';

import { getTextContent, isRenderFields, isRenderFieldsGroup, mapFieldsKeys } from './utils';

export const Mapper = ({
  data,
  fieldsKeys,
  isIndividual,
  renderAddress,
  renderWholeName,
}) => mapFieldsKeys(fieldsKeys).map((fieldInfo) => {
  if (fieldsRowRenderingCondition(fieldInfo, isIndividual)) {
    return null;
  }

  if (isRenderFieldsGroup(fieldInfo.key)) {
    const isRenderChildren = data.readonlyFields.some(el => fieldInfo.fieldsToRender.includes(el));
    const renderFn = fieldInfo.key === 'address' ? renderAddress : renderWholeName;

    return (
      <FieldsGroup key={fieldInfo.key} fieldInfo={fieldInfo}>
        {isRenderChildren ? renderFn(fieldInfo.key) : null}
      </FieldsGroup>
    );
  }

  return (
    <FieldsConnected
      key={fieldInfo.key}
      fieldInfo={fieldInfo}
      isRenderFields={isRenderFields(data, fieldInfo.key)}
      textContent={getTextContent(data, fieldInfo.type, fieldInfo.key)}
    />
  );
});

Mapper.propTypes = {
  data: PropTypes.object,
  isIndividual: PropTypes.bool,
  renderAddress: PropTypes.func,
  renderWholeName: PropTypes.func,
  fieldsKeys: PropTypes.arrayOf(PropTypes.string),
};

