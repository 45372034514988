import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const NavLink = ({ title, link, isActive, className, large, target }) => (
  <a
    href={link}
    target={target}
    className={cx('component', className)}
  >
    <Text
      isInline
      isLarge={large}
      isTitle={large}
      className={cx('title', { active: isActive })}
    >
      {title}
    </Text>
  </a>
);

NavLink.defaultProps = {
  target: '_self',
};

NavLink.propTypes = {
  large: PropTypes.bool,
  link: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  className: PropTypes.any,
  target: PropTypes.string,
};
