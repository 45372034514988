import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropTypes } from 'types';
import classNames from 'classnames/bind';

import { Text } from 'components';
import { useActiveTabContext } from 'components/Tabs';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const NavListItem = ({ children, index, className }) => {
  const [activeTab, setActiveTab] = useActiveTabContext();
  const isActive = activeTab === index;

  return (
    <li className={cx('item', className)}>
      <button
        type="button"
        className={cx('button', { isActive })}
        onClick={() => setActiveTab(index)}
      >
        <Text>{children}</Text>
      </button>
    </li>
  );
};


NavListItem.propTypes = {
  children: childrenPropTypes,
  className: PropTypes.string,
  index: PropTypes.number,
};
