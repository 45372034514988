
export const FN_COMPANY = {
  type: 'companyType',
  inn: 'inn',
  ogrn: 'ogrn',
  ogrnip: 'ogrnip',
  name: 'companyName',
  nameEn: 'companyNameEn',
  siteaddress: 'siteAddress',
  kpp: 'kpp',
  okpo: 'okpo',
  contactPerson: 'contactPerson',
  contactPersonPhone: 'contactPersonPhone',
};
export const FN_COMPANYGROUPS = {
  contactData: 'ContactData',
  addressData: 'AddressData',
  bankData: 'BankData',
};

export const FN_USER = {
  fioEn: 'fioEn',
  gender: 'gender',
  name: 'name',
  surname: 'surname',
  middlename: 'middlename',
  wholeName: 'wholeName',
  birthDate: 'birthDate',
  birthplace: 'birthplace',
  nationality: 'nationality',
  docId: 'docId',
  docNo: 'docNo',
  docDate: 'docDate',
  docIssue: 'docIssue',
  docIssuerCode: 'docIssuerCode',
};

export const FN_EMAIL = 'email';

export const FN_BANK = {
  name: 'bankName',
  rs: 'rs',
  ks: 'ks',
  bik: 'bik',
};

export const FN_ADDRESS = {
  address: 'address',
  postindex: 'postIndex',
  city: 'city',
  street: 'street',
  building: 'building',
  apartment: 'apartment',
};

export const FN_OFFER_AGREEMENT = 'offerAgreement';
export const FN_PERSON_DATA_AGREEMENT = 'personalDataAgreement';
