import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import commonStyles from 'styles/common.pcss';

import { Text } from 'components/Text';
import {
  getDisplayValue,
  getHiddenFieldsAliases,
  validateSiteAddress,
  mapFieldInfo,
  getValue,
} from 'utils/orders/helpers';
import { FIELD_TYPES } from 'utils/orders/constants';
import { getWorkSchemeGroup } from 'store/features/details-order/selectors';
import { getAllTexts } from 'store/common/texts/selectors';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const headTexts = {
  AuthorizationCode: 'Код авторизации',
  CompanyInfo: 'Контактные данные',
  BankInfo: 'Банковские реквизиты',
  Operators: 'Операторы',
  WorkScheme: 'Схема работы',
  AuthOnly: 'Способ взаимодействия',
  ClientsData: 'Данные клиентов для передачи',
  AddFee: 'Стоимость',
  TargetsDescription: 'Для каких целей планируется использовать Мобильный ID',
  IPAddresses: 'Адреса запросов в Мобильный ID',
};

const FieldSection = ({ group, workSchemeGroup, dictionary }) => {
  const renderDataRow = (field, i) => {
    const fieldName = mapFieldInfo[field.alias]?.name;
    let fieldValue = getDisplayValue(field);

    if (!fieldName || !fieldValue) {
      return null;
    }

    if (field.fieldType === FIELD_TYPES.Boolean && !getValue(field)) return null;

    if (field.alias === 'SiteAddress') fieldValue = validateSiteAddress(fieldValue);

    if (/ClientsData/.test(group.alias)) {
      const info = mapFieldInfo[field.alias].info?.find(item => item.itemAlias === getValue(field));

      return (
        <div key={i} className={cx('clients-data')}>
          <Text>
            {getDisplayValue(field)}
          </Text>

          {info && (
            <Text isSmall isTitle className={cx('mart-xxs')}>
              {dictionary[info.text]}
            </Text>
          )}
        </div>
      );
    }

    if (/AuthOnly/.test(group.alias)) {
      if (getHiddenFieldsAliases([workSchemeGroup], 'AuthOnly').includes(field.alias)) {
        return null;
      }
    }

    if (/TargetsDescription/.test(group.alias)) {
      return (
        <div key={i} className={cx('field-value')}>
          <p>{fieldValue}</p>
        </div>
      );
    }

    return (
      <div key={i} className={cx('data-row')}>
        <div className={cx('field-name')}>
          <p className={cx({ without_margin: field.alias === 'SMSPush' })}>{fieldName}</p>
          {
            field.alias === 'SMSPush' && <div className={cx('littleText')}>(Для абонентов билайн)</div>
          }
        </div>
        <div className={cx('field-value')}>
          <p>{fieldValue}</p>
        </div>
      </div>
    );
  };

  return (
    <section className={cx('field-block', { 'with-border-top': group.stepNumber === 4 })}>
      <Text className={cx('block-head-text')}>
        {headTexts[group.alias]}
      </Text>
      {
        group?.fields
          .filter(({ alias }) => (alias !== 'WorkScheme'))
          .map((field, i) => renderDataRow(field, i))
      }
    </section>
  );
};

FieldSection.propTypes = {
  group: PropTypes.shape({
    alias: PropTypes.string,
    fields: PropTypes.array,
    stepNumber: PropTypes.number,
  }),
  workSchemeGroup: PropTypes.shape({
    alias: PropTypes.string,
    fields: PropTypes.array,
  }),
  dictionary: PropTypes.object,
};

const mapStateToProps = state => ({
  workSchemeGroup: getWorkSchemeGroup(state),
  dictionary: getAllTexts(state),
});

export default connect(mapStateToProps)(FieldSection);
