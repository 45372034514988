import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { deleteCompanyMember, makeMemberOwner } from 'store/features/company-details/action';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import AccessCompanyPopup from 'components/Popup/components/AccessCompanyPopup';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const AccessCard = ({ data, canEdit, deleteMember, makeOwner }) => {
  const [editPopup, setEditPopup] = useState(false);

  const handleDeleteMember = () => {
    deleteMember({
      companyId: data.companyId,
      phone: data.phone,
    });
  };

  const handleMakeOwner = () => {
    makeOwner({
      companyId: data.companyId,
      phone: data.phone,
    });
  };

  const renderBottom = () => {
    if (data?.isOwner) return <div className={cx('bottomText')}>Администратор</div>;

    if (canEdit) {
      return (
        <>
          {data?.canToBeAssignedAsOwner &&
            <button className={cx('action')} onClick={handleMakeOwner}>Назначить администратором</button>
          }
          {data?.canToBeDeleted &&
            <button className={cx('action')} onClick={handleDeleteMember}>Удалить</button>
          }
        </>
      );
    }

    return <div className={cx('bottomText')}>Редактор</div>;
  };

  return (
    <div className={cx('container')}>
      <div className={cx('upperRow')}>
        <div className={cx('data')}>
          <Text className={cx('fio')}>{data?.fio}</Text>
          <Text className={cx('phone')}>{data?.phone}</Text>
          <Text className={cx('position')}>{data?.position}</Text>
        </div>
        {
          canEdit && (
            <button className={cx('editBtn')} onClick={() => setEditPopup(true)}>
              <Icon className={cx('icon')} name="edit" />
            </button>
          )
        }
      </div>
      <div className={cx('bottomRow')}>
        {renderBottom()}
      </div>
      <AccessCompanyPopup
        isEditablePopup
        data={data}
        isOpen={editPopup}
        onCloseCallback={() => setEditPopup(false)}
      />
    </div>
  );
};

AccessCard.propTypes = {
  canEdit: PropTypes.bool,
  data: PropTypes.shape({
    phone: PropTypes.string,
    fio: PropTypes.string,
    position: PropTypes.string,
    companyId: PropTypes.string,
    isOwner: PropTypes.bool,
    canToBeDeleted: PropTypes.bool,
    isYou: PropTypes.bool,
    canToBeAssignedAsOwner: PropTypes.bool,
  }),
  deleteMember: PropTypes.func,
  makeOwner: PropTypes.func,
};

const mapDispatchToProps = {
  deleteMember: deleteCompanyMember,
  makeOwner: makeMemberOwner,
};

export default connect(null, mapDispatchToProps)(AccessCard);
