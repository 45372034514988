import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEmployeeOrderPage } from 'store/common/content';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Text } from 'components';
import { GroupTitle } from 'shared';
import { Row, RowItem } from 'shared/Order/Grid';

import styles from './styles.pcss';

const VirusTotalStatusComponent = ({ className, content: { title, text, statuses }, status }) => (
  <div className={className}>
    <GroupTitle className="marb-se" title={title} />

    <Row >
      <RowItem>
        <Text isSecondary>
          {text}
        </Text>
      </RowItem>

      <RowItem>
        <Text className={styles[status]}>
          {statuses[status]}
        </Text>
      </RowItem>
    </Row>
  </div>
);

const mapStateToProps = (state) => ({
  content: getEmployeeOrderPage(state)?.virusTotal,
});

VirusTotalStatusComponent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    statuses: PropTypes.shape({
      Malicious: PropTypes.string,
      Unknown: PropTypes.string,
      Harmless: PropTypes.string,
      Suspicious: PropTypes.string,
    }),
  }),
  status: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export const VirusTotalStatus = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(VirusTotalStatusComponent);
