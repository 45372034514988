import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';

import {
  getCreateOdrerData,
  isCreateOrderInitial,
  isCreateOrderLoaded,
} from 'store/features/create-order/selectors';
import { getIsAuthOnly } from 'store/features/draft-order/selectors';
import { createOrder as createOrderAction, resetAfterCreating } from 'store/features/create-order/action';
import { clearDraftAfterSuccess as clearDraftAction } from 'store/features/draft-order/action';
import { clearAfterSuccess as clearAfterSuccessAction } from 'store/features/review/action';
import {
  getReviewId,
  isReviewSendError as isReviewSendErrorSelector,
  isReviewSendSuccess as isReviewSendSuccessSelector,
} from 'store/features/review/selectors';
import { openSendInvoicePopup } from 'store/features/user/action';

import { MAIN_PAGE_URL } from 'constants';
import { TITLE_TEXT, DESCRIPTION_TEXT, DESCRIPTION_TEXT_IS_AUTH_ONLY, TITLE_TEXT_IS_AUTH_ONLY } from 'constants/texts';
import { compose } from 'utils/fn';
import PageLayout from 'shared/PageLayout';
import ResultPopup from 'shared/ResultPopup';
import { Preloader } from 'components';
import FixedOverlay from 'components/Popup/components/FixedOverlay';
import OrderGroups from './components/OrderGroups';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const CreateOrderPage = ({
  data,
  createOrder,
  isDataInitial,
  isDataLoaded,
  isAuthOnly,
  isReviewSendSuccess,
  isReviewSendError,
  reviewId,
  clearReview,
  clearDraft,
  clearCreate,
  history,
  handleSendInvoicePopupOpen,
}) => {
  const companyId = localStorage.getItem('companyId');

  const [isResultPopupOpen, setIsResultPopupOpen] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  useEffect(() => {
    if (isDataInitial) {
      if (companyId) {
        createOrder({
          companyId,
        });
      }
    }
  }, [isDataInitial, isDataLoaded, createOrder]);

  useEffect(() => {
    if (data && reviewId === data.id && (isReviewSendSuccess || isReviewSendError)) {
      setIsResultPopupOpen(true);
    }
  }, [data, reviewId, isReviewSendSuccess, isReviewSendError]);

  useEffect(() => () => {
    clearCreate();
    clearReview();
    clearDraft();
  }, []);

  const onBackClick = () => {
    history.push(MAIN_PAGE_URL);
    if (isReviewSendSuccess) {
      clearReview();
      clearDraft();
      clearCreate();
    }
  };

  const composeTextsPopup = () => {
    if (isAuthOnly) {
      return {
        title: TITLE_TEXT_IS_AUTH_ONLY,
        description: DESCRIPTION_TEXT_IS_AUTH_ONLY,
      };
    }

    return {
      title: TITLE_TEXT,
      description: DESCRIPTION_TEXT,
    };
  };

  return (
    <PageLayout isLoading={!isDataLoaded}>
      {data && (
        <OrderGroups
          data={data}
          openInvoicePopup={handleSendInvoicePopupOpen}
          setIsLoaderActive={setIsLoaderActive}
        />
      )}
      {isResultPopupOpen && (
        <ResultPopup
          onClose={isReviewSendSuccess ? onBackClick : () => setIsResultPopupOpen(false)}
          isSuccess={isReviewSendSuccess}
          texts={composeTextsPopup()}
        />)
      }
      <FixedOverlay
        className={cx('overlay')}
        isOpen={isLoaderActive}
        type="preloader"
      >
        <Preloader />
      </FixedOverlay>
    </PageLayout>
  );
};

CreateOrderPage.propTypes = {
  data: PropTypes.object,
  createOrder: PropTypes.func,
  clearReview: PropTypes.func,
  clearDraft: PropTypes.func,
  clearCreate: PropTypes.func,
  isDataInitial: PropTypes.bool,
  isDataLoaded: PropTypes.bool,
  isAuthOnly: PropTypes.bool,
  isReviewSendSuccess: PropTypes.bool,
  isReviewSendError: PropTypes.bool,
  reviewId: PropTypes.string,
  history: PropTypes.object,
  handleSendInvoicePopupOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  data: getCreateOdrerData(state),
  isDataInitial: isCreateOrderInitial(state),
  isDataLoaded: isCreateOrderLoaded(state),
  isAuthOnly: getIsAuthOnly(state),
  isReviewSendSuccess: isReviewSendSuccessSelector(state),
  isReviewSendError: isReviewSendErrorSelector(state),
  reviewId: getReviewId(state),
});

const mapDispatchToProps = {
  createOrder: createOrderAction,
  clearReview: clearAfterSuccessAction,
  clearDraft: clearDraftAction,
  clearCreate: resetAfterCreating,
  handleSendInvoicePopupOpen: openSendInvoicePopup,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CreateOrderPage);
