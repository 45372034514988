import React from 'react';
import { NumberMaskedInput } from './NumberMaskedInput';

const Series = (props) => (
  <NumberMaskedInput
    {...props}
    mask="____"
    maxLength={4}
  />
);

const Number = (props) => (
  <NumberMaskedInput
    {...props}
    mask="______"
    maxLength={6}
  />
);

export const PassportInput = { Series, Number };
