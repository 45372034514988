import React from 'react';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEmployeeOrderPage, getLoadingText, getOmnesEmployeeProfiles } from 'store/common/content';

import { Text } from 'components';
import DataTable from 'components/DataTable';
import DropDown from 'components/Dropdown';
import TablePageLayout from 'components/DataTable/components/TablePageLayout';
import OrderStatus from 'pages/Main/components/OrderStatus';
import Pagination from 'shared/Pagination';

import { FIRST_PAGE_NUMBER, DECREMENT_NUMBER } from './constants';

import styles from './styles.pcss';
import { useRows } from './useRows';

const cx = classNames.bind(styles);

const initRowQuantity = 12;

const OmnesEmployeeProfiles = ({ loadingText, omnesEmployeeProfiles, virusTotal }) => {
  const {
    tableRows,
    isOrdersLoaded,
    totalPages,
    activePage,
    rowQuantity,
    loadMore,
    loadMorePages,
    pageClick,
    changeRowQuantity,
  } = useRows({ initRowQuantity });
  const isLoadMoreVisible = totalPages > FIRST_PAGE_NUMBER && loadMorePages < totalPages;

  const personCellTemplate = (data) => (
    <div className={cx('person-cell')}>
      <div>{data.person.name}</div>
      <div>{data.person.email}</div>
      <div>{data.person.phone}</div>
    </div>
  );

  const companyNameTemplate = ({ title }) => (
    <div className={cx('link-container')}>{title}</div>
  );

  const typeTemplate = (data) => (
    <div className={cx('type-container')}>
      {data.companyIsAggregator ? 'Агрегатор' : 'Клиент'}
    </div>
  );

  const statusTemplate = ({ currentStatusAlias }) => (
    <OrderStatus status={currentStatusAlias} withText={false} />
  );

  const virusTotalStatusTemplate = ({ securityStatusAlias }) => (
    <Text className={cx('statusText', securityStatusAlias)} isInline>
      {virusTotal[securityStatusAlias]}
    </Text>
  );

  const { applications, tableColums } = omnesEmployeeProfiles;

  const getColumns = () => [
    {
      title: tableColums.title,
      key: 'title',
      prerenderFunction: companyNameTemplate,
      minSize: '115px',
      maxSize: 2,
    },
    {
      title: tableColums.person,
      key: 'person',
      prerenderFunction: personCellTemplate,
      minSize: '305px',
      maxSize: 2.2,
    },
    {
      title: tableColums.type,
      key: 'companyIsAggregator',
      prerenderFunction: typeTemplate,
      minSize: '110px',
      maxSize: 2,
    },
    {
      title: tableColums.virusTotalStatus,
      key: 'virusTotalStatus',
      prerenderFunction: virusTotalStatusTemplate,
      minSize: '140px',
      maxSize: 2,
    },
    {
      title: tableColums.status,
      key: 'currentStatusAlias',
      prerenderFunction: statusTemplate,
      minSize: '140px',
      maxSize: 2,
    },
  ];

  return (
    <TablePageLayout>
      <div className={cx('omnes-employee-title')}>
        <span>{applications}</span>
      </div>
      {isOrdersLoaded ? (
        <div className={cx('omnes-employee-profiles')}>
          <div className={cx('filter-panel')}>
            {/* <InputView onKeyDown={this.shearsHandle} className={cx('shears-input')} placeholder={'Поиск'}/> */}
            <div className={cx('quality-dropdown')}>
              <DropDown
                onChange={changeRowQuantity}
                placeholder="12"
                minWidth="10%"
              />
            </div>
          </div>
          {tableRows.length && (
            <>
              <DataTable
                tableName="OmnesEmployeeProfiles"
                columns={getColumns()}
                rows={tableRows}
                lastPrewRow={(activePage - DECREMENT_NUMBER) * rowQuantity}
              />
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                pageQuality={rowQuantity}
                changePage={pageClick}
                loadMore={loadMore}
                isLoadMoreVisible={isLoadMoreVisible}
              />
            </>
          )}
        </div>
      ) : (
        loadingText
      )}
    </TablePageLayout>
  );
};

const mapStateToProps = (state) => ({
  loadingText: getLoadingText(state),
  omnesEmployeeProfiles: getOmnesEmployeeProfiles(state),
  virusTotal: getEmployeeOrderPage(state)?.virusTotal?.statuses,
});

OmnesEmployeeProfiles.propTypes = {
  loadingText: PropTypes.string,
  omnesEmployeeProfiles: PropTypes.object,
  virusTotal: PropTypes.object,
};

export default connect(mapStateToProps)(OmnesEmployeeProfiles);
