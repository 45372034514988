import { pushDataLayer } from 'utils/ga';
import {
  EA_CLICK,
  EA_CREATE_APP,
  EA_CREATE_COMPANY,
  EA_ERROR,
  EA_FIELD_FOCUS,
  EA_FIELD_SUCCESS,
  EA_LOGIN,
  EA_SENT,
  EA_STEPS,
  EA_TOP_UP,
  EC_CONVERTION,
  EC_FORM,
  EC_PUSH,
  EL_STEP,
  EVENT,
  EVENT_CATEGORY,
} from './constants';

export const pushAnalytic = (eventAction, eventLabel) => {
  pushDataLayer({
    event: EVENT,
    eventCategory: EVENT_CATEGORY,
    eventAction,
    eventLabel,
  });
};

export const pushFormAnalytics = ({ formName, event }) => {
  pushDataLayer({
    event: EVENT,
    eventCategory: `${EC_FORM}${formName}`,
    ...event,
  });
};

const pushFieldAnalytics = ({ formName, field, eventAction }) => {
  pushFormAnalytics({
    formName,
    event: {
      eventLabel: field,
      eventAction,
    },
  });
};

export const GALoginAction = () => pushAnalytic(EA_LOGIN);
export const GATopUpAction = () => pushAnalytic(EA_TOP_UP);
export const GACreateAppAction = () => pushAnalytic(EA_CREATE_APP);
export const GACreateCompanyAction = () => pushAnalytic(EA_CREATE_COMPANY);

export const GAFieldFocusAction = ({ formName, field }) => pushFieldAnalytics({
  formName,
  field,
  eventAction: EA_FIELD_FOCUS,
});

export const GAFieldSuccessAction = ({ formName, field }) => pushFieldAnalytics({
  formName,
  field,
  eventAction: EA_FIELD_SUCCESS,
});

export const GAFieldErrorAction = ({ formName, field }) => pushFormAnalytics({
  formName,
  event: {
    eventAction: EA_ERROR,
    eventLabel: field,
  },
});

export const GAAuthAction = (event) => pushDataLayer({
  event: EVENT,
  eventCategory: EC_PUSH,
  eventAction: EA_SENT,
  ...event,
});

export const GACompanyCreationStepsAction = ({ formName, step }) => pushDataLayer({
  event: EVENT,
  eventCategory: `${EC_FORM}${formName}`,
  eventAction: EA_STEPS,
  eventLabel: `${EL_STEP}${step}`,
});

export const GAAuthSubmitFailAction = ({ formName, failedFields }) => pushFormAnalytics({
  formName,
  event: {
    eventLabel: failedFields,
    eventAction: EA_ERROR,
  },
});

export const GAClickAction = ({ text }) => pushDataLayer({
  event: EVENT,
  eventCategory: EC_CONVERTION,
  eventAction: EA_CLICK,
  eventLabel: text,
});
