import React from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Row = ({ children }) => (
  <div className={cx('row')}>
    {children}
  </div>
);

Row.propTypes = {
  children: PropTypes.any,
};

export const RowItem = ({ children, isWide, className, isNarrow }) => (
  <div className={cx('rowItem', { wide: isWide }, { narrow: isNarrow }, className)}>
    {children}
  </div>
);

RowItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  isWide: PropTypes.bool,
  isNarrow: PropTypes.bool,
};
