import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useRequest } from 'ahooks';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getSearchTexts } from 'store/common/content';

import { SuggestionList } from '../../../SuggestionList';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

/* eslint-disable no-unused-expressions */

export const withSuggestions = (WrappedInput) => {
  const InputWithSuggestions = ({
    onBlur,
    onFocus,
    onChange,
    suggestionsFn,
    externalHandleChange,
    preventCustomChange,
    onSelect,
    isSelected,
    selectItemText,
    ...restProps
  }) => {
    const [canShowWidget, setCanShowWidget] = useState(true);
    const [selectionError, setSelectionError] = useState('');
    const { data = {}, loading, run } = useRequest(suggestionsFn, { debounceInterval: 180, manual: true });

    const handleChange = useCallback((v, e) => {
      onSelect?.(false);
      if (isSelected) {
        setSelectionError('');
      }

      onChange(v, e);
      if (v.length >= 2) {
        run(v);
      }
    }, [onChange]);

    const handleFocus = useCallback((v, e) => {
      setCanShowWidget(true);
      onFocus?.(v, e);
    }, [onFocus]);

    const handleBlur = useCallback((v, e) => {
      e.persist();
      onBlur?.(v, e);

      if (e.relatedTarget?.dataset?.isSuggestionButton) {
        return;
      }

      if (preventCustomChange && !isSelected) {
        setSelectionError(selectItemText);
      }

      /* Crutch to iOS mobile */
      setTimeout(() => {
        setCanShowWidget(false);
      });
    }, [onBlur]);

    const onSuggestionClick = useCallback((v) => {
      if (preventCustomChange) {
        onSelect(true);
        setSelectionError('');
      }

      onChange(v);
      setCanShowWidget(false);
      externalHandleChange?.(v, data.response);
    }, [onChange, externalHandleChange, JSON.stringify(data.response)]);

    return (
      <div className={cx('wrapper')}>
        <WrappedInput
          {...restProps}
          autoComplete="off"
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          error={selectionError || restProps.error}
        />

        <SuggestionList
          suggestions={data.suggestions}
          onSuggestionClick={onSuggestionClick}
          shouldRender={!loading && data.suggestions?.length && canShowWidget}
        />
      </div>
    );
  };

  const mapStateToProps = (state) => ({
    selectItemText: getSearchTexts(state).selectItem,
  });

  InputWithSuggestions.propTypes = {
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    suggestionsFn: PropTypes.func,
    externalHandleChange: PropTypes.func,
    onSelect: PropTypes.func,
    isSelected: PropTypes.bool,
    preventCustomChange: PropTypes.bool,
    selectItemText: PropTypes.string,
  };

  return connect(mapStateToProps)(InputWithSuggestions);
};
