import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { getCompanyBlock } from 'store/common/content';

import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { Text } from 'components/Text';
import { InfoTooltip } from 'components/index';
import { CREATE_COMPANY_ROUTE, VIEW_COMPANY_ROUTE } from 'constants/routes';
import { getCompanyName } from 'utils/fn';
import { openSendInvoicePopup } from 'store/features/user/action';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const CompanyBlock = ({
  handleSendInvoicePopupOpen,
  company,
  content,
  isLoaded,
  history,
}) => {
  const onFillCompanyClick = () => {
    history.push(CREATE_COMPANY_ROUTE);
  };

  const noCompany = () => (
    <>
      <Text isLarge isTitle isBold>
        {content.title}
      </Text>
      <Button className={cx('createCompanyBtn')} onClick={onFillCompanyClick}>
        {content.createCompanyButton}
      </Button>
    </>
  );

  if (!isLoaded) return null;

  if (!company?.id) return noCompany();

  return (
    <>
      <Text isLarge isTitle isBold>
        {content.title}
      </Text>
      <Text className={cx('companyName')}>
        {getCompanyName(company).match(/.{1,40}/g).join(' ')}
      </Text>
      <div className={cx('linkWrapper')}>
        <Link className={cx('link')} href={`${VIEW_COMPANY_ROUTE}${company.id}`}>
          {content.view}
        </Link>
        {content.tooltip && (
          <InfoTooltip className={cx('tooltip')}>
            <div className={cx('tooltip-content')}>
              {content.tooltip}
            </div>
          </InfoTooltip>
        )}
      </div>
      {(company?.ban) && (
      <div className={cx('linkWrapper')}>
        <Link className={cx('link')} onClick={handleSendInvoicePopupOpen}>
          Пополнить счёт
        </Link>
      </div>)
      }
    </>
  );
};

CompanyBlock.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    ban: PropTypes.string,
    companyName: PropTypes.string,
    isOwner: PropTypes.bool,
  }),
  isLoaded: PropTypes.bool,
  history: PropTypes.object,
  content: PropTypes.object,
  handleSendInvoicePopupOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  content: getCompanyBlock(state),
});

const mapDispatchToProps = {
  handleSendInvoicePopupOpen: openSendInvoicePopup,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(CompanyBlock);
