import { withIcon, withCleanup } from '../enhancers';
import Input from '../views/Input';

/**
 * Core input used for most of the enhancers it has:
 * - `isClearable` enabled by default,
 * - an icon
 * - `autoTrim` enabled by default
 */
export default withIcon(withCleanup(Input));
