import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCompanyTypes } from 'store/features/companyForm/selectors';
import { setConfirmResetPopupState } from 'store/features/companyForm/actions';

import { getPreparedOptions } from '../helpers';

export const DropDownWrapper = ({ children, allCompanyTypes, openPopup }) => cloneElement(children, {
  options: getPreparedOptions(allCompanyTypes),
  onChangeProxy: (value, event, prevValue) => {
    openPopup(value);
    return prevValue;
  },
});

DropDownWrapper.proptypes = {
  children: PropTypes.node,
  openPopup: PropTypes.func,
  allCompanyTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  allCompanyTypes: getCompanyTypes(state),
});

const mapDispatchToProps = (dispatch) => ({
  openPopup: (type) => { dispatch(setConfirmResetPopupState(type || true)); },
});

export const CompanyTypeHOC = connect(mapStateToProps, mapDispatchToProps)(DropDownWrapper);
