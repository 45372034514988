import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { childrenPropTypes } from 'types';

import { Text } from 'components';
import Tooltip from 'components/Tooltip';
import { FormToggle } from '../FormToggle';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const FormTemplate = ({ title, description, tooltip, link, onClick, children }) => (
  <div className={cx('component')}>
    <Text isLarge isTitle className={cx('title')}>
      {title}
    </Text>

    {description && (
      <Text isSmall className={cx('description')}>
        {description}
      </Text>
    )}

    {children}

    <div className={cx('links')}>
      {onClick && (
        <FormToggle onClick={onClick}>
          {link}
        </FormToggle>
      )}

      {tooltip && (
        <Tooltip
          tooltipMessage={tooltip.message}
          className={cx('mart-xs', 'tooltip')}
          tooltipPosition={{ bottom: false, right: false }}
        >
          <Text className={cx('tooltip-text')} isSmall isInline>
            {tooltip.text}
          </Text>
        </Tooltip>
      )}
    </div>
  </div>
);

FormTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tooltip: PropTypes.shape({
    message: PropTypes.string,
    text: PropTypes.string,
  }),
  link: PropTypes.string,
  onClick: PropTypes.func,
  children: childrenPropTypes,
};
