import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Preloader as ExPreloader, Text } from 'components';
import {
  FinalForm as ExFinalForm,
  FFTextInput as ExFFTextInput,
  FFPhoneInput as ExFFPhoneInput,
} from 'components/FinalForm';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const PropChildren = {
  children: PropTypes.node,
};

const Preloader = () => <ExPreloader className={cx('loaderInnerColor')} />;

const HeadText = ({ children }) => <Text className={cx('headText')}>{children}</Text>;

HeadText.propTypes = PropChildren;

const DescText = ({ children }) => <Text className={cx('descText')}>{children}</Text>;

DescText.propTypes = PropChildren;

const FinalForm = ({ children, ...rest }) => <ExFinalForm className={cx('form')} {...rest}>{children}</ExFinalForm>;

FinalForm.propTypes = PropChildren;

const Label = ({ children }) => <label className={cx('label')}>{children}</label>;

Label.propTypes = PropChildren;

const FFTextInput = ({ children, ...rest }) => (
  <ExFFTextInput className={cx('input')} {...rest}>
    {children}
  </ExFFTextInput>
);

FFTextInput.propTypes = PropChildren;

const FFPhoneInput = ({ ...rest }) => (<ExFFPhoneInput className={cx('input')} {...rest} />);

export default { Preloader, HeadText, DescText, FinalForm, Label, FFTextInput, FFPhoneInput };
