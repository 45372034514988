import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setContent } from 'store/common/content';

import { getReactModelRequest } from 'requests/api';

const convertReactModelData = (rawData) => rawData.reduce((accum, { alias, data }) => ({
  ...accum,
  [alias]: data,
}), {});

export const withReactModel = (models) => (WrappedComponent) => {
  const Component = ({ onSetContent, ...props }) => {
    useEffect(() => {
      getReactModelRequest(models).then((data) => onSetContent(convertReactModelData(data)));
    }, []);

    return (
      <WrappedComponent {...props} />
    );
  };

  Component.propTypes = {
    onSetContent: PropTypes.func,
  };

  return connect(null, { onSetContent: setContent })(Component);
};
