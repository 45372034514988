import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { RadioGroup } from '../../RadioGroup';
import { FFRadioTab } from '../index';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const RadioTabs = ({ name, options }) => (
  <RadioGroup className={cx('component')} name={name}>
    {options.map((option, index) => (
      <FFRadioTab
        key={index.toString()}
        className={cx('tab')}
        value={option.alias}
      >
        {option.text}
      </FFRadioTab>
    ))}
  </RadioGroup>
);

RadioTabs.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};
