import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getInstructionButtonText } from 'store/common/content';
import { INSTRUCTION_FULL_ROUTE } from 'constants/routes';

import { Icon, Text, Link } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const InstructionButtonComponent = ({ text, className }) => (
  <Link className={cx('component', className)} href={INSTRUCTION_FULL_ROUTE} target="_blank">
    <Text className={cx('text')}>
      {text}
    </Text>

    <Icon className={cx('icon')} name="instruction" />
  </Link>
);

const mapStateToProps = (state) => ({
  text: getInstructionButtonText(state),
});

InstructionButtonComponent.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export const InstructionButton = connect(mapStateToProps)(InstructionButtonComponent);
