import React from 'react';

import PropTypes from 'prop-types';

import { BasePopupStateless } from 'components/Popup/components/BasePopup';
import { Text } from 'components/Text';
import Error from 'shared/Error';
import classNames from 'classnames/bind';
import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const ResultPopup = ({ onClose, isSuccess, texts }) => (
  <BasePopupStateless className={cx('popup')} isOpen onClose={onClose}>
    <div className={cx('popup-content')}>
      {isSuccess ? (
        <>
          {texts.title && <Text isTitle isLarge>{texts.title}</Text>}
          {texts.description && <Text isSmall className={cx('mart-xs')}>{texts.description}</Text>}
        </>
      ) : (
        <Error />
      )}
    </div>
  </BasePopupStateless>
);

ResultPopup.propTypes = {
  onClose: PropTypes.func,
  isSuccess: PropTypes.bool,
  texts: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ResultPopup;
