import { createSelector } from '@reduxjs/toolkit';
import { INSTRUCTION_BLOCKS, REACT_MODELS } from 'constants';
import { blankObj, blankArr } from 'utils/fn';
import { getIsEmployee } from 'store/features/auth/selectors';


export const getContent = (state) => state.content;
export const getB2BSelfService = (state) => getContent(state)[REACT_MODELS.B2B_SELF_SERVICE] || blankObj;
export const getInstructionPage = (state) => getContent(state)[REACT_MODELS.B2B_FULL_INSTRUCTION];
export const getInstructionPageHeading = createSelector(getInstructionPage, (state) => state?.heading || '');
export const getInstructionPageContent = createSelector(getInstructionPage, (state) => state?.content || blankArr);
export const getInstructionLoginBlock = createSelector(
  getInstructionPageContent,
  (state) => state.find((item) => item.key === INSTRUCTION_BLOCKS.LOGIN),
);
export const getInstructionAcceptBlock = createSelector(
  getInstructionPageContent,
  (state) => state.find((item) => item.key === INSTRUCTION_BLOCKS.ACCEPT),
);
export const getInstructionRegistrationBlock = createSelector(
  getInstructionPageContent,
  (state) => state.find((item) => item.key === INSTRUCTION_BLOCKS.REGISTRATION),
);
export const getInstructionBasicsBlock = createSelector(
  getInstructionPageContent,
  (state) => state.find((item) => item.key === INSTRUCTION_BLOCKS.BASICS),
);
export const getInstructionFirstCallBlock = createSelector(
  getInstructionPageContent,
  (state) => state.find((item) => item.key === INSTRUCTION_BLOCKS.FIRST_CALL),
);
export const getApiInstructionBlock = createSelector(
  getInstructionPageContent,
  (state) => state.find((item) => item.key === INSTRUCTION_BLOCKS.API),
);

export const getShortInstructionPage = (state) => getContent(state)[REACT_MODELS.B2B_SHORT_INSTRUCTION];
export const getShortInstructionPageHeading = createSelector(getShortInstructionPage, (state) => state?.heading || '');
export const getShortInstructionPageContent = createSelector(getShortInstructionPage, (state) => state?.content || blankArr);

export const getContentReady = createSelector(getB2BSelfService, (state) => !!state?.loadingText);

export const getLoadingText = createSelector(getB2BSelfService, (state) => state?.loadingText || '');
export const getApiDoc = createSelector(getB2BSelfService, (state) => state?.['api-doc'] || blankObj);
export const getRoutes = createSelector(getB2BSelfService, (state) => state?.headerRoutes || blankArr);
export const getApiDocContent = createSelector(getApiDoc, ({ content }) => content || blankArr);
export const getPluginsDoc = createSelector(getB2BSelfService, (state) => state?.plugins || blankArr);
export const getAccessDeniedPage = createSelector(getB2BSelfService, (state) => state?.accessDenied || blankObj);
export const getNotFoundPage = createSelector(getB2BSelfService, (state) => state?.notFound || blankObj);
export const getCompanyBlock = createSelector(getB2BSelfService, (state) => state?.companyBlock || blankObj);
export const getCreateOrderBlock = createSelector(getB2BSelfService, (state) => state?.createOrderBlock || blankObj);
export const getDocumentsBlock = createSelector(getB2BSelfService, (state) => state?.documentsBlock || blankObj);
export const getFooter = createSelector(getB2BSelfService, (state) => state?.footer || blankObj);
export const getEditOrderBlock = createSelector(getB2BSelfService, (state) => state?.editOrderBlock || blankObj);
export const getOrderStatus = createSelector(getB2BSelfService, (state) => state?.orderStatus || blankObj);
export const getSendInvoicePopup = createSelector(getB2BSelfService, (state) => state?.sendInvoicePopup || blankObj);
export const getRoutesInfo = createSelector(getB2BSelfService, (state) => state?.routesInfo || blankArr);
export const getHeader = createSelector(getB2BSelfService, (state) => state?.header || blankObj);
export const getHeaderLogo = createSelector(getHeader, (state) => state?.logo || blankObj);
export const getHeaderMenu = createSelector(getHeader, (state) => state?.menu || blankObj);
export const getInstructionButtonText = createSelector(getB2BSelfService, (state) => state?.instructionButton);
export const getHelpAndSupport = createSelector(getB2BSelfService, (state) => state?.helpAndSupport || blankObj);
export const getHelpAndSupportForm = createSelector(getHelpAndSupport, (state) => state?.form || blankObj);
export const getTopUpCompanyBalance = createSelector(getB2BSelfService, (state) => state?.topUpCompanyBalance || blankObj);
export const getOmnesEmployeeProfiles = createSelector(
  getB2BSelfService,
  (state) => state?.omnesEmployeeProfiles || blankObj,
);
export const getSearchTexts = createSelector(getB2BSelfService, (state) => state?.search || blankObj);
export const getPersonalDataTransfer = createSelector(getB2BSelfService, (state) => state?.personalDataTransfer || blankObj);
export const getLoginPage = createSelector(getB2BSelfService, (state) => state?.loginPage || blankObj);
export const getEmployeeOrderPage = createSelector(getB2BSelfService, (state) => state?.employeeOrderPage || blankObj);
export const shouldRenderPDTransfer = createSelector(
  [getPersonalDataTransfer, getIsEmployee],
  (data, isEmployee) => (isEmployee ? (!!data.title && !data.hideFromEmployee) : !!data.title),
);
export const getSuccessfullyInfoPopup = createSelector(getB2BSelfService, (state) => state?.successfullyInfoPopup || blankObj);
export const getMemberCreateInfoPopup = createSelector(getB2BSelfService, (state) => state?.memberPopup?.create || blankObj);
export const getMemberEditInfoPopup = createSelector(getB2BSelfService, (state) => state?.memberPopup?.edit || blankObj);
export const getDefaultErrorText = createSelector(getB2BSelfService, (state) => state?.defaultErrorText || '');
export const getIsAuthCaptchaData = createSelector(getB2BSelfService, (state) => !!state?.authCaptcha?.isAuthCaptcha);
export const getAuthCaptchaToken = createSelector(getB2BSelfService, (state) => state?.authCaptcha?.token || '');
