import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { FN_COMPANY } from 'constants/fieldNames';

import { Text } from 'components';
import {
  FFINNInput,
  FFTextInput,
  FFPhoneInput,
  FFOGRNInput,
  FFTextInputSuggestion,
} from 'components/FinalForm';
import { FormTabs } from 'shared/CompanyForm/components/FormTabs';
import { FormDropDown } from 'shared/CompanyForm/components/FormDropDown';
import { CompanyTypeHOC } from 'shared/CompanyForm/components/CompanyTypeHOC';

import { fetchBankSuggestion } from 'requests/api/company';
import { composeLengthValidator } from 'utils/field-validators';
import {
  getCompanyFormValues,
  getIsIndividualEntrepreneur,
} from 'store/features/companyForm/selectors';
import {
  loadCompanyDataByINN,
  setCompanyFormValues,
  setFormValuesByBankSuggestion,
} from 'store/features/companyForm/actions';

import styles from '../../styles.pcss';
const cx = classNames.bind(styles);

const Fields = ({
  fieldInfo,
  isRenderFields,
  textContent,
  formValues,
  onFormChange,
  isIndividual,
  onBankInputChange,
  fetchCompanyDataSuggestion,
}) => {
  const onINNInputChange = useCallback(
    (value) => {
      const validationResult = composeLengthValidator('not valid', {
        min: isIndividual ? 12 : 10,
      })(value);

      if (typeof validationResult !== 'string') {
        fetchCompanyDataSuggestion(value);
      }
      return value;
    },
    [isIndividual, fetchCompanyDataSuggestion],
  );

  let FieldComponent = (
    <FFTextInput
      className={cx('input')}
      name={fieldInfo.key}
      placeholder={fieldInfo.placeholder}
    />
  );

  if (fieldInfo.type === 'phone') {
    FieldComponent = (
      <FFPhoneInput
        className={cx('input')}
        name={fieldInfo.key}
        placeholder={fieldInfo.placeholder}
      />
    );
  }

  if (fieldInfo.type === 'tab') {
    FieldComponent = (
      <FormTabs
        {...fieldInfo}
        initialValue={formValues.gender}
        onTabChange={(v) => onFormChange({ ...formValues, gender: v })}
      />
    );
  }

  if (fieldInfo.type === FN_COMPANY.inn) {
    FieldComponent = (
      <FFINNInput
        className={cx('input')}
        name={fieldInfo.key}
        placeholder={isIndividual ? '012345678912' : fieldInfo.placeholder}
        isIndividual={isIndividual}
        onChangeProxy={onINNInputChange}
      />
    );
  }

  if (
    fieldInfo.type === FN_COMPANY.ogrn ||
    fieldInfo.type === FN_COMPANY.ogrnip
  ) {
    FieldComponent = (
      <FFOGRNInput
        className={cx('input')}
        name={fieldInfo.key}
        isIndividual={isIndividual}
        placeholder={fieldInfo?.placeholder}
      />
    );
  }

  if (fieldInfo.alias === 'bankName') {
    FieldComponent = (
      <FFTextInputSuggestion
        className={cx('input')}
        name={fieldInfo.key}
        placeholder={fieldInfo?.placeholder}
        suggestionsFn={fetchBankSuggestion}
        externalHandleChange={onBankInputChange}
      />
    );
  }

  if (fieldInfo.type === 'dropdown') {
    FieldComponent = (
      <div className={cx('input')}>
        <CompanyTypeHOC>
          <FormDropDown alias={fieldInfo.key} />
        </CompanyTypeHOC>
      </div>
    );
  }

  return (
    <div className={cx('fieldContainer')}>
      <Text className={cx('fieldName')}>
        {isIndividual && fieldInfo?.individualOptions
          ? fieldInfo.individualOptions.name
          : fieldInfo.name}
      </Text>
      {isRenderFields ? (
        FieldComponent
      ) : (
        <Text className={cx('fieldValue')}>{textContent}</Text>
      )}
    </div>
  );
};

Fields.propTypes = {
  fieldInfo: PropTypes.object,
  isRenderFields: PropTypes.bool,
  textContent: PropTypes.string,
  formValues: PropTypes.object,
  isIndividual: PropTypes.object,
  onFormChange: PropTypes.func,
  onBankInputChange: PropTypes.func,
  fetchCompanyDataSuggestion: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formValues: getCompanyFormValues(state),
  isIndividual: getIsIndividualEntrepreneur(state),
});

const mapDispatchToProps = {
  onFormChange: setCompanyFormValues,
  onBankInputChange: setFormValuesByBankSuggestion,
  fetchCompanyDataSuggestion: loadCompanyDataByINN,
};

export const FieldsConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fields);
