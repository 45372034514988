import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import BaseButton from '../BaseButton';

const cx = classNames.bind(styles);

const Link = ({ children, className, href, ...props }) => {
  const Comp = href ? 'a' : BaseButton;

  return (
    <Comp
      className={cx('link', className)}
      href={href} {...props}
    >
      {children}
    </Comp>
  );
};

Link.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  href: PropTypes.string,
};

export { Link };
