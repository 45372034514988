import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../Text';
import { Link } from '../Link';

export const DocumentLink = ({ link, linkText, textBeforeLink, textAfterLink }) => (
  <>
    {textBeforeLink && (
      <>
        <Text isInline isSmall>
          {textBeforeLink}
        </Text>
        {' '}
      </>
    )}

    <Link
      download
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {linkText}
    </Link>

    {textAfterLink && (
      <>
        {' '}
        <Text isInline isSmall>
          {textAfterLink}
        </Text>
      </>
    )}
  </>
);

DocumentLink.propTypes = {
  textAfterLink: PropTypes.string,
  textBeforeLink: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};
