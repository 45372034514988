import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { changeOrderStatusRequest } from 'requests/api/change-status';
import { EMPLOYEE_MAIN_PAGE_URL } from 'constants';
import { composeRequiredValidator } from 'utils/field-validators';
import commonStyles from 'styles/common.pcss';

import { FIELD_CANNOT_BE_EMPTY_TEXT } from 'constants/validators';

import { BasePopupStateless } from 'components/Popup/components/BasePopup';
import { FinalForm } from 'components/FinalForm/FinalForm';
import { Text } from 'components/Text';
import { FFTextarea } from 'components/FinalForm';
import { Button } from 'components/Button';
import Error from 'shared/Error';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const RejectPopup = ({ onClose, id, history }) => {
  const [isError, setIsError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const onSubmit = ({ comment }) => {
    setIsPending(true);
    changeOrderStatusRequest({ comment, orderId: id, status: 'Draft' })
      .then(() => history.push(EMPLOYEE_MAIN_PAGE_URL))
      .catch(() => {
        setIsError(true);
        setIsPending(false);
      });
  };

  return (
    <BasePopupStateless className={cx('popup')} isOpen onClose={onClose}>
      <div className={cx('popup-content')}>
        {isError ? (
          <Error />
        ) : (
          <>
            <Text isLarge isTitle className={cx('marb-xs')}>
              Отправить на доработку
            </Text>
            <Text isSmall className={cx('marb-s')}>
              Добавьте комментарий для доработки анкеты
            </Text>
            <FinalForm
              isValidateOnSubmit
              onSubmit={async (values) => {
                if (isPending) return;
                onSubmit(values);
              }}
              getValidators={() => ({
                comment: composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
              })}
            >
              <FFTextarea
                name="comment"
                height="114px"
                placeholder="Ваш комментарий"
              />
              <Button isDisabled={isPending} isSubmit isWide className={cx('mart-s')}>
                Отправить
              </Button>
            </FinalForm>
          </>
        )}
      </div>
    </BasePopupStateless>
  );
};

RejectPopup.defaultProps = {

};
RejectPopup.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(RejectPopup);
