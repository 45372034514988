import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer } from 'utils/redux-stateful';
import { GET_DETAIL_ORDER, SET_FIELDS } from './constants';

const stateReducer = createStatefulReducer(GET_DETAIL_ORDER);
const actions = createStatefulActionTypes(GET_DETAIL_ORDER);
const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
  }),
  [SET_FIELDS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      currentStatusAlias: 'Accepted',
      fieldsGroups: [
        ...state.data.fieldsGroups.map(group => {
          const changedGroups = action.data;
          const changedGroupsAliases = changedGroups.map(el => el.alias);
          if (changedGroupsAliases.includes(group.alias)) {
            const groupToReplace = changedGroups.find(g => g.alias === group.alias);
            return { ...groupToReplace };
          }
          return group;
        }),
      ],
    },
  }),
};
export const orderDetailsReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
