import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CustomPropTypes from 'utils/prop-types';

import { Text } from '../../../Text';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function withError(WrappedInput) {
  function InputWithError({ status, error, keepErrorIndent, className, ...otherProps }) {
    return (
      <div className={cx('container', className)}>
        <WrappedInput
          {...otherProps}
          status={error ? 'fail' : status}
        />
        {(error || keepErrorIndent) && (
          <Text isSmall className={cx('error')}>
            {error}
          </Text>
        )}
      </div>
    );
  }

  InputWithError.propTypes = {
    ...WrappedInput.propTypes,

    /** added by the HOC */
    status: PropTypes.oneOf(['fail']),
    error: PropTypes.string,
    keepErrorIndent: PropTypes.bool,

    /** reused by the HOC */
    className: PropTypes.string,

    /** is not an actual prop */
    domNodeRef: CustomPropTypes.ref,
  };

  InputWithError.defaultProps = {
    ...WrappedInput.defaultProps,

    // FIXME: should actually position error absolutely, this would fix all the problems with stacking indents
    keepErrorIndent: false,
  };


  /**
   * Override component name by prepending `Contained~`
   * to make it look nice, for example: `ContainedTextInput`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
    InputWithError.displayName = `Contained${WrappedComponentName}`;
  }

  return InputWithError;
}
