import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import {
  COMPANY_BANK_FIELDS,
  COMPANY_CONTACT_FIELDS,
} from 'utils/companies/helpers';

import { FN_BANK, FN_COMPANY } from 'constants/fieldNames';

import { scrollToErrors } from 'utils/form/helpers';

import { updateCompanyInfo } from 'store/features/company-details/action';
import {
  getCompanyFormValues,
  getIsIndividualEntrepreneur,
} from 'store/features/companyForm/selectors';
import { setCompanyFormValues } from 'store/features/companyForm/actions';
import { hasAvailableOrders } from 'store/features/orders/selectors';

import { Button, Text } from 'components';
import { FinalForm } from 'components/FinalForm';
import { ConfirmResetPopup } from 'shared/CompanyForm/ConfirmResetDataPopup';
import { Mapper } from './Mapper';

import { formatValues, getValidators } from './utils';

import styles from '../../styles.pcss';
const cx = classNames.bind(styles);

const SAVE_TEXT = 'Сохранить';
const BANK_DETAILS_TEXT = 'Банковские реквизиты';
const CONTACT_DATA_TEXT = 'Контактные данные';

const EditableForm = ({
  data,
  formValues,
  onFormChange,
  onSaveCallback,
  updateCompany,
  isIndividual,
  hasOrders,
  renderAddress,
  renderWholeName,
}) => {
  const configureReadonlyFields = useCallback(() => {
    if (hasOrders) {
      return { readonlyFields: data.readonlyFields };
    }
    const { contactPerson, contactPersonPhone } = FN_COMPANY;
    const { ks, rs, bik, name } = FN_BANK;
    const editableFields = [
      contactPerson,
      contactPersonPhone,
      ks,
      rs,
      bik,
      name,
    ];

    return {
      readonlyFields: Object.keys(data).filter((value) => !editableFields.includes(value)),
    };
  }, [data, hasOrders]);

  const fieldsToValidate = Object.keys(formValues).reduce((acc, value) => {
    if (
      COMPANY_CONTACT_FIELDS.includes(value) ||
      COMPANY_BANK_FIELDS.includes(value)
    ) {
      acc = {
        ...acc,
        [value]: formValues[value],
      };
    }

    return acc;
  }, {});

  const onSubmit = useCallback(
    (values) => {
      const formattedValues = formatValues(values, data, isIndividual);

      return updateCompany(formattedValues).then(onSaveCallback);
    },
    [updateCompany, onSaveCallback],
  );

  return (
    <>
      <ConfirmResetPopup />

      <FinalForm
        isValidateOnSubmit
        values={formValues}
        onSubmit={onSubmit}
        onChange={onFormChange}
        onGetErrors={scrollToErrors}
        getValidators={getValidators(fieldsToValidate, isIndividual)}
      >
        <Text className={cx('groupName')}>{CONTACT_DATA_TEXT}</Text>
        <div className={cx('groupContainer')}>
          <Mapper
            data={{ ...data, ...configureReadonlyFields() }}
            isIndividual={isIndividual}
            renderAddress={renderAddress}
            renderWholeName={renderWholeName}
            fieldsKeys={COMPANY_CONTACT_FIELDS}
          />
        </div>

        <Text className={cx('groupName')}>{BANK_DETAILS_TEXT}</Text>
        <div className={cx('groupContainer')}>
          <Mapper
            data={data}
            isIndividual={isIndividual}
            renderAddress={renderAddress}
            renderWholeName={renderWholeName}
            fieldsKeys={COMPANY_BANK_FIELDS}
          />
        </div>

        <div className={cx('buttonsContainer')}>
          <Button isSubmit>{SAVE_TEXT}</Button>
        </div>
      </FinalForm>
    </>
  );
};

EditableForm.propTypes = {
  data: PropTypes.object,
  formValues: PropTypes.object,
  isIndividual: PropTypes.bool,
  hasOrders: PropTypes.bool,
  onFormChange: PropTypes.func,
  updateCompany: PropTypes.func,
  onSaveCallback: PropTypes.func,
  renderAddress: PropTypes.func,
  renderWholeName: PropTypes.func,
};

const mapStateToProps = (state) => ({
  formValues: getCompanyFormValues(state),
  isIndividual: getIsIndividualEntrepreneur(state),
  hasOrders: hasAvailableOrders(state),
});

const mapDispatchToProps = {
  updateCompany: updateCompanyInfo,
  onFormChange: setCompanyFormValues,
};

export const EditCompanyForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditableForm);
