import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { PopupWithBackBtn } from 'components/Popup/components/PopupWithBackBtn';
import { Button } from 'components/Button';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ConfirmPopup = ({
  children,
  confirmText = 'Да',
  failureText = 'Нет',
  failureEvent,
  confirmEvent,
  ...props
}) => (
  <PopupWithBackBtn overlayClassName={cx('confirm-overlay')} className={cx('confirm-wrapper')} {...props}>
    <div className={cx('confirm-container')}>
      {children}
      <div className={cx('buttons-wrapper')}>
        <Button onClick={confirmEvent} isWide>{confirmText}</Button>
        <Button onClick={failureEvent} isWide isSecondary>{failureText}</Button>
      </div>
    </div>
  </PopupWithBackBtn>
);

ConfirmPopup.propTypes = {
  ...PopupWithBackBtn.propTypes,
  confirmEvent: PropTypes.func,
  confirmText: PropTypes.string,
  failureText: PropTypes.string,
  failureEvent: PropTypes.func,
};
