import { axiosAuthorizedRequest } from 'requests/helpers';
import {
  getCreateCompanyUrl,
  getUpdateCompanyUrl,
  getCheckPassportUrl,
  getCompanyDetailsUrl,
  getBankSuggestionUrl,
  getCompanyTemplateUrl,
  getCompanyDataByINNUrl,
  getUpdateCompanyBanUrl,
  getMakeCompanyOwnerUrl,
  getAvailableCompaniesUrl,
  getDeleteCompanyMemberUrl,
  getUpdateCompanyMemberUrl,
  getCreateCompanyMemberUrl, getCitySuggestionUrl,
} from 'requests/api/constants';
import { mapCompanyTemplateData, mapCompanyDataByINN, mapBankSuggestion, mapCitySuggestion } from '../mappers/company';

export const getCreateCompanyRequest = (values) => axiosAuthorizedRequest({
  url: getCreateCompanyUrl(),
  method: 'POST',
  data: values,
})
  .then(({ data }) => data);

export const getUpdateCompanyRequest = (values) => axiosAuthorizedRequest({
  url: getUpdateCompanyUrl(),
  method: 'PUT',
  data: values,
})
  .then(({ data }) => data);

export const getUpdateCompanyBanRequest = ({ companyId, ban }) => axiosAuthorizedRequest({
  url: getUpdateCompanyBanUrl(),
  method: 'POST',
  data: { companyId, ban },
})
  .then(({ data }) => data);

export const getAvailableCompaniesRequest = () => axiosAuthorizedRequest({
  url: getAvailableCompaniesUrl(),
})
  .then(({ data }) => data);

export const getCompanyDetailsRequest = (id) => axiosAuthorizedRequest({
  url: getCompanyDetailsUrl(id),
})
  .then(({ data }) => data);

export const getCreateCompanyMemberRequest = ({ companyId, phone, fio, position }) => axiosAuthorizedRequest({
  url: getCreateCompanyMemberUrl(),
  method: 'POST',
  data: {
    companyId,
    phone,
    fio,
    position,
  },
})
  .then(({ data }) => data);

export const getUpdateCompanyMemberRequest = ({ companyId, phone, fio, position }) => axiosAuthorizedRequest({
  url: getUpdateCompanyMemberUrl(),
  method: 'POST',
  data: {
    companyId,
    phone,
    fio,
    position,
  },
})
  .then(({ data }) => data);

export const getDeleteCompanyMemberRequest = ({ companyId, phone }) => axiosAuthorizedRequest({
  url: getDeleteCompanyMemberUrl(),
  method: 'DELETE',
  data: {
    companyId,
    phone,
  },
})
  .then(({ data }) => data);

export const getMakeCompanyOwnerRequest = ({ companyId, phone }) => axiosAuthorizedRequest({
  url: getMakeCompanyOwnerUrl(),
  method: 'POST',
  data: { companyId, phone },
})
  .then(({ data }) => data);

export const fetchCompanyTemplateData = () => axiosAuthorizedRequest({
  url: getCompanyTemplateUrl(),
})
  .then(({ data }) => mapCompanyTemplateData(data))
  .catch((e) => (console.log(e)));

export const fetchCompanyDataByINN = (inn) => axiosAuthorizedRequest({
  url: getCompanyDataByINNUrl(inn),
})
  .then(({ data }) => mapCompanyDataByINN(data))
  .catch((e) => { console.dir(e); });

export const fetchBankSuggestion = (qs) => axiosAuthorizedRequest({
  method: 'GET',
  url: getBankSuggestionUrl(qs),
})
  .then(mapBankSuggestion)
  .catch(() => ({ suggestions: [] }));

export const fetchCitySuggestion = (qs) => axiosAuthorizedRequest({
  method: 'GET',
  url: getCitySuggestionUrl(qs),
})
  .then(mapCitySuggestion)
  .catch(() => ({ suggestions: [] }));

export const checkPassportRequest = (docId, docNo) => axiosAuthorizedRequest({
  method: 'POST',
  url: getCheckPassportUrl(),
  data: { docId, docNo },
})
  .then(() => true)
  .catch(() => false);
