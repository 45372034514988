import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const RadioButton = ({ name, value, disabled, onChange, className }) => {
  const handleChange = useCallback((e) => {
    // eslint-disable-next-line no-unused-expressions
    onChange?.(e.target.value);
  }, [onChange]);

  return (
    <input
      type="radio"
      id={value}
      name={name}
      value={value}
      disabled={disabled}
      className={cx('radio', value && 'has-value', className)}
      onChange={handleChange}
    />
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default RadioButton;
