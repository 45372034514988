export const getCompanyForm = (state) => state.companyForm;

export const getCompanyTypes = (state) => getCompanyForm(state)?.companyTypes;
export const getCountriesDictionary = (state) => getCompanyForm(state)?.countriesDictionary;
export const getCompanyFormValues = (state) => getCompanyForm(state)?.values;
export const getIsIndividualEntrepreneur = (state) => Boolean(getCompanyForm(state)?.isIndividualEntrepreneur);
export const getConfirmResetPopupState = (state) => getCompanyForm(state)?.confirmResetPopupState;
export const getPassportIsValid = (state) => getCompanyForm(state)?.isValidPassport;
export const getInvalidPassportModal = (state) => getCompanyForm(state)?.invalidPassportModalIsOpen;
export const getCitySelected = (state) => getCompanyForm(state)?.citySelected;
