import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ENTER_THE_CORRECT_PHONE_NUMBER_TEXT,
  FIELD_CANNOT_BE_EMPTY_TEXT,
  INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT,
} from 'constants/validators';

import PopupForm from 'components/Popup/components/PopupForm';

import {
  composePhoneValidator,
  composeRequiredValidator,
  composeXSSValidator,
} from 'utils/field-validators';
import { getMemberCreateInfoPopup } from 'store/common/content/selectors';
import { createCompanyMember } from 'store/features/company-details/action';
import { createMemberResponseError, createMemberState } from 'store/features/company-details/selectors';
import { Button } from 'components/Button';
import { stateValues } from 'utils/redux-stateful';
import SuccessfullyInfoPopup from 'components/Popup/components/SuccessfullyInfoPopup';
import Error from 'shared/Error';

const CreateForm = ({ id, createNewMember, state, onPopupClose, responseError, popupInfo }) => {
  if (state === stateValues.Pending) {
    return <PopupForm.Preloader />;
  }

  if (state === stateValues.Success) {
    return (<SuccessfullyInfoPopup onCloseClick={onPopupClose} />);
  }

  if (state === stateValues.Error) {
    return (<Error
      heading={responseError}
      subTitle={responseError ? null : undefined}
      btnText="Закрыть"
      onClick={onPopupClose}
    />);
  }

  const handleSubmit = (values) => {
    const { phone, fio, position } = values;

    createNewMember({
      companyId: id,
      phone: `7${phone}`,
      fio,
      position,
    });
  };

  const { heading, subTitle, placeholders, labels, submitButtonText } = popupInfo;

  return (
    <>
      <PopupForm.HeadText>{heading}</PopupForm.HeadText>
      <PopupForm.DescText>{subTitle}</PopupForm.DescText>
      <PopupForm.FinalForm
        isValidateOnSubmit
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
        getValidators={() => ({
          phone: (val) => {
            const isNotEmptyFn = composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT);

            if (isNotEmptyFn(val)) return isNotEmptyFn(val);
            return composePhoneValidator(ENTER_THE_CORRECT_PHONE_NUMBER_TEXT)(val);
          },
          fio: [
            composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
            composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT),
          ],
          position: [
            composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
            composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT),
          ],
        })}
      >
        <PopupForm.Label>{labels.phoneNumber}</PopupForm.Label>
        <PopupForm.FFPhoneInput name="phone" placeholder={placeholders.phoneNumber} />
        <PopupForm.Label>{labels.fio}</PopupForm.Label>
        <PopupForm.FFTextInput
          name="fio"
          placeholder={placeholders.fio}
        />
        <PopupForm.Label>{labels.position}</PopupForm.Label>
        <PopupForm.FFTextInput
          name="position"
          placeholder={placeholders.position}
        />
        <Button isWide isSubmit>{submitButtonText}</Button>
      </PopupForm.FinalForm>
    </>
  );
};

CreateForm.propTypes = {
  id: PropTypes.string,
  createNewMember: PropTypes.func,
  state: PropTypes.string,
  responseError: PropTypes.string,
  onPopupClose: PropTypes.func,
  popupInfo: PropTypes.shape({
    heading: PropTypes.string,
    subTitle: PropTypes.string,
    submitButtonText: PropTypes.string,
    labels: PropTypes.shape({
      phoneNumber: PropTypes.string,
      fio: PropTypes.string,
      position: PropTypes.string,
    }),
    placeholders: PropTypes.shape({
      phoneNumber: PropTypes.string,
      fio: PropTypes.string,
      position: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state) => ({
  state: createMemberState(state),
  responseError: createMemberResponseError(state),
  popupInfo: getMemberCreateInfoPopup(state),
});

const mapDispatchToProps = {
  createNewMember: createCompanyMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
