import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer } from 'utils/redux-stateful';
import { FETCH_COMPANIES_LIST } from './constants';

const stateReducer = createStatefulReducer(FETCH_COMPANIES_LIST);
const actions = createStatefulActionTypes(FETCH_COMPANIES_LIST);

const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
  }),
};

export const companiesReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
