import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Text } from '../Text';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const RadioTab = ({
  id,
  disabled,
  isError,
  name,
  value,
  onClick,
  className,
  children,
  onChange,
  checked,
  displayValue,
  ...restProps
}) => (
  <label
    htmlFor={id}
    onClick={() => onChange(value)}
    className={className}
  >
    <input
      {...restProps}
      onChange={onChange}
      disabled={disabled}
      id={id}
      data-invalid={isError}
      className={cx('radio')}
      name={name}
      type="radio"
      value={value}
      checked={checked}
    />

    <button
      type="button"
      className={cx('button', { checked })}
    >
      <div className={cx('circle', { checked })} />

      <Text isPrimary>
        {children}
      </Text>
    </button>
  </label>
);

RadioTab.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  checked: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  displayValue: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  children: PropTypes.any,
};
