import React from 'react';


/**
 * Similar to https://github.com/donavon/use-event-listener
 * Except accepts domNodes and refs as domTarget
 */
export function useEventListener(eventName, handler, domTarget = global) {
  const savedHandler = React.useRef();
  const getDOMNode = () => domTarget && domTarget.current || domTarget || {};

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      const domNode = getDOMNode();
      const isSupported = domNode.addEventListener;

      if (!isSupported) return undefined;

      const eventListener = event => savedHandler.current(event);
      domNode.addEventListener(eventName, eventListener);
      return () => {
        domNode.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, getDOMNode()],
  );
}
