import { blankObj } from 'utils/fn';
import { stateValues } from 'utils/redux-stateful';
import { isGroupFilled } from 'utils/orders/helpers';

const getDraftOrder = (state) => state.draftOrder ?? blankObj;

export const getFilledGroups = (state) => getDraftOrder(state)?.filledGroups;
export const getLastSendGroupAlias = (state) => getDraftOrder(state).lastSendGroupAlias;

export const getDraftOrderState = (state) => getDraftOrder(state).state;

export const getIsAllOperators = state =>
    getFilledGroups(state)?.find(el => el.alias === 'Operators')?.fields[0].selectedEnumAlias === 'All';

export const getIsBeelineOperator = state =>
  !!getFilledGroups(state)?.find(el => el.alias === 'Operators')
    ?.fields?.find(({ selectedEnumAlias }) => selectedEnumAlias === 'Beeline');

export const getIsAuthOnly = state =>
  getFilledGroups(state)?.find(el => el.alias === 'ClientsData')?.fields[0].selectedEnumAlias === 'AuthOnly';

export const getSendToAcceptStatus = state => getFilledGroups(state)
  ?.filter?.(({ stepNumber }) => stepNumber < 6)
  ?.every?.(({ fields }) => isGroupFilled(fields));

export const isDraftOrderSended = (state) => getDraftOrderState(state) === stateValues.Success;
export const isDraftOrderLoading = (state) => getDraftOrderState(state) === stateValues.Initial;
export const isDraftOrderError = (state) => getDraftOrderState(state) === stateValues.Error;
export const isDraftOrderInitial = (state) => getDraftOrderState(state) === stateValues.Initial;
