import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Tooltip from 'components/Tooltip';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const InfoTooltip = ({ children, className, isMobile }) => (
  <Tooltip
    tooltipMessage={children}
    className={className}
    isMobile={isMobile}
  >
    <span className={cx('info-span')}>!</span>
  </Tooltip>
);

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default InfoTooltip;
