import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Code = ({ code }) => (
  <div className={cx('container, marb-se')}><code className={cx('code')}><pre>{code}</pre></code></div>);

Code.propTypes = {
  code: PropType.string,
};
