import { createAction } from '@reduxjs/toolkit';
import { createStatefulRequest } from 'utils/redux-stateful';

import { fetchCategoriesRequest, submitMessageRequest } from 'requests/api';

import {
  FETCH_CATEGORIES,
  SET_HELP_POPUP,
  SET__CURRENT_SUPPORT_CATEGORY,
  SET_MESSAGE_SENT,
  SET_SENDING_ERROR,
} from './constants';

export const fetchCategories = createStatefulRequest(FETCH_CATEGORIES, fetchCategoriesRequest);

export const setPopup = createAction(SET_HELP_POPUP);
export const setMessageSent = createAction(SET_MESSAGE_SENT);
export const setCurrentCategory = createAction(SET__CURRENT_SUPPORT_CATEGORY);
export const setSendingError = createAction(SET_SENDING_ERROR);

export const openPopup = () => (dispatch) => {
  dispatch(setPopup(true));
};

export const closePopup = () => (dispatch) => {
  dispatch(setPopup(false));
  dispatch(setMessageSent(false));
  dispatch(setCurrentCategory(''));
};

export const submitMessage = (values, currentCategory) => (dispatch) => {
  submitMessageRequest(values, currentCategory)
    .catch(() => {
      dispatch(setSendingError(true));
    })
    .finally(() => {
      dispatch(setMessageSent(true));
    });
};
