import { connectRouter } from 'connected-react-router';

import breakpointReducer from './common/breakpoint/reducers';
import { textsReducer } from './common/texts/reducer';
import { ordersReducer } from './features/orders/reducer';
import { authReducer } from './features/auth/reducer';
import { createOrderReducer } from './features/create-order/reducer';
import { draftOrderReducer } from './features/draft-order/reducer';
import { reviewReducer } from './features/review/reducer';
import { orderDetailsReducer } from './features/details-order/reducer';
import { userReducer } from './features/user/reducer';
import { employeeOrderReducer } from './features/employee-order/reducer';
import { claimReducer } from './features/claim/reducer';
import { companiesReducer } from './features/companies/reducer';
import { currentCompanyReducer } from './features/currentCompany/reducer';
import { companyDetailsReducer } from './features/company-details/reducer';
import { companyFormReducer } from './features/companyForm/reducer';
import { navigationReducer } from './common/navigation/reducer';
import { helpAndSupportReducer } from './features/helpAndSupport/reducer';
import { contentReducer } from './common/content';


export const createRootReducer = (history) => (state = { common: {} }, action) => ({
  ...state,
  common: {
    ...state.common,
    breakpoint: breakpointReducer(state.common.breakpoint, action),
    texts: textsReducer(state.common.texts, action),
    auth: authReducer(state.common.auth, action),
    navigation: navigationReducer(state.common.navigation, action),
  },
  user: userReducer(state.user, action),
  router: connectRouter(history)(state.router, action),
  createOrder: createOrderReducer(state.createOrder, action),
  orderDetail: orderDetailsReducer(state.orderDetail, action),
  draftOrder: draftOrderReducer(state.draftOrder, action),
  employeeOrder: employeeOrderReducer(state.employeeOrder, action),
  claim: claimReducer(state.claim, action),
  review: reviewReducer(state.review, action),
  orders: ordersReducer(state.orders, action),
  companies: companiesReducer(state.companies, action),
  currentCompany: currentCompanyReducer(state.currentCompany, action),
  companyDetails: companyDetailsReducer(state.companyDetails, action),
  companyForm: companyFormReducer(state.companyForm, action),
  helpAndSupport: helpAndSupportReducer(state.helpAndSupport, action),
  content: contentReducer(state.content, action),
});
