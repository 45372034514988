import { axiosAuthorizedRequest } from '../helpers';
import { getEmloyeeChangeOrderStatusUrl } from './constants';

export const changeOrderStatusRequest = ({
  status,
  orderId,
  comment,
}) => axiosAuthorizedRequest({
  url: getEmloyeeChangeOrderStatusUrl(),
  method: 'POST',
  data: {
    newStatusAlias: status,
    customerFormId: orderId,
    comment,
  },
})
  .then(({ data }) => data);
