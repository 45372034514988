/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { FixedOverlay, Preloader } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


function GlobalPreloader({ isOpen, className }) {
  const [isTiredOfWaiting, setTiredOfWaiting] = React.useState(false);
  const timerRef = React.useRef(0);

  React.useEffect(() => {
    if (isOpen) {
      timerRef.current = setTimeout(() => setTiredOfWaiting(true), 450);

      return () => clearTimeout(timerRef.current);
    }
    clearTimeout(timerRef.current);
    setTiredOfWaiting(false);
  }, [isOpen]);
  return (
    <FixedOverlay isOpen={isOpen} onClose={() => null} className={cx('container', className)}>
      {isTiredOfWaiting && (
        <Preloader />
      )}
    </FixedOverlay>
  );
}

GlobalPreloader.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default GlobalPreloader;
