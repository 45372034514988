import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FIELD_CANNOT_BE_EMPTY_TEXT, INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT } from 'constants/validators';

import PopupForm from 'components/Popup/components/PopupForm';

import { composeRequiredValidator, composeXSSValidator } from 'utils/field-validators';
import { updateCompanyMember } from 'store/features/company-details/action';
import { getMemberEditInfoPopup } from 'store/common/content/selectors';
import { updateMemberResponseError, updateMemberState } from 'store/features/company-details/selectors';
import { stateValues } from 'utils/redux-stateful';
import { Button } from 'components/Button';
import SuccessfullyInfoPopup from 'components/Popup/components/SuccessfullyInfoPopup';
import Error from 'shared/Error';

const EditForm = ({ data, updateMember, state, onPopupClose, responseError, popupInfo }) => {
  const initialValues = {
    fio: data?.fio,
    position: data?.position,
  };

  const [formValues, setFormValues] = useState(initialValues);

  const onChange = useCallback((values) => {
    setFormValues(values);
  }, []);

  if (state === stateValues.Pending) {
    return <PopupForm.Preloader />;
  }

  if (state === stateValues.Success) {
    return (<SuccessfullyInfoPopup onCloseClick={onPopupClose} />);
  }

  if (state === stateValues.Error) {
    return (<Error
      heading={responseError}
      subTitle={responseError ? null : undefined}
      btnText="Закрыть"
      onClick={onPopupClose}
    />);
  }

  const handleSubmit = (values) => {
    const { fio, position } = values;
    updateMember({
      companyId: data.companyId,
      phone: data.phone.slice(-11),
      fio,
      position,
    });
  };

  const { submitButtonText, heading, subTitle, labels, placeholders } = popupInfo;

  return (
    <>
      <PopupForm.HeadText>{heading}</PopupForm.HeadText>
      <PopupForm.DescText>{subTitle}</PopupForm.DescText>
      <PopupForm.FinalForm
        isValidateOnSubmit
        values={formValues}
        onChange={onChange}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
        getValidators={() => ({
          fio: [composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
            composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT)],
          position: [composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT),
            composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT)],
        })}
      >
        <PopupForm.Label>{labels.fio}</PopupForm.Label>
        <PopupForm.FFTextInput
          name="fio"
          placeholder={placeholders.fio}
        />
        <PopupForm.Label>{labels.position}</PopupForm.Label>
        <PopupForm.FFTextInput
          name="position"
          placeholder={placeholders.position}
        />
        <Button isWide isSubmit>{submitButtonText}</Button>
      </PopupForm.FinalForm>
    </>
  );
};

EditForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    phone: PropTypes.string,
    fio: PropTypes.string,
    position: PropTypes.string,
    companyId: PropTypes.string,
  }),
  updateMember: PropTypes.func,
  state: PropTypes.string,
  responseError: PropTypes.string,
  onPopupClose: PropTypes.func,
  popupInfo: PropTypes.shape({
    heading: PropTypes.string,
    subTitle: PropTypes.string,
    submitButtonText: PropTypes.string,
    labels: PropTypes.shape({
      fio: PropTypes.string,
      position: PropTypes.string,
    }),
    placeholders: PropTypes.shape({
      fio: PropTypes.string,
      position: PropTypes.string,
    }),
  }),
};

const mapStateToProps = state => ({
  state: updateMemberState(state),
  responseError: updateMemberResponseError(state),
  popupInfo: getMemberEditInfoPopup(state),
});

const mapDispatchToProps = {
  updateMember: updateCompanyMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
