export const scrollToErrors = (errors) => {
  const errorFieldsArray = Object.keys(errors);
  if (errorFieldsArray.length > 0) {
    const firstInputWithError = errorFieldsArray[0];
    const inputNode = document.getElementsByName(firstInputWithError)[0];

    if (inputNode && inputNode.type === 'hidden') {
      inputNode.nextSibling?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else if (inputNode) {
      inputNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
};
