import { useState, useEffect, useCallback } from 'react';

export const useContentMargin = (elementRef) => {
  const [marginLeft, setMargin] = useState(null);

  const updateMargin = useCallback(() => {
    if (elementRef && elementRef.current) {
      const style = getComputedStyle(elementRef.current);
      setMargin(parseInt(style.marginLeft, 10));
    }
  }, [elementRef]);

  useEffect(() => {
    updateMargin();
    window.addEventListener('resize', updateMargin);

    return () => {
      window.removeEventListener('resize', updateMargin);
    };
  }, [updateMargin]);

  return [marginLeft];
};
