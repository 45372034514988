import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { mapContent } from 'pages/PluginsPage/mappers';

import { ContentMapper } from './components/ContentMapper';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Description = ({ children }) => {
  const mappedChildren = useMemo(() => mapContent(children), [children]);

  return (
    <div className={cx('component')}>
      {mappedChildren.map((child, index) => (
        <ContentMapper key={index.toString()} {...child} />
      ))}
    </div>
  );
};

Description.propTypes = {
  children: PropTypes.array,
};
