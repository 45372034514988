export const MARGIN_BOTTOM = {
  xxl: 'marb-xxl',
  xl: 'marb-xl',
  xx: 'marb-xx',
  xs: 'marb-xs',
  l: 'marb-l',
  se: 'marb-se',
};

export const MARGIN_TOP = {
  xxl: 'mart-xxl',
  xl: 'mart-xl',
  xx: 'mart-xx',
  xs: 'mart-xs',
  l: 'mart-l',
  se: 'mart-se',
};
