let API_URL = '/b2b/api/v1';
if (/localhost/.test(window.location.hostname)) API_URL = '/api/v1';
// if (/beelinetst/.test(window.location.hostname)) API_URL = 'http://apipromo.beelinetst.ru';

export const getCreateOrderUrl = ({ companyId }) => `${API_URL}/customer/application/create/${companyId}`;
export const getSaveOrderGroupFieldsUrl = () => `${API_URL}/customer/application/savefields/`;
export const getSendToReviewRequestUrl = (id) => `${API_URL}/customer/application/sendtoreview/${id}`;
export const getCustomerOrdersDataUrl = () => `${API_URL}/customer/application/list`;
export const getCompanyOrdersUrl = (companyId) => `${API_URL}/customer/application/list/${companyId}`;
export const getOrderDetailsUrl = ({ id }) => `${API_URL}/customer/application/details/${id}`;
export const getCheckUrlsUrl = () => `${API_URL}/customer/application/check-urls`;

export const getEmployeeOrderDetailsUrl = ({ id }) => `${API_URL}/employee/application/details/${id}`;
export const getEmloyeeSaveOrderGroupFieldsUrl = () => `${API_URL}/employee/application/savefields/`;
export const getEmloyeeChangeOrderStatusUrl = () => `${API_URL}/employee/application/changestatus/`;
export const getEmloyeeDownloadDocxUrl = ({ id }) => `${API_URL}/employee/application/document/docx/${id}`;
export const getEmloyeeSendMsgToOperatorsUrl = () => `${API_URL}/employee/sendmessagetooperators`;
export const getCreateClaimIDUrl = () => `${API_URL}/employee/application/idgw/`;
export const getCreateSubscriptionUrl = (id) => `${API_URL}/employee/application/createSubscription/${id}`;

export const getRegistrationUrl = () => `${API_URL}/auth/registration/`;
export const getAuthUrl = () => `${API_URL}/auth/isemployee/`;
export const getIsEmployeeUrl = () => `${API_URL}/auth/isemployee/`;
export const getAdAvailableUrl = () => `${API_URL}/auth/ad/available/`;
export const getAuthAdUrl = () => `${API_URL}/auth/ad/`;
export const getAuthMobileIdUrl = () => `${API_URL}/auth/mobileid/`;
export const getAuthMobileIdStatusUrl = () => `${API_URL}/auth/mobileid/result/`;
export const getUserInfoUrl = () => `${API_URL}/auth/info/`;
export const getLogOutUrl = () => `${API_URL}/auth/logout`;

export const getFeedbackCategoriesUrl = () => `${API_URL}/feedback/topic/list`;
export const postFeedbackSubmitUrl = () => `${API_URL}/feedback`;
export const getOmnesEmployeeProfilesTableData = () => `${API_URL}/employee/application/list`;

export const getCompanyTemplateUrl = () => `${API_URL}/company/template`;
export const getCreateCompanyUrl = () => `${API_URL}/company/create`;
export const getUpdateCompanyUrl = () => `${API_URL}/company/update`;
export const getUpdateCompanyBanUrl = () => `${API_URL}/company/update-ban`;
export const getAvailableCompaniesUrl = () => `${API_URL}/company/available/list`;
export const getCompanyDetailsUrl = (id) => `${API_URL}/company/details/${id}`;
export const getCreateCompanyMemberUrl = () => `${API_URL}/company/member/create`;
export const getUpdateCompanyMemberUrl = () => `${API_URL}/company/member/update`;
export const getDeleteCompanyMemberUrl = () => `${API_URL}/company/member/delete`;
export const getMakeCompanyOwnerUrl = () => `${API_URL}/company/member/makeowner`;
export const getCompanyDataByINNUrl = (inn = '') => `${API_URL}/company/inn-suggestion/${inn}`;
export const getCheckPassportUrl = () => `${API_URL}/company/check-passport`;
export const getBankSuggestionUrl = (qs) => `${API_URL}/company/banks?bankName=${encodeURIComponent(qs)}`;
export const getCitySuggestionUrl = (value) => `${API_URL}/similar-cities?cityNameStart=${encodeURIComponent(value)}`;

export const getIPListValidationUrl = () => `${API_URL}/both/application/validate-ip-list`;
export const getSendInvoiceUrl = ({ companyId, amount }) => (
  `${API_URL}/company/createcase/${companyId}?amount=${amount}`
);

export const getTextsUrl = () => `${API_URL}/contents/texts`;
export const getReactModelUrl = () => `${API_URL}/contents/reactModel`;
export const getDocumentsUrl = () => `${API_URL}/contents/files/mainpage`;
export const getNavigateItemUrl = (alias) => `${API_URL}/contents/files/alias/${alias}`;

export const getFakeCtnListUrl = (companyId) => `${API_URL}/company/${companyId}/fake-ctn-list`;
export const getProceedPaymentUrl = () => `${API_URL}/payment/proceed`;
export const get3DSTermUrl = () => `${API_URL}/payment/proceed-3ds`;
export const getPaymentStatusUrl = (paymentId) => `${API_URL}/payment/status/${paymentId}`;
