import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer } from 'utils/redux-stateful';
import { CREATE_ORDER, RESET_AFTER_CREATING} from './constants';

const stateReducer = createStatefulReducer(CREATE_ORDER);
const actions = createStatefulActionTypes(CREATE_ORDER);
const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
  }),
  [RESET_AFTER_CREATING]: () => ({ state: 'initial' }),
};
export const createOrderReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
