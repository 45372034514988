import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { MAIN_PAGE_URL, EMPLOYEE_MAIN_PAGE_URL } from 'constants';

import { getIsEmployee } from 'store/features/auth/selectors';
import { getNotFoundPage } from 'store/common/content';

import PageLayout from 'shared/PageLayout';
import ErrorPage from 'shared/ErrorPage';
import { Button } from 'components/Button';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const NotFound = ({ history, isEmployee, content }) => {
  const onButtonClick = () => {
    isEmployee ? history.replace(EMPLOYEE_MAIN_PAGE_URL) : history.replace(MAIN_PAGE_URL);
  };

  return (
    <PageLayout>
      <div className={cx('content')}>
        <ErrorPage
          headingText={content.heading}
          descriptionText={content.description}
        />
        <Button className={cx('button')} onClick={onButtonClick}>
          {content.buttonText}
        </Button>
      </div>
    </PageLayout>
  );
};

NotFound.propTypes = {
  history: PropTypes.object,
  content: PropTypes.shape(({
    heading: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
  })),
  isEmployee: PropTypes.bool,
};

const mapStateToProps = state => ({
  isEmployee: getIsEmployee(state),
  content: getNotFoundPage(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
)(NotFound);
