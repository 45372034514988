import { createReducer } from '@reduxjs/toolkit';
import {
  setCompanyFormValues,
  setCompanyFormTemplate,
  setIsIndividualEntrepreneur,
  resetCompanyForm,
  setConfirmResetPopupState,
  setPassportIsValid,
  setInvalidPassportModal,
  setCitySelected,
} from './actions';

const initialState = {};

export const companyFormReducer = createReducer(initialState, {
  [resetCompanyForm.type]: (state) => { state.values = initialState; },
  [setCompanyFormTemplate.type]: (state, action) => action.payload,
  [setCitySelected.type]: (state, action) => { state.citySelected = action.payload; },
  [setCompanyFormValues.type]: (state, action) => { state.values = action.payload; },
  [setIsIndividualEntrepreneur.type]: (state, action) => { state.isIndividualEntrepreneur = action.payload; },
  [setConfirmResetPopupState.type]: (state, action) => { state.confirmResetPopupState = action.payload; },
  [setPassportIsValid.type]: (state, action) => { state.isValidPassport = action.payload; },
  [setInvalidPassportModal.type]: (state, action) => { state.invalidPassportModalIsOpen = action.payload; },
});
