import { createReducer } from '@reduxjs/toolkit';

import { setContent } from './actions';

const initialState = {};

export const contentReducer = createReducer(initialState, {
  [setContent.type]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
});
