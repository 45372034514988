import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';
import { FFTextInput } from 'components/FinalForm';
import { getFieldInfo } from 'utils/companies/helpers';

import styles from '../../styles.pcss';
const cx = classNames.bind(styles);

export const FieldsGroup = ({ fieldInfo, children }) => {
  if (React.Children.count(children)) return children;

  if (fieldInfo instanceof Array) {
    return fieldInfo.map(el => {
      const { name, placeholder } = getFieldInfo(el);

      return (
        <div key={el} className={cx('fieldContainer')}>
          <Text className={cx('fieldName')}>
            {name}
          </Text>
          <FFTextInput
            name={el}
            className={cx('input')}
            placeholder={placeholder}
          />
        </div>
      );
    });
  }

  return null;
};

FieldsGroup.propTypes = {
  fieldInfo: PropTypes.object,
  children: PropTypes.node,
};
