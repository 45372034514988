import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { compose, map } from 'utils/fn';
import withIsMobile from 'enhancers/withIsMobile';
import StatelessTableCheckBox from 'components/Checkbox/StatelessTableCheckBox';
import { isIE11 } from 'enhancers/helpers';
import { EMPLOYEE_EDIT_ORDER_ROUTE } from 'constants/routes';
import TableRow from './components/TableRow';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const isAllCheck = (activeObjList) => !Object.keys(activeObjList).find((key) => !activeObjList[key]);

const checkboxInitialState = (rows) => (rows ? rows.reduce((result, { id }) => ({ ...result, [id]: false }), {}) : {});

const DataTable = ({ columns, rows, tableName, lastPrewRow, isMobile, history }) => {
  const [activeObjList, setActiveObjList] =
  useState(checkboxInitialState(rows));

  const widthIE = useMemo(
    () => (isIE11() ? (columns.reduce((accum, elem) => accum + elem.maxSize, 0) + 0.6) / 100 : false),
    [columns],
  );

  useEffect(() => {
    setActiveObjList(checkboxInitialState(rows));
  }, [rows]);

  const checkboxChangeHandle = ({ rowId, status }) => {
    setActiveObjList({ ...activeObjList, [rowId]: status });
  };

  const generalCheckboxChangeHandle = ({ status }) => {
    setActiveObjList(map(activeObjList, () => status));
  };

  const tableRowClickHandle = (rowId) => () => {
    history.push(`${EMPLOYEE_EDIT_ORDER_ROUTE}${rowId}`);
  };

  const columnsReader = () => {
    const headObj = {
      columnsSizes: 'minmax(50px, .3fr) minmax(50px, .3fr)',
      thTemplates: [],
    };
    columns.forEach(headLabel => {
      headObj.columnsSizes = `${headObj.columnsSizes} minmax(${headLabel.minSize},  ${headLabel.maxSize || 1}fr)`;
      headObj.thTemplates.push((
        <th
          style={widthIE ? { width: (`${headLabel.maxSize / widthIE}%`) } : {}}
          key={headLabel.key} className={cx('table-header-cells')}
        >
          <div className={cx('default-cell')}>{headLabel.title}</div>
        </th>));
    });
    return headObj;
  };

  const headObj = columnsReader();
  const style = widthIE ? { width: (`${0.6 / widthIE}%`) } : {};

  return (
    <div className={cx(isMobile ? 'mobile-container' : '')}>
      <table id={tableName} style={{ gridTemplateColumns: headObj.columnsSizes }}>
        <thead>
          <tr>
            <th
              style={style}
              key="checkbox"
              className={cx('table-header-cells', 'first-cell')}
            >
              <StatelessTableCheckBox onChange={generalCheckboxChangeHandle} checked={isAllCheck(activeObjList)} />
            </th>
            <th
              style={style}
              key="number"
              className={cx('table-header-cells')}
            >
              №
            </th>
            {headObj.thTemplates}
          </tr>
        </thead>
        <tbody>
          {
            rows?.map((itemRow, index) => (
              <TableRow
                key={Math.random()}
                rowNumber={lastPrewRow + index + 1}
                rowId={itemRow.id}
                itemRow={itemRow}
                active={activeObjList[itemRow.id]}
                checkBoxChangeAction={checkboxChangeHandle}
                columns={columns}
                widthIE={widthIE}
                onRowClick={tableRowClickHandle(itemRow.id)}
              />
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    prerenderFunction: PropTypes.func,
    minSize: PropTypes.string,
    maxSize: PropTypes.number,
  })).isRequired,
  rows: PropTypes.array,
  tableName: PropTypes.string.isRequired,
  lastPrewRow: PropTypes.number,
  isMobile: PropTypes.bool,
  history: PropTypes.object,
};

export default compose(withRouter, withIsMobile)(DataTable);
