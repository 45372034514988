import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);


export function Button({
  children,
  href,
  onClick,
  isLight,
  isWide,
  isDisabled,
  isSubmit,
  className,
  isSecondary,
  type = null,
  download,
}) {
  const TagName = isSubmit || !href ? 'button' : 'a';
  return (
    <TagName
      href={isSubmit ? null : href}
      onClick={onClick}
      download={download}
      type={isSubmit ? 'submit' : type}
      className={cx(
        'component',
        {
          wide: isWide,
          light: isLight,
          disabled: isDisabled,
          secondary: isSecondary,
        },
        className,
      )}
    >
      {children}
    </TagName>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  download: PropTypes.bool,
  isLight: PropTypes.bool,
  isWide: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSubmit: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
};
