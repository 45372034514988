import { NOTHING_FOUND_TEXT } from 'constants/texts';

import { isEmptyObject } from 'utils/fn';
import { getNextIsIndividualValue } from 'utils/companies/helpers';

const mapCountriesDictionary = (countries) => countries?.map(({ code, name }) => ({
  label: name,
  value: code,
}));

export const mapCompanyTemplateData = (data) => {
  if (isEmptyObject(data)) {
    return Promise.reject(new Error('Cannot fetch form template'));
  }

  const { allCompanyTypes, citiesForAutocomplete, id, ban, clientName, countries, ...templateData } = data;

  const companyType = templateData.companyType || 'ООО';

  return {
    companyTypes: allCompanyTypes,
    countriesDictionary: mapCountriesDictionary(countries),
    template: {
      ...templateData,
      companyType,
      gender: templateData.gender || 'M',
      contactPersonPhone: templateData.contactPersonPhone.slice(-10),
    },
    isIndividualEntrepreneur: getNextIsIndividualValue(companyType, allCompanyTypes),
  };
};

export const mapCompanyDataByINN = (data) => {
  if (isEmptyObject(data)) {
    return Promise.reject(new Error('Cannot fetch suggestion'));
  }

  const { isIndividualEntrepreneur, ...restData } = data;

  return restData;
};

export const mapBankSuggestion = ({ data }) => {
  if (!data.length) {
    return Promise.reject(new Error('Cannot fetch suggestion'));
  }

  return {
    response: data,
    suggestions: data.map((el) => el.bankName),
  };
};

export const mapCitySuggestion = ({ data }) => {
  if (!data.length) {
    return {
      response: data,
      suggestions: [NOTHING_FOUND_TEXT],
    };
  }

  return {
    response: data.map((el) => ({ city: el })),
    suggestions: data,
  };
};
