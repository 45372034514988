import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';

import { getInstructionFirstCallBlock } from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { Text } from 'components';
import { Table } from './Table';
import { Example } from './Example';
import { MARGIN_BOTTOM } from '../../constants';

const cx = classNames.bind(commonStyles);

const FirstCallBlock = ({ content: { text, table, updatedTable, example }, isMobile, className }) => (
  <div className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl, className)}>
    <Text
      isInterphases
      isMedium
      className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xl)}
    >
      {text}
    </Text>

    {/* TODO: Remove old table after release, as it's incompatible with new table structure */}
    {table && <Table
      content={table}
      updated={updatedTable}
      className={cx(isMobile ? MARGIN_BOTTOM.l : MARGIN_BOTTOM.xxl)}
    />}

    <Example {...example} isMobile={isMobile} />
  </div>
);

const mapStateToProps = (state) => ({
  content: getInstructionFirstCallBlock(state),
  shouldRender: !!getInstructionFirstCallBlock(state),
  isMobile: isScreenBelow800(state),
});

FirstCallBlock.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    table: PropTypes.array,
    updatedTable: PropTypes.object,
    example: PropTypes.object,
  }),
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

export const FirstCall = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(FirstCallBlock);
