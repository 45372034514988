import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectCategories, selectCurrentCategory } from 'store/features/helpAndSupport/selectors';
import { setCurrentCategory, submitMessage } from 'store/features/helpAndSupport/action';
import { getHelpAndSupportForm } from 'store/common/content';

import { composeEmailValidator, composeRequiredValidator } from 'utils/field-validators';

import { FFTextarea, FFTextInput, FinalForm } from 'components/FinalForm';
import DropDown from 'components/Dropdown';
import { Button, Heading, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const FormComponent = ({ currentCategory, categories, content, onSubmit, onSetCategory }) => (
  <FinalForm
    className={cx('help-form')}
    isValidateOnSubmit
    onSubmit={async values => {
      onSubmit(values, currentCategory);
    }}
    getValidators={() => ({
      email: [
        composeEmailValidator(content.validatorMessages.email),
        composeRequiredValidator(content.validatorMessages.emailRequired),
      ],
      comment: composeRequiredValidator(content.validatorMessages.comment),
    })}
  >
    <div className={cx('text-container')}>
      <Heading size="2" isMobile className={cx('text-container-heading')}>
        {content.title}
      </Heading>

      <Text isSmall>
        {content.description}
      </Text>
    </div>

    <DropDown
      name="category"
      placeholder={content.placeholders.category}
      onChange={onSetCategory}
      options={categories}
    />

    <FFTextInput
      className={cx('email-input')}
      name="email"
      placeholder={content.placeholders.email}
    />

    <FFTextarea
      className={cx('textarea-wrapper')}
      name="comment"
      placeholder={content.placeholders.comment}
      height="100px"
    />

    <Button className={cx('help-button')} isDisabled={!currentCategory} isSubmit isWide>
      {content.buttonText}
    </Button>
  </FinalForm>
);

const mapStateToProps = (state) => ({
  currentCategory: selectCurrentCategory(state),
  categories: selectCategories(state),
  content: getHelpAndSupportForm(state),
});

const mapDispatchToProps = {
  onSetCategory: setCurrentCategory,
  onSubmit: submitMessage,
};

FormComponent.propTypes = {
  currentCategory: PropTypes.string,
  categories: PropTypes.array,
  onSetCategory: PropTypes.func,
  onSubmit: PropTypes.func,
  content: PropTypes.object,
};

export const Form = connect(mapStateToProps, mapDispatchToProps)(FormComponent);
