import React from 'react';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'types';

import { Icon } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const Wrapper = ({ children }) => (
  <div className={cx('component')}>
    <Icon className={cx('logo')} name="mobileIdLogo" />

    {children}
  </div>
);

Wrapper.propTypes = {
  children: childrenPropTypes,
};
