import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';

import {
  getReviewId,
  isReviewSendError as isReviewSendErrorSelector,
  isReviewSendSuccess as isReviewSendSuccessSelector,
} from 'store/features/review/selectors';
import { getIsAuthOnly } from 'store/features/draft-order/selectors';
import { getOrderDetails as getOrderDetailsAction } from 'store/features/details-order/action';
import { setGroupFields, clearDraftAfterSuccess } from 'store/features/draft-order/action';
import {
  getOdrerDetailsData,
  isOrderDetailsLoaded,
  isOrderDetailsLoading,
} from 'store/features/details-order/selectors';
import { openSendInvoicePopup } from 'store/features/user/action';

import { compose } from 'utils/fn';
import { MAIN_PAGE_URL } from 'constants';
import { excludeReadOnlyFields } from 'utils/orders/helpers';
import { clearAfterSuccess as clearAfterSuccessAction } from 'store/features/review/action';

import PageLayout from 'shared/PageLayout';
import ResultPopup from 'shared/ResultPopup';
import OrderGroups from 'pages/CreateOrderPage/components/OrderGroups';
import { Preloader } from 'components';
import FixedOverlay from 'components/Popup/components/FixedOverlay';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const GROUPS_TO_HIDE = ['AuthorizationCode', 'BankInfo', 'FakePhone', 'TestPeriod', 'Fee', 'AddFee', 'CompanyInfo'];

const TITLE_TEXT = 'Учетная запись для Мобильного ID уже создается.';
const DESCRIPTION_TEXT = 'Очень скоро в вашей анкете появятся Client ID и Client Secret для подключения по API';
const TITLE_TEXT_IS_AUTH_ONLY = 'Учетная запись для Мобильного ID уже создана.';
const DESCRIPTION_TEXT_IS_AUTH_ONLY = 'Можете приступить к интеграции по&nbsp;<a href="//static.beelinetst.ru/upload/MobileID/contents/10666/Beeline.MobileID.Aggregator.API.v1.0.pdf" target="_blank">API</a>.';

const EditOrderPage = ({
  getOrderDetails,
  isDataLoading,
  data,
  isReviewSendSuccess,
  isReviewSendError,
  reviewId,
  clearReview,
  history,
  match,
  setFields,
  clearDraft,
  isAuthOnly,
  handleSendInvoicePopupOpen,
}) => {
  const [isResultPopupOpen, setIsResultPopupOpen] = useState(false);
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  useEffect(() => {
    getOrderDetails({ id: match.params.id });
  }, [match.params.id]);

  useEffect(() => {
    if (data && reviewId === data.id && (isReviewSendSuccess || isReviewSendError)) {
      setIsResultPopupOpen(true);
    }
  }, [data, reviewId, isReviewSendSuccess, isReviewSendError]);

  const filteredGroups = useMemo(() => {
    if (data) {
      const fieldsGroups = excludeReadOnlyFields(data.fieldsGroups)
        .filter(group => !GROUPS_TO_HIDE.includes(group.alias));
      fieldsGroups.map(el => setFields(el));
      return {
        ...data,
        fieldsGroups,
      };
    }
  }, [data]);

  const onBackClick = () => {
    history.push(MAIN_PAGE_URL);
    if (isReviewSendSuccess) {
      clearReview();
      clearDraft();
    }
  };

  useEffect(() => {
    return () => {
      clearReview();
      clearDraft();
    };
  }, []);

  const composeTextsPopup = () => {
    if (isAuthOnly) {
      return {
        title: TITLE_TEXT_IS_AUTH_ONLY,
        description: DESCRIPTION_TEXT_IS_AUTH_ONLY,
      };
    }

    return {
      title: TITLE_TEXT,
      description: DESCRIPTION_TEXT,
    };
  };

  return (
    <PageLayout isLoading={isDataLoading}>
      {data && (
        <OrderGroups
          data={filteredGroups}
          openInvoicePopup={handleSendInvoicePopupOpen}
          setIsLoaderActive={setIsLoaderActive}
        />
      )}

      {isResultPopupOpen && (
        <ResultPopup
          onClose={isReviewSendSuccess ? onBackClick : () => setIsResultPopupOpen(false)}
          isSuccess={isReviewSendSuccess}
          texts={composeTextsPopup()}
        />
      )}
      <FixedOverlay
        className={cx('overlay')}
        isOpen={isLoaderActive}
        type="preloader"
      >
        <Preloader />
      </FixedOverlay>
    </PageLayout>
  );
};

EditOrderPage.propTypes = {
  getOrderDetails: PropTypes.func,
  clearReview: PropTypes.func,
  clearDraft: PropTypes.func,
  setFields: PropTypes.func,
  isDataLoading: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    fieldsGroups: PropTypes.array,
  }),

  isReviewSendSuccess: PropTypes.bool,
  isReviewSendError: PropTypes.bool,
  reviewId: PropTypes.string,
  isAuthOnly: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  handleSendInvoicePopupOpen: PropTypes.func,
};

const mapStateToProps = (state) => ({
  data: getOdrerDetailsData(state),
  isDataLoaded: isOrderDetailsLoaded(state),
  isDataLoading: isOrderDetailsLoading(state),
  isAuthOnly: getIsAuthOnly(state),
  isReviewSendSuccess: isReviewSendSuccessSelector(state),
  isReviewSendError: isReviewSendErrorSelector(state),
  reviewId: getReviewId(state),
});

const mapDispatchToProps = {
  getOrderDetails: getOrderDetailsAction,
  clearReview: clearAfterSuccessAction,
  clearDraft: clearDraftAfterSuccess,
  setFields: setGroupFields,
  handleSendInvoicePopupOpen: openSendInvoicePopup,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EditOrderPage);

