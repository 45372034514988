import { useMemo } from 'react';
import { getFieldInfo, mapCompanyFieldInfo } from 'utils/companies/helpers';
import { composeCheckedValidator, composeLengthValidator, composeRequiredValidator } from 'utils/field-validators';
import { getCreateCompanyRequest } from 'requests/api/company';

import { MAIN_PAGE_URL } from 'constants';

import {
  FN_COMPANY,
  FN_OFFER_AGREEMENT,
  FN_PERSON_DATA_AGREEMENT,
} from 'constants/fieldNames';

import {
  getFormGroupsEntries,
  omitCheckboxValue,
  getFieldsRowEntries,
  formatValues,

} from './helpers';

const ENTER_VALID_OGRNIP = 'Введите корректный ОГРНИП';
const ENTER_VALID_OGRN = 'Введите корректный ОГРН';
const ENTER_VALID_INN = 'Введите корректный  ИНН';
const MUST_AGREE_TO_THE_TERMS = 'Необходимо согласиться с условиями';

export const useForm = ({ allFormValues, allCompanyTypes, resetForm, history, isIndividual }) => {
  const groups = useMemo(() => getFormGroupsEntries(omitCheckboxValue(allFormValues)), []);

  const fieldsRowEntries = getFieldsRowEntries(allFormValues);

  const getValidators = () => {
    const validatorsObject = fieldsRowEntries.reduce((acc, { alias: field }) => {
      const fieldInfo = getFieldInfo(field);
      if ((fieldInfo.isIndividual !== undefined) && (fieldInfo.isIndividual !== isIndividual)) return acc;
      let validators = fieldInfo?.validator?.length ?
        [...fieldInfo?.uniqueValidator, ...fieldInfo.validator] : fieldInfo?.uniqueValidator || null;

      let fieldByIndividual = field;
      switch (field) {
        case FN_COMPANY.ogrnip:
        case FN_COMPANY.ogrn:
          validators = [
            composeRequiredValidator(isIndividual ?
              mapCompanyFieldInfo[FN_COMPANY.ogrnip]?.errorText :
              mapCompanyFieldInfo[FN_COMPANY.ogrn]?.errorText),
            composeLengthValidator(
              isIndividual ? ENTER_VALID_OGRNIP : ENTER_VALID_OGRN,
              { min: isIndividual ? 15 : 13 },
            ),
          ];
          fieldByIndividual = isIndividual ? FN_COMPANY.ogrnip : FN_COMPANY.ogrn;
          break;
        case FN_COMPANY.inn:
          validators = [
            composeRequiredValidator(mapCompanyFieldInfo[FN_COMPANY.inn]?.errorText),
            composeLengthValidator(ENTER_VALID_INN, { min: isIndividual ? 12 : 10 }),
          ];
          break;
        default:
      }
      return ({
        ...acc,
        [fieldByIndividual]: validators,
      });
    }, {});
    return {
      ...validatorsObject,
      [FN_OFFER_AGREEMENT]: composeCheckedValidator(MUST_AGREE_TO_THE_TERMS),
      [FN_PERSON_DATA_AGREEMENT]: composeCheckedValidator(MUST_AGREE_TO_THE_TERMS),
    };
  };

  const handleSubmit = (values) => {
    const formattedValues = formatValues(values, allCompanyTypes);
    return getCreateCompanyRequest(formattedValues).then(() => {
      history.push(MAIN_PAGE_URL);
      resetForm();
    });
  };

  return {
    groups,
    getValidators,
    handleSubmit,
  };
};
