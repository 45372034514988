import { IS_TEST } from 'constants/index';

export const IFRAME_PARAMS = {
  prod: {
    origin: 'https://oplata.beeline.ru',
    src: 'https://oplata.beeline.ru/widget/payment/new',
  },
  test: {
    origin: 'https://ms-mcardreg003.bee.vimpelcom.ru:4843',
    src: 'https://ms-mcardreg003.bee.vimpelcom.ru:4843/payment/new/',
  },
};

export const getIFrameSrc = () => (
  IS_TEST ? IFRAME_PARAMS.test.src : IFRAME_PARAMS.prod.src
);

export const getIFrameOrigin = () => (
  IS_TEST ? IFRAME_PARAMS.test.origin : IFRAME_PARAMS.prod.origin
);
