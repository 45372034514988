import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer, stateValues } from 'utils/redux-stateful';
import { SEND_TO_ACCEPT, SET_ACCEPTED } from './constants';

const stateReducer = createStatefulReducer(SEND_TO_ACCEPT);
const actions = createStatefulActionTypes(SEND_TO_ACCEPT);
const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
  }),
  [SET_ACCEPTED]: (state, action) => ({ state: stateValues.Initial, data: action.data }),
};
export const employeeOrderReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
