import React from 'react';
import PropTypes from 'prop-types';

import attention from './assets/attention.svg';
import arrow from './assets/Arrow.svg';
import dropdownArrow from './assets/dropdownArrow.svg';
import trash from './assets/trash.svg';
import successCircle from './assets/success-circle.svg';
import help from './assets/help.svg';
import mobileIdLogo from './assets/mobileIdLogo.svg';
import copy from './assets/copy.svg';
import edit from './assets/edit.svg';
import document from './assets/document.svg';
import instruction from './assets/instruction.svg';


const icons = {
  attention,
  arrow,
  dropdownArrow,
  trash,
  successCircle,
  help,
  mobileIdLogo,
  copy,
  edit,
  document,
  instruction,
};

export const Icon = ({ name, className }) => {
  const Svg = icons[name];

  return <Svg className={className} />;
};

Icon.propTypes = {
  className: PropTypes.any,
  name: PropTypes.oneOf(Object.keys(icons)),
};
