import { getStore } from 'store/core';
import { getIsAuthOnly } from 'store/features/draft-order/selectors';

import { DEFAULT_TABS_VALUES } from 'utils/orders/constants';
import { getFieldInfo, getValue, isShouldShowField, getDisplayValue } from 'utils/orders/helpers';
import { composeXSSValidator } from 'utils/field-validators';
import { INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT } from 'constants/validators';

const defaultValuesMap = new Map(Object.entries(DEFAULT_TABS_VALUES));

export const getExtSelectValue = (values, alias) => (
  values[alias]?.withExtension
    ? { [alias]: values.ExtendedValue }
    : values
);

export const mapFields = (fields) => fields.map(field => getFieldInfo(field));
export const filterFields = (fields, hiddenFields) => fields.filter(field => !hiddenFields.includes(field.alias));

export const getInitialValues = (fields, hiddenFields) => filterFields(fields, hiddenFields).reduce((acc, field) => ({
  ...acc,
  [field.alias]: getFieldInfo(field)?.initialValue ?? getValue(field),
}), {});

export const swapEnumItems = (field) => {
  if (defaultValuesMap.get(field.alias) === field.enumItems[1]) {
    [field.enumItems[0], field.enumItems[1]] = [field.enumItems[1], field.enumItems[0]];
  }
};

export const getValidators = (fieldsInfo, formValues) => () => fieldsInfo.reduce((acc, field) => {
  const store = getStore();
  if (!isShouldShowField(field, formValues)) return acc;

  if (getIsAuthOnly(store.getState()) && field.alias === 'IncomingIPAddresses') {
    delete field.validator;
  }

  if (!field?.validator && !field?.errorText) return acc;

  const validators = field?.validator?.length
    ? [field?.uniqueValidator, ...field?.validator] : field?.uniqueValidator;

  return ({
    ...acc,
    [field.alias]: [...validators, composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT)],
  });
}, {});

export const getFieldsDisplayValue = (fields) => fields.reduce((acc, field) => (
  field.fieldType !== 'Enum' || getDisplayValue(field)
    ? acc
    : { ...acc, [field.alias]: DEFAULT_TABS_VALUES[field.alias] }
), {});
