import { isPlainObject } from 'utils/fn';

export const getNavigationContent = (content) => content.map(({ key, system }) => ({ key, system }));

export const getNavigation = (data) => data.map(({ key, heading, content }) => ({
  key,
  heading,
  content: getNavigationContent(content),
}));

export const getIsActiveCategory = (content, activePluginKey) => content.findIndex(({ key }) => key === activePluginKey) !== -1;

export const getPluginContent = (data, activePluginKey) => data.reduce((acc, { content }) => {
  const item = content.find((plugin) => plugin.key === activePluginKey);

  return item || acc;
}, {});

const mapInnerContent = (content) => {
  if (!content || !content.length) return [];

  return content.map((item) => {
    if (isPlainObject(item)) {
      if (item.multiple) {
        return {
          multiple: item.multiple,
        };
      }

      if (item.input) {
        return {
          text: item.text,
          input: item.input,
        };
      }

      if (item.radioButtons) {
        return {
          text: item.text,
          radioButtons: item.radioButtons,
        };
      }

      const key = Object.keys(item)[1];

      return {
        text: item.text,
        list: {
          type: key,
          content: mapInnerContent(item[key]),
        },
      };
    }

    return {
      text: item,
    };
  });
};

export const mapContent = (content) => {
  if (!content || !content.length) return [];

  return content.map((item) => {
    if (isPlainObject(item)) {
      if (item.button) {
        return {
          buttonText: item.button,
        };
      }

      if (item.input) {
        return {
          input: item.input,
        };
      }

      if (item.radioButtons) {
        return {
          text: item.text,
          radioButtons: item.radioButtons,
        };
      }

      const key = Object.keys(item)[0];

      return {
        list: {
          type: key,
          content: mapInnerContent(item[key]),
        },
      };
    }

    return {
      text: item,
    };
  });
};
