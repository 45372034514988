import { createSelector } from '@reduxjs/toolkit';

import { stateValues } from 'utils/redux-stateful';

import { getAppCommons } from 'store/selectors';

const getAuth = state => getAppCommons(state).auth;
const getAuthState = state => getAuth(state)?.state;

export const getAuthStatus = state => getAuth(state)?.status;
export const getIsEmployee = state => getAuth(state)?.isEmployee;
export const isAuthStatusLoaded = state => getAuthState(state) === stateValues.Success;
export const isAuthStatusLoading = state => getAuthState(state) === stateValues.Pending;
export const selectIsUser = createSelector(
  [isAuthStatusLoaded, getIsEmployee],
  (authStatusLoaded, isEmployee) => authStatusLoaded && !isEmployee,
);
