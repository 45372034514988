import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { getOrderStatus } from 'store/common/content';

import { Text } from 'components/Text';
import Tooltip from 'components/Tooltip';
import { convertISODateToDMString } from 'utils/date';
import { STATUS_TEXTS } from 'utils/orders/constants';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const formatDate = (date, user) => `от ${convertISODateToDMString(date)}, ${user}`;

const tooltipMessageTemplate = (orderHistory, messageTexts) => (
  <div className={cx('tooltip-message-container')}>
    {orderHistory?.map(elem => (
      <div className={cx('tooltip-message-item')} key={elem.created}>
        <span className={cx('tooltip-date')}>{convertISODateToDMString(elem.created)}</span>
        <span className={cx('tooltip-text')}>
          {messageTexts[elem.statusAlias]}{!elem.employee && (` ${  elem.person}`)}
        </span>
      </div>
    ))
    }
  </div>
);

const OrderStatus = ({ status, date, orderHistory, user, messageTexts, withText = true }) => (
  <div className={cx('wrap')}>
    <Text className={cx('statusText', status)} isInline>
      {STATUS_TEXTS[status]}
    </Text>
    {withText && orderHistory && messageTexts && (
      <Tooltip tooltipMessage={tooltipMessageTemplate(orderHistory, messageTexts)} position="bottom">
        <Text isInline isSecondary>
          {user && formatDate(date, user)}
        </Text>
      </Tooltip>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  messageTexts: getOrderStatus(state),
});

OrderStatus.propTypes = {
  status: PropTypes.oneOf(['Draft', 'Review', 'Accepted', 'Rejected', 'Blocked', 'Deleted']),
  date: PropTypes.string,
  orderHistory: PropTypes.arrayOf(PropTypes.shape({
    created: PropTypes.string,
    statusAlias: PropTypes.string,
    person: PropTypes.string,
    employee: PropTypes.bool,
  })),
  user: PropTypes.string,
  withText: PropTypes.bool,
};

export default connect(mapStateToProps)(OrderStatus);
