import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { useWillMount, useUnauthorizedZone } from 'hooks';
import withReactRouter from 'enhancers/withReactRouter';
import { withDataConverter, withHMR, withReduxStore, withAdaptivity, withReactModel } from 'enhancers';

import { initializeStore } from 'store';
import { getTexts } from 'store/common/texts/action';
import { getContentReady } from 'store/common/content';
import {
  fetchAuthStatus as fetchAuthStatusAction,
  fetchIsEmployee as fetchIsEmployeeAction,
} from 'store/features/auth/action';
import { isAuthStatusLoaded as isAuthStatusLoadedSelector } from 'store/features/auth/selectors';
import { breakpoints, setBreakpoint, getBreakpoint } from 'store/common/breakpoint';

import PageLayout from 'shared/PageLayout';
import { TopUpCompanyBalanceWidget } from 'shared/TopUpCompanyBalance';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import dataConverter from './dataConverter';
import { routes } from './routes';

const App = ({ isAuthStatusLoaded, contentReady, fetchAuthStatus, fetchIsEmployee, fetchTexts }) => {
  /* Страница с короткой инструкцией должна находится в неавторизованной зоне */
  const isUnauthorizedZone = useUnauthorizedZone();

  useWillMount(fetchTexts);

  useEffect(() => {
    if (!isUnauthorizedZone) {
      fetchAuthStatus();
    }
  }, [fetchAuthStatus]);

  useEffect(() => {
    if (isAuthStatusLoaded && !isUnauthorizedZone) {
      fetchIsEmployee();
    }
  }, [isAuthStatusLoaded]);

  if ((!isAuthStatusLoaded && !isUnauthorizedZone) || !contentReady) {
    return (
      <PageLayout isLoading />
    );
  }

  return (
    <>
      <TopUpCompanyBalanceWidget />
      <Switch>
        {routes.map((route, i) => (
          <Route
            key={`${route.path}-${i}`}
            {...route}
          />
        ))}
      </Switch>
    </>
  );
};

App.propTypes = {
  contentReady: PropTypes.bool,
  isAuthStatusLoaded: PropTypes.bool,
  fetchAuthStatus: PropTypes.func,
  fetchIsEmployee: PropTypes.func,
  fetchTexts: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isAuthStatusLoaded: isAuthStatusLoadedSelector(state),
  contentReady: getContentReady(state),
});

const mapDispatchToProps = ({
  fetchAuthStatus: fetchAuthStatusAction,
  fetchIsEmployee: fetchIsEmployeeAction,
  fetchTexts: getTexts,
});

export default compose(
  withHMR(module),
  withDataConverter(dataConverter),
  withReduxStore(initializeStore),
  withReactModel(),
  withReactRouter(),
  withAdaptivity({ breakpoints, setBreakpoint, getBreakpoint }),
  connect(mapStateToProps, mapDispatchToProps),
)(App);
