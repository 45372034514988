import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { useLockScroll } from 'hooks';

import { ToggleButton } from '../ToggleButton';
import FixedOverlay from '../Popup/components/FixedOverlay';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const OverlayMenu = ({ button, className, buttonClassName, navigation }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleState = useCallback(() => setIsOpen(prevState => !prevState), []);

  useLockScroll(isOpen);

  return (
    <>
      <ToggleButton showIcon={isOpen} className={cx('marb-xs', buttonClassName)} onClick={toggleState}>
        {button}
      </ToggleButton>

      <FixedOverlay
        isOpen={isOpen}
        hasScrollOverlay
        className={cx('overlay-container', className)}
      >
        <ToggleButton showIcon className={buttonClassName} onClick={toggleState} />

        <nav className={cx('navigation', 'mart-x')}>
          {navigation}
        </nav>
      </FixedOverlay>
    </>
  );
};

OverlayMenu.propTypes = {
  button: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.any,
  buttonClassName: PropTypes.any,
  navigation: PropTypes.node,
};
