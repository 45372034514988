import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Button, Heading } from 'components';
import { AccordionContent, Description } from 'shared';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Content = ({ title, link, instruction, className }) => (
  <div className={cx('component', className)}>
    <Heading className={cx('title')} size={1}>
      {title}
    </Heading>

    <AccordionContent
      content={instruction}
      render={({ description }) => (
        <Description>
          {description}
        </Description>
      )}
    />


    <Button className={cx('button')} href={link} target="_blank" download>
      Скачать плагин
    </Button>
  </div>
);

Content.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  instruction: PropTypes.array,
  className: PropTypes.string,
};
