import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { FormSpy } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import iuliia from 'iuliia';

import {
  FN_COMPANY,
  FN_OFFER_AGREEMENT,
  FN_PERSON_DATA_AGREEMENT,
} from 'constants/fieldNames';

import {
  getCompanyTypes,
  getCompanyFormValues,
  getIsIndividualEntrepreneur,
  getCountriesDictionary,
  getPassportIsValid, getCitySelected,
} from 'store/features/companyForm/selectors';
import {
  resetCompanyForm,
  sendCheckPassportRequest,
  setCompanyFormValues,
} from 'store/features/companyForm/actions';

import { compose } from 'utils/fn';
import { scrollToErrors } from 'utils/form/helpers';

import { Button, DocumentLink } from 'components';
import { FinalForm, FFCheckbox } from 'components/FinalForm';
import { FormGroup } from './components/FormGroup';
import { ConfirmResetPopup } from './ConfirmResetDataPopup';
import { InvalidPassportPopup } from './InvalidPassportPopup';

import { formSpyHandler } from './formSpyHandler';
import { useForm } from './useForm';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const CONDITION_USE_MOBILEID = 'условиями оказания услуги Мобильный ID';

const CONDITION_PERSONAL_DATA_PROCESSING =
  'условиями обработки персональных данных';
const I_AGREE = 'Я согласен с';

const STATIC_LINK_CREATE_COMPANY_OFFER =
  '//static.beeline.ru/upload/MobileID/contents/10666/mobileid_b2b_create_company_offer.pdf';

const STATIC_LINK_PERSONAL_DATA =
  '//static.beeline.ru/upload/MobileID/contents/10666/consent_to_the_processing_of_personal_data.pdf';

const CompanyForm = ({
  history,
  resetForm,
  isCitySelected,
  isIndividual,
  isValidPassport,
  onFormChange,
  allFormValues,
  allCompanyTypes,
  dictionaries,
  checkPassport,
}) => {
  const passportDataLastSubmit = React.useRef({ docId: null, docNo: null });

  const { groups, getValidators, handleSubmit } = useForm({
    allFormValues,
    allCompanyTypes,
    resetForm,
    history,
    isIndividual,
  });

  const handleFormSpyChange = ({ values, active }) => {
    formSpyHandler(
      values,
      active,
      checkPassport,
      passportDataLastSubmit.current,
    );

    if (active === FN_COMPANY.name) {
      const strTr = iuliia.translate(
        values[FN_COMPANY.name] || '',
        iuliia.ICAO_DOC_9303,
      );
      onFormChange({ ...values, companyNameEn: strTr });
    }
  };

  return (
    <>
      <ConfirmResetPopup />

      <InvalidPassportPopup />

      <FinalForm
        isValidateOnSubmit
        values={allFormValues}
        onChange={onFormChange}
        onSubmit={(isCitySelected || typeof isCitySelected === 'undefined') ? handleSubmit : undefined}
        onGetErrors={scrollToErrors}
        getValidators={getValidators}
      >
        <FormSpy
          subscription={{ values: true, active: true }}
          onChange={handleFormSpyChange}
        />

        {groups?.map((group) => (
          <FormGroup
            alias={group.alias}
            name={group.name}
            fields={group.fields}
            allFormValues={allFormValues}
            dictionaries={dictionaries}
            isIndividual={isIndividual}
            onFormChange={onFormChange}
          />
        ))}

        <FFCheckbox
          name={FN_OFFER_AGREEMENT}
          id={FN_OFFER_AGREEMENT}
          className={cx('checkbox')}
        >
          <label htmlFor={FN_OFFER_AGREEMENT} className={cx('label')}>
            <DocumentLink
              linkText={CONDITION_USE_MOBILEID}
              textBeforeLink={I_AGREE}
              link={STATIC_LINK_CREATE_COMPANY_OFFER}
            />
          </label>
        </FFCheckbox>

        <FFCheckbox
          name={FN_PERSON_DATA_AGREEMENT}
          id={FN_PERSON_DATA_AGREEMENT}
          className={cx('checkbox')}
        >
          <label htmlFor={FN_PERSON_DATA_AGREEMENT} className={cx('label')}>
            <DocumentLink
              linkText={CONDITION_PERSONAL_DATA_PROCESSING}
              textBeforeLink={I_AGREE}
              link={STATIC_LINK_PERSONAL_DATA}
            />
          </label>
        </FFCheckbox>

        <Button
          isSubmit
          className={cx('button')}
          isDisabled={isIndividual && !isValidPassport}
        >
          Сохранить
        </Button>
      </FinalForm>
    </>
  );
};

CompanyForm.propTypes = {
  allFormValues: PropTypes.object,
  history: PropTypes.object,
  isIndividual: PropTypes.bool,
  isValidPassport: PropTypes.bool,
  isCitySelected: PropTypes.bool,
  onFormChange: PropTypes.func,
  resetForm: PropTypes.func,
  dictionaries: PropTypes.object,
  checkPassport: PropTypes.func,
  allCompanyTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  dictionaries: {
    countries: getCountriesDictionary(state),
  },
  allCompanyTypes: getCompanyTypes(state),
  allFormValues: getCompanyFormValues(state),
  isIndividual: getIsIndividualEntrepreneur(state),
  isValidPassport: getPassportIsValid(state),
  isCitySelected: getCitySelected(state),
});

const mapDispatchToProps = {
  resetForm: resetCompanyForm,
  onFormChange: setCompanyFormValues,
  checkPassport: sendCheckPassportRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(CompanyForm);
