import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CrossSVG from 'shared/Header/assets/cross.svg';
import BaseButton from '../BaseButton';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const ToggleButton = ({ showIcon, className, children, ...restProps }) => (
  <BaseButton className={cx('component', className)} {...restProps}>
    {showIcon ? <CrossSVG className={cx('cross-icon')} /> : children}
  </BaseButton>
);

ToggleButton.propTypes = {
  showIcon: PropTypes.bool,
  className: PropTypes.any,
  children: PropTypes.node,
};
