import React, { } from 'react';

import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import { mapFieldInfo } from 'utils/orders/helpers';
import { GROUPS_ALIASES } from 'utils/orders/constants';

import { Row, RowItem } from 'shared/Order/Grid';
import { Text } from 'components/Text';
import { TextInput } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const EditFieldsForm = ({ fields, alias, onChange, formValues, errors }) => (
  <div>
    {fields.map((field) => (
      <Row key={field.alias}>
        <RowItem>
          <Text isSecondary>{mapFieldInfo[field.alias]?.name}</Text>
        </RowItem>
        <RowItem>
          <TextInput
            name={field.alias}
            className={cx('input', { currency: alias === GROUPS_ALIASES.addFee })}
            value={String(formValues[field.alias] ?? '')}
            error={errors[field.alias]}
            onChange={(value) => {
              onChange({ value, fieldAlias: field.alias });
            }}
          />
        </RowItem>
      </Row>
      ))}
  </div>
);

EditFieldsForm.propTypes = {
  alias: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  formValues: PropTypes.object,
  errors: PropTypes.objectOf(PropTypes.string),
};

export default EditFieldsForm;
