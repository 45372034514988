import { flatten, filter, compose, sliceBy } from 'utils/fn';
import { getNextIsIndividualValue, companyGroups, getFieldInfo } from 'utils/companies/helpers';
import { FN_COMPANY, FN_OFFER_AGREEMENT, FN_PERSON_DATA_AGREEMENT, FN_USER } from 'constants/fieldNames';

export const formatValues = (values, allCompanyTypes) => {
  const fieldsToDelete = getNextIsIndividualValue(values.companyType, allCompanyTypes) ? [
    FN_COMPANY.name,
    FN_COMPANY.nameEn,
    FN_COMPANY.kpp,
    FN_COMPANY.okpo,
    FN_OFFER_AGREEMENT,
    FN_PERSON_DATA_AGREEMENT,
  ] : [
    FN_USER.gender,
    FN_USER.name,
    FN_USER.surname,
    FN_USER.middlename,
    FN_USER.fioEn,
    FN_USER.birthDate,
    FN_USER.birthplace,
    FN_USER.nationality,
    FN_USER.docId,
    FN_USER.docNo,
    FN_USER.docDate,
    FN_USER.docIssue,
    FN_USER.docIssuerCode,
    FN_OFFER_AGREEMENT,
    FN_PERSON_DATA_AGREEMENT,
  ];

  return {
    ...filter(values, (val, key) => !fieldsToDelete.includes(key)),
    contactPersonPhone: `7${values.contactPersonPhone}`,
  };
};

export const omitCheckboxValue = (formValues) => {
  const { offerAgreement, personalDataAgreement, ...values } = formValues;
  return values;
};

const getFieldsInfo = (values) => Object.keys(values).map(el => getFieldInfo(el));
const getFieldsRows = (fieldsInfo) => sliceBy(fieldsInfo, (fields) => fields.companyGroup ?? NaN)
  .map(arr => arr.sort((a, b) => a.groupOrder - b.groupOrder));
const getFormGroups = (fieldsRows) => fieldsRows.map((group, idx) => ({
  name: Object.values(companyGroups)[idx],
  alias: Object.keys(companyGroups)[idx],
  fields: group,
}));

export const getFieldsRowEntries = compose(flatten, getFieldsRows, getFieldsInfo);
export const getFormGroupsEntries = compose(getFormGroups, getFieldsRows, getFieldsInfo);


export const getPreparedOptions = (allCompanyTypes) => (
  allCompanyTypes.map(el => ({ value: el.title, label: el.title }))
);

export const fieldsRowRenderingCondition = (el, comparatorValue) => (
  el.isIndividual !== undefined && el.isIndividual !== comparatorValue
);

export const isRenderFieldsRows = (row, comparatorValue) => !(
  row.every(el => fieldsRowRenderingCondition(el, comparatorValue))
);

export const sortFieldsRows = (row) => row.slice().sort((a, b) => a.rowOrder - b.rowOrder);

