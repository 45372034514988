// export const FIELD_CANNOT_BE_EMPTY_TEXT = 'Поле не может быть пустым';
export const FIELD_CANNOT_BE_EMPTY_TEXT = 'Поле должно быть заполнено';
export const ENTER_THE_CORRECT_PHONE_NUMBER_TEXT = 'Укажите корректный номер телефона';
export const INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT = 'Введены некорректные символы';
export const FIELD_CAN_ONLY_CONTAIN_INTEGERS_AND_DECIMALS_TEXT = 'Поле может содержать только целые и десятичные числа';
export const FIELD_CAN_ONLY_CONTAIN_INTEGERS_TEXT = 'Поле может содержать только целые числа';
export const FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT = 'Поле может содержать только числа';
export const INCORRECT_URL_FORMAT_TEXT = 'Некорректный формат URL';
export const INCORRECT_HTTP_PROTOCOL = 'Адрес сервиса обязательно должен работать по безопасным протоколу https';
export const CAN_ONLY_ENTER_ENGLISH_LETTERS_TEXT = 'Можно вводить только английские буквы';
export const INCORRECT_EMAIL_TEXT = 'Некорректно введён E-mail адрес'; // 'Некорректно введён E-mail'
export const MINIMAL_PAY_TEXT = 'Минимальная сумма оплаты 1000 рублей';
export const FIELD_CAN_ONLY_CONTAIN_LETTERS_TEXT = 'Поле может содержать только буквы';
export const FIELD_FIXED_DATE_FORMAT_TEXT = 'Поле должно быть заполнено в формате ДД.ММ.ГГГГ';
export const CHOOSE_ONE_OF_THE_OPTIONS_TEXT = 'Выберите один из вариантов';
export const PASSWORDS_DO_NOT_MATCH_TEXT = 'Пароли не совпадают';
export const ENTER_THE_PASSPORT_SERIES_TEXT = 'Введите серию паспорта';
export const ENTER_PASSPORT_NUMBER_TEXT = 'Введите номер паспорта';
export const ENTER_THE_CORRECT_USERNAME_TEXT = 'Укажите корректный логин';
export const ENTER_THE_CORRECT_PASSWORD_TEXT = 'Укажите корректный пароль';
export const ENTER_THE_CORRECT_INN_TEXT = 'Введите корректный  ИНН';
export const ENTER_THE_CORRECT_OGRN_TEXT = 'Введите корректный ОГРН';
export const CAPCHA_REQUIRED = 'Подтвердите, что вы не робот';
