import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Tabs } from 'components';
import { getTabName } from 'utils/companies/helpers';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const FormTabs = ({ initialValue, onTabChange, enumItems, alias }) => (
  <Tabs
    className={cx('tabs')}
    onTabChange={onTabChange}
    initiallyActive={initialValue}
    tabs={enumItems.map(el => ({
      alias: el,
      children: (
        <div className={cx('tab-content')}>
          {getTabName(alias, el)}
        </div>
      ),
      className: cx('tab'),
    }))}
  />
);

FormTabs.propTypes = {
  name: PropTypes.string,
  alias: PropTypes.string,
  enumItems: PropTypes.array,
  onTabChange: PropTypes.func,
  initialValue: PropTypes.string,
};
