import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';
import Header from 'shared/Header';
import Footer from 'shared/Footer';
import GlobalPreloader from 'shared/GlobalPreloader';
import { useContentMargin } from 'hooks';

import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const TablePageLayout = ({ children, isWide, isLoading }) => {
  const contentRef = useRef(null);
  const [marginLeft] = useContentMargin(contentRef);

  return (
    <div className={cx('container')}>
      <Header />
      <div className={cx('middle')}>
        <div ref={contentRef} className={cx('content', { wide: isWide })}>
          {children}
        </div>
      </div>
      <Footer marginLeft={marginLeft} />
      <GlobalPreloader isOpen={isLoading} />
    </div>
  );
};

TablePageLayout.propTypes = {
  children: PropTypes.node,
  isWide: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default TablePageLayout;
