import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectIsUser } from 'store/features/auth/selectors';

import { Button, Popup } from './components';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const HelpAndSupport = ({ isUser, className }) => (
  <>
    <div className={cx('help-container', className)}>
      <Button />
    </div>

    <Popup shouldRender={isUser} />
  </>
);

const mapStateToProps = (state) => ({
  isUser: selectIsUser(state),
});

HelpAndSupport.propTypes = {
  isUser: PropTypes.bool,
  className: PropTypes.string,
};

export default connect(mapStateToProps)(HelpAndSupport);
