import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getDocumentsBlock } from 'store/common/content';

import { Icon } from 'components/Icon';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const DocumentsBlock = ({ documents, title }) => (
  <>
    <div className={cx('headText')}>
      {title}
    </div>
    <div className={cx('docsContainer')}>
      {documents.map(({ link, displayName, fileTypeUpperCase, fileSize, fileSizeUnits }) => (
        <a
          download
          key={link}
          href={link}
          target="_blank"
          rel="noreferrer"
          className={cx('item')}
        >
          <div>
            <Icon name="document" />
          </div>
          <div className={cx('desc')}>
            <span>{displayName}</span>
            <span>{`${fileTypeUpperCase}`}{!!fileSize && fileSizeUnits && ` ${fileSize} ${fileSizeUnits}`}</span>
          </div>
        </a>
        ))
      }
    </div>
  </>
);

const mapStateToProps = (state) => ({
  title: getDocumentsBlock(state).title || '',
});


DocumentsBlock.propTypes = {
  title: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    fileSize: PropTypes.number,
    fileSizeUnits: PropTypes.string,
    fileType: PropTypes.string,
    fileTypeUpperCase: PropTypes.string,
    link: PropTypes.string,
  })),
};

export default connect(mapStateToProps)(DocumentsBlock);
