import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer } from 'utils/redux-stateful';

import {
  FETCH_CATEGORIES,
  SET_HELP_POPUP,
  SET__CURRENT_SUPPORT_CATEGORY,
  SET_MESSAGE_SENT,
  SET_SENDING_ERROR,
} from './constants';

const initialState = {
  error: false,
  messageSent: false,
  currentCategory: '',
  isOpen: false,
  categories: [],
};

const stateReducer = createStatefulReducer(FETCH_CATEGORIES);
const actions = createStatefulActionTypes(FETCH_CATEGORIES);
const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    ...initialState,
    categories: action.data,
  }),

  [SET_HELP_POPUP]: (state, { payload }) => ({
    ...state,
    isOpen: payload,
  }),
  [SET_MESSAGE_SENT]: (state, { payload }) => ({
    ...state,
    messageSent: payload,
  }),
  [SET_SENDING_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
  }),
  [SET__CURRENT_SUPPORT_CATEGORY]: (state, { payload }) => ({
    ...state,
    currentCategory: payload,
  }),
};
export const helpAndSupportReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
