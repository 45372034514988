import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { getSearchTexts } from 'store/common/content';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const componentPropTypes = {
  nothingFoundText: PropTypes.string,
  onSuggestionClick: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
};

const SuggestionListComponent = ({ suggestions, nothingFoundText, onSuggestionClick }) => {
  const handleClick = (suggestion) => {
    if (suggestion === nothingFoundText) return;

    onSuggestionClick(suggestion);
  };

  return (
    <ul type="none" className={cx('container')}>
      {suggestions.map((suggestion, index) => (
        <li key={index.toString()} className={cx('item-wrapper')}>
          <button
            type="button"
            data-is-suggestion-button
            className={cx('item-btn')}
            onClick={() => handleClick(suggestion)}
          >
            {suggestion}
          </button>
        </li>
      ))}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  nothingFoundText: getSearchTexts(state).nothingFound,
});

SuggestionListComponent.propTypes = componentPropTypes;

export const SuggestionList = compose(
  connect(mapStateToProps),
  withRenderFlag,
)(SuggestionListComponent);

SuggestionList.propTypes = {
  ...componentPropTypes,
  shouldRender: PropTypes.bool,
};
