import { blankObj } from 'utils/fn';
import { stateValues } from 'utils/redux-stateful';

const getCreateOrder = (state) => state.createOrder ?? blankObj;

export const getCreateOdrerData = (state) => getCreateOrder(state).data;

const getCreateOrderState = (state) => getCreateOrder(state).state;

export const isCreateOrderLoaded = (state) => getCreateOrderState(state) === stateValues.Success;
export const isCreateOrderLoading = (state) => getCreateOrderState(state) === stateValues.Initial;
export const isCreateOrderError = (state) => getCreateOrderState(state) === stateValues.Error;
export const isCreateOrderInitial = (state) => getCreateOrderState(state) === stateValues.Initial;
