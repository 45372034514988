import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './../styles.pcss';
import CheckIcon from './../assets/check.svg';

const cx = classNames.bind(styles);

const StatelessTableCheckBox = (props) => {
  const { children, name, disabled, onChange, className, checked, rowId } = props;

  const handleCheckboxChange = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e.stopPropagation();
    onChange?.({
      rowId,
      status: !checked,
    });
  };
  return (
    <div className={cx('container', { disabled }, className)}>
      <input type="checkbox" checked={checked} name={name} />
      <span className={cx('checkmark')} onClick={handleCheckboxChange}>
        <CheckIcon name="check" className={cx('icon', { checked })} />
      </span>
      {children}
    </div>
  );
};

StatelessTableCheckBox.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  rowId: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

export default StatelessTableCheckBox;
