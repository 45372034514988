export const EVENT = 'GA_event';

export const EVENT_CATEGORY = 'mobileid_account';
export const EC_PUSH = 'push';
export const EC_CONVERTION = 'micro-conversion';
export const EC_FORM = 'form_';

export const EL_STEP = 'step';
// MOBID-2343
export const EL_LOGIN = 'https://mobileid.beeline.ru/b2b/login/';

export const EA_LOGIN = 'enter_account';
export const EA_TOP_UP = 'fill_account';
export const EA_CREATE_APP = 'create_app';
export const EA_CREATE_COMPANY = 'create_firm';
export const EA_FIELD_FOCUS = 'onfocus';
export const EA_FIELD_SUCCESS = 'successFilled';
export const EA_ERROR = 'error';
export const EA_SENT = 'sent';
export const EA_STEPS = 'steps';
export const EA_CLICK = 'click';
