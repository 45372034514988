import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'types';

import { openPopup } from 'store/features/helpAndSupport/action';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const HelpAndSupportButton = ({ onClick, children }) => (
  <button className={cx('component')} onClick={onClick}>
    {children}
  </button>
);

const mapDispatchToProps = {
  onClick: openPopup,
};

HelpAndSupportButton.propTypes = {
  onClick: PropTypes.func,
  children: childrenPropTypes,
};

export default connect(null, mapDispatchToProps)(HelpAndSupportButton);
