import { createAction } from '@reduxjs/toolkit';
import { createStatefulRequest } from 'utils/redux-stateful';
import {
  getUserInfoRequest,
  proceedPaymentRequest,
  fetchPaymentStatus,
  fetchUserCtn,
} from 'requests/api/user';
import { MAIN_ROUTE } from 'constants/routes';
import { getNeedMakeProceedReq, getTransId } from './selectors';
import {
  FETCH_USER_INFO,
  SET_USER_INFO,
  TOGGLE_TOP_UP_POPUP,
  TOGGLE_SEND_INVOICE_POPUP,
  SET_AMOUNT_VALUE,
  SET_USER_CTN,
  SET_TRANS_ID,
  SET_POPUP_STEP,
  SET_MAKE_PROCEED_PAYMENT_REQUEST, MODAL_STEPS, SET_USER_ACCESS_LEVEL,
} from './constants';

const clearLocationQueryString = () => {
  window.history.pushState({ path: MAIN_ROUTE }, '', MAIN_ROUTE);
};

export const fetchUserInfo = createStatefulRequest(FETCH_USER_INFO, getUserInfoRequest);
export const setUserInfo = (data) => ({ type: SET_USER_INFO, data });
export const setAmountValue = createAction(SET_AMOUNT_VALUE);
export const toggleTopUpPopup = createAction(TOGGLE_TOP_UP_POPUP);
export const toggleSendInvoicePopup = createAction(TOGGLE_SEND_INVOICE_POPUP);
export const setUserCtn = createAction(SET_USER_CTN);
export const setUserAccessLevel = createAction(SET_USER_ACCESS_LEVEL);
export const setTransactionId = createAction(SET_TRANS_ID);
export const setModalStep = createAction(SET_POPUP_STEP);
export const setNeedToMakeProceedPaymentReq = createAction(SET_MAKE_PROCEED_PAYMENT_REQUEST);

export const openTopUpPopup = () => toggleTopUpPopup(true);
export const closeTopUpPopup = () => (dispatch) => {
  dispatch(toggleTopUpPopup(false));
  dispatch(setModalStep(MODAL_STEPS.amountForm));
};

export const openSendInvoicePopup = () => (dispatch) => dispatch(toggleSendInvoicePopup(true));
export const closeSendInvoicePopup = () => (dispatch) => dispatch(toggleSendInvoicePopup(false));

export const checkToContinuePaymentProcess = () => (dispatch) => {
  if (new URLSearchParams(window.location.search).has('paRes')) {
    dispatch(setNeedToMakeProceedPaymentReq(true));
    dispatch(setModalStep(MODAL_STEPS.pending));
    dispatch(openTopUpPopup());
  }
};

export const loadUserCtn = (companyId) => async (dispatch) => {
  const response = await fetchUserCtn(companyId);
  dispatch(setUserAccessLevel(response.hasRequiredAccessLevel));
  dispatch(setUserCtn(response.ctn));
};

export const toPendingPaymentView = () => setModalStep(MODAL_STEPS.pending);
export const toErrorTopUpView = () => setModalStep(MODAL_STEPS.fail);
export const toSuccessTopUpView = () => setModalStep(MODAL_STEPS.success);

const paymentStatusService = (transId, dispatch) => fetchPaymentStatus(transId)
  .then(() => { dispatch(toSuccessTopUpView()); })
  .catch(() => { dispatch(toErrorTopUpView()); })
  .finally(clearLocationQueryString);

export const continuePaymentProcess = () => (dispatch, getState) => {
  const needToMakeProceedPayment = getNeedMakeProceedReq(getState());
  const transactionId = getTransId(getState());

  if (needToMakeProceedPayment) {
    proceedPaymentRequest()
      .then(() => { paymentStatusService(transactionId, dispatch); })
      .catch(() => { dispatch(toErrorTopUpView()); clearLocationQueryString(); });
  } else {
    paymentStatusService(transactionId, dispatch);
  }
};

