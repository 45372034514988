import React from 'react';

import PropTypes from 'prop-types';

import BaseButton from 'components/BaseButton';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Tab = ({
  onClick,
  children,
  className,
  isWide,
  isActive,
}) => {
  const clickHandler = (e) => {
    if (!isActive) onClick(e);
  };

  return (
    <BaseButton
      className={cx('btn', className, {
        wide: isWide,
        active: isActive,
      })}
      onClick={clickHandler}
    >
      <div className={cx('radioCircle', { active: isActive })} />
      <div className={cx('content')}>
        {children}
      </div>
    </BaseButton>
  );
};

Tab.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.any,
  isWide: PropTypes.bool,
  isActive: PropTypes.bool,
};
