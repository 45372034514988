import { SmartCaptcha } from '@yandex/smart-captcha';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { withError, withLabel } from '../enhancers';

import styles from './CaptchaInput.pcss';
const cx = classNames.bind(styles);


function CaptchaInput({ captchaToken, onSuccess, className, onChange }) {
  const onCapchaSuccess = (token) => {
    onSuccess(token);
    onChange(true);
  };

  return (
    <div className={cx('mart-xs', 'component', className)}>
      <SmartCaptcha
        sitekey={captchaToken}
        onSuccess={onCapchaSuccess}
      />
    </div>
  );
}

CaptchaInput.propTypes = {
  onSuccess: PropTypes.func,
  onChange: PropTypes.func,
  captchaToken: PropTypes.string,
  className: PropTypes.string,
};

export default withError(withLabel(CaptchaInput));
