import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer, stateValues } from 'utils/redux-stateful';
import { CREATE_CLAIM_ID, CLEAR_STATUS } from './constants';

const stateReducer = createStatefulReducer(CREATE_CLAIM_ID);
const actions = createStatefulActionTypes(CREATE_CLAIM_ID);

const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_SUCCESS]: (state, action) => ({
    state: stateReducer(state, action),
    data: action.data,
  }),
  [CLEAR_STATUS]: () => ({ state: stateValues.Initial }),
};

export const claimReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
