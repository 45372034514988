import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import {
  getDraftOrderState,
  getFilledGroups, getIsAuthOnly,
  getLastSendGroupAlias,
  getSendToAcceptStatus,
  isDraftOrderError as isDraftOrderErrorSelector,
  isDraftOrderSended as isDraftOrderSendedSelector,
} from 'store/features/draft-order/selectors';
import { getAllTexts } from 'store/common/texts/selectors';
import { saveGroupFieldsDraft as saveGroupFieldsDraftAction } from 'store/features/draft-order/action';
import { sendToReview as sendToReviewAction } from 'store/features/review/action';
import {
  getReviewId,
  isReviewSendError as isReviewSendErrorSelector,
  isReviewSendPending as isReviewSendPendingSelector,
} from 'store/features/review/selectors';

import {
  getHiddenFieldsAliases,
  getGroupName,
  getGroupTooltip,
  getGroupDescription,
} from 'utils/orders/helpers';
import { GROUPS_ALIASES } from 'utils/orders/constants';
import { ipListValidationRequest } from 'requests/api/create-order';

import { GroupTitle } from 'shared';
import OrderGroupInfo from 'shared/Order/OrderGroupInfo';
import OrderFormGroup from 'shared/Order/OrderFormGroup';
import { Text } from 'components/Text';


import styles from './styles.pcss';

const cx = classNames.bind(styles);

const hasNextStep = (currentStep, groups) => {
  const currIndex = groups.findIndex(({ alias }) => currentStep === alias);

  return (currIndex !== groups.length - 1);
};

const getNextStep = (currentStep, groups) => {
  const currIndex = groups.findIndex(({ alias }) => currentStep === alias);

  if (!hasNextStep(currentStep, groups)) return currentStep;

  return groups[currIndex + 1].alias;
};

const LAST_STEP = '___LAST_STEP___';

const SAVE_GROUP_ERROR_TEXT = 'Что-то пошло не так.<br /> Попробуйте отправить повторно.';
const OrderGroups = ({
  data,
  saveGroupFields,
  filledGroups,
  lastSendGroupAlias,
  isDraftOrderSended,
  isDraftOrderError,
  draftOrderState,
  isReviewSendError,
  isReviewSendPending,
  reviewId,
  sendToReview,
  dictionary,
  openInvoicePopup,
  canSendToAccept,
  setIsLoaderActive,
}) => {
  const [step, setStep] = useState('');
  useEffect(() => {
    if (data) {
      setStep(data.fieldsGroups[0].alias);
    }
  }, [data?.fieldsGroups[0].alias]);

  useEffect(() => {
    if (isDraftOrderSended) {
      const isLastStep = !hasNextStep(lastSendGroupAlias, data.fieldsGroups);

      setStep(isLastStep && !(isReviewSendPending || isReviewSendError) ?
        LAST_STEP :
        getNextStep(lastSendGroupAlias, data.fieldsGroups));
    }
  }, [isDraftOrderSended, lastSendGroupAlias, draftOrderState, isReviewSendPending, isReviewSendError]);

  useEffect(() => {
    const el = document.querySelector(`[data-id="${step}"]`);

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [step]);

  const onGroupSubmit = async ({ alias, values, isFinalSubmit }) => {
    if (alias === GROUPS_ALIASES.ipAddresses) {
      await ipListValidationRequest(values.IncomingIPAddresses);
    }

    /**
     * временно убрать фционал запроса на '/check-auth' задача MOBID-410
    */
    // if (alias === GROUPS_ALIASES.authOnly) {
    //   await appUrlsValidationRequest(values).catch((err) => {
    //     if (isAuthOnly && err.HttpsJWKSUrl) {
    //       return;
    //     }

    //     throw new Error();
    //   });
    // }

    if (isFinalSubmit) {
      if (setIsLoaderActive) {
        setIsLoaderActive(true);
      }

      openInvoicePopup();

      await sendToReview({
        id: data.id,
        lastGroup: {
          alias,
          values,
          fields: data.fieldsGroups.find(group => group.alias === alias).fields,
        },
      });

      if (setIsLoaderActive) {
        setIsLoaderActive(false);
      }

      return;
    }

    saveGroupFields({
      id: data.id,
      alias,
      // TODO: Костыль из-за MOBID-1377 и MOBID-1235 - всегда схему DI
      values: { ...values, ...(alias === 'WorkScheme' ? { WorkScheme: 'DI' } : {}) },
      fields: data.fieldsGroups.find(group => group.alias === alias).fields,
    });
  };

  const onEditClick = (alias) => {
    setStep(alias);
  };

  if (!data) return null;

  return (
    data?.fieldsGroups.map(({ alias, fields, stepNumber, stepsTotal }) => {
      const filled = filledGroups?.find(filledGroup => filledGroup.alias === alias);
      const newFields = filled ?
        fields.map(field => {
          const filledField = filled.fields.find(f => f.alias === field.alias);
          if (filledField) return { ...field, ...filledField };
          return field;
        }) :
        fields;

      // MOBID-2548
      const filteredFields = newFields.reduce((acc, field) => {
        if (field.alias === 'ClientDataAccessLevel') {
          acc.push({
            ...field,
            enumItems: field.enumItems.filter((item) => item !== 'KYC'),
          });
        } else {
          acc.push(field);
        }

        return acc;
      }, []);
      const isActiveStep = alias === step;
      const isFinalStep = !hasNextStep(step, data.fieldsGroups);

      const isReviewError = isReviewSendError && data.id === reviewId;
      const isSubmitError = isReviewError || (isDraftOrderError && lastSendGroupAlias === alias);
      return (
        <div key={alias} className={cx('formGroupWrap')}>
          <div className={cx('groupTitle')}>
            <GroupTitle
              title={getGroupName({ alias })}
              tooltip={getGroupTooltip(alias)}
            />
            <div className={cx('step')}>
              <span>{stepNumber}</span>
              <span>/</span>
              <span>{stepsTotal}</span>
            </div>
          </div>
          {isActiveStep && alias !== 'WorkScheme' && getGroupDescription({ alias }) && (
            <Text isSmall className={cx('groupDescription')}>
              {dictionary[getGroupDescription({ alias })]}
            </Text>
          )}
          {isActiveStep && (
            <div data-id={alias}>
              <OrderFormGroup
                alias={alias}
                fields={filteredFields}
                onSubmit={onGroupSubmit}
                submitError={isSubmitError ? SAVE_GROUP_ERROR_TEXT : null}
                hiddenFields={getHiddenFieldsAliases(filledGroups, step)}
                isFinalStep={isFinalStep}
              />
            </div>
          )}
          {(!isActiveStep && filled) && (
            <OrderGroupInfo
              alias={alias}
              fields={filteredFields}
              onEditClick={onEditClick}
              canShowEditButton={canSendToAccept}
            />
          )}
        </div>
      );
    })
  );
};

OrderGroups.propTypes = {
  data: PropTypes.object,
  saveGroupFields: PropTypes.func,
  filledGroups: PropTypes.array,
  draftOrderState: PropTypes.string,
  isDraftOrderSended: PropTypes.bool,
  isDraftOrderError: PropTypes.bool,
  lastSendGroupAlias: PropTypes.string,
  sendToReview: PropTypes.func,
  isReviewSendPending: PropTypes.bool,
  isReviewSendError: PropTypes.bool,
  isAuthOnly: PropTypes.bool,
  reviewId: PropTypes.string,
  dictionary: PropTypes.shape({}),
  openInvoicePopup: PropTypes.func,
  setIsLoaderActive: PropTypes.func,
};


const mapStateToProps = (state) => ({
  filledGroups: getFilledGroups(state),
  lastSendGroupAlias: getLastSendGroupAlias(state),
  isDraftOrderSended: isDraftOrderSendedSelector(state),
  isDraftOrderError: isDraftOrderErrorSelector(state),
  draftOrderState: getDraftOrderState(state),
  isReviewSendError: isReviewSendErrorSelector(state),
  isReviewSendPending: isReviewSendPendingSelector(state),
  reviewId: getReviewId(state),
  dictionary: getAllTexts(state),
  canSendToAccept: getSendToAcceptStatus(state),
  isAuthOnly: getIsAuthOnly(state),
});

const mapDispatchToProps = {
  saveGroupFields: saveGroupFieldsDraftAction,
  sendToReview: sendToReviewAction,
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderGroups);
