import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { BasePopupStateless, Button, Heading, Text } from 'components';

import { getInvalidPassportModal } from 'store/features/companyForm/selectors';
import { closeInvalidPassportModal } from 'store/features/companyForm/actions';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ModalComponent = ({ isOpen, closeModal }) => (
  <BasePopupStateless
    isWide={false}
    isOpen={isOpen}
    onClose={closeModal}
    overlayClassName={cx('overlay')}
  >
    <section className={cx('container')}>
      <Heading size={3}>
        Внимание
      </Heading>
      <Text className="mart-s marb-se">
        Указанные вами паспортные данные некорректны.
        Дальнейшее оформление невозможно. Пожалуйста, проверьте корректность введенных паспортных данных
      </Text>
      <Button type="button" onClick={closeModal} className="marb-l">
        Продолжить
      </Button>
    </section>
  </BasePopupStateless>
);

ModalComponent.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isOpen: getInvalidPassportModal(state),
});

const mapDispatchToProps = {
  closeModal: closeInvalidPassportModal,
};

export const InvalidPassportPopup = connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
