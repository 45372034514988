import { isEmptyObject, shallowEqual } from 'utils/fn';
import { CREATE_PAYMENT } from './messageTypes';

export const isInitialState = (initialState, state) => shallowEqual(initialState, state);

export const isPaymentSuccessful = ({ resultCode, items = [] }) => (
  (resultCode === 0) && (items[0]?.errorCode === 0)
);

export const is3DSecure = (message) => (
  message.type?.toLowerCase() === CREATE_PAYMENT
  && !isEmptyObject(message?.threeDSec)
);

export const getValidationMsg = (obj) => obj?.errors?.[0];

export const getTransactionId = (obj) => obj.items[0].id;
