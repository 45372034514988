import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import withIsMobile from 'enhancers/withIsMobile';
import { BaseButton } from 'components/index';
import {
  TOTAL_NUMBERS_3,
  TOTAL_NUMBERS_4,
  TOTAL_BUTTONS_5,
  TOTAL_BUTTONS_6,
  ACTIVE_PAGE_2,
  WITHOUT_PAGE_NEIGHBOURS,
  PAGE_NEIGHBOURS_1,
  START_PAGE_1,
  START_PAGE_2,
  INCREMENT_NUMBER,
  DECREMENT_NUMBER,
} from './constants';

import Icon from './assets/Arrow.svg';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const rangeArr = [];

  while (i <= to) {
    rangeArr.push(i);
    i += step;
  }

  return rangeArr;
};

const Pagination = ({
  totalPages,
  activePage,
  pageQuality,
  changePage,
  loadMore,
  isMobile,
  isLoadMoreVisible,
}) => {
  const TOTAL_NUMBERS = isMobile ? TOTAL_NUMBERS_3 : TOTAL_NUMBERS_4;
  const TOTAL_BUTTONS = isMobile ? TOTAL_BUTTONS_5 : TOTAL_BUTTONS_6;
  const PAGE_NEIGHBOURS =
    isMobile && (activePage > ACTIVE_PAGE_2 && activePage < totalPages - DECREMENT_NUMBER)
      ? WITHOUT_PAGE_NEIGHBOURS
      : PAGE_NEIGHBOURS_1;

  const calculatePageNumbers = useCallback(() => {
    if (totalPages > TOTAL_BUTTONS) {
      const startPage = Math.max(START_PAGE_2, activePage - PAGE_NEIGHBOURS);
      const endPage = Math.min(totalPages - DECREMENT_NUMBER, activePage + PAGE_NEIGHBOURS);
      let pages = range(startPage, endPage);

      const hasLeftSpill = startPage > START_PAGE_2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = TOTAL_NUMBERS - (pages.length + INCREMENT_NUMBER);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - START_PAGE_1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + INCREMENT_NUMBER, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  }, [activePage, totalPages, isMobile, TOTAL_NUMBERS, TOTAL_BUTTONS, PAGE_NEIGHBOURS]);

  const pages = calculatePageNumbers();

  return (
    <div className={cx('container')}>
      {
        isLoadMoreVisible &&
        <BaseButton
          className={cx('loadMoreBtn')}
          onClick={loadMore}
          disabled={activePage === totalPages}
        >
          {`Показать еще ${pageQuality}`}
        </BaseButton>
      }
      <div className={cx('controls')}>
        <BaseButton
          className={cx('side_button', 'reverse')}
          onClick={() => changePage(activePage - 1)}
          disabled={activePage === 1}
        >
          <Icon className={cx('icon')} />
        </BaseButton>
        <div className={cx('pages')}>
          {
            pages.map((el, idx) => {
              if (el === LEFT_PAGE) {
                return (
                  <button
                    key={el + idx}
                    className={cx('page_button', { isMobile })}
                    onClick={() => changePage(activePage - 2)}
                  >
                    &hellip;
                  </button>
                );
              }

              if (el === RIGHT_PAGE) {
                return (
                  <button
                    key={el + idx}
                    className={cx('page_button', { isMobile })}
                    onClick={() => changePage(activePage + 2)}
                  >
                    &hellip;
                  </button>
                );
              }

              return (
                <button
                  key={el}
                  className={cx('page_button', { active: el === activePage }, { isMobile })}
                  onClick={() => changePage(el)}
                >
                  {el}
                </button>
              );
            })
          }
        </div>
        <BaseButton
          className={cx('side_button')}
          onClick={() => changePage(activePage + 1)}
          disabled={activePage === totalPages}
        >
          <Icon className={cx('icon')} />
        </BaseButton>
      </div>
    </div>

  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  activePage: PropTypes.number,
  pageQuality: PropTypes.number,
  isMobile: PropTypes.bool,
  changePage: PropTypes.func,
  loadMore: PropTypes.func,
  isLoadMoreVisible: PropTypes.bool,
};

export default withIsMobile(Pagination);
