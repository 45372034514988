import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Icon } from './../../Icon';

import styles from './../styles.pcss';
const cx = classNames.bind(styles);

const isValueEmpty = value => value === undefined || value === null;

export default class DropdownCustom extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })),
    value: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    wide: PropTypes.bool,
    collapsed: PropTypes.bool,
    size: PropTypes.oneOf(['default', 'big']),
    demoState: PropTypes.oneOf(['opened']),
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
    minWidth: PropTypes.string,
  }

  static defaultProps = {
    placeholder: 'Выберите значение',
    size: 'default',
    name: null,
    wide: false,
    collapsed: false,
    className: null,
    demoState: null,
    minWidth: '100%',
    options: [
      { value: '12', label: '12' },
      { value: '24', label: '24' },
      { value: '36', label: '36' },
    ],
  }

  constructor(props) {
    super(props);

    this.state = {
      opened: this.props.demoState === 'opened',
    };
  }

  componentWillUnmount = () => this.removeEventListeners();

  getSelectedLabel = () => this.props.options.reduce(
    (acc, next) => (next.value === this.props.value ? next.label : acc),
    this.props.placeholder,
  );

  getLongestLabel = () => this.props.options
    .reduce((acc, next) => (acc?.length >= next?.label?.length ? acc : next?.label), '');

  addEventListeners = () => {
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('touchstart', this.handleOutsideClick, false);
  }

  removeEventListeners = () => {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('touchstart', this.handleOutsideClick, false);
  }

  handleOpen = () => this.setState({ opened: true }, this.addEventListeners);
  handleClose = () => this.setState({ opened: false }, this.removeEventListeners);
  handleSelect = (value) => {
    if (value !== this.props.value) {
      this.props.onChange(value);
    }
    this.handleClose();
  }

  handleOutsideClick = (event) => {
    if (!this.node.contains(event.target)) {
      this.handleClose();
    }
  }

  focus = () => !this.state.opened && this.handleOpen();

  renderOption = ({ value, label }) => (
    <div
      className={cx({ selected: this.props.value === value }, 'option')}
      onClick={() => this.handleSelect(value)}
      key={`dropdown-option-${label}-${value}`}
      role="button"
      tabIndex={0}
    >
      {label}
    </div>
  );

  renderList = () => {
    if (!this.state.opened) {
      return null;
    }
    return <div className={cx('list')}>{this.props.options.map(this.renderOption)}</div>;
  }

  render = () => {
    const { disabled, wide, value, collapsed, status, minWidth } = this.props;
    const { opened } = this.state;
    const classes = cx(
      { opened, disabled, wide, empty: isValueEmpty(value) },
      this.props.className, 'dropdown', `${this.props.size}Size`, status,
    );
    const scaleRatio = this.props.size === 'big' ? 1 : 0.75;
    const handleClick = opened ? this.handleClose : this.handleOpen;

    return (
      <div
        className={cx({ wide, collapsed }, 'wrapper')}
        style={{ minWidth }}
        ref={(node) => { this.node = node; }}
      >
        <input type="hidden" name={this.props.name} value={value || ''} disabled={disabled} />
        <div className={classes} onClick={disabled ? null : handleClick}>
          <div className={cx('value')}>
            <div className={cx('valueText')}>
              {this.getSelectedLabel()}
            </div>
          </div>

          {this.renderList()}
          <Icon name="dropdownArrow" className={cx('arrow')} />
        </div>
      </div>
    );
  }
}
