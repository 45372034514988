// import { getStore } from 'store/core';
// import { browserHistory } from 'store/history';
// import { SET_AUTH_STATUS } from 'store/features/auth/constants';
// import { SET_USER_INFO } from 'store/features/user/constants';
// import { SET_CURRENT_COMPANY } from 'store/features/currentCompany/constants';

import { authMobileIdStatuses, AUTH_REDIRECT_URL } from 'constants';
import { axiosAuthorizedRequest, axiosBaseRequest, isAuthErrorStatusCode } from '../helpers';
import {
  getAdAvailableUrl,
  getAuthUrl,
  getAuthMobileIdUrl,
  getAuthMobileIdStatusUrl,
  getAuthAdUrl,
  getLogOutUrl,
  getIsEmployeeUrl,
  getRegistrationUrl,
} from './constants';

export const getRegistrationRequest = ({ login, password, phone }) => axiosBaseRequest({
  url: getRegistrationUrl(),
  method: 'POST',
  data: new URLSearchParams({ login, password, phone }),
});

export const getAuthStatusRequest = () => axiosBaseRequest({
  url: getAuthUrl(),
})
  .then(() => ({ isAuthed: true }))
  .catch((err) => {
    if (isAuthErrorStatusCode(err)) return { isAuthed: false };
    if (err.response.status === 400) return { isAuthed: true };

    throw err;
  });

export const getAdAvailableRequest = () => axiosBaseRequest({
  url: getAdAvailableUrl(),
});
export const authAdRequest = ({ login, password }) => axiosBaseRequest({
  url: getAuthAdUrl(),
  method: 'POST',
  data: new URLSearchParams({ userName: login, password }),
});

export const authMobileIdRequest = ({ phone, token }) => axiosBaseRequest({
  url: getAuthMobileIdUrl(),
  method: 'POST',
  headers: { Authorization: `Bearer ${token}` },
  data: new URLSearchParams({ phone, agreement: true }),
})
  .then(({ data }) => data);

export const getAuthMobileIdStatusRequest = ({ operationId }) => axiosBaseRequest({
  url: getAuthMobileIdStatusUrl(),
  params: { operationId },
})
  .then((res) => {
    if (res.status === 200) return authMobileIdStatuses.success;
    if (res.status === 204) return authMobileIdStatuses.retry;

    return authMobileIdStatuses.error;
  })
  .catch(() => (authMobileIdStatuses.error));

export const requestAuthMobileIdStatus = ({ operationId }) => axiosBaseRequest({
  url: getAuthMobileIdStatusUrl(),
  params: { operationId },
})
  .then((res) => {
    if (res.status === 200) return authMobileIdStatuses.success;
    if (res.status === 204) return authMobileIdStatuses.retry;

    return res;
  });

export const logOutRequest = () => axiosBaseRequest({
  url: getLogOutUrl(),
  method: 'POST',
})
  .then(() => {
    // TODO: Переделать на корректный ресет сторе без перезагрузки страницы
    /* const store = getStore();
    store.dispatch({ type: SET_AUTH_STATUS, isAuthed: false });
    store.dispatch({ type: SET_USER_INFO, data: {} });
    store.dispatch({ type: SET_CURRENT_COMPANY, data: null });
    browserHistory.push(AUTH_REDIRECT_URL); */
    window.location.href = AUTH_REDIRECT_URL;
  });

export const getIsEmployeeRequest = () => axiosAuthorizedRequest({
  url: getIsEmployeeUrl(),
})
  .then(() => ({ isEmployee: true }))
  .catch((err) => {
    const { status: statusCode } = err.response;

    if (statusCode === 400) return ({ isEmployee: false });

    throw err;
  });
