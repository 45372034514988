import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { getSendInvoicePopup } from 'store/common/content';
import { getCurrentCompany } from 'store/features/currentCompany';

import commonStyles from 'styles/common.pcss';
import { BasePopupStateless } from 'components/Popup';
import { Text, Preloader } from 'components';
import Error from 'shared/Error';

import classNames from 'classnames/bind';
import styles from './styles.pcss';
import { IMG_ALT } from './constants';
const cx = classNames.bind({ ...commonStyles, ...styles });

const steps = {
  initial: 'initial',
  loading: 'loading',
  success: 'success',
  error: 'error',
};

const SendInvoicePopup = ({ currentCompany, onPopupClose, content }) => {
  const [step] = useState(steps.initial);
  const companyBan = currentCompany?.ban;

  return (
    <BasePopupStateless className={cx('popup')} isOpen onClose={onPopupClose}>
      <div className={cx('popup-content', { 'success-step': step === steps.success })}>
        {step === steps.initial && (
          <>
            <Text isLarge isTitle className={cx('marb-xs')}>
              {content.initial?.title}
            </Text>
            <Text isSmall className={cx('marb-m')}>
              {content.initial?.description}
            </Text>
            {companyBan && (
              <Text isSmall className={cx('marb-m')}>
                  {`Номер договора: ${companyBan}.`}
              </Text>
            )}
            {content.initial?.imageSrc && (
              <img src={content.initial.imageSrc} alt={IMG_ALT} className={cx('image')} />
            )}
          </>
        )}
        {step === steps.success && (
          <>
            <Text isLarge isTitle className={cx('marb-xs')}>
              {content.success?.title}
            </Text>
            <Text isSmall className={cx('marb-m')}>
              {content.success?.description}
            </Text>
          </>
        )}
        {step === steps.loading && (
          <div className={cx('loder-wrap')}>
            <Preloader />
          </div>
        )}
        {step === steps.error && (
          <Error />
        )}
      </div>
    </BasePopupStateless>
  );
};

const mapStateToProps = (state) => ({
  content: getSendInvoicePopup(state),
  currentCompany: getCurrentCompany(state),
});

SendInvoicePopup.propTypes = {
  onPopupClose: PropTypes.func,
  content: PropTypes.object,
  currentCompany: PropTypes.object,
};

export default compose(
  connect(mapStateToProps),
  withRouter,
)(SendInvoicePopup);
