import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectIsError } from 'store/features/helpAndSupport/selectors';
import { getHelpAndSupport } from 'store/common/content';

import { Icon, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const MessageComponent = ({ error, content }) => (
  <div className={cx('sent-message')}>
    {error ? (
      <>
        <Icon name="attention" />

        <Text isLarge className={cx('error-sent-text')}>
          {content.message.error?.title || ''}
        </Text>

        <Text>
          {content.message.error?.description || ''}
        </Text>
      </>
    ) : (
      <>
        <Text isLarge className={cx('sent-text-head')}>
          {content.message.success?.title || ''}
        </Text>

        <Text>
          {content.message.success?.description || ''}
        </Text>
      </>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  error: selectIsError(state),
  content: getHelpAndSupport(state),
});

MessageComponent.propTypes = {
  error: PropTypes.bool,
  content: PropTypes.object,
};

export const Message = connect(mapStateToProps)(MessageComponent);
