import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Accordion, AccordionItem } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const AccordionContent = ({ content, render, className }) => (
  <Accordion className={cx('accordion', className)}>
    <ol className={cx('list')}>
      {content.map((item, index) => (
        <li key={index.toString()} className={cx('listItem')}>
          <AccordionItem title={item?.step} id={item?.key} className={cx('item')}>
            {render(item)}
          </AccordionItem>
        </li>
      ))}
    </ol>
  </Accordion>
);

AccordionContent.propTypes = {
  render: PropTypes.func,
  content: PropTypes.array,
  className: PropTypes.string,
};
