import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { getOrderDetails as getOrderDetailsAction } from 'store/features/details-order/action';
import {
  getOdrerDetailsData,
  getWorkScheme,
  isOrderDetailsLoading,
} from 'store/features/details-order/selectors';
import { compose } from 'utils/fn';
import { getDisplayValue, getValue, mapFieldInfo, sortGroupFieldsByOrder } from 'utils/orders/helpers';
import { Button } from 'components';
import PageLayout from 'shared/PageLayout';
import { EDIT_ORDER_ROUTE } from 'constants/routes';
import FieldSection from './components/FieldSection';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ViewOrderPage = ({
  getOrderDetails,
  isDataLoading,
  data,
  history,
  match,
  workScheme,
}) => {
  useEffect(() => {
    getOrderDetails({ id: match.params.id });
  }, [match.params.id]);

  const onEditClick = () => {
    history.push(`${EDIT_ORDER_ROUTE}${match.params.id}`);
  };

  const firstGroup = useMemo(() => {
    const appGroup = data?.fieldsGroups.find(el => el.alias === 'ApplicationName');
    const authCodeGroup = data?.fieldsGroups.find(el => el.alias === 'AuthorizationCode') ?? [];

    if (appGroup?.fields) {
      return [...appGroup.fields, ...authCodeGroup.fields];
    }

    return null;
  }, [data]);

  const renderFirstGroupField = field => {
    if (!field) return null;

    const fieldName = mapFieldInfo[field.alias]?.name;
    const fieldValue = getDisplayValue(field);

    if (!fieldName || !fieldValue) {
      return null;
    }

    return (
      <div key={field?.alias} className={cx('data-row')}>
        <div className={cx('field-name')}>
          <p>{fieldName}</p>
        </div>
        <div className={cx('field-value')}>
          <p>{fieldValue}</p>
        </div>
      </div>
    );
  };

  return (
    <PageLayout isLoading={isDataLoading}>
      <div className={cx('firstGroup')}>
        <h2 className={cx('headText')}>Приложение</h2>
        {!!firstGroup && firstGroup.map(el => {
          if (workScheme === 'SI' && el?.alias === 'ClientSecret') return null;
          return renderFirstGroupField(el);
        })}
      </div>
      <div className={cx('groups')}>
        {
          data?.fieldsGroups
            .filter(group =>
              group.stepNumber < 8 && group.stepNumber > 1 && group.fields.some(field => getValue(field)))
            .sort((a, b) => a.stepNumber - b.stepNumber)
            .map(group => (
              <FieldSection key={group.alias} group={sortGroupFieldsByOrder(group)} />
            ))
        }
      </div>
      <section className={cx('buttons')}>
        {
          data?.currentStatusAlias === 'Draft' ?
            <Button className={cx('edit-button')} onClick={onEditClick}>
              Редактировать
            </Button>
            : null
        }
        {/* <button className={cx('delete-button')}> */}
        {/*  <Icon className={cx('trash-icon')} /> */}
        {/*  Удалить */}
        {/* </button> */}
      </section>
    </PageLayout>
  );
};

ViewOrderPage.propTypes = {
  getOrderDetails: PropTypes.func,
  isDataLoading: PropTypes.bool,
  data: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.string,
    fieldsGroups: PropTypes.array,
    currentStatusAlias: PropTypes.string,
  }),
  history: PropTypes.object,
  match: PropTypes.object,
  workScheme: PropTypes.string,
};

const mapStateToProps = (state) => ({
  data: getOdrerDetailsData(state),
  isDataLoading: isOrderDetailsLoading(state),
  workScheme: getWorkScheme(state),
});

const mapDispatchToProps = {
  getOrderDetails: getOrderDetailsAction,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ViewOrderPage);

