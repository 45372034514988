import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Button } from '../Button';
import { AccordionItem } from './AccordionItem';

import DownSVG from './down.svg';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const Accordion = ({ initiallyShowItems, children, buttonType, moreText, hideText, buttonSize, className }) => {
  const [showAll, setShowAll] = useState(!initiallyShowItems);

  const onClick = () => setShowAll((prev) => !prev);

  return (
    <>
      <div className={cx('component', className)}>{showAll ? children : children.slice(0, initiallyShowItems)}</div>
      {!!initiallyShowItems && (
        <div className={cx('button-wrapper')}>
          <Button onClick={onClick} styleType={buttonType} styleSize={buttonSize}>
            {showAll ? hideText : moreText}
            <DownSVG className={cx('down-arrow', { flipped: showAll })} />
          </Button>
        </div>
      )}
    </>
  );
};

Accordion.defaultProps = {
  buttonSize: 'mini',
  buttonType: 'secondary',
  moreText: 'Показать ещё',
  hideText: 'Скрыть',
};

Accordion.propTypes = {
  initiallyShowItems: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([AccordionItem]),
  }))]),
  moreText: PropTypes.string,
  hideText: PropTypes.string,
  buttonSize: PropTypes.oneOf(['standard', 'mini']),
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'optional']),
};
