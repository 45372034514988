import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { isScreenBelow1024 } from 'store/common/breakpoint/selectors';

import { BaseButton, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ItemContentComponent = ({ onClick, activePluginKey, isMobile, content, className }) => (
  <ul className={cx('content', className)}>
    {content.map(({ key, system }) => (
      <li key={key} className={cx('item')}>
        <BaseButton onClick={() => onClick(key)}>
          <Text className={cx('text')} isLarge={isMobile} isSecondary={activePluginKey !== key}>
            {system}
          </Text>
        </BaseButton>
      </li>
    ))}
  </ul>
);

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow1024(state),
});

ItemContentComponent.propTypes = {
  onClick: PropTypes.func,
  activePluginKey: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.any,
  content: PropTypes.array,
};

export const ItemContent = connect(mapStateToProps)(ItemContentComponent);

