import { isEmptyObject } from 'utils/fn';

export const mapHealthCheckResults = (healthCheckResults) => {
  const preparedValidationResults = Object.keys(healthCheckResults).reduce((acc, url) => (
    healthCheckResults[url]
      ? acc : {
        ...acc,
        [url]: 'Введен несуществующий адрес. Пожалуйста, введите корректный',
      }
  ), {});

  if (isEmptyObject(preparedValidationResults)) {
    return undefined;
  }

  throw preparedValidationResults;
};
