import { isValid } from 'date-fns';

const URL_PATTERN = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([sum-zа-яё\\d]([sum-zа-яё\\d-]*[a-zа-яё\\d])*)\\.)+[a-zа-яё]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-zа-яё\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-zа-яё\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-zа-яё\\d_]*)?$', 'i'); // fragment locator
const URL_PATTERN_WITH_REQUIRED_PROTOCOL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;


/**
 * Function used to inject validators into form controlled by redux-forms
 * @param getValidators {function} - a function returning dict of field to validators
 * @returns {function(values)} - takes values and returns large object with errors for every separate validator
 *
 * Iterate payFormFields that have validators and each validator individually
 * gather field errors in a large object and return it
 */
export const composeFormValidator = getValidators =>
  (values) => {
    values = values || {};
    const validators = getValidators(values);

    return Object.keys(validators).reduce((errors, name) => {
      let fieldValidatorsList = validators[name] || [];
      if (typeof validators[name] === 'function') {
        fieldValidatorsList = [fieldValidatorsList];
      }
      fieldValidatorsList.some((fieldValidator) => {
        const fieldError = fieldValidator(values[name], values);
        if (fieldError) {
          errors[name] = fieldError;
          return true;
        }
        return false;
      });
      return errors;
    }, {});
  };

/**
 * Add extra check to pass validator if value is empty
 */
const allowEmpty = wrappedValidator => value => (!value || value.length === 0 ? undefined : wrappedValidator(value));


export const composeRequiredValidator = message => value => (
  value && ( // value should not be empty
    typeof value !== 'string' || // also it should either be not string
    value.replace(/^\s*(.*?)\s*$/, '$1').length // or not just spaces
  ) ? undefined : message
);

/**
 * 10 digits starting with 9
 */
export const composePhoneValidator = message => allowEmpty(value => (
  !/^(([0-9]){10})$/.test(value) ? message : undefined
));

export const composeEnglishLettersValidator = message => allowEmpty(value => (
  /^[A-Za-z ]+$/.test(value) ? undefined : message
));

export const composeEmailValidator = message => allowEmpty(value => (
  /^([A-Za-z0-9_-]+\.)*[A-Za-z0-9_-]+@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*\.[a-z]{2,6}$/
    .test(value) ? undefined : message || ''
));

export const composeConfirmPasswordValidator = message => values => (
  values.password === values.confirmPassword ? undefined : message
);

export const composeCheckedValidator = message => value => (!value ? message : undefined);

export const composeNameValidator = message => allowEmpty(value => (
  /^(?![- ])[A-Za-zёЁА-Яа-я -]{0,60}$/.test(value) ? undefined : message
));

export const composeSlugValidator = message => allowEmpty(value => (
  value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, '').length === 0 ? message : undefined
));

export const composeNumberValidator = (message, { min = null, max = null }) => (value) => {
  /** validator does not make field required */
  if (!value && typeof value !== 'number') return undefined;
  if (min !== null && value < min || max !== null && value > max) return message;
  return undefined;
};

export const composeDigitValidator = (message) => (value) => {
  /** validator does not make field required */
  if (!value) return undefined;
  if (/^[0-9]+$/.test(value)) return undefined;
  return message;
};
export const composeDecimalValidator = (message) => (value) => {
  /** validator does not make field required */
  if (!value) return undefined;
  if (/^\d+\.?\d{0,2}$/.test(value)) return undefined;
  return message;
};

export const composeLengthValidator = (message, { min = null, max = null }) => (value) => {
  /** validator does not make field required */
  if (!value && typeof value !== 'string') return undefined;
  if (min !== null && value.length < min || max !== null && value.length > max) return message;
  return undefined;
};

export const composeMinLengthValidator = (minLength = null, message) => (value) => {
  /** validator does not make field required */
  const validationMessage = message || `Напишите не менее ${minLength} символов.`;
  if (minLength !== null && value.length < minLength && typeof value === 'string') return validationMessage;
  return undefined;
};

export const composeMaxLengthValidator = (maxLength = null, message) => (value) => {
  /** validator does not make field required */
  const validationMessage = message || `Максимальная длина строки ${maxLength} символов.`;
  if (maxLength !== null && value.length > maxLength && typeof value === 'string') return validationMessage;
  return undefined;
};

export const composeUrlValidator = (message, { hasProtocol } = {}) => allowEmpty(value => {
  if (hasProtocol) return !URL_PATTERN_WITH_REQUIRED_PROTOCOL.test(value) ? message : undefined;

  return !URL_PATTERN.test(value) ? message : undefined;
});

export const composeDateValidator = (message, { min = null, max = null }) => allowEmpty((value) => (
  (!isValid(new Date(value)))
  || (value.length !== 10)
  || min !== null && new Date(value).getTime() < min.getTime()
  || max !== null && new Date(value).getTime() > max.getTime()
    ? message : undefined
));

/**
 * given key: value object with errors,
 * concatenates all the errors into single object, separated by dot and space
 */
export const mergeErrorObject = (errors, ignoreFields = []) =>
  Object.keys(errors)
    .filter(key => ignoreFields.indexOf(key) === -1)
    .map(key => errors[key].replace(/\.$/, ''))
    .join('. ');

export const composeXSSValidator = (message) => (value) => {
  if (!value) return undefined;
  if (/^<|>$/.test(value)) return message;
  return undefined;
};

export const composeHttpsValidator = (message) => (value) => {
  if (/^https:\/\/\S+/.test(value)) return undefined;
  return message;
};
