import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { composeRequiredValidator } from 'utils/field-validators';
import PropTypes from 'prop-types';
import commonStyles from 'styles/common.pcss';

import { FIELD_CANNOT_BE_EMPTY_TEXT } from 'constants/validators';

import {
  getClientId,
  getClientSecret,
  getOdrerDetailsId,
  getClientSecretGroup,
  getIsAggregator,
  getClientIdAggregator,
  getClientSecretAggregator,
} from 'store/features/details-order/selectors';
import {
  getClientData,
} from 'store/features/claim/selectors';
import { updateSecretAggregator } from 'store/features/employee-order/action';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { FinalForm } from 'components/FinalForm/FinalForm';
import { FFTextInput } from 'components/FinalForm';
import { TextInput } from 'components/TextInput';
import styles from './styles.pcss';

const cx = classNames.bind({ ...commonStyles, ...styles });

const AuthCodeSection = (props) => {
  const { orderId, isAggregator, clientIdAggregator, clientSecretAggregator, updateSecret, clientData } = props;

  const clientId = clientData?.clientId || props.clientId;
  const clientSecret = clientData?.clientSecret || props.clientSecret;

  const initialValues = {
    clientIdAggregator,
    clientSecretAggregator,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [isSaveError, setIsSaveError] = useState(false);
  const [readonlyFields, setReadonlyFields] = useState(false);


  const switchFormMode = useCallback(
    () => {
      setReadonlyFields(!readonlyFields);
    },
    [readonlyFields, setReadonlyFields],
  );

  const onChange = (values) => {
    setFormValues(values);
    setIsSaveError(false);
  };

  const handleSubmit = (values) => {
    updateSecret({ values, id: orderId })
      .then(() => {
        setReadonlyFields(true);
      });
  };

  return (
    <div>
      <Text isLarge isTitle className={cx('mart-m', 'marb-s')}>
        Креды авторизации
      </Text>
      <div>
        {clientSecret && (
          <div className={cx('row')}>
            <div className={cx('field-name')}>
              <p>Client Secret (билайн)</p>
            </div>
            <div className={cx('field-value')}>
              <p>{clientSecret}</p>
            </div>
          </div>)}
        <div className={cx('row')}>
          <div className={cx('field-name')}>
            <p>Client ID (билайн)</p>
          </div>
          <div className={cx('field-value')}>
            <p>{clientId}</p>
          </div>
        </div>
      </div>
      {isAggregator && (
      <div>
        {readonlyFields ? (
          <div>
            {clientSecret && (
            <div className={cx('row')}>
              <div className={cx('field-name')}>
                <p>Client Secret (агрегатор)</p>
              </div>
              <div className={cx('field-value')}>
                <p>{formValues.clientSecretAggregator}</p>
              </div>
            </div>)}
            <div className={cx('row')}>
              <div className={cx('field-name')}>
                <p>Client ID (агрегатор)</p>
              </div>
              <div className={cx('field-value')}>
                <p>{formValues.clientIdAggregator}</p>
              </div>
            </div>
            <Button className={cx('mart-s')} onClick={switchFormMode}>
              Редактировать
            </Button>
          </div>) :
          <FinalForm
            isValidateOnSubmit
            values={formValues}
            onChange={onChange}
            onSubmit={async (values) => {
            handleSubmit(values);
          }}
            getValidators={() => {
            const isNotEmptyFn = composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT);

            return {
              clientIdAggregator: isNotEmptyFn,
              clientSecretAggregator: isNotEmptyFn,
            };
          }}
          >
            <Text isTitle className={cx('mart-m', 'marb-s')}>
              Client Secret (агрегатор)
            </Text>
            <FFTextInput className={cx('mart-s')} name="clientSecretAggregator" />
            <Text isTitle className={cx('mart-m', 'marb-s')}>
              Client ID (агрегатор)
            </Text>
            <FFTextInput className={cx('mart-s')} name="clientIdAggregator" />
            <Button isSubmit className={cx('mart-s')}>
              Сохранить
            </Button>

          </FinalForm>}
        {isSaveError && (
        <TextInput
          hidden
          error="Что-то пошло не так. Попробуйте отправить повторно."
        />
          )}
      </div>
      )}
    </div>
  );
};

AuthCodeSection.propTypes = {
  orderId: PropTypes.string,
  clientId: PropTypes.string,
  clientSecret: PropTypes.string,
  isAggregator: PropTypes.bool,
  updateSecret: PropTypes.func,
  clientIdAggregator: PropTypes.string,
  clientSecretAggregator: PropTypes.string,
  clientData: PropTypes.object,
};

const mapStateToProps = (state) => ({
  orderId: getOdrerDetailsId(state),
  clientId: getClientId(state),
  clientSecret: getClientSecret(state),
  clientSecretGroup: getClientSecretGroup(state),
  isAggregator: getIsAggregator(state),
  clientIdAggregator: getClientIdAggregator(state),
  clientSecretAggregator: getClientSecretAggregator(state),
  clientData: getClientData(state),
});

const mapDispatchToProps = {
  updateSecret: updateSecretAggregator,
};


export default connect(mapStateToProps, mapDispatchToProps)(AuthCodeSection);
