import axios, { CancelToken } from 'axios';
import Cookies from 'js-cookie';
import { getStore } from 'store/core';
import { SET_AUTH_STATUS } from 'store/features/auth/constants';
import { AUTH_REDIRECT_URL, ACCESS_DENIED_URL } from 'constants';
import { browserHistory } from 'store/history';


/**
 * A base request with common params predefined
 * fully customizable, baseRequest is used to prevent boilerplate code generation
 * @returns {AxiosPromise}
 */
export const axiosBaseRequest = ({
  method = 'GET', url, responseType = 'json', withCredentials = true, timeout = 60000, ...options
}) => {
  const source = CancelToken.source();
  if (timeout) {
    setTimeout(() => {
      source.cancel();
    }, timeout);
  }

  return axios({
    method,
    url,
    responseType,
    withCredentials,
    ...options,
    cancelToken: source.token,
  });
};

export const isAuthErrorStatusCode = (error) => {
  const { status: statusCode } = error.response;
  return (statusCode === 401 || statusCode === 403);
};

export const getErrorStatusCode = (error) => error.response.status;

/**
 * @returns {AxiosPromise | Promise<Error>}
 */
export const axiosAuthorizedRequest = (data) => {
  const authToken = process.env.NODE_ENV === 'development' ? 'token' : Cookies.get('token');

  if (!authToken) {
    browserHistory.push(AUTH_REDIRECT_URL, {
      from: browserHistory.location.pathname,
    });
    const store = getStore();
    store.dispatch({ type: SET_AUTH_STATUS, isAuthed: false });
  }

  return axiosBaseRequest(data).catch((err) => {
    const store = getStore();
    if (isAuthErrorStatusCode(err)) {
      if (getErrorStatusCode(err) === 401) {
        store.dispatch({ type: SET_AUTH_STATUS, isAuthed: false });
        browserHistory.push(AUTH_REDIRECT_URL, {
          from: browserHistory.location.pathname,
        });
      }
      if (getErrorStatusCode(err) === 403) {
        browserHistory.push(ACCESS_DENIED_URL);
      }
    }
    throw err;
  });
};

export const getResponseError = ({ response }) => {
  if (response?.data?.errors) {
    const { errors } = response.data;
    if (!Array.isArray(errors) && typeof errors === 'object') {
      const errorKeys = Object.keys(errors);
      if (errorKeys.length) return errors[errorKeys[0]];
    }
  }
  return undefined;
};
