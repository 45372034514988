import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';

import { getInstructionPage, getInstructionPageHeading, getInstructionPageContent } from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { REACT_MODELS, INSTRUCTION_BLOCKS } from 'constants';

import { withRenderFlag } from 'enhancers/withRenderFlag';
import { withReactModel } from 'enhancers/withReactModel';

import { Suspense, PageLayout, AccordionContent } from 'shared';
import { Heading } from 'components';
import { Accept, Basics, FirstCall, Login, Registration } from './components';
import { ApiInstruction } from './components/ApiInstruction';

import styles from './styles.pcss';
import { MARGIN_BOTTOM, MARGIN_TOP } from './constants';

const cx = classNames.bind(({ ...commonStyles, ...styles }));

const InstructionPageComponent = ({ heading, content, isMobile }) => (
  <PageLayout isWide>
    <div className={cx('component', isMobile ? MARGIN_TOP.x : MARGIN_TOP.xx)}>
      <Heading className={cx('heading', isMobile ? MARGIN_BOTTOM.se : MARGIN_BOTTOM.xxl)} size={1}>
        {heading}
      </Heading>

      <AccordionContent
        className={cx('accordion')}
        content={content}
        render={({ key }) => {
          switch (key) {
            case INSTRUCTION_BLOCKS.LOGIN: {
              return <Login className={cx('content')} />;
            }
            case INSTRUCTION_BLOCKS.ACCEPT: {
              return <Accept className={cx('content')} />;
            }
            case INSTRUCTION_BLOCKS.REGISTRATION: {
              return <Registration className={cx('content')} />;
            }
            case INSTRUCTION_BLOCKS.BASICS: {
              return <Basics className={cx('content')} />;
            }
            case INSTRUCTION_BLOCKS.FIRST_CALL: {
              return <FirstCall className={cx('content')} />;
            }
            case INSTRUCTION_BLOCKS.API: {
              return <ApiInstruction className={cx('content')} />;
            }
            default: return null;
          }
        }}
      />
    </div>
  </PageLayout>
);

InstructionPageComponent.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  heading: getInstructionPageHeading(state),
  content: getInstructionPageContent(state),
  isMobile: isScreenBelow800(state),
  shouldRender: !!getInstructionPage(state),
});

export const InstructionPage = compose(
  connect(mapStateToProps),
  withReactModel(REACT_MODELS.B2B_FULL_INSTRUCTION),
  withRenderFlag,
)(InstructionPageComponent, <Suspense />);
