import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'utils/fn';

import { FN_COMPANYGROUPS } from 'constants/fieldNames';

import { getCompanyDetails as getCompanyDetailsAction } from 'store/features/company-details/action';
import { getCompanyDetailsData } from 'store/features/company-details/selectors';
import {
  getFieldInfo,
  getTabName,
  COMPANY_CONTACT_FIELDS,
  COMPANY_BANK_FIELDS,
} from 'utils/companies/helpers';
import {
  setCompanyFormTemplate,
  resetCompanyForm,
} from 'store/features/companyForm/actions';
import { getIsIndividualEntrepreneur } from 'store/features/companyForm/selectors';
import { fetchCompanyOrders } from 'store/features/orders/action';

import PageLayout from 'shared/PageLayout';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import CompanyAccess from './components/CompanyAccess';
import { EditCompanyForm } from './components/EditCompanyForm';
import { PersonalDataTransfer } from './components/PersonalDataTransfer';
import { getFormInitialValues } from './components/EditCompanyForm/utils';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ViewCompanyPage = ({
  match,
  fetchCompanyDetails,
  loadCompanyOrdersData,
  details,
  setFormTemplate,
  isIndividual,
  resetFormData,
}) => {
  const [editMode, setEditMode] = useState(false);

  useEffect(() => resetFormData, []);

  useEffect(() => {
    fetchCompanyDetails(match.params.id).then(({ data }) => {
      setFormTemplate({
        template: getFormInitialValues(data),
        companyTypes: data.allCompanyTypes,
        isIndividualEntrepreneur: data.companyType === 'ИП',
      });
    });
  }, [match.params.id]);

  useEffect(() => {
    loadCompanyOrdersData(match.params.id);
  }, [match.params.id]);

  const onEditClick = () => {
    setEditMode(true);
  };

  const onSaveClick = () => {
    setEditMode(false);
  };

  const renderWholeName = (field) => {
    const { name, surname, middlename } = details;
    return (
      <div key={field} className={cx('fieldContainer')}>
        <Text className={cx('fieldName')}>{getFieldInfo(field).name}</Text>
        <Text className={cx('fieldValue')}>
          {`${name} ${surname}${middlename ? ` ${middlename}` : ''}`}
        </Text>
      </div>
    );
  };

  const renderAddress = (field) => {
    const { postIndex, city, street, building, apartment } = details;
    const getAddressStr = () =>
      [postIndex, city, street, building, apartment]
        .filter((i) => i)
        .join(', ');
    return (
      <div key={field} className={cx('fieldContainer')}>
        <Text className={cx('fieldName')}>{getFieldInfo(field).name}</Text>
        <Text className={cx('fieldValue')}>{getAddressStr()}</Text>
      </div>
    );
  };

  const renderGroup = (arr) => (
    <>
      <Text className={cx('groupName')}>
        {getFieldInfo(arr[0]).companyGroup === FN_COMPANYGROUPS.contactData
          ? 'Контактные данные'
          : 'Банковские реквизиты'}
      </Text>
      <div className={cx('groupContainer')}>
        {arr.map((field) => {
          const fieldInfo = getFieldInfo(field);
          if (
            fieldInfo.isIndividual !== undefined &&
            fieldInfo.isIndividual !== isIndividual
          ) {
            return null;
          }
          if (field === 'address') return renderAddress(field);
          if (field === 'wholeName') return renderWholeName(field);
          return (
            <div key={field} className={cx('fieldContainer')}>
              <Text className={cx('fieldName')}>{fieldInfo.name}</Text>
              <Text className={cx('fieldValue')}>
                {fieldInfo.type === 'tab'
                  ? getTabName(field, details[field])
                  : details[field]}
              </Text>
            </div>
          );
        })}
      </div>
    </>
  );

  const renderHead = () => (
    <h1 className={cx('clientName')}>
      {isIndividual && (details.name || details.surname)
        ? `${details?.name} ${details?.surname}`
        : details?.companyName}
    </h1>
  );

  return (
    <PageLayout>
      {details && renderHead()}
      {details && !editMode && renderGroup(COMPANY_CONTACT_FIELDS)}
      {details && !editMode && renderGroup(COMPANY_BANK_FIELDS)}

      {details && editMode && (
        <EditCompanyForm
          data={details}
          onSaveCallback={onSaveClick}
          renderAddress={renderAddress}
          renderWholeName={renderWholeName}
        />
      )}

      {details?.accessInfo.canEditCompanyData && !editMode && (
        <Button onClick={onEditClick}>Редактировать</Button>
      )}

      {details?.accessInfo.canViewCompanyMembership && (
        <CompanyAccess
          id={details?.id}
          members={details?.members}
          isEditable={details?.accessInfo.canEditCompanyMembership}
        />
      )}

      <PersonalDataTransfer id={details?.id} />
    </PageLayout>
  );
};

ViewCompanyPage.propTypes = {
  match: PropTypes.object,
  fetchCompanyDetails: PropTypes.func,
  details: PropTypes.object,
  setFormTemplate: PropTypes.func,
  resetFormData: PropTypes.func,
  isIndividual: PropTypes.bool,
  loadCompanyOrdersData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  details: getCompanyDetailsData(state),
  isIndividual: getIsIndividualEntrepreneur(state),
});

const mapDispatchToProps = {
  resetFormData: resetCompanyForm,
  setFormTemplate: setCompanyFormTemplate,
  fetchCompanyDetails: getCompanyDetailsAction,
  loadCompanyOrdersData: fetchCompanyOrders,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ViewCompanyPage);
