import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer, stateValues } from 'utils/redux-stateful';
import {
  SAVE_GROUP_FIELDS_DRAFT, SET_GROUP_FIELDS, SET_LAST_SEND_GROUP_ALIAS, CLEAR_DRAFT_AFTER_SUCCESS
} from './constants';

const stateReducer = createStatefulReducer(SAVE_GROUP_FIELDS_DRAFT);
const actions = createStatefulActionTypes(SAVE_GROUP_FIELDS_DRAFT);
const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ ...state, state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ ...state, state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ ...state, state: stateReducer(state, action) }),

  [SET_LAST_SEND_GROUP_ALIAS]: (state, { data }) => ({ ...state, lastSendGroupAlias: data.alias }),
  [SET_GROUP_FIELDS]: (state, { data }) => ({
    ...state,
    state: stateValues.Success,
    filledGroups: [
      ...state.filledGroups?.filter(({ alias }) => alias !== data.alias) ?? [],
      data,
    ],
  }),
  [CLEAR_DRAFT_AFTER_SUCCESS]: () => ({ state: stateValues.Initial }),
};
export const draftOrderReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
