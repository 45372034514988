import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Link, Text } from 'components';

import commonStyles from 'styles/common.pcss';

import styles from './styles.pcss';

const cx = classNames.bind(({ ...commonStyles, ...styles }));

export const Example = ({ heading, list, isMobile, className }) => (
  <div className={className}>
    <Text className={cx('heading')} isInterphases isLarge>
      {heading}
    </Text>

    {list?.length && (
      <ul className={cx('list')}>
        {list.map((item, index) => (
          <li key={index.toString()} className={cx('list-item')}>
            {item?.title && (
              <Text isInterphases className={cx('title')}>
                {item.title}
              </Text>
            )}
            {item?.text && (
              <Text
                isInterphases
                isMedium
                className={cx('text')}
              >
                {item.text}
              </Text>
            )}
            {item?.link && (
              <Text isInterphases isMedium>
                <Link href={item.link}>
                  {item.link}
                </Link>
              </Text>
            )}
            {(item?.imageSrc || item?.mobileImageSrc) && (
              <img alt={item?.alt || ''} src={isMobile && item.mobileImageSrc ? item.mobileImageSrc : item.imageSrc} />
            )}
          </li>
        ))}
      </ul>
    )}
  </div>
);

Example.propTypes = {
  heading: PropTypes.string,
  list: PropTypes.array,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};
