import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import AccessCompanyPopup from 'components/Popup/components/AccessCompanyPopup';
import AccessCard from '../AccessCard';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const copyToClipboard = (id) => {
  const textField = document.createElement('textarea');
  textField.innerText = id;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

const CompanyAccess = ({ id, members, isEditable }) => {
  const [showAccessInfo, setShowAccessInfo] = useState(true);
  const [createPopup, setCreatePopup] = useState(false);

  return (
    <div className={cx('container')}>
      <div className={cx('fieldContainer')}>
        <Text className={cx('fieldName')}>ID компании</Text>
        <>
          <Text className={cx('fieldValue')}>{id}</Text>
          <button className={cx('copyButton')} onClick={() => copyToClipboard(id)}>
            <Icon className={cx('copyIcon')} name="copy" />
          </button>
        </>
      </div>
      <div className={cx('textWrapper')}>
        <Text className={cx('headText')}>Администратор</Text>
        <button className={cx('arrow-button')} onClick={() => setShowAccessInfo(!showAccessInfo)}>
          <Icon className={cx('icon', { showAccessInfo })} name="arrow" />
        </button>
      </div>
      {
        showAccessInfo ?
          <>
            <AccessCard
              data={members.find(el => el.isOwner)}
              canEdit={isEditable}
            />
            {
              members.some(el => !el.isOwner) ?
                <>
                  <div className={cx('accessText')}>
                    Редакторы
                  </div>
                  <div className={cx('membersWrapper')}>
                    {
                      members.map(el => {
                        if (el.isOwner === true) return null;
                        return <AccessCard key={el.phone} data={el} canEdit={isEditable} />;
                      })
                    }
                  </div>
                </>
                : null
            }
          </>
           : null
      }
      {
        isEditable && (
          <Button
            className={cx('accessBtn')}
            isSecondary
            onClick={() => setCreatePopup(true)}
          >
            Пригласить редактора
          </Button>
        )
      }
      <AccessCompanyPopup id={id} isOpen={createPopup} onCloseCallback={() => setCreatePopup(false)} />
    </div>
  );
};

CompanyAccess.propTypes = {
  id: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.shape({})),
  isEditable: PropTypes.bool,
};

export default CompanyAccess;
