import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'types';

import { isScreenBelow1024 } from 'store/common/breakpoint/selectors';

import { BaseButton, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const ListItemComponent = ({ title, isActive, isMobile, children }) => {
  const [open, setOpen] = useState(isActive);

  const toggle = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <li className={cx('component')}>
      <BaseButton onClick={toggle}>
        <Text
          className={cx('text')}
          isMedium={!isMobile}
          isLarge={isMobile}
          isSecondary={!isActive && !open}
        >
          {title}
        </Text>
      </BaseButton>

      {open ? children : null}
    </li>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isScreenBelow1024(state),
});

ListItemComponent.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  isMobile: PropTypes.bool,
  children: childrenPropTypes,
};

export const ListItem = connect(mapStateToProps)(ListItemComponent);
