import { axiosAuthorizedRequest } from '../helpers';
import { getOrderDetailsUrl, getEmployeeOrderDetailsUrl } from './constants';

export const getOrderDetailsRequest = ({
  id,
}) => axiosAuthorizedRequest({
  url: getOrderDetailsUrl({ id }),
})
  .then(({ data }) => data);

export const getEmployeeOrderDetailsRequest = ({
  id,
}) => axiosAuthorizedRequest({
  url: getEmployeeOrderDetailsUrl({ id }),
})
  .then(({ data }) => data);
