import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';

import { fetchAuthStatus, fetchIsEmployee } from 'store/features/auth/action';

import { authMobileIdStatuses, EMPLOYEE_MAIN_PAGE_URL, MAIN_PAGE_URL } from 'constants';

import {
  authAdRequest,
  authMobileIdRequest,
  getAdAvailableRequest,
  requestAuthMobileIdStatus,
} from 'requests/api/auth';

import { ERRORS } from '../constants';

let start;
let maxWaitingTime;

export const useLoginRequests = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const previousLocation = location.state?.from;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [from, setFrom] = useState(null);
  const [isAdAuthAvailable, setIsAdAuthAvailable] = useState(false);

  useEffect(() => {
    if (!from && previousLocation) {
      setFrom(previousLocation);
    }
  }, []);

  const onSuccess = () => {
    setLoading(false);

    dispatch(fetchAuthStatus);

    if (from) {
      history.replace(from);
    }
  };

  const handleError = useCallback((err) => {
    if (loading) {
      setLoading(false);
    }

    if (err === ERRORS.TIMEOUT) {
      setError(err);
      return;
    }

    if (err.response.status === 403) {
      setError(ERRORS.FORBIDDEN);
      return;
    }

    setError(ERRORS.DEFAULT);
  }, [loading]);

  /* Default Login */
  const { run: onGetStatus, cancel } = useRequest(requestAuthMobileIdStatus, {
    manual: true,
    pollingInterval: 1000,
    onSuccess: (status) => {
      const timeout = Date.now() - start > maxWaitingTime;

      if (status === authMobileIdStatuses.success) {
        cancel();
        onSuccess();

        history.replace(MAIN_PAGE_URL);
      }

      if (status === authMobileIdStatuses.retry && timeout) {
        handleError(ERRORS.TIMEOUT);
        cancel();
      }
    },
    onError: (err) => {
      cancel();
      handleError(err);
    },
  });

  const { run: onDefaultLogin } = useRequest(authMobileIdRequest, {
    manual: true,
    onBefore: () => {
      setLoading(true);
    },
    onSuccess: (data) => {
      start = Date.now();
      maxWaitingTime = data.timeSeconds * 1000;

      setTimeout(() => onGetStatus(data), 2500);
    },
    onError: handleError,
  });

  /* Employee Login */
  const { run: onEmployeeLogin } = useRequest(authAdRequest, {
    manual: true,
    onBefore: () => {
      setLoading(true);
    },
    onSuccess: () => {
      dispatch(fetchIsEmployee());
      onSuccess();
      history.replace(EMPLOYEE_MAIN_PAGE_URL);
    },
    onError: handleError,
  });

  const { run: checkCompanies } = useRequest(getAdAvailableRequest, {
    manual: true,
    onSuccess: () => { setIsAdAuthAvailable(true); },
    onError: () => { setIsAdAuthAvailable(false); },
  });

  useEffect(() => {
    checkCompanies();
  }, []);

  return {
    error,
    loading,
    onDefaultLogin,
    onEmployeeLogin,
    checkCompanies,
    isAdAuthAvailable,
  };
};
