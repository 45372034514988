import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import commonStyles from 'styles/common.pcss';

import {
  getShortInstructionPage,
  getShortInstructionPageHeading,
  getShortInstructionPageContent,
} from 'store/common/content';
import { isScreenBelow800 } from 'store/common/breakpoint/selectors';

import { REACT_MODELS } from 'constants';

import { withRenderFlag } from 'enhancers/withRenderFlag';
import { withReactModel } from 'enhancers/withReactModel';

import { Suspense, PageLayout, AccordionContent } from 'shared';
import { Heading, Text } from 'components';

import styles from './styles.pcss';

const cx = classNames.bind(({ ...commonStyles, ...styles }));

const ShortInstructionPageComponent = ({ heading, content, isMobile }) => (
  <PageLayout isWide>
    <div className={cx('component', isMobile ? 'mart-x' : 'mart-xx')}>
      <Heading className={cx('heading', isMobile ? 'marb-se' : 'marb-xxl')} size={1}>
        {heading}
      </Heading>

      <AccordionContent
        className={cx('accordion')}
        content={content}
        render={({ text, imageSrc, mobileImageSrc, alt, list }) => (
          <div className={cx(isMobile ? 'marb-l' : 'marb-xxl', 'content')}>
            {text && (
              <Text isInterphases isMedium isDarker className="marb-se">
                {text}
              </Text>
            )}

            {(imageSrc || mobileImageSrc) && (
              <img alt={alt || ''} src={isMobile && mobileImageSrc ? mobileImageSrc : imageSrc} />
            )}

            {list?.length && (
              <ul className={cx('list')}>
                {list.map((item, index) => (
                  <li key={index.toString()} className={cx('list-item', isMobile ? 'marb-xs' : 'marb-xl')}>
                    {item.title && (
                      <Text isMedium className={cx('title', isMobile ? 'marb-xs' : 'marb-se')}>
                        {item.title}
                      </Text>
                    )}

                    {item.text && (
                      <Text isInterphases isMedium isDarker className={cx('content')}>
                        {item.text}
                      </Text>
                    )}

                    {(item.imageSrc || item.mobileImageSrc) && (
                      <img
                        alt={alt || ''}
                        src={isMobile && item.mobileImageSrc ? item.mobileImageSrc : item.imageSrc}
                      />
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      />
    </div>
  </PageLayout>
);

ShortInstructionPageComponent.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

const mapStateToProps = state => ({
  heading: getShortInstructionPageHeading(state),
  content: getShortInstructionPageContent(state),
  isMobile: isScreenBelow800(state),
  shouldRender: !!getShortInstructionPage(state),
});

export const ShortInstructionPage = compose(
  connect(mapStateToProps),
  withReactModel(REACT_MODELS.B2B_SHORT_INSTRUCTION),
  withRenderFlag,
)(ShortInstructionPageComponent, <Suspense />);
