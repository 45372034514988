import { isNullOrUndefined } from 'utils/fn';
import { FIELD_TYPES } from './constants';

export const mapValueToSendObject = ({ values, fields, isExcludeEmpty }) => {
  const newFields = fields.map((field) => {
    const baseField = { alias: field.alias };

    const value = values[field.alias];

    if (isExcludeEmpty && isNullOrUndefined(value)) return undefined;
    switch (field.fieldType) {
      case FIELD_TYPES.Enum: return { ...baseField, selectedEnumAlias: value };
      case FIELD_TYPES.Integer: return { ...baseField, integerValue: parseInt(value, 10) };
      case FIELD_TYPES.Decimal: return { ...baseField, decimalValue: parseFloat(value, 10) };
      case FIELD_TYPES.Boolean: return { ...baseField, booleanValue: value };

      case FIELD_TYPES.String:
      default:
        return { ...baseField, stringValue: value };
    }
  }).filter(f => !isNullOrUndefined(f));

  return newFields;
};
