import { getAuthStatusRequest } from 'requests/api';
import { getIsEmployeeRequest } from 'requests/api/auth';
import { createStatefulRequest } from 'utils/redux-stateful';
import { FETCH_AUTH_STATUS, SET_IS_EMPLOYEE } from './constants';

export const fetchAuthStatus = createStatefulRequest(FETCH_AUTH_STATUS, getAuthStatusRequest);

export const setIsEmployee = ({ isEmployee }) => ({ type: SET_IS_EMPLOYEE, data: { isEmployee } });
export const fetchIsEmployee = () => async (dispatch) => {
  try {
    const { isEmployee } = await getIsEmployeeRequest();
    return dispatch(setIsEmployee({ isEmployee }));
  } catch (err) {
    return dispatch(setIsEmployee({ isEmployee: false }));
  }
};
