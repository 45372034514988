import React from 'react';

import PropTypes from 'prop-types';

import BaseButton from 'components/BaseButton';
import { Icon } from 'components/Icon';
import { Text } from 'components/Text';
import classNames from 'classnames/bind';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const BackButton = ({ children, text, onClick, className }) => {
  return (
    <BaseButton onClick={onClick} className={cx('backBtn', className)}>
      <div className={cx('backArrowWrap')}>
        <Icon name="arrow" className={cx('backArrow')} />
      </div>
      {text ? (
        <Text isInline isSecondary className={cx('backBtnText')}>{text}</Text>
      ) : children}
    </BaseButton>
  );
};

BackButton.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default BackButton;
