import React from 'react';
import PropTypes from 'prop-types';

import DropDownCustom from './components/DropdownCustom';


export const DropDownStateless = DropDownCustom;

export default class DropDown extends React.PureComponent {
  static propTypes = {
    ...DropDownStateless.propTypes,
    value: PropTypes.any,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    ...DropDownStateless.defaultProps,
    value: null,
    onChange: () => undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || null,
    };
  }

  componentWillReceiveProps = ({ value }) => {
    if (value !== this.props.value) {
      this.setState({ value });
    }
  }

  getChildrenProps = () => ({
    ...this.props,
    ...this.state,
    onChange: this.handleChange,
  });

  handleChange = value => this.setState({ value }, () => this.props.onChange(value));

  focus = () => this.dropdown.focus();

  render = () => (
    <DropDownStateless
      ref={(e) => { this.dropdown = e; }}
      {...this.getChildrenProps()}
    />
  );
}
