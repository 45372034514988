import React, { } from 'react';

import PropTypes from 'prop-types';

import { Row, RowItem } from 'shared/Order/Grid';
import { TextInput } from 'components';

import DropDown from 'components/Dropdown';

const SelectsForm = ({ fields, onChange, formValues, errors }) => (
  <div>
    {fields.map((field) => (
      <Row key={field.alias}>
        <RowItem isWide>
          <DropDown
            name={field.alias}
            options={field.valueVariants.map(variant => ({
              label: variant.text,
              value: variant.alias,
            }))}
            value={formValues[field.alias] ?? ''}
            error={errors[field.alias]}
            onChange={(value) => {
              onChange({ value, fieldAlias: field.alias });
            }}
          />
          <TextInput
            hidden
            status={errors[field.alias] ? 'fail' : undefined}
            error={errors[field.alias]}
          />
        </RowItem>
      </Row>
      ))}
  </div>
);

SelectsForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  formValues: PropTypes.object,
  errors: PropTypes.objectOf(PropTypes.string),
};

export default SelectsForm;
