import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Heading, Text } from 'components';
import { Code } from '../Code';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export const APIDocContent = ({ title, text, className, isMobile }) => (
  <section className={cx('content', 'text', className)}>
    {title && <Heading className="marb-se" size={2}>{title}</Heading>}

    {text.map((el, idx) => (el.code
        ? <Code key={idx.toString()} {...el} />
        : <Text className="marb-se" isMedium={!isMobile} key={idx.toString()}>{el}</Text>
    ))}
  </section>
);

APIDocContent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};
