import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { selectIsMessageSent, selectIsPopupOpen } from 'store/features/helpAndSupport/selectors';
import { closePopup, fetchCategories } from 'store/features/helpAndSupport/action';

import { withRenderFlag } from 'enhancers/withRenderFlag';

import { BasePopupStateless } from 'components';
import { Form } from '../Form';
import { Message } from '../Message';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

const PopupComponent = ({ isMessageSent, isOpen, onClose, onFetchCategories }) => {
  useEffect(() => {
    onFetchCategories();
  }, []);

  return (
    <BasePopupStateless
      className={cx('popup-container')}
      overlayClassName={cx('help-overlay')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div>
        {isMessageSent ? <Message /> : <Form /> }
      </div>
    </BasePopupStateless>
  );
};

const mapStateToProps = (state) => ({
  isOpen: selectIsPopupOpen(state),
  isMessageSent: selectIsMessageSent(state),
});

const mapDispatchToProps = {
  onClose: closePopup,
  onFetchCategories: fetchCategories,
};

PopupComponent.propTypes = {
  isMessageSent: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onFetchCategories: PropTypes.func,
};

export const Popup = compose(
  withRenderFlag,
  connect(mapStateToProps, mapDispatchToProps),
)(PopupComponent);
