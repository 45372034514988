import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import CustomPropTypes from 'utils/prop-types';

import styles from '../styles.pcss';
import { valueToPixel } from '../enhancers/helpers';
const cx = classNames.bind(styles);


/**
 * Input with visual customization
 */
class InputView extends Component {
  /**
   * we have to override onChange, onFocus and onBlur,
   * normally they fire with event as first argument,
   * we put value as first argument, event as second
   */
  handleChange = (event) => {
    const { onChange } = this.props;
    if (onChange) onChange(event.target.value, event);
  };

  handleFocus = (event) => {
    const { onFocus, value } = this.props;
    if (onFocus) onFocus(value, event);
  };

  handleBlur = (event) => {
    const { onBlur, value } = this.props;
    if (onBlur) onBlur(value, event);
  };

  handleKeyDown = (event) => {
    const { onKeyDown } = this.props;
    if (onKeyDown) onKeyDown(event.target.value, event);
  }

  render() {
    const {
      size,
      status,
      width,
      className,
      domNodeRef,
      ...otherProps
    } = this.props;

    /**
     * Fields like status and size are passed straight into class, the validation is done in propTypes
     */
    const classes = cx(
      'component',
      size,
      status,
      className,
    );
    return (
      <input
        autoComplete="on"
        style={{ maxWidth: valueToPixel(width) }}
        {...otherProps}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        className={classes}
        ref={domNodeRef}
      />
    );
  }
}


InputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.oneOf(['fail']),
  size: PropTypes.oneOf(['big']),
  /**
   * width of input, HOCs that override DOM container are supposed to apply width to their container
   */
  width: PropTypes.number,
  className: PropTypes.string,

  /**
   * some notable default input props are listed here
   * all the other props are simply passed down
   */
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,

  /** is not an actual prop */
  domNodeRef: CustomPropTypes.ref,
};

export default InputView;
