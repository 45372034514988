import React from 'react';
import classNames from 'classnames/bind';

import { childrenPropTypes } from 'types';

import { Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const DescriptionText = ({ children, ...restProps }) => (
  <Text className={cx('text')} {...restProps}>
    {children}
  </Text>
);

DescriptionText.propTypes = {
  children: childrenPropTypes,
};
