import { DropDownStateless } from '../Dropdown';
import Checkbox from '../Checkbox';
import {
  TextInput,
  PhoneInput,
  PriceInput,
  PasswordInput,
  DateInput,
  CaptchaInput,
  Textarea,
  INNInput,
  PassportInput,
  OGRNInput,
} from '../TextInput';
import { RadioTab } from '../RadioTab';

import withFinalField from './enhancers/withFinalField';
import { withError, withLabel, withTooltip, withSuggestions, withAnalytics } from '../TextInput/enhancers';

export const FFTextInput = withFinalField(withTooltip(TextInput));
export const FFPhoneInput = withFinalField(withTooltip(PhoneInput));
export const FFINNInput = withFinalField(withTooltip(INNInput));
export const FFOGRNInput = withFinalField(withTooltip(OGRNInput));
export const FFPriceInput = withFinalField(PriceInput);
export const FFPasswordInput = withFinalField(PasswordInput);
export const FFDateInput = withFinalField(DateInput);
export const FFCaptchaInput = withFinalField({ type: 'checkbox' })(CaptchaInput);
export const FFTextarea = withFinalField(Textarea);
export const FFDropDown = withFinalField(withTooltip(withError(DropDownStateless)));
export const FFCheckbox = withFinalField({ type: 'checkbox' })(withError(withLabel(Checkbox)));
export const FFPassportInput = {
  Series: withFinalField(PassportInput.Series),
  Number: withFinalField(PassportInput.Number),
};
export const FFTextInputSuggestion = withFinalField(withTooltip(withSuggestions(TextInput)));
export const FFRadioTab = withFinalField({ type: 'radio' })(RadioTab);

/* WithAnalytics */
export const FFPhoneInputGA = withFinalField(withTooltip(withAnalytics(PhoneInput)));
export const FFTextInputGA = withFinalField(withTooltip(withAnalytics(TextInput)));
export const FFPasswordInputGA = withFinalField(withAnalytics(PasswordInput));

export { FinalForm } from './FinalForm';
