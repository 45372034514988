import { axiosAuthorizedRequest } from '../helpers';
import { getFeedbackCategoriesUrl, postFeedbackSubmitUrl } from './constants';
import { mapCategories } from '../mappers/helpAndSupport';

export const fetchCategoriesRequest = () => axiosAuthorizedRequest({ url: getFeedbackCategoriesUrl() })
  .then(({ data }) => mapCategories(data));

export const submitMessageRequest = (values, currentCategory) => axiosAuthorizedRequest({
  url: postFeedbackSubmitUrl(),
  method: 'POST',
  data: {
    topicId: currentCategory,
    content: values.comment,
    email: values.email,
  },
});
