import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import { getEditOrderBlock } from 'store/common/content';

import { convertISODateToDMString } from 'utils/date';

import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { VIEW_ORDER_ROUTE } from 'constants/routes';
import BaseActionBlock from '../BaseActionBlock';
import OrderStatus from '../OrderStatus';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const EditOrderBlock = ({ id, name, date, status, orderHistory, user, content }) => (
  <BaseActionBlock
    buttonSection={(
      <Link to={`${VIEW_ORDER_ROUTE}${id}`}>
        <Button isWide isSecondary>
          {content.buttonText}
        </Button>
      </Link>
    )}
  >
    <div className={cx('content')}>
      <div className={cx('info')}>
        <Text isLarge isTitle className={cx('title')}>
          {name}
        </Text>
      </div>
      <>
        <div className={cx('status')}>
          <OrderStatus
            status={status}
            orderHistory={orderHistory}
            user={user}
            date={date}
          />
        </div>
        <Text className={cx('date')} isSecondary>
          {`${content.created} ${convertISODateToDMString(date)}`}
        </Text>
      </>
    </div>
  </BaseActionBlock>
);

const mapStateToProps = (state) => ({
  content: getEditOrderBlock(state),
});

EditOrderBlock.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  orderHistory: PropTypes.array,
  user: PropTypes.string,
  content: PropTypes.shape({
    buttonText: PropTypes.string,
    created: PropTypes.string,
  }),
};

export default connect(mapStateToProps)(EditOrderBlock);
