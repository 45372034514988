import React from 'react';
import PropTypes from 'prop-types';

import { childrenPropTypes } from 'types';

import { Link, Text } from 'components';

export const FormToggle = ({ onClick, children }) => (
  <Link className="mart-xs" onClick={onClick}>
    <Text isSmall>
      {children}
    </Text>
  </Link>
);

FormToggle.propTypes = {
  onClick: PropTypes.func,
  children: childrenPropTypes,
};
