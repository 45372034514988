import { passAsIs } from 'utils/fn';
import { createStatefulActionTypes, createStatefulReducer, stateValues } from 'utils/redux-stateful';
import { SEND_TO_REVIEW, CLEAR_AFTER_SUCCESS } from './constants';

const stateReducer = createStatefulReducer(SEND_TO_REVIEW);
const actions = createStatefulActionTypes(SEND_TO_REVIEW);
const reducers = {
  [actions.SET_INITIAL]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_PENDING]: (state, action) => ({ state: stateReducer(state, action) }),
  [actions.SET_ERROR]: (state, action) => ({ state: stateReducer(state, action), id: action.data.id }),
  [actions.SET_SUCCESS]: (state, action) => ({ state: stateReducer(state, action), id: action.data.id }),
  [CLEAR_AFTER_SUCCESS]: () => ({ state: stateValues.Initial, id: null }),
};
export const reviewReducer = (state, action) => {
  const reducer = reducers[action.type] ?? passAsIs;

  return reducer(state, action);
};
