import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';

import { selectIsUser } from 'store/features/auth/selectors';
import { getRoutesInfo } from 'store/common/content';

import { MAIN_PAGE_URL, EMPLOYEE_MAIN_PAGE_URL } from 'constants';

import BackButton from 'components/BackButton';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const Breadcrumbs = ({ history, match, isUser, routes }) => {
  const mainPage = useMemo(() => routes[0], [routes]);

  if (!routes.find(({ path }) => match.path === path) || match.path === mainPage.path) return null;

  const onBackClick = () => {
    const page = isUser ? MAIN_PAGE_URL : EMPLOYEE_MAIN_PAGE_URL;
    history.push(page);
  };

  return (
    <div className={cx('backBtnWrap')}>
      <BackButton onClick={onBackClick}>
        <p className={cx('backBtnText')}>
          {mainPage.name}
        </p>
      </BackButton>
      <p className={cx('inactive-link')}>
        {routes.find(({ path }) => match.path === path)?.name}
      </p>
    </div>
  );
};

Breadcrumbs.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  routes: PropTypes.array,
  isUser: PropTypes.bool,
};

const mapStateToProps = state => ({
  isUser: selectIsUser(state),
  routes: getRoutesInfo(state),
});

export default compose(
  connect(mapStateToProps),
  withRouter,
)(Breadcrumbs);
