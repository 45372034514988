import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components/Text';

import commonStyles from 'styles/common.pcss';
const cx = classNames.bind(commonStyles);


export function withLabel(WrappedInput) {
  function InputWithLabel({ label, className, ...otherProps }) {
    return (
      <div className={className}>
        {label && <Text isSmall className={cx('marb-xxs')}>{label}</Text>}
        <WrappedInput {...otherProps} />
      </div>
    );
  }

  InputWithLabel.propTypes = {
    label: PropTypes.node,
    ...WrappedInput.propTypes,
  };

  /**
   * Override component name by prepending `Label~`
   * to make it look nice, for example: `LabelTextInput`
   */
  if (process.env.NODE_ENV !== 'production') {
    const WrappedComponentName = WrappedInput.displayName || WrappedInput.name || 'Input';
    InputWithLabel.displayName = `Label${WrappedComponentName}`;
  }

  return InputWithLabel;
}
