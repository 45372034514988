import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import { Text } from 'components/Text';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const LegalInfo = ({ className, children }) => (
  <div className={className}>
    <Text isLegal className={cx('legal')}>
      {children}
    </Text>
  </div>
);

LegalInfo.propTypes = {
  className: PropTypes.any,
};

export default LegalInfo;
