import React from 'react';

import { Text } from 'components';
import { withTooltip } from 'components/TextInput/enhancers';

export const GroupTitle = withTooltip(({ title }) => (
  <Text isLarge isTitle isBold>
    {title}
  </Text>
));
