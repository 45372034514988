import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getIsEmployee } from 'store/features/auth/selectors';
import { getAccessDeniedPage } from 'store/common/content';

import { MAIN_PAGE_URL, EMPLOYEE_MAIN_PAGE_URL } from 'constants';

import PageLayout from 'shared/PageLayout';
import Error from 'shared/Error';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const AccessDenied = ({ content, history, isEmployee }) => {
  const onButtonClick = () => {
    isEmployee ? history.replace(EMPLOYEE_MAIN_PAGE_URL) : history.replace(MAIN_PAGE_URL);
  };

  return (
    <PageLayout>
      <Error
        className={cx('content')}
        heading={content.heading}
        btnText={content.buttonText}
        onClick={onButtonClick}
      />
    </PageLayout>
  );
};

AccessDenied.propTypes = {
  history: PropTypes.object,
  isEmployee: PropTypes.bool,
  content: PropTypes.object,
};

const mapStateToProps = state => ({
  isEmployee: getIsEmployee(state),
  content: getAccessDeniedPage(state),
});

export default connect(mapStateToProps)(withRouter(AccessDenied));
