import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Text } from 'components';

import { childrenPropTypes } from 'types';
import { compose, getCompanyName } from 'utils/fn';
import { CREATE_COMPANY_ROUTE } from 'constants/routes';

import { setCurrentCompany as setCurrentCompanyAction } from 'store/features/currentCompany/action';
import { getIsEmployee } from 'store/features/auth/selectors';
import { getHeaderMenu } from 'store/common/content';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const HeaderMenu = ({
  content,
  children,
  isMenuActive,
  companies,
  history,
  username,
  setCompany,
  isEmployee,
  innerRef,
  className,
}) => {
  const toggleOnCompany = (id) => {
    if (id === localStorage.getItem('companyId')) return null;

    localStorage.setItem('companyId', id);
    const companyToSet = companies?.find(el => el.id === id);
    setCompany(companyToSet);

    history.push('/b2b');
  };

  const onFillCompanyClick = () => {
    history.push(CREATE_COMPANY_ROUTE);
    localStorage.removeItem('companyId');
  };

  const menuText = useMemo(() => (isEmployee ? content.account : content.company), [content, isEmployee]);
  const buttonText = useMemo(
    () => (companies?.length && companies?.every(el => !el.isOwner) ?
      content.createCompany
      : content.fillCompanyProfile)
    , [content, companies],
  );

  return (
    <div ref={innerRef} className={cx('account-menu', { isMenuActive }, className)}>
      <div className={cx('account-menu__header')}>
        {menuText}
      </div>
      {
        !isEmployee && (
          <div className={cx('companies')}>
            {companies?.every(el => !el.isOwner) ?
              <div className={cx('emptyCompanyWrapper')}>
                <Text>{username}</Text>
                <button className={cx('fillCompanyBtn')} onClick={onFillCompanyClick}>
                  {buttonText}
                </button>
              </div> : null}

            {companies?.map(el => (
              <div key={el?.id} className={cx('companyWrapper')}>
                <button className={cx('link')} onClick={() => toggleOnCompany(el?.id)}>
                  {getCompanyName(el)}
                </button>

                {localStorage.getItem('companyId') === el.id && <div className={cx('circle')} />}
              </div>
            ))}
          </div>
        )
      }

      {children}
    </div>
  );
};

HeaderMenu.propTypes = {
  isMenuActive: PropTypes.bool,
  history: PropTypes.object,
  content: PropTypes.object,
  companies: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    isOwner: PropTypes.bool,
    isAggregator: PropTypes.bool,
  })),
  username: PropTypes.string,
  setCompany: PropTypes.func,
  innerRef: PropTypes.any,
  isEmployee: PropTypes.bool,
  className: PropTypes.any,
  children: childrenPropTypes,
};

const mapStateToProps = (state) => ({
  isEmployee: getIsEmployee(state),
  content: getHeaderMenu(state),
});
const mapDispatchToProps = { setCompany: setCurrentCompanyAction };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderMenu);
