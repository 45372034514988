import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import styles from './styles.pcss';
const cx = classNames.bind(styles);

const Textarea = ({
  wrapperClass,
  placeholder,
  autoFocus,
  maxLength,
  onSubmit,
  disabled,
  readOnly,
  height,
  action,
  resize,
  status,
  width,
  name,
  value: propValue,
  onChange,
  onBlur,
  onFocus,
  id,
}) => {
  const [value, setValue] = useState(propValue);

  const inputElement = useRef(null);

  useEffect(() => {
    if (propValue && inputElement) {
      inputElement.current.value = propValue;
    }
  }, []);

  useEffect(() => {
    if (propValue || propValue === '') {
      setValue(propValue);
    }
  }, []);

  const handleFocus = useCallback(() => {
    if (onFocus) onFocus(value);
  }, [onFocus, value]);

  const handleBlur = useCallback(() => {
    if (onBlur) onBlur(value);
  }, [onBlur, value]);

  const handleChange = useCallback(({ target }) => {
    const { value: targetValue } = target;
    setValue(targetValue);
    if (onChange) onChange(targetValue);
  }, [onChange]);

  return (
    <div
      action={action}
      onSubmit={onSubmit}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={`${cx('field-wrapper')} ${wrapperClass}`}
    >
      <div className={cx('field', status)}>
        <textarea
          id={id}
          name={name}
          className={cx('field-textarea', status)}
          style={{ height, width, resize }}
          {...{ autoFocus, disabled, maxLength, placeholder, readOnly }}
          onChange={handleChange}
          value={value}
          ref={inputElement}
        />
      </div>
    </div>
  );
};


Textarea.propTypes = {
  /** Класс обёртки текстового поля */
  wrapperClass: PropTypes.string,
  /** Текст внутри текстового поля */
  placeholder: PropTypes.string,
  /** Максимальная длинна */
  maxLength: PropTypes.string,
  /** Автоматический фокус */
  autoFocus: PropTypes.bool,
  /** Дополнительный сласс отображения textarea */
  status: PropTypes.string,
  /** Callback, срабатывает при изменении value в textarea */
  onChange: PropTypes.func,
  /** Можно ли пользователю изменять размеры текстового поля */
  resize: PropTypes.string,
  /** Блокирует доступ к активации для textarea */
  disabled: PropTypes.bool,
  /** Может ли текстовое поле изменяться пользователем */
  readOnly: PropTypes.bool,
  /** ? */
  action: PropTypes.string,
  /** Callback */
  onSubmit: PropTypes.func,
  /** Callback, срабатывает при фокусе */
  onFocus: PropTypes.func,
  /** Ширина элемента textarea */
  width: PropTypes.string,
  /** Высота элемента textarea */
  height: PropTypes.string,
  /** Текст внутри textarea */
  value: PropTypes.string,
  /** Уникальное имя элемента */
  name: PropTypes.string,
  /** Callback, срабатывает при расфокусировки */
  onBlur: PropTypes.func,
  /** Уникальный идентификатор для textarea */
  id: PropTypes.string,
};

Textarea.defaultProps = {
  wrapperClass: '',
  placeholder: null,
  autoFocus: false,
  disabled: false,
  readOnly: false,
  maxLength: null,
  resize: 'none',
  height: '80px',
  width: '100%',
  value: '',
};

export default Textarea;
