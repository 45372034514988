import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Tooltip from '../../../Tooltip';
import { Text } from '../../../Text';
import { Link } from '../../../Link';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

export const withTooltip = (WrappedComponent) => {
  const Component = ({ tooltip, tooltipSign = '?', className, link, ...restProps }) => (
    <div className={cx('wrapper', className)}>
      <WrappedComponent {...restProps} />

      {tooltip && (
        <Tooltip
          className="marl-xs"
          tooltipMessage={tooltip}
        >
          <Text className={cx('tooltip-sign')} isSecondary isSmaller isInline>
            {tooltipSign}
          </Text>
        </Tooltip>
      )}

      {link && (
        <Link className={cx('link')} href={link.url} target="_blank">
          {link.name}
        </Link>
      )}
    </div>
  );

  Component.propTypes = {
    tooltip: PropTypes.string,
    tooltipSign: PropTypes.string,
    className: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  return Component;
};
