import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { openPopup } from 'store/features/helpAndSupport/action';
import { getHelpAndSupport } from 'store/common/content';

import BaseButton from 'components/BaseButton';
import { Icon, Text } from 'components';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const ButtonComponent = ({ onClick, text }) => (
  <BaseButton className={cx('component')} onClick={onClick}>
    <Text className={cx('text')}>
      {text}
    </Text>

    <Icon className={cx('icon')} name="help" />
  </BaseButton>
);

const mapStateToProps = (state) => ({
  text: getHelpAndSupport(state).buttonText,
});

const mapDispatchToProps = {
  onClick: openPopup,
};

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export const Button = connect(mapStateToProps, mapDispatchToProps)(ButtonComponent);
