import { saveOrderGroupFieldsRequest } from 'requests/api/create-order';
import { mapValueToSendObject } from 'utils/orders/mapValuesToSend';
import { createStatefulRequest } from 'utils/redux-stateful';
import {
  SET_GROUP_FIELDS,
  SAVE_GROUP_FIELDS_DRAFT,
  SET_LAST_SEND_GROUP_ALIAS,
  CLEAR_DRAFT_AFTER_SUCCESS,
} from './constants';

export const setGroupFields = (data) => ({ type: SET_GROUP_FIELDS, data });
export const setLastSendGroupAlias = (alias) => ({ type: SET_LAST_SEND_GROUP_ALIAS, data: { alias } });
export const clearDraftAfterSuccess = () => ({ type: CLEAR_DRAFT_AFTER_SUCCESS });
export const saveGroupFieldsDraft = ({ id, alias, values, fields }) => async (dispatch, getState) => {
  const newFields = mapValueToSendObject({ values, fields });
  dispatch(setLastSendGroupAlias(alias));
  const result = await createStatefulRequest(
    SAVE_GROUP_FIELDS_DRAFT,
    saveOrderGroupFieldsRequest,
    {
      success: () => dispatch(setGroupFields({ alias, fields: newFields })),
    },
  )({
    alias,
    orderId: id,
    fields: newFields,
  })(dispatch, getState);

  return result;
};
