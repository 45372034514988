import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
const cx = classNames.bind(styles);

const BaseActionBlock = ({ children, buttonSection, className }) => (
  <div className={cx('wrapper')}>
    <div className={cx('content', className)}>
      {children}
    </div>
    <div className={cx('buttonSection')}>
      {buttonSection}
    </div>
  </div>
);

BaseActionBlock.propTypes = {
  children: PropTypes.any,
  buttonSection: PropTypes.any,
};

export default BaseActionBlock;
