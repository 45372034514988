import React from 'react';
import { NumberMaskedInput } from './NumberMaskedInput';

const entityConf = {
  mask: '__________',
  maxLength: 10,
};
const individualConf = {
  mask: '____________',
  maxLength: 12,
};

export const INNInput = ({ isIndividual, ...restProps }) => (
  <NumberMaskedInput
    {...restProps}
    {...(isIndividual ? individualConf : entityConf)}
  />
);
