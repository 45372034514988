import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { RadioButton, Text } from 'components';
import TooltipIcon from './tooltip.svg';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind(({ ...commonStyles, ...styles }));

export const DescriptionRadioButton = ({ label, description, value, isActive, tooltip }) => (
  <div className={cx('component', { isActive }, !description && !value && 'label-only')}>
    <RadioButton className={cx('radio-button', 'marr-s')} value={isActive} />

    <div className={cx('container')}>
      <Text isInterphases className={cx('label')} >
        {label}
      </Text>
      {description && (
        <Text isInterphases className={cx('description', 'mart-xxxs', 'marb-s')} >
          {description}
        </Text>
      )}
      {value && (
        <Text isInterphases className={cx('value')}>
          {value}
        </Text>
      )}
    </div>
    {tooltip && <TooltipIcon className={cx('tooltip')} />}
  </div>
);

DescriptionRadioButton.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.bool,
  isActive: PropTypes.bool,
};

export const DescriptionMultipleRadioButtons = ({ radioButtons }) => (
  <div className={cx('radio-buttons')}>
    {radioButtons.map((child, index) => <DescriptionRadioButton key={index.toString()} {...child} />)}
  </div>
);

DescriptionMultipleRadioButtons.propTypes = {
  radioButtons: PropTypes.array,
};
