import { useReducer, useEffect } from 'react';

import { usePostMessageListener } from 'hooks';
import { paymentRequest } from 'requests/api/user';

import { isInitialState, isPaymentSuccessful, is3DSecure, getTransactionId, getValidationMsg } from './helpers';
import { getIFrameOrigin } from '../iframeParams';
import { SET_DIRTY, CREATE_PAYMENT, VALIDATION_OK, VALIDATION_ERROR, INIT } from './messageTypes';

const getInitialState = () => ({
  id: null,
  hid: null,
  isValid: false,
  isDirty: false,
  validationMsg: '',
  isSuccess: false,
  isSubmitError: false,
});

const reducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        hid: JSON.parse(action.payload)?.data?.hid,
      };
    case SET_DIRTY:
      return { ...state, isDirty: true };
    case VALIDATION_OK:
      return { ...state, isValid: true, validationMsg: undefined };
    case VALIDATION_ERROR:
      return { ...state, isValid: false, validationMsg: getValidationMsg(action.payload) };
    case CREATE_PAYMENT:
      return {
        ...state,
        ...(isPaymentSuccessful(action.payload)
          ? { id: getTransactionId(action.payload), isSuccess: true }
          : { isSubmitError: true }
        ),
      };
    default:
      return state;
  }
};

export const usePaymentWidget = ({ onChangeTransactionId, onSuccess, onError }) => {
  const [state, reactDispatch] = useReducer(reducer, null, getInitialState);

  usePostMessageListener(
    getIFrameOrigin(),
    (data) => (is3DSecure(data)
      ? paymentRequest(data.threeDSec)
      : reactDispatch({ type: data.type?.toLowerCase() ?? INIT, payload: data })),
  );

  useEffect(() => {
    if (!isInitialState(getInitialState(), state) && !state.isDirty) {
      reactDispatch({ type: SET_DIRTY });
    }

    if (state.id) {
      onChangeTransactionId(state.id);
    }

    if (state.isSuccess) {
      onSuccess();
    }

    if (state.isSubmitError) {
      onError();
    }
  }, [state]);

  return state;
};
