import { axiosBaseRequest, axiosAuthorizedRequest } from 'requests/helpers';
import { getTextsUrl, getDocumentsUrl, getNavigateItemUrl } from './constants';
import { NAVIGATE_ITEM_PRICING } from '../../constants';

export const getTextsRequest = () => axiosBaseRequest({
  url: getTextsUrl(),
})
  .then(({ data }) => data);

export const getDocumentsRequest = () => axiosAuthorizedRequest({
  url: getDocumentsUrl(),
})
  .then(({ data }) => data);

export const fetchNavigateItems = () => axiosBaseRequest({ url: getNavigateItemUrl(NAVIGATE_ITEM_PRICING) });
