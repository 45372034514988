/* eslint-disable max-len */
import { CHOOSE_ONE_OF_THE_OPTIONS_TEXT, ENTER_THE_CORRECT_PHONE_NUMBER_TEXT, FIELD_CANNOT_BE_EMPTY_TEXT, FIELD_CAN_ONLY_CONTAIN_INTEGERS_AND_DECIMALS_TEXT, FIELD_CAN_ONLY_CONTAIN_INTEGERS_TEXT, FIELD_CAN_ONLY_CONTAIN_LETTERS_TEXT, FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT, INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT, INCORRECT_EMAIL_TEXT, INCORRECT_URL_FORMAT_TEXT, INCORRECT_HTTP_PROTOCOL } from 'constants/validators';
import {
  composeDecimalValidator,
  composeDigitValidator,
  composeEmailValidator,
  composeLengthValidator,
  composeMinLengthValidator,
  composeMaxLengthValidator,
  composeNameValidator,
  composePhoneValidator,
  composeRequiredValidator,
  composeUrlValidator,
  composeXSSValidator,
  composeHttpsValidator,
} from 'utils/field-validators';
import { isNullOrUndefined, noop } from 'utils/fn';
import { formatPhone } from 'utils/formatting';
import {
  FIELD_TYPES,
  FIELDS_ORDER_FOR_COMPANY_INFO,
  VIEW_TYPES,
  GROUPS_ALIASES,
  APP_NAME_MAX_LENGTH,
  APP_NAME_MAX_LENGTH_MESSAGE,
  APP_SITE_MAX_LENGTH,
  TARGET_DESCRIPTION_MIN_LENGTH,
  TARGET_DESCRIPTION_MAX_LENGTH,
} from './constants';

const mapGroupAliasToName = {
  [GROUPS_ALIASES.company]: 'Контактные данные',
  [GROUPS_ALIASES.bank]: 'Банковские реквизиты',
  [GROUPS_ALIASES.operators]: 'Подключаемые операторы',
  /* MOBID-1235
  [GROUPS_ALIASES.workScheme]: 'Схема работы', */
  [GROUPS_ALIASES.workScheme]: 'Уровень доступа',
  [GROUPS_ALIASES.authOnly]: 'Настройки подключения',
  [GROUPS_ALIASES.clientsData]: 'Данные клиентов для передачи',
  [GROUPS_ALIASES.applicationName]: 'Подключить цифровой ресурс',
  [GROUPS_ALIASES.targetsDescription]: 'Для каких целей планируется использовать Мобильный ID',
  [GROUPS_ALIASES.ipAddresses]: 'Адреса запросов в Мобильный ID',
};

const groupTooltips = {
  [GROUPS_ALIASES.workScheme]: 'Метод проверки подлинности пользователя с помощью одного фактора (наличие устройства), или двух факторов (наличие устройства и знание PIN-кода)',
  [GROUPS_ALIASES.authOnly]: 'Настройки, необходимые для интеграции вашего ресурса и Мобильного ID',
};

export const isNotEmptyFn = composeRequiredValidator(FIELD_CANNOT_BE_EMPTY_TEXT);

const uniqueValidatorFn = (alias) => composeRequiredValidator(mapFieldInfo[alias]?.errorText ?? FIELD_CANNOT_BE_EMPTY_TEXT);

export const getRequiredValidatorWithMaxLength = ({ min = null, max = 200 } = {}) => {
  let lengthErrorText = `Максимальная длина строки ${max} символов`;

  if (max === null && min !== null) {
    lengthErrorText = `Минимальная длина строки ${min} символов`;
  } else if (max === min) {
    lengthErrorText = `Длина строки должна быть ${max} символов`;
  }

  return [
    isNotEmptyFn,
    composeLengthValidator(lengthErrorText, { min, max }),
  ];
};

// значения в полях tooltip и description - это названия свойств <Dictionary> c текстами (server/routes/stubs/texts)

export const mapFieldInfo = {
  ClientId: {
    name: 'Client ID',
  },
  ClientSecret: {
    name: 'Client Secret',
  },
  FakePhone: {
    name: 'Фиктивный номер телефона',
    validator: [
      composePhoneValidator(ENTER_THE_CORRECT_PHONE_NUMBER_TEXT),
    ],
  },
  AuthenticationFee: {
    name: 'Стоимость аутентификации',
    validator: [
      composeDecimalValidator(FIELD_CAN_ONLY_CONTAIN_INTEGERS_AND_DECIMALS_TEXT),
    ],
  },
  DataTransferFee: {
    name: 'Стоимость передачи данных',
    validator: [
      composeDecimalValidator(FIELD_CAN_ONLY_CONTAIN_INTEGERS_AND_DECIMALS_TEXT),
    ],
  },
  FreeAuthentications: {
    name: 'Кол-во бесплатных аутентификаций',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_INTEGERS_TEXT),
    ],
  },
  FreeDataPackages: {
    name: 'Кол-во бесплатных пакетов данных',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_INTEGERS_TEXT),
    ],
  },
  FeeType: {
    name: 'Способ оплаты',
    validator: [
      isNotEmptyFn,
    ],
  },
  ApplicationName: {
    name: 'Название ресурса',
    placeholder: 'Название ресурса',
    isWide: true,
    validator: [
      isNotEmptyFn,
      composeMaxLengthValidator(APP_NAME_MAX_LENGTH, APP_NAME_MAX_LENGTH_MESSAGE),
    ],
    errorText: 'Введите название ресурса',
    tooltipMsg: 'Ваш клиент увидит это название в пуше при подтверждении',
  },
  ApplicationSiteAddress: {
    name: 'Сайт ресурса',
    placeholder: 'Введите сайт ресурса',
    validator: [
      composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT),
      composeMaxLengthValidator(APP_SITE_MAX_LENGTH),
    ],
  },
  ClientName: {
    name: 'Клиент',
    placeholder: 'Наименование клиента',
    group: 'client',
    groupOrder: 1,
    isWide: true,
    validator: isNotEmptyFn,
    errorText: 'Введите наименование клиента',
    tooltip: 'FieldClientTooltip',
  },
  CompanyName: {
    name: 'Название компании',
    placeholder: 'Название компании',
    // Поля с одинаковыми group будут отображаться в одной строке
    group: 'name',
    groupOrder: 2,
    errorText: 'Введите название компании',
    validator: getRequiredValidatorWithMaxLength(),
  },
  CompanyNameEN: {
    name: 'Название компании на английском',
    placeholder: 'Название компании на английском',
    group: 'name',
    groupOrder: 2,
    errorText: 'Введите название компании на английском',
    validator: getRequiredValidatorWithMaxLength(),
  },
  SiteAddress: {
    name: 'Сайт',
    placeholder: 'Введите сайт компании',
    group: 'contacts',
    groupOrder: 3,
    errorText: 'Введите сайт компании',
    validator: getRequiredValidatorWithMaxLength(),
  },
  Email: {
    name: 'Корпоративный E-mail',
    placeholder: 'Корпоративный E-mail компании',
    group: 'contacts',
    groupOrder: 3,
    errorText: 'Введите корпоративный E-mail',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 100 }),
      composeEmailValidator(INCORRECT_EMAIL_TEXT),
    ],
  },
  INN: {
    name: 'ИНН компании',
    placeholder: 'Введите ИНН компании',
    groupOrder: 4,
    errorText: 'Введите ИНН компании',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ max: 12 }),
    ],
  },
  ContactPerson: {
    name: 'ФИО контактного лица',
    placeholder: 'Введите ФИО контактного лица',
    groupOrder: 5,
    errorText: 'Введите ФИО контактного лица',
    validator: [
      ...getRequiredValidatorWithMaxLength({ max: 60 }),
      composeNameValidator(FIELD_CAN_ONLY_CONTAIN_LETTERS_TEXT),
    ],
  },
  ContactPersonPhone: {
    name: 'Телефон контактного лица',
    placeholder: '+7 ',
    groupOrder: 6,
    errorText: 'Введите телефон контактного лица',
    validator: [
      isNotEmptyFn,
      composePhoneValidator(ENTER_THE_CORRECT_PHONE_NUMBER_TEXT),
    ],
  },
  TargetsDescription: {
    name: 'Для каких целей планируется использовать Мобильный ID',
    groupOrder: 7,
    placeholder: 'Опишите, для каких целей планируется использовать Мобильный ID',
    isWide: true,
    errorText: 'Опишите, для каких целей планируется использовать Мобильный ID',
    validator: [
      composeRequiredValidator(),
      composeMinLengthValidator(TARGET_DESCRIPTION_MIN_LENGTH),
      composeMaxLengthValidator(TARGET_DESCRIPTION_MAX_LENGTH),
    ],
  },
  IncomingIPAddresses: {
    name: 'IP адрес или диапазон',
    groupOrder: 8,
    isWide: true,
    placeholder: 'Введите IP адрес(а)',
    validator: [composeRequiredValidator()],
    notation: [
      'Пример: 186.191.0.1, 187.195.0.2',
      'Пример для диапазонов: 177.194.0.0/26, 186.191.0.1 - 186.191.0.12',
    ],
  },
  BankName: {
    name: 'Полное наименование банка организации',
    placeholder: 'Наименование банка',
    errorText: 'Введите наименование банка',
    validator: getRequiredValidatorWithMaxLength({ max: 200 }),
  },
  RS: {
    name: 'Расчетный счет',
    placeholder: 'Введите расчетный счет',
    errorText: 'Введите корректный расчетный счет',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 20, max: 20 }),
    ],
  },
  KS: {
    name: 'Корреспондентский счет',
    placeholder: 'Введите корреспондентский счет',
    errorText: 'Введите корректный корреспондентский счет',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 20, max: 20 }),
    ],
  },
  BIK: {
    name: 'БИК',
    placeholder: 'Введите БИК банка',
    errorText: 'Введите БИК банка',
    validator: [
      composeDigitValidator(FIELD_CAN_ONLY_CONTAIN_NUMBERS_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 9, max: 9 }),
    ],
  },
  Provider: {
    // Для табов указываем заголовок в title
    title: '',
    // Здесь name используется только при отображении названия форме результата
    name: 'Подключаемые операторы',
    tooltips: [
      {
        itemAlias: 'Beeline',
        text: 'ProviderBeelineTooltip',
      },
      {
        itemAlias: 'All',
        text: 'ProviderAllTooltip',
      },
    ],
  },
  WorkScheme: {
    name: 'Я хочу использовать экраны со своим дизайном',
    title: '',
    description: 'FieldWorkSchemeDescription',
    tooltips: [
      {
        itemAlias: 'DI',
        text: 'FieldDITooltip',
      },
      {
        itemAlias: 'SI',
        text: 'FieldSITooltip',
      },
    ],
  },
  SecurityLevel: {
    name: 'Уровень доступа ',
    /* MOBID-1235 */
    title: '',
    description: 'FieldSecurityLevelDescription',
    tooltips: [
      {
        itemAlias: 'LoA2',
        text: 'FieldLoa2Tooltip',
      },
      {
        itemAlias: 'LoA3',
        text: 'FieldLoa3Tooltip',
      },
    ],
    // notation: 'SecurityLevelGreyBlockText',
  },
  Seamless: {
    name: 'Бесшовный вход/регистрация ',
    title: 'Бесшовная аутентификация ',
    description: 'FieldSecurityLevelDescription',
    tooltip: 'Однофакторная аутентификация без PUSH сообщения',
    checkboxNotation: 'SeamlessGreyBlockText',
    notation: 'SecurityLevelGreyBlockText',
    isConditional: true,
    uniqueValidator: noop,
  },
  SMSPush: {
    name: 'Если PUSH не доставлен ',
    title: 'Если PUSH не доставлен ',
    description: 'FieldSMSPushDescription',
    isConditional: true,
    tooltips: [
      {
        itemAlias: 'SMSUrl',
        text: 'FieldSMSURLTooltip',
      },
      {
        itemAlias: 'SMSOtp',
        text: 'FieldSMSOTPTooltip',
      },
    ],
  },
  OTPUrl: {
    name: 'Адрес получения запроса для отражения формы SMS OTP',
    title: '',
    // Означает, что это поле может (не)отобржаться
    // в зависимости от значения другого поля в этой же групе (управляется в isShouldShow())
    isConditional: true,
    isWide: true,
    tooltip: 'FieldOTPURLTooltip',
  },
  HttpsNotificationUrl: {
    name: 'Адрес обратного вызова',
    nameForEmployee: 'HTTPS Notification URL',
    isWide: true,
    tooltip: 'FieldHTTPSNotificationUrlTooltip',
    errorText: 'Введите значение',
    validator: [
      composeUrlValidator(INCORRECT_URL_FORMAT_TEXT, { hasProtocol: true }),
      composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 10, max: 100 }),
    ],
  },
  HttpsJWKSUrl: {
    name: 'Адрес публикации открытых ключей',
    nameForEmployee: 'HTTPS JWKS URL',
    isWide: true,
    tooltip: 'FieldHTTPSJWKSUrlTooltip',
    errorText: 'Введите значение',
    validator: [
      composeUrlValidator(INCORRECT_URL_FORMAT_TEXT, { hasProtocol: true }),
      composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT),
      ...getRequiredValidatorWithMaxLength({ min: 10, max: 100 }),
    ],
  },
  HttpsCallbackUrl: {
    name: 'Адрес для приёма результата',
    nameForEmployee: 'Callback URL',
    isWide: true,
    tooltip: 'FieldHTTPSCallbackUrlTooltip',
    errorText: 'Введите значение',
    validator: [
      composeUrlValidator(INCORRECT_URL_FORMAT_TEXT, { hasProtocol: true }),
      composeXSSValidator(INCORRECT_CHARACTERS_HAVE_BEEN_ENTERED_TEXT),
      composeHttpsValidator(INCORRECT_HTTP_PROTOCOL),
      ...getRequiredValidatorWithMaxLength({ min: 10, max: 100 }),
    ],
  },
  ClientDataAccessLevel: {
    name: 'Данные клиентов для передачи',
    isBig: true,
    tooltips: [
      {
        itemAlias: 'AllData',
        text: 'AllDataTooltip',
      },
      {
        itemAlias: 'Standart',
        text: 'StandartTooltip',
      },
      {
        itemAlias: 'Base',
        text: 'BaseTooltip',
      },
      {
        itemAlias: 'AuthOnly',
        text: 'AuthOnlyTooltip',
      },
    ],
    info: [
      {
        itemAlias: 'AllData',
        text: 'FieldAllDataTooltip',
        littleText: '(включая Базовый и Стандартный)',
      },
      {
        itemAlias: 'Standart',
        text: 'FieldStandartDataTooltip',
        littleText: '(включая Базовый)',
      },
      {
        itemAlias: 'Base',
        text: 'FieldBaseDataTooltip',
      },
      {
        itemAlias: 'AuthOnly',
        text: 'FieldOnlyAuthDataTooltip',
      },
      {
        itemAlias: 'KYC',
        text: 'FieldKYCDataText',
      },
    ],
    notation: [
      'Передача данных доступна у операторов билайн, Мегафон, МТС',
    ],
  },
  CompanyType: {
    name: 'Тип  юридического лица',
  },
  CompanyNameEn: {
    name: 'Название компании на английском',
  },
  CompanySiteAddress: {
    name: 'Сайт компании',
  },
  CompanyEmail: {
    name: 'Корпоративный E-mail',
  },
  CompanyINN: {
    name: 'ИНН компании',
  },
  CompanyAddress: {
    name: 'Адрес',
  },
  CompanyContactPerson: {
    name: 'ФИО контактного лица',
  },
  CompanyContactPersonPhone: {
    name: 'Телефон контактного лица',
  },
  CompanyBankName: {
    name: 'Полное наименование банка организации',
  },
  CompanyRS: {
    name: 'Расчетный счет',
  },
  CompanyKS: {
    name: 'Корреспондентский счет',
  },
  CompanyBIK: {
    name: 'БИК',
  },
  Tariff: {
    name: '',
    validator: isNotEmptyFn,
  },
  AccountType: {
    name: '',
    validator: isNotEmptyFn,
  },
};

export const getGroupName = ({ alias }) => mapGroupAliasToName[alias];
export const getGroupTooltip = (alias) => groupTooltips[alias];
export const getGroupDescription = ({ alias }) => {
  switch (alias) {
    case GROUPS_ALIASES.ipAddresses:
      return 'FieldIPAddressesDataDescription';
    case GROUPS_ALIASES.clientsData:
      return 'FieldClientDataAccessLevelDescription';
    case GROUPS_ALIASES.workScheme:
      return 'FieldWorkSchemeDescription';
    default:
      return null;
  }
};


// Возвращает список полей, которые должны быть скрыты в зав-ти
// от значений выбраных на предыдущих шагах
export const getHiddenFieldsAliases = (filledGroups, currentStep) => {
  if (currentStep !== GROUPS_ALIASES.authOnly) return [];
  const selectedWorkScheme = filledGroups
    ?.find(filledGroup => filledGroup?.alias === GROUPS_ALIASES.workScheme)
    ?.fields
    .find(f => f.alias === 'WorkScheme')
    .selectedEnumAlias;

  const selectedClientData = filledGroups
    ?.find(filledGroup => filledGroup?.alias === GROUPS_ALIASES.clientsData)
    ?.fields
    .find(f => f.alias === 'ClientDataAccessLevel')
    .selectedEnumAlias;

  if (selectedWorkScheme === 'DI') {
    return selectedClientData === 'AuthOnly' ? ['HttpsNotificationUrl', 'HttpsJWKSUrl'] : ['HttpsNotificationUrl'];
  }

  return ['HttpsCallbackUrl'];
};

// Решает отображать поле или нет в зав-ти
// от значений выбраных на текущем шаге
export const isShouldShowField = (fieldInfo, groupValues) => {
  if (!fieldInfo.isConditional) return true;
  switch (fieldInfo.alias) {
    /* MOBID-1235 */
    case 'WorkScheme': return false;
    case 'OTPUrl':
      return groupValues.SMSPush === 'SMSOtp' && groupValues.SecurityLevel !== 'LoA3';
    case 'SMSPush':
      return groupValues.SecurityLevel === 'LoA2' && groupValues.WorkScheme === 'SI';
    case 'Seamless':
      return groupValues.WorkScheme === 'DI' && groupValues.SecurityLevel === 'LoA2';
    default:
      return true;
  }
};

export const isShouldShowNotation = (fieldInfo, groupValues, externalValue) => {
  if (!fieldInfo.notation) return false;
  switch (fieldInfo.alias) {
    case 'IncomingIPAddresses':
      return true;
    case 'Seamless':
      return groupValues.SecurityLevel === 'LoA3' && externalValue;
    case 'ClientDataAccessLevel':
      return groupValues.ClientDataAccessLevel !== 'AuthOnly' && externalValue;
    default:
      return false;
  }
};

export const isShouldShowCheckboxNotation = (fieldInfo, groupValues, externalValue) => {
  if (!fieldInfo.checkboxNotation) return false;
  switch (fieldInfo.alias) {
    case 'Seamless':
      return groupValues.WorkScheme === 'DI' && groupValues.SecurityLevel === 'LoA2' && externalValue;
    default:
      return false;
  }
};

const mapAliasToViewType = {
  ContactPersonPhone: VIEW_TYPES.Phone,
  FakePhone: VIEW_TYPES.Phone,
  TargetsDescription: VIEW_TYPES.Textarea,
  // TargetsDescription: VIEW_TYPES.ExtSelect,
};

const getViewType = (fieldType, alias, field) => {
  if (alias === 'WorkScheme') return VIEW_TYPES.Checkbox;
  if (fieldType === FIELD_TYPES.Enum) return VIEW_TYPES.Tab;
  if (fieldType === FIELD_TYPES.String && field.usePredefinedValuesForStringField) return VIEW_TYPES.Select;
  if (fieldType === FIELD_TYPES.Boolean) return VIEW_TYPES.Checkbox;

  return mapAliasToViewType[alias] || VIEW_TYPES.Text;
};

const mapAliasToDisplayValueFn = {
  ContactPersonPhone: ({ stringValue }) => (isNullOrUndefined(stringValue) ?
    stringValue :
    formatPhone(stringValue)),
  FakePhone: ({ stringValue }) => (isNullOrUndefined(stringValue) ?
    stringValue :
    formatPhone(stringValue)),
};

const mapEnumItemsToName = {
  ClientDataAccessLevel: [
    {
      itemAlias: 'AllData',
      name: 'Автозаполнение - Расширенный тариф<br/>от 70 руб без НДС',
    },
    {
      itemAlias: 'Standart',
      name: 'Автозаполнение - Стандартный тариф<br/>от 56 руб без НДС',
    },
    {
      itemAlias: 'Base',
      name: 'Автозаполнение - Базовый тариф<br/>от 43 руб без НДС',
    },
    {
      itemAlias: 'AuthOnly',
      name: 'Только Вход/регистрация<br/>от 1.69 руб без НДС',
    },
    {
      itemAlias: 'KYC',
      name: 'Верификация<br/>15 руб без НДС',
    },
  ],
  Provider: [
    {
      itemAlias: 'Beeline',
      name: 'билайн',
    },
    {
      itemAlias: 'All',
      name: 'билайн, МТС, Мегафон, Теле2',
    },
  ],
  SMSPush: [
    {
      itemAlias: 'SMSUrl',
      name: 'SMS URL',
    },
    {
      itemAlias: 'SMSOtp',
      name: 'SMS OTP',
    },
  ],
  FeeType: [
    {
      itemAlias: 'Prepaid',
      name: 'Авансовый договор',
    },
    {
      itemAlias: 'Postpaid',
      name: 'Постоплата',
    },
  ],
  SecurityLevel: [
    {
      itemAlias: 'LoA2',
      name: 'Простой вход/регистрация',
    },
    {
      itemAlias: 'LoA3',
      name: 'Вход/регистрация с PIN-кодом',
    },
  ],
  WorkScheme: [
    {
      itemAlias: 'DI',
      name: 'Экраны оператора',
    },
    {
      itemAlias: 'SI',
      name: 'Экраны с Вашим дизайном',
    },
  ],
};

export const validateSiteAddress = address => {
  let url;
  try {
    url = new URL(address);
  } catch (_) {
    return address;
  }

  return url.host;
};

export const getTabName = (fieldAlias, itemAlias) => {
  const names = mapEnumItemsToName[fieldAlias];
  return names?.find(n => n.itemAlias === itemAlias)?.name ?? itemAlias;
};

export const getTabLittleText = (fieldAlias, itemAlias) => {
  const names = mapEnumItemsToName[fieldAlias];
  return names?.find(n => n.itemAlias === itemAlias)?.littleText ?? '';
};

const mapBooleanToName = {
  Seamless: ['Отключена', 'Добавлена'],
};

export const getCheckboxDisplayValue = (fieldAlias, values) => {
  const [forFalse, forTrue] = mapBooleanToName[fieldAlias] ?? ['Отключено', 'Включено'];
  return values ? forTrue : forFalse;
};

export const getValue = (field) => {
  const {
    fieldType,
    stringValue,
    selectedEnumAlias,
    integerValue,
    decimalValue,
    booleanValue,
  } = field;
  switch (fieldType) {
    case FIELD_TYPES.Enum: return selectedEnumAlias;
    case FIELD_TYPES.Integer: return integerValue;
    case FIELD_TYPES.Decimal: return decimalValue;
    case FIELD_TYPES.Boolean: return booleanValue;
    case FIELD_TYPES.String:
    default:
      return stringValue;
  }
};

export const setValue = (field, value) => {
  const { fieldType } = field;
  switch (fieldType) {
    case FIELD_TYPES.Enum: return { ...field, selectedEnumAlias: value };
    case FIELD_TYPES.Integer: return { ...field, integerValue: value };
    case FIELD_TYPES.Decimal: return { ...field, decimalValue: value };
    case FIELD_TYPES.Boolean: return { ...field, booleanValue: value };
    case FIELD_TYPES.String:
    default:
      return { ...field, stringValue: value };
  }
};

export const getDisplayValue = (field) => {
  const { alias, fieldType, selectedEnumAlias, booleanValue } = field;

  if (mapAliasToDisplayValueFn[alias]) return mapAliasToDisplayValueFn[alias](field);
  if (fieldType === FIELD_TYPES.Enum) {
    return getTabName(alias, selectedEnumAlias);
  }
  if (fieldType === FIELD_TYPES.Boolean) {
    return getCheckboxDisplayValue(alias, booleanValue);
  }

  return getValue(field);
};

export const excludeReadOnlyFields = (fieldsGroups) => fieldsGroups
  .map((group) => ({
    ...group,
    fields: group.fields.filter((field) => !field.readOnly),
  }));

export const isGroupFilled = (fields) => fields.filter(field => !getFieldInfo(field).isConditional).every(field => getDisplayValue(field));

const chooseValueValidator = composeRequiredValidator(CHOOSE_ONE_OF_THE_OPTIONS_TEXT);

/**
 *
 * @param {object} field
 * @returns {{
 *  alias: string,
 *  readOnly: boolean,
 *  fieldType: string,
 *  viewType: string,
 *  displayValue: string,
 *  enumItems: Array<string>,
 *  name: string,
 *  group?: string,
 *  isWide?: boolean,
 *  placeholder: string,
 * }}
 */
export const getFieldInfo = (field) => {
  const { alias, readOnly, fieldType, enumItems } = field;

  return ({
    uniqueValidator: fieldType === FIELD_TYPES.Enum ? chooseValueValidator : uniqueValidatorFn(alias),
    ...mapFieldInfo[alias],
    alias,
    viewType: getViewType(fieldType, alias, field),
    readOnly,
    fieldType,
    enumItems,
    displayValue: getDisplayValue(field),
  });
};

const fieldsOrderForGroup = {
  CompanyInfo: FIELDS_ORDER_FOR_COMPANY_INFO,
};

const createFieldObj = (alias, stringValue, data) => ({
  readOnly: false,
  fieldType: 'String',
  editedByCustomer: true,
  enumItems: [],
  valueVariants: [],
  usePredefinedValuesForStringField: false,
  integerValue: null,
  selectedEnumAlias: null,
  decimalValue: null,
  booleanValue: null,

  alias,
  stringValue,

  ...data,
});

export const getCompanyInfoGroup = ({ companyData }) => {
  const getAddressStr = () => [
    companyData.city,
    companyData.postIndex,
    companyData.street,
    companyData.building,
    companyData.apartment,
  ].filter(i => i).join(', ');
  return {
    alias: 'Employee-Company',
    fields: [
      createFieldObj('CompanyType', companyData.companyType),
      createFieldObj('CompanyName', companyData.companyName),
      createFieldObj('CompanyNameEn', companyData.companyNameEn),
      createFieldObj('CompanySiteAddress', companyData.siteAddress),
      createFieldObj('CompanyEmail', companyData.email),
      createFieldObj('CompanyINN', companyData.inn),
      createFieldObj('CompanyAddress', getAddressStr()),
      createFieldObj('CompanyContactPerson', companyData.contactPerson),
      createFieldObj('CompanyContactPersonPhone', companyData.contactPersonPhone),
      createFieldObj('CompanyBankName', companyData.bankName),
      createFieldObj('CompanyRS', companyData.rs),
      createFieldObj('CompanyKS', companyData.ks),
      createFieldObj('CompanyBIK', companyData.bik),
    ],
  };
};

export const sortGroupFieldsByOrder = (group) => {
  if (!group) {
    return null;
  }

  if (!fieldsOrderForGroup[group.alias]) {
    return group;
  }

  const sortedFields = [...group.fields].sort((a, b) =>
    fieldsOrderForGroup[group.alias].indexOf(a.alias) - fieldsOrderForGroup[group.alias].indexOf(b.alias));

  return { ...group, fields: sortedFields };
};
