import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Text } from 'components';
import TooltipIcon from './tooltip.svg';

import commonStyles from 'styles/common.pcss';
import styles from './styles.pcss';

const cx = classNames.bind(({ ...commonStyles, ...styles }));

export const DescriptionInput = ({ label, value, tooltip }) => (
  <div className={cx('component')}>
    <Text isInterphases className={cx('label', 'marb-xs')} >
      {label}
    </Text>

    <div className={cx('container')}>
      <Text isInterphases className={cx('value')}>
        {value}
      </Text>

      {tooltip && <TooltipIcon className={cx('tooltip')} />}
    </div>
  </div>
);

DescriptionInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  tooltip: PropTypes.bool,
};

export const DescriptionInputs = ({ inputs }) => (
  <div className={cx('inputs')}>
    {inputs.map((child, index) => <DescriptionInput key={index.toString()} {...child} />)}
  </div>
);

DescriptionInputs.propTypes = {
  inputs: PropTypes.array,
};
