export const MOCK_RESPONSE = {
  ctn: 'ctn_not_found',
  hasRequiredAccessLevel: false,
};

export const mapFakeCtnList = (fakeCtnList) => {
  const ctnInfo = fakeCtnList.find((el) => el.clientDataAccessLevel === 'AuthOnly');

  if (!ctnInfo) return Promise.reject();

  return {
    ctn: ctnInfo.ctn,
    hasRequiredAccessLevel: true,
  };
};

export const mapPaymentStatus = (status) => ('transfered'.includes(status?.toLowerCase())
  ? Promise.resolve()
  : Promise.reject());
