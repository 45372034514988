import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { getDefaultErrorText } from 'store/common/content/selectors';

import { Text } from 'components/Text';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button';
import commonStyles from 'styles/common.pcss';

import classNames from 'classnames/bind';
import styles from './styles.pcss';
const cx = classNames.bind({ ...commonStyles, ...styles });

const Error = ({
  className,
  onClick,
  iconName,
  heading,
  subTitle,
  btnText,
  defaultErrorText,
}) => (
  <div className={cx('wrap', className)}>
    {iconName && <Icon name={iconName} />}
    <Text isTitle isLarge className={cx('mart-xxs')}>
      {heading || defaultErrorText}
    </Text>
    {subTitle && (
      <Text isSmall className={cx('mart-xxs')}>
        {subTitle}
      </Text>
    )}
    {onClick && (
      <Button isWide className={cx('mart-s')} onClick={onClick}>
        {btnText}
      </Button>
    )}
  </div>
);

Error.defaultProps = {
  iconName: 'attention',
  subTitle: 'Доступ только для действующих клиентов.',
  btnText: 'Авторизация отклонена',
};
Error.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.any,
  onClick: PropTypes.func,
  heading: PropTypes.string,
  subTitle: PropTypes.string,
  btnText: PropTypes.string,
  defaultErrorText: PropTypes.string,
};

const mapStateToProps = state => ({
  defaultErrorText: getDefaultErrorText(state),
});

export default connect(mapStateToProps)(Error);
