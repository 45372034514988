export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_AMOUNT_VALUE = 'SET_AMOUNT_VALUE';
export const TOGGLE_TOP_UP_POPUP = 'TOGGLE_TOP_UP_POPUP';
export const TOGGLE_SEND_INVOICE_POPUP = 'TOGGLE_SEND_INVOICE_POPUP';
export const SET_USER_CTN = 'SET_USER_CTN';
export const SET_USER_ACCESS_LEVEL = 'SET_USER_ACCESS_LEVEL';
export const SET_TRANS_ID = 'SET_TRANS_ID';
export const SET_POPUP_STEP = 'SET_POPUP_STEP';
export const SET_MAKE_PROCEED_PAYMENT_REQUEST = 'SET_MAKE_PROCEED_PAYMENT_REQUEST';

export const MODAL_STEPS = {
  amountForm: 'AMOUNT_FORM',
  cardView: 'CARD_VIEW',
  pending: 'PENDING',
  fail: 'FAILED',
  success: 'SUCCESS',
};
